export interface IInsightsResponse<T> {
  data?: T;
  filters?: IInsightsFilter;
  widget?: string;
}

export interface IInsightsFilter {
  from_date?: string | null;
  job_ids?: string;
  to_date?: string | null;
  unit_ids?: string[];
}
export interface ICandidatesOverviewInsights {
  total?: number;
  hired?: number;
  in_process?: number;
  rejected?: number;
  waiting_list?: number;
}

export interface ITimeOverviewInsights {
  interval_to_hire?: string;
}

export interface ISourcingChannelOverviewInsights {
  dataset?: ISourcingChannelOverviewInsightsRow[];
  attraction_rate?: number;
  drop_off_rate?: number;
}

export interface ISourcingChannelOverviewInsightsRow {
  applications?: number;
  logo?: string;
  source?: string;
  views?: number;
  views_conversion?: number;
}

export interface ISatisfactionOverviewInsights {
  experience_score?: number;
  surveys_completed?: number;
}

export interface IInsightsCardData<T> {
  data?: T;
  loading?: boolean;
  failed?: boolean;
}


export interface ICandidateInteractionsOverviewInsights {
  job_interviews_done?: number;
  messages_sent_to_candidates?: number;
  one_way_video_interviews_recorded?: number;
  reference_checks_completed?: number;
  screening_questions_answered?: number;
}

export interface ICandidatesInsights {
  applicants_needed_per_hire?: number;
  average_candidate_rating?: number;
  hired?: number;
  in_process?: number;
  qualified_candidates_per_hire?: number;
  rejected?: number;
  total?: number;
  total_hired_ratio?: number;
  waiting_list?: number;
  total_in_process_ratio?: number;
  total_rejected_ratio?: number;
  total_waiting_list_ratio?: number;
}

export interface ICandidateInteractionsInsights {
  dataset?: ICandidateInteractionsInsight[];
  job_interviews_done?: number;
  messages_seen_by_candidates?: number;
  messages_sent_to_candidates?: number;
  one_way_video_interviews_recorded?: number;
  reference_checks_completed?: number;
  screening_questions_answered?: number;
}

export interface ICandidateInteractionsInsight {
  interval_to_decision?: string;
  interval_to_done?: string;
  interval_to_first_request?: string;
  name?: string;
  num_requests?: number;
  num_requests_done?: number;
  num_requests_done_ratio?: number;
}

export interface IHiringProcessInsights {
  dataset?: IHiringProcessInsightsTableRow[];
  interval_in_in_process?: string;
  interval_to_hire?: string;
  interval_to_qualify?: string;
  interval_to_reject?: string;
  interval_to_waiting_list?: string;
}

export interface IHiringProcessInsightsTableRow {
  name?: string;
  num_moves?: number;
  rejected?: number;
  yield_ratio?: number;
  time_in_status?: string;
  time_to_get_in_status?: string;
}

export interface ICandidateExperiencesInsight {
  dataset?: ICandidateExperiencesInsightsTableRow[];
  surveys_completed?: number;
  experience_score?: number;
  num_promoters?: number;
  num_detractors?: number;
  num_passives?: number;
  random_comment?: string;
}

export interface ICandidateExperiencesInsightsTableRow {
  name?: string;
  passives?: number;
  promoters?: number;
  detractors?: number;
  score?: number;
  surveys_completed?: number;
  surveys_sent?: number;
}

export interface ISourcingChannelsChartInsights {
  dataset?: ISourcingChannelChartItem[];
  labels?: string[];
}

export interface ISourcingChannelChartItem {
  data?: number[];
  color?: string;
  logo?: string;
  name?: string;
}

export interface ISourcingChannelsInsights {
  chart?: ISourcingChannelsChartInsights;
  table?: ISourcingChannelsTableInsights;
  funnel?: ISourcingChannelsFunnelInsights;
}

export interface ISourcingChannelsFunnelInsights {
  application_sent?: number;
  hired?: number;
  views?: number;
  qualified?: number;
}

export interface ISourcingChannelsTableInsights {
 dataset?: ISourcingChannelsTableRowInsights[];
}

export interface ISourcingChannelsTableRowInsights {
  applied_conversion?: number;
  applications?: number;
  color?: string;
  views?: number;
  logo?: string;
  qual_cand?: number;
  qualified_conversion?: number;
  hired_cand?: number;
  source?: string;
  source_color?: string;
  source_logo?: string;
  user_ids?: string[];
  views_conversion?: number;
  avg_rating?: number;
}

export interface IJobFilter {
  id?: number;
  internal_title?: string;
  position_function_id?: number;
  title?: string;
}

export interface IInsightsStoreFilter {
  api_filter?: IInsightsStoreApiFilter;
  url_filter?: IInsightsStoreURLFilter;
}

export interface IInsightsStoreApiFilter extends IInsightsStoreBaseFilter {
  to_date?: string;
  from_date?: string;
}
export interface IInsightsStoreURLFilter extends IInsightsStoreBaseFilter {
  date_range?: string;
}

export interface IInsightsStoreBaseFilter {
  job_ids?: string;
  unit_ids?: string;
  position_function_ids?: string;
}

export enum OverviewCardId {
  candidates = 'candidates',
  time = 'time',
  sourcingChannels = 'sourcingChannels',
  conversion = 'conversion',
  candidateInterations = 'candidateInterations',
  satisfaction = 'satisfaction',
}

export enum ProcessesCardId {
  candidates = 'candidates',
  hiringProcess = 'hiringProcess',
  candidatesInteractions = 'candidatesInteractions',
  candidatesExperience = 'candidatesExperience',
}

export enum SourcingCardId {
  sourcingChannels = 'sourcingChannels',
  deviceStatistics = 'deviceStatistics',
}

export enum DaysFilterOptions {
  ALL_TIME = '',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_2_WEEKS = 'LAST_2_WEEKS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_180_DAYS = 'LAST_180_DAYS',
  CURRENT_CALENDAR_YEAR = 'CURRENT_CALENDAR_YEAR',
  LAST_CALENDAR_YEAR = 'LAST_CALENDAR_YEAR',
  SINCE_LAST_TIME_SET_TO_LIVE = 'SINCE_LAST_TIME_SET_TO_LIVE',
  DATE_RANGE = 'DATE_RANGE',
}

export enum FiltersIds {
  DAYS,
  UNITS,
  ROLES,
  JOBS,
}

export enum ViewStates {
  OVERVIEW,
  SOURCING,
  PROCESSES,
}

export interface ViewStateChip {
  id: ViewStates;
  value: boolean;
}

export interface IProcessCardsOpenStates {
  [ProcessesCardId.candidates]?: boolean;
  [ProcessesCardId.hiringProcess]?: boolean;
  [ProcessesCardId.candidatesInteractions]?: boolean;
  [ProcessesCardId.candidatesExperience]?: boolean;
}

export interface ISourcingCardsOpenStates {
  [SourcingCardId.sourcingChannels]?: boolean;
  [SourcingCardId.deviceStatistics]?: boolean;
}
