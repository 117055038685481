import { action, observable } from 'mobx';
import IUnit, { IUnitEmployment } from '../mj-models/unit.interface';
import UnitApi from '../mj-api/unit.api';

export default interface ISelectUnitEmploymentStore {
  units: IUnit[];
  searchTerm: string;
  isLoading: boolean;
  isLoadingMore: boolean;
  pageSize: number;

  getUnitEmployments: (page: number, pageSize: number) => void;
  setSearchTerm: (searchTerm: string) => void;
  loadMore: () => void;
}
export class SelectUnitEmploymentStore implements ISelectUnitEmploymentStore {
  @observable public units: IUnit[] = [];
  @observable public isLoading: boolean = false;
  @observable public isLoadingMore: boolean = false;
  @observable public searchTerm: string = '';
  @observable public pageSize: number = 50;

  private page: number = 1;
  private maxPage: number = 0;

  private unitApi: UnitApi;

  constructor(unitApi: UnitApi) {
    this.unitApi = unitApi;
  }

  @action('GET UNIT EMPLOYMENTS')
  public getUnitEmployments = async (page: number, pageSize: number) => {
    try {
      this.page = page;
      const response = await this.unitApi.getCurrentUserUnitEmployments(
        pageSize,
        page,
        this.searchTerm
      );
      const count = response.count;
      this.maxPage = Math.ceil(count / this.pageSize);
      if (page === 1) {
        this.units = response.results;
      } else {
        this.units = [...this.units, ...response.results];
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action('SET SEARCH TERM')
  public setSearchTerm = async (searchTerm: string) => {
    this.isLoading = true;
    this.searchTerm = searchTerm;
    await this.getUnitEmployments(1, this.pageSize);
    this.isLoading = false;
  };

  @action('LOAD MORE')
  public loadMore = async () => {
    if (this.page < this.maxPage && !this.isLoading) {
      this.isLoadingMore = true;
      await this.getUnitEmployments(this.page + 1, this.pageSize);
      this.isLoadingMore = false;
    }
  };
}
