import {
  ApplicationBulkDelete,
  IApplication,
  IApplicationExport,
  IApplicationRating,
  IApplicationWebtempExportResponse,
} from '../mj-models/application.interface';
import { IUserDocument } from '../mj-models/user.interface';
import { IESPage, IPage, IPaginator } from '../mj-models/utils.interface';
import BaseApi from './base.api';
import {
  JobProcessAppQuestionAnswer,
  JobProcessAppScreeningQuestion,
} from '../mj-models/process.interface';
import { MotimateCourseCompletion } from '../mj-models/motimate.interface';
import { ApplicationJobMove } from '../mj-models/application.interface';
import { IUnitAttribute, IUserAttribute } from '../mj-models/business-settings.interface';
import { CancelTokenSource } from 'axios';
import { MjFilterParsedQuery } from '../mj-utils/screening.filter.utils';

export default class ApplicationApi extends BaseApi {
  public createApplication = (jobId: number, application: IApplication): Promise<IApplication> =>
    this.axios
      .post(`${this.baseUrl}jobs/${jobId}/applications/`, application)
      .then((response) => response.data);

  public deleteApplication = (jobId: number, applicationId: number): Promise<boolean> =>
    this.axios
      .delete(`${this.baseUrl}jobs/applications/${applicationId}/`)
      .then((response) => response.status === 204);

  public moveApplicationsToAnotherJob = (
    movingApplications: ApplicationJobMove[]
  ): Promise<boolean> =>
    this.axios
      .post(
        `${this.baseUrl}jobs/${movingApplications[0].old_job_id}/application-job-moves/`,
        movingApplications
      )
      .then((response) => response.status === 201);

  public checkIfApplicationExists = (jobId: number): Promise<boolean> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/applications/exists/`)
      .then((response) => response.data);

  public checkIfApplicationExistsWithApplicationId = (applicationId: number): Promise<boolean> =>
    this.axios
      .get(`${this.baseUrl}jobs/applications/${applicationId}/exists/`)
      .then((response) => response.data);

  public getVideoInterviewQuestions = (
    applicationId: number
  ): Promise<JobProcessAppScreeningQuestion[]> =>
    this.axios
      .get(`${this.baseUrl}jobs/applications/${applicationId}/video-questions/`)
      .then((response) => response.data);

  public getVideoInterviewAnswers = (
    applicationId: number
  ): Promise<JobProcessAppQuestionAnswer[]> =>
    this.axios
      .get(`${this.baseUrl}jobs/applications/${applicationId}/videointerviewanswers/`)
      .then((response) => response.data);

  public getApplicationDetail = (applicationId: number): Promise<IApplication> =>
    this.axios
      .get(`${this.baseUrl}users/applications/${applicationId}/`)
      .then((response) => response.data);

  public getJobApplicationDetail = (jobId: number, applicationId: number): Promise<IApplication> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/applications/${applicationId}/`)
      .then((response) => response.data);

  public getApplicationRatings = (applicationId: number): Promise<IApplicationRating[]> =>
    this.axios
      .get(`${this.baseUrl}ratings/application/${applicationId}/application-ratings/`)
      .then((response) => response.data);

  public sendApplicationRating = (
    applicationId: number,
    rating: IApplicationRating
  ): Promise<IApplicationRating> =>
    this.axios
      .post(`${this.baseUrl}ratings/application/${applicationId}/application-ratings/`, rating)
      .then((response) => response.data);

  public createVideoInterviewAnswer = (
    answer: FormData,
    application_id: number,
    codecs: string
  ): Promise<JobProcessAppQuestionAnswer> =>
    this.axios
      .post(
        `${this.baseUrl}jobs/applications/${application_id}/videointerviewanswers/?codecs=${codecs}`,
        answer
      )
      .then((response) => response.data);

  public getApplicantUserDocuments = async (applicationId: number): Promise<IUserDocument[]> =>
    this.axios
      .get(`${this.baseUrl}jobs/applications/${applicationId}/documents/`)
      .then((response) => response.data);

  public getApplicationAnswers = async (
    applicationId: number
  ): Promise<JobProcessAppQuestionAnswer[]> =>
    this.axios
      .get(`${this.baseUrl}jobs/answers/${applicationId}/`)
      .then((response) => response.data);

  public getApplicationsByProcessStepId = (
    jobId: number,
    query: string,
    searchValue: string,
    processStepId: number,
    page: number,
    pageSize: number = 15,
    orderingQuery: string = '-num_unread_notifications,-updated_at',
    cancelTokenSource?: CancelTokenSource
  ): Promise<IPage<IApplication>> =>
    this.axios
      .get(
        `${this.baseUrl}jobs/${jobId}/application-list/?ql=${query}&search=${searchValue}&process_step_id=${processStepId}&page=${page}&page_size=${pageSize}&ordering=${orderingQuery}`,
        cancelTokenSource ? { cancelToken: cancelTokenSource.token } : undefined
      )
      .then((response) => response.data);

  public getApplicationsTablePagination = async (
    jobId: number,
    query: string,
    searchValue: string,
    paginator: IPaginator,
    ordering: string
  ): Promise<IPage<IApplication>> =>
    this.axios.get(
      `${this.baseUrl}jobs/${jobId}/application-list-search/?ql=${query}&page=${
        paginator.page
      }&page_size=${
        paginator.itemsPerPage
        // all rejected must go in the end
      }&search=${searchValue}&ordering=process_step__is_failure${ordering ? ',' + ordering : ''}`
    );

  public getApplications = (args: {
    searchValue: string;
    stepId?: number;
    jobId: number;
    page: number;
    query: MjFilterParsedQuery[];
    pageSize: number;
    cancelTokenSource?: CancelTokenSource;
    sort?: string;
  }): Promise<IPage<IApplication>> => {
    let params = {
      page: args.page.toString(),
      page_size: args.pageSize.toString(),
    } as Record<string, string>;

    if (args.jobId) {
      params = { ...params, job_id: args.jobId.toString() };
    }

    if (args.searchValue.length > 0) {
      // Must add asterix to achieve wildcard behaviour
      params = { ...params, search_query_string: `*${args.searchValue}*` };
    }

    if (args.stepId) {
      if (
        args.query.findIndex(
          (filter: MjFilterParsedQuery) => filter.field === 'process_step_id'
        ) !== -1
      ) {
        // If job process step is already filtered with stepId we use terms filter in order
        // to make sure the board columns shows correct applicants.
        params = {
          ...params,
          process_step_id__term: args.stepId.toString(),
        };
      } else {
        params = { ...params, process_step_id: args.stepId.toString() };
      }
    }

    const queryParams = new URLSearchParams({ ...params });

    for (const q of args.query) {
      queryParams.append(q.field, q.query);
    }

    if (args.sort) {
      const sortQueryArray: string[] = args.sort.split(',');
      for (const sort of sortQueryArray) {
        queryParams.append('ordering', sort);
      }
    }

    return this.axios
      .get(`${this.baseUrl}jobs/${args.jobId}/application-list-search/?${queryParams.toString()}`, {
        cancelToken: args.cancelTokenSource?.token,
      })
      .then((response) => response.data);
  };

  public updateApplicationStatusChanges = (
    jobId: number,
    applications: IApplication[]
  ): Promise<any> =>
    this.axios
      .put(`${this.baseUrl}jobs/${jobId}/application-bulk-status-change/`, {
        applications,
      })
      .then((response) => response.data);

  public getMotimateCourseCompletion = (
    motimateOrganizationId: number | string,
    userId: number
  ): Promise<MotimateCourseCompletion> =>
    this.axios
      .get(
        `${this.baseUrl}motimate/organization/${motimateOrganizationId}/user/${userId}/course-completion/`
      )
      .then((response) => response.data);

  public getLearnifierProjectParticipations = (applicationId: number): Promise<any[]> =>
    this.axios
      .get(
        `${this.baseUrl}integrations/applications/${applicationId}/learnifier/project-participations/`
      )
      .then((response) => response.data);

  public sendLearnifierLoginLink = (
    applicationId: number,
    projectId: number,
    participationId: number
  ): Promise<any> =>
    this.axios
      .post(
        `${this.baseUrl}integrations/applications/${applicationId}/learnifier/projects/${projectId}/participations/${participationId}/send-invite-link/`
      )
      .then((response) => response.data);

  public getApplicationPdf = (jobId: number, applicationId: number): Promise<Blob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/applications/${applicationId}/pdf-export/`, {
        responseType: 'blob',
      })
      .then((response) => response.data);

  public createApplicationExportXlsx = (
    jobId: number,
    applicationExportRequest: IApplicationExport
  ): Promise<IApplicationExport> =>
    this.axios
      .post(`${this.baseUrl}jobs/${jobId}/application-export/xlsx/`, applicationExportRequest)
      .then((response) => response.data);

  public exportApplicantToWebtemp = (
    applicationId: number
  ): Promise<IApplicationWebtempExportResponse> =>
    this.axios
      .get(`${this.baseUrl}jobs/applications/${applicationId}/webtemp-export/`)
      .then((response) => response.data);

  public onBulkDeleteApplications = (
    jobId: number,
    bulkDeleteApplications: ApplicationBulkDelete[]
  ): Promise<ApplicationBulkDelete[]> =>
    this.axios
      .post(`${this.baseUrl}jobs/${jobId}/application-deletion/`, bulkDeleteApplications)
      .then((response) => response.data);

  public getApplicationAttributes = (
    applicationId: number,
    pageSize = 200
  ): Promise<IPage<IUnitAttribute>> =>
    this.axios
      .get(`${this.baseUrl}jobs/applications/${applicationId}/attributes/?page_size=${pageSize}`)
      .then((response) => response.data);

  public createApplicationAttribute = (userAttribute: IUserAttribute): Promise<IUserAttribute> =>
    this.axios
      .post(`${this.baseUrl}users/attributes/`, userAttribute)
      .then((response) => response.data);

  public updateApplicationAttribute = (
    userAttribute: IUserAttribute
  ): Promise<IPage<IUserAttribute>> =>
    this.axios
      .put(`${this.baseUrl}users/attributes/${userAttribute.id}/`, userAttribute)
      .then((response) => response.data);
}
