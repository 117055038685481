import { action, observable } from 'mobx';
import UnitApi from '../mj-api/unit.api';
import IUnit, {
  ICareerPage,
  IUnitEmployment,
  IUsersAssignmentsByJobId,
} from '../mj-models/unit.interface';
import { ScreeningQuestionSuggestion } from '../mj-models/process.interface';
import AnalyticsApi from '../mj-api/analytics.api';
import INotificationStore from './notification.store';
import { IUser } from '../mj-models/user.interface';

export default interface IUnitStore {
  careerPage: ICareerPage;
  currentUnit: IUnit;
  availableUnits: IUnit[];
  unitWithAnalyticsAttributes: IUnit;
  unitEmployments: IUnitEmployment[];

  unitEmploymentsUsers: IUser[] | null;
  jobAssignmentsUsersByJobId: IUsersAssignmentsByJobId;

  fetchCareerPage: (unitId: number) => Promise<boolean>;
  getUnit: (unitId: number) => Promise<IUnit | undefined>;
  fetchUnitTree: (unitId: number) => Promise<boolean>;
  fetchAccessibleUnitTree: (unitId: number) => Promise<IUnit | undefined>;
  fetchUnitEmployments: (unitId: number) => Promise<boolean>;
  searchUnitEmployments: (
    unitId: number,
    term?: string
  ) => Promise<IUnitEmployment[] | false>;
  sendInvitation: (invitation: any) => Promise<boolean>;
  resendInvitation: (unitId: number, assignmentId: number) => Promise<boolean>;
  fetchUnitRecManAttributes: (
    unitId: number
  ) => Promise<ScreeningQuestionSuggestion[]>;
  fetchUnitLearnifierProjects: (unitId: number) => Promise<any>;
  fetchUnitPlandayDepartments: (
    unitId: number,
    limit: number,
    offset: number
  ) => Promise<any>;
  fetchUnitPlandayEmployeeGroups: (
    unitId: number,
    limit: number,
    offset: number
  ) => Promise<any>;
  fetchUnitAnalyticsAttributes: (unitId: number) => Promise<IUnit | undefined>;
  fetchEntireUnitTreeFlattened: (unitId: number) => Promise<IUnit[]>;
  deleteUnitEmployment: (
    unitId: number,
    unitEmploymentId: number
  ) => Promise<boolean>;
  updateUnitEmployment: (
    unitEmployment: IUnitEmployment
  ) => Promise<IUnitEmployment | undefined>;
  flattenUnitTree: (unit: IUnit) => IUnit[];
}
export class UnitStore implements IUnitStore {
  @observable public careerPage: ICareerPage = {};
  @observable public currentUnit: IUnit = {};
  @observable public availableUnits: IUnit[] = [];
  @observable public unitEmployments: IUnitEmployment[] = [];
  @observable public unitWithAnalyticsAttributes: IUnit = {};

  @observable public unitEmploymentsUsers: IUser[] | null = null;
  @observable public jobAssignmentsUsersByJobId: IUsersAssignmentsByJobId = {};

  public unitApi: UnitApi;
  public analyticsApi: AnalyticsApi;
  public notificationStore: INotificationStore;

  constructor(
    unitApi: UnitApi,
    analyticsApi: AnalyticsApi,
    notificationStore: INotificationStore
  ) {
    this.unitApi = unitApi;
    this.analyticsApi = analyticsApi;
    this.notificationStore = notificationStore;
  }

  @action('FETCH PUBLIC CAREER PAGE')
  public fetchCareerPage = async (unitId: number): Promise<boolean> => {
    try {
      this.careerPage = await this.unitApi.fetchCareerPage(unitId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('FETCH UNIT TREE')
  public fetchUnitTree = async (unitId: number): Promise<boolean> => {
    try {
      const resp = await this.unitApi.fetchUnitTree(unitId);
      this.availableUnits = this._flatten([resp]);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('FETCH ACCESSIBLE UNIT TREE')
  public fetchAccessibleUnitTree = async (
    unitId: number
  ): Promise<IUnit | undefined> => {
    try {
      return await this.unitApi.fetchAccessibleUnitTree(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH ENTIRE UNIT TREE FLATTENED')
  public fetchEntireUnitTreeFlattened = async (
    unitId: number
  ): Promise<IUnit[]> => {
    try {
      const resp = await this.unitApi.fetchEntireUnitTree(unitId);
      return this._flatten([resp]);
    } catch (error) {
      return [];
    }
  };

  @action('FETCH UNIT EMPLOYMENTS')
  public fetchUnitEmployments = async (unitId: number): Promise<boolean> => {
    try {
      const resp: any = await this.unitApi.fetchUnitEmployments(unitId);
      this.unitEmployments = resp.results;
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('SEARCH UNIT EMPLOYMENTS')
  public searchUnitEmployments = async (
    unitId: number,
    term = '',
    pageSize = 700
  ): Promise<IUnitEmployment[] | false> => {
    try {
      const resp: any = await this.unitApi.searchUnitEmployments(
        unitId,
        term,
        pageSize
      );
      return resp.results;
    } catch (error) {
      return false;
    }
  };

  @action('SEND INVITATION')
  public sendInvitation = async (invitation: any): Promise<boolean> => {
    try {
      const response: any = await this.unitApi.sendInvitation(invitation);
      const assignment = {
        user: { ...response.user } as IUser,
        has_accepted_invitation: response.has_accepted_invitation,
        has_seen_intro: response.has_seen_intro,
        role: response.role,
      };
      this.unitEmployments = [...this.unitEmployments, assignment];
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('RESEND INVITATION')
  public resendInvitation = async (
    unitId: number,
    assignmentId: number
  ): Promise<boolean> => {
    try {
      await this.unitApi.resendInvitation(unitId, assignmentId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('GET UNIT')
  public getUnit = async (unitId: number): Promise<IUnit | undefined> => {
    try {
      return await this.unitApi.getUnit(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH UNIT RECMAN ATTRIBUTES')
  public fetchUnitRecManAttributes = async (
    unitId: number
  ): Promise<ScreeningQuestionSuggestion[]> => {
    try {
      const recManQuestionRes = await this.unitApi.getRecManAttributes(unitId);
      return recManQuestionRes.questions;
    } catch (error) {
      return [];
    }
  };

  @action('FETCH UNIT LEARNIFIER PROJECTS')
  public fetchUnitLearnifierProjects = async (unitId: number): Promise<any> => {
    try {
      return await this.unitApi.getLearnifierProjects(unitId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return [];
    }
  };

  @action('FETCH UNIT PLANDAY DEPARTMENTS')
  public fetchUnitPlandayDepartments = async (
    unitId: number,
    limit: number,
    offset: number
  ): Promise<any> => {
    try {
      return await this.unitApi.getPlandayDepartments(unitId, limit, offset);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return [];
    }
  };

  @action('FETCH UNIT PLANDAY EMPLOYEE GROUPS')
  public fetchUnitPlandayEmployeeGroups = async (
    unitId: number,
    limit: number,
    offset: number
  ): Promise<any> => {
    try {
      return await this.unitApi.getPlandayEmployeeGroups(unitId, limit, offset);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return [];
    }
  };

  @action('FETCH UNIT ANALYTICS ATTRIBUTES')
  public fetchUnitAnalyticsAttributes = async (
    unitId: number
  ): Promise<IUnit | undefined> => {
    try {
      return await this.analyticsApi.fetchUnitAnalyticsAccounts(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH UNIT ANALYTICS ATTRIBUTES')
  public deleteUnitEmployment = async (
    unitId: number,
    unitEmploymentId: number
  ): Promise<boolean> => {
    try {
      await this.unitApi.deleteUnitEmployment(unitId, unitEmploymentId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('FETCH UNIT ANALYTICS ATTRIBUTES')
  public updateUnitEmployment = async (
    unitEmployment: IUnitEmployment
  ): Promise<IUnitEmployment | undefined> => {
    try {
      return await this.unitApi.updateUnitEmployment(unitEmployment);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('FLATTEN UNIT TREE')
  public flattenUnitTree = (unit: IUnit): IUnit[] => {
    return this._flatten([unit]);
  };

  private _flatten = (unitTreeArray: any[]) => {
    return unitTreeArray.reduce((unitArr, { children, ...rest }) => {
      unitArr.push(rest);
      if (children) {
        unitArr.push(...this._flatten(children));
      }
      return unitArr;
    }, []);
  };
}
