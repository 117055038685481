











import { Component, Prop, Vue } from 'vue-property-decorator';

const widthValues = [
  'fill',
  '1of12',
  '2of12',
  '3of12',
  '4of12',
  '5of12',
  '6of12',
  '7of12',
  '8of12',
  '9of12',
  '10of12',
  '11of12',
  '12of12',
];

const flexWrapValues = [
  'inherit',
  'initial',
  'unset',
  'nowrap',
  'wrap',
  'wrap-reverse',
];

const justifyContentValues = [
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'left',
  'right',
];

@Component
export default class MjGridCell extends Vue {
  @Prop({ default: 'div', type: String }) public readonly tag!: string;
  @Prop({
    default: 'fill',
    type: String,
    validator: (value: string) => widthValues.includes(value),
  })
  public readonly width!: string;
  @Prop({ default: 'center', type: String }) public readonly align!: string;

  @Prop({ default: 'start', type: String })
  public readonly justifyContent!: string;

  @Prop({ default: false, type: Boolean }) public readonly isColumn!: boolean;
  @Prop({
    default: 'unset',
    type: String,
    validator: (value: string) => flexWrapValues.includes(value),
  })
  public readonly flexWrap!: string;

  get computedWidth() {
    return `cell-${this.width}`;
  }

  get direction() {
    return this.isColumn ? 'column' : '';
  }
}
