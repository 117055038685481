import { render, staticRenderFns } from "./MjBrowserRedirect.vue?vue&type=template&id=076974c2&scoped=true&"
import script from "./MjBrowserRedirect.vue?vue&type=script&lang=ts&"
export * from "./MjBrowserRedirect.vue?vue&type=script&lang=ts&"
import style0 from "./MjBrowserRedirect.vue?vue&type=style&index=0&id=076974c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../apps/application-process/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "076974c2",
  null
  
)

export default component.exports