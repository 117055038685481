












































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MobileAppLink } from '@mojob/mj-shared/mj-models/utility.interface';

@Component({
  inheritAttrs: true,
})
export default class MjAppStoreDownload extends Vue {
  @Prop({ default: '200', type: String })
  public readonly width!: string;

  @Prop({ default: MobileAppLink.APPLICANT_APPSTORE, type: String })
  public readonly appStoreLink!: MobileAppLink;

  private get getWidth(): string {
    return `${this.width}px`;
  }
}
