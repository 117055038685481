




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: true,
})
export default class MjDivider extends Vue {
  @Prop({ default: false, type: Boolean }) public readonly vertical!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly thick!: boolean;
  @Prop({ type: String }) public readonly verticalMargin!: string;
  @Prop({ default: false, type: Boolean }) public readonly noMargin!: boolean;
  @Prop({ default: '#000000', type: String })
  public readonly backgroundColor!: string;

  public data() {
    return {};
  }

  get styleObject() {
    return {
      margin: this.verticalMargin ? `${this.verticalMargin} 0` : 'unset',
      backgroundColor: this.backgroundColor,
    };
  }
  get classes() {
    return {
      'mj-divider--vertical': this.vertical,
      'mj-divider--vertical--thick': this.vertical && this.thick,
      'mj-divider--horizontal': !this.vertical,
      'mj-divider--horizontal--thick': !this.vertical && this.thick,
      'mj-divider--no-margin': this.noMargin,
    };
  }
}
