import {
  IReferenceAnswer,
  IReferenceContact,
  IReferenceQuestion,
  IReferenceSurvey,
} from '../mj-models/references.interface';
import { action, observable } from 'mobx';
import ReferenceApi from '../mj-api/reference.api';
import { downloadArrayBufferAsPdf } from '../mj-utils/files.utils';

interface IReferenceRequestInfo {
  contacts?: number;
  daysToProvideContacts?: number;
  jobTitle?: string;
}

interface IReferenceSurveyReportInfo {
  referenceSurvey?: IReferenceSurvey;
  referenceSurveyQuestions?: IReferenceQuestion[];
  referenceSurveyQuestionsAnswers?: IReferenceQuestion[];
}

export enum REF_SURVEY_PAGES {
  REFERENCE_CONTACTS = 0,
  SURVEY_REPORT = 1,
}

export default interface IReferenceStore {
  referenceRequestInfo: IReferenceRequestInfo;
  referenceSurveyReportInfo: IReferenceSurveyReportInfo;
  ref_survey_page: REF_SURVEY_PAGES;
  getSurvey: (surveyId: string) => Promise<IReferenceSurvey | false>;
  updateSurvey: (
    surveyId: string,
    referenceContacts: IReferenceContact[]
  ) => Promise<IReferenceContact | false>;
  getReferenceSurveyQuestions: (
    referenceSurveyId: string
  ) => Promise<IReferenceQuestion[] | false>;
  getReferenceSurveyReport: (
    referenceSurveyId: string
  ) => Promise<IReferenceQuestion[] | false>;
  patchReferenceSurvey: (
    referenceSurveyId: string,
    survey: IReferenceSurvey
  ) => Promise<IReferenceSurvey | false>;
  patchReferenceContact: (
    referenceContact: IReferenceContact
  ) => Promise<IReferenceContact | false>;
  callSurvey: (surveyId: string, contactId: string) => Promise<boolean>;
  declineSurvey: (surveyId: string, contactId: string) => Promise<boolean>;
  submitSurvey: (
    surveyId: string,
    answers: IReferenceAnswer[]
  ) => Promise<IReferenceAnswer[] | false>;
  hubspotAddPassiveLead: (
    referenceContact: IReferenceContact
  ) => Promise<boolean>;
  hubspotAddLearnMoreLead: (
    referenceContact: IReferenceContact
  ) => Promise<boolean>;
  changeReferenceSurveyReportTab?: (newPage: REF_SURVEY_PAGES) => void;
  printReferenceSurveyReport: (
    referenceSurveyId: string,
    filename: string
  ) => Promise<any>;
}

export class ReferenceStore implements IReferenceStore {
  public referenceApi: ReferenceApi;
  @observable public referenceRequestInfo: IReferenceRequestInfo = {};
  @observable public referenceSurveyReportInfo: IReferenceSurveyReportInfo = {
    referenceSurveyQuestions: [],
  };
  @observable public ref_survey_page: REF_SURVEY_PAGES =
    REF_SURVEY_PAGES.REFERENCE_CONTACTS;
  @observable public changeReferenceSurveyReportTab?: (
    newPage: REF_SURVEY_PAGES
  ) => void = undefined;

  constructor(referenceApi: ReferenceApi) {
    this.referenceApi = referenceApi;
  }

  @action('UPDATE SURVEY')
  public updateSurvey = async (
    surveyId: string,
    referenceContacts: IReferenceContact[]
  ): Promise<IReferenceContact | false> => {
    try {
      const result = await this.referenceApi.updateSurvey(
        surveyId,
        referenceContacts
      );

      return result;
    } catch (error) {
      return false;
    }
  };

  @action('GET REFERENCE SURVEY')
  public getSurvey = async (
    referenceSurveyId: string
  ): Promise<IReferenceSurvey | false> => {
    try {
      return await this.referenceApi.getSurvey(referenceSurveyId);
    } catch (error) {
      return false;
    }
  };

  @action('GET REFERENCE SURVEY QUESTIONS')
  public getReferenceSurveyQuestions = async (
    referenceSurveyId: string
  ): Promise<IReferenceQuestion[] | false> => {
    try {
      const data = await this.referenceApi.getReferenceSurveyQuestions(
        referenceSurveyId
      );
      return data.results;
    } catch (error) {
      return false;
    }
  };

  @action('GET REFERENCE SURVEY REPORT')
  public getReferenceSurveyReport = async (
    referenceSurveyId: string
  ): Promise<IReferenceQuestion[] | false> => {
    try {
      return await this.referenceApi.getReferenceSurveyReport(
        referenceSurveyId
      );
    } catch (error) {
      return false;
    }
  };

  @action('PATCH REFERENCE SURVEY')
  public patchReferenceSurvey = async (
    referenceSurveyId: string,
    survey: IReferenceSurvey
  ): Promise<IReferenceSurvey | false> => {
    try {
      return await this.referenceApi.patchReferenceSurvey(
        referenceSurveyId,
        survey
      );
    } catch (error) {
      return false;
    }
  };

  @action('PATCH REFERENCE CONTACT')
  public patchReferenceContact = async (
    referenceContact: IReferenceContact
  ): Promise<IReferenceContact | false> => {
    try {
      return await this.referenceApi.patchReferenceContact(referenceContact);
    } catch (error) {
      return false;
    }
  };

  @action('REQUEST CALL SURVEY')
  public callSurvey = async (
    surveyId: string,
    contactId: string
  ): Promise<boolean> => {
    try {
      await this.referenceApi.callSurvey(surveyId, contactId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('DECLINE SURVEY')
  public declineSurvey = async (
    surveyId: string,
    contactId: string
  ): Promise<boolean> => {
    try {
      await this.referenceApi.declineSurvey(surveyId, contactId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('SUBMIT REFERENCE SURVEY')
  public submitSurvey = async (
    surveyId: string,
    answers: IReferenceAnswer[]
  ): Promise<IReferenceAnswer[] | false> => {
    try {
      const result = await this.referenceApi.submitSurvey(surveyId, answers);
      return result;
    } catch (error) {
      return false;
    }
  };

  @action('HUBSPOT - ADD PASSIVE LEAD')
  public hubspotAddPassiveLead = async (
    referenceContact: IReferenceContact
  ): Promise<boolean> => {
    try {
      const formId = '257cc59b-5eee-46be-95c5-1553a3bca21b';

      await this.referenceApi.submitReferenceContactToHubspot(
        formId,
        referenceContact
      );

      return true;
    } catch {
      return false;
    }
  };

  @action('HUBSPOT - ADD LEARN-MORE LEAD')
  public hubspotAddLearnMoreLead = async (
    referenceContact: IReferenceContact
  ): Promise<boolean> => {
    try {
      const formId = '5febaef4-dcf6-415a-a347-547dae3b2ec4';

      await this.referenceApi.submitReferenceContactToHubspot(
        formId,
        referenceContact
      );

      return true;
    } catch {
      return false;
    }
  };

  @action('REFERENCE SURVEY REPORT - PRINT')
  public printReferenceSurveyReport = async (
    referenceSurveyId: string,
    filename: string
  ): Promise<any> => {
    try {
      const arrayBuffer = await this.referenceApi.getReferenceSurveyReportPdf(
        referenceSurveyId
      );

      downloadArrayBufferAsPdf(arrayBuffer, filename);

      return true;
    } catch {
      return false;
    }
  };
}
