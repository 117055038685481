import IUnit, { IUnitEmployment } from './unit.interface';
import {
  BaseQuestion,
  JobProcessAppScreeningQuestion,
  JobProcessAppQuestionAnswer,
  CollapsibleTile,
  Orderable,
} from './process.interface';
import { IMessage } from './ws.interface';
import { IMediaImage } from './media.interface';
import { IReferenceQuestion } from './references.interface';

export interface IGDPRSettings {
  id?: number;
  managers?: number[];
  managers_serialized?: IUnitEmployment[];
  permission_expiration_period?: PermissionExpirationPeriod;
  created_at?: string;
  updated_at?: string;
}

export enum PermissionExpirationPeriod {
  NO_EXPIRATION = 'NO_EXPIRATION',
  MONTHS_3 = 'MONTHS_3',
  MONTHS_6 = 'MONTHS_6',
  MONTHS_12 = 'MONTHS_12',
  MONTHS_18 = 'MONTHS_18',
  MONTHS_24 = 'MONTHS_24',
}

export enum QuestionsTemplatesType {
  SCREENING_QUESTIONS = 'screening-questions',
  VIDEO_QUESTIONS = 'video-questions',
}

export interface IUnitQuestionTemplate {
  id?: number;
  unit_id?: number;
  question?: JobProcessAppScreeningQuestion;
}

export interface IUnitMessageTemplate {
  id?: number;
  unit_id?: number;
  internal_title?: string;
  message?: IMessage;
}

export interface IUnitReasonForDeclineTemplate {
  id?: number;
  unit_id?: number;
  internal_title?: string;
  message?: IMessage;
}


export interface IUnitReferenceQuestionTemplate {
  id?: number;
  unit_id?: number;
  question?: IReferenceQuestion;
}

export interface IUnitAttribute
  extends BaseQuestion,
    CollapsibleTile,
    Orderable {
  [key: string]: unknown;

  unit_id: number;
  name?: string;

  user_attribute?: IUserAttribute | null;
}

export interface IUnitAttributeUsage {
  question_apps_count: number;
}

export interface IUserAttribute {
  id?: number;
  answer?: JobProcessAppQuestionAnswer;
  unit_attribute_id?: number;
  user_id?: number;
}

/**
 * Recruiters can create new attribute answers.
 * In order to avoid them appearing in the screening questions, we have to strip `question_id` and `application_id`.
 */
export interface IApplicationSafeQuestionAnswer
  extends Omit<JobProcessAppQuestionAnswer, 'question_id' | 'application_id'> {
  question_id?: undefined;
  application_id?: undefined;
}

export interface IApplicationSafeUserAttribute
  extends Omit<IUserAttribute, 'answer'> {
  answer: IApplicationSafeQuestionAnswer;
}

export interface IUnitGalleryImage {
  id?: number;
  image_id?: number;
  image?: IMediaImage;
  unit_id?: number;
  unit?: IUnit;
}
