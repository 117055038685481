import { IJobProcess, IProcessStep } from '../../mj-models/process.interface';

export const isProcessValid = (process: IJobProcess) => {
  const stepsAmount = process.base_process!.steps!.length;
  const expectedOrders = [...Array(stepsAmount).keys()];
  const actualOrders = process.base_process!.steps!.map((step) => step.position!);

  for (let i = 0; i < stepsAmount; i++) {
    if (expectedOrders[i] !== actualOrders[i]) {
      return false;
    }
  }

  return true;
};

export const fixProcess = (process: IJobProcess) => {
  const sortedSteps = process.base_process!.steps!.sort((a: IProcessStep, b: IProcessStep) => {
    return a.position! < b.position! ? -1 : 0;
  });
  const fixedPositionSteps = sortedSteps.map((step, i) => ({
    ...step,
    position: i,
  }));

  const fixedProcess: IJobProcess = {
    ...process,
    base_process: {
      ...process.base_process!,
      steps: fixedPositionSteps,
    },
  };

  return fixedProcess;
};

const yes_translations = [
  'yes', // English
  'kyllä', // Finnish
  'ja', // Norwegian (Bokmål)
  'tak', // Ukrainian
  'po', // Albanian
  'አዎ', // Amharic
  'نعم', // Arabic
  'այո', // Armenian
  'bəli', // Azerbaijani
  'bai', // Basque
  'да', // Belarusian
  'হ্যাঁ', // Bengali
  'da', // Bosnian
  'sí', // Catalan
  '是', // Chinese (Mandarin)
  'ano', // Czech
  'jah', // Estonian
  'oui', // French
  'დიახ', // Georgian
  'ναι', // Greek
  'हाँ', // Hindi
  'igen', // Hungarian
  'já', // Icelandic
  'iya', // Indonesian
  'כן', // Hebrew
  'sì', // Italian
  'はい', // Japanese
  '예', // Korean
  'taip', // Lithuanian
  'iva', // Maltese
  'sim', // Portuguese
  'ano', // Slovak
  'si', // Spanish
  'oo', // Somali
  'evet', // Turkish
];

const no_translations = [
  'no', // English
  'ei', // Finnish
  'nei', // Norwegian (Bokmål)
  'ні', // Ukrainian
  'jo', // Albanian
  'አይ', // Amharic
  'لا', // Arabic
  'ոչ', // Armenian
  'xeyr', // Azerbaijani
  'ez', // Basque
  'не', // Belarusian
  'না', // Bengali
  'ne', // Bosnian
  'нет', // Bulgarian
  'no', // Catalan
  '不', // Chinese (Mandarin)
  'ne', // Czech
  'nej', // Danish
  'nee', // Dutch
  'ei', // Estonian
  'non', // French
  'არა', // Georgian
  'όχι', // Greek
  'नहीं', // Hindi
  'nem', // Hungarian
  'nei', // Icelandic
  'tidak', // Indonesian
  'לא', // Hebrew
  'no', // Italian
  'いいえ', // Japanese
  '아니요', // Korean
  'ne', // Lithuanian
  'ivana', // Maltese
  'não', // Portuguese
  'нет', // Russian
  'ne', // Slovak
  'no', // Spanish
  'ne', // Slovenian
  'maya', // Somali
  'hayir', // Turkish
];

export function isYesAlternative(answer: string): boolean {
  /**
   * Check if the answer is a yes alternative by comparing it to the list of translations
   */
  return yes_translations.includes(answer.toLowerCase().trim());
}

export function isNoAlternative(answer: string): boolean {
  /**
   * Check if the answer is a yes alternative by comparing it to the list of translations
   */
  return no_translations.includes(answer.toLowerCase().trim());
}
