import { IApplication } from './application.interface';
import { IUser } from './user.interface';
import { IProcessStep } from './process.interface';

export interface IGlobalSearchHighlight {
  [key: string]: unknown;
  title?: string[];
  internal_title?: string[];
  unit_display_name?: string[];
  position_function_name_en?: string[];
  position_function_name_nb?: string[];
  address?: string[];
  job_listing_header_title?: string[];
  job_listing_header_subtitle?: string[];
  job_listing_description?: string[];

  external_referer?: string[];
}

export interface IGlobalSearchJob {
  id?: number;
  title?: string;
  internal_title?: string;
  unit_display_name?: string;
  position_function_name_en?: string;
  position_function_name_nb?: string;
  address?: string;
  unit_logo_url?: string;
  publisher_published_at?: string;
  job_listing_hearder_title?: string;
  job_listing_header_subititle?: string;
  job_listing_description?: string;
  highlight?: IGlobalSearchHighlight;
}
export interface IGlobalSearchApplication {
  id?: number;
  applicant: IUser;
  process_step: IProcessStep;
  job_id: number;
  unit_room: { id: number };
  highlight?: IGlobalSearchHighlight;
}

export interface IGlobalSearch {
  applications: IGlobalSearchApplication[];
  jobs: IGlobalSearchJob[];
}

export enum IGlobalSearchChipState {
  ALL,
  JOBS,
  APPLICATIONS,
}

export interface IGlobalSearchChip {
  id: IGlobalSearchChipState;
  value: boolean;
}
