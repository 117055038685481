import BaseApi from './base.api';
import {
  IJobListing,
  IGalleryParams,
  IUnsplashPhotoInfo,
  IJobListingItem,
} from '../mj-models/job-listing.interface';
import {
  ICareerPage,
  ICareerPageSubdomain,
  IUnitFeatureFlag,
} from '../mj-models/unit.interface';
import { IPage } from '../mj-models/utils.interface';
export default class ListingApi extends BaseApi {
  public getCareerPage = (unitId: number): Promise<ICareerPage> =>
    this.axios
      .get(`${this.baseUrl}listings/company/${unitId}/career-page/`)
      .then((response) => response.data);

  public getCareerPageBySubdomain = (subdomain: string): Promise<ICareerPage> =>
    this.axios
      .get(`${this.baseUrl}listings/career-page/subdomain/${subdomain}/`)
      .then((response) => response.data);

  public createCareerPage = (careerPage: ICareerPage): Promise<ICareerPage> =>
    this.axios
      .post(`${this.baseUrl}listings/career-page/`, careerPage)
      .then((response) => response.data);

  public updateCareerPage = (careerPage: ICareerPage): Promise<ICareerPage> =>
    this.axios
      .put(`${this.baseUrl}listings/career-page/${careerPage.id}/`, careerPage)
      .then((response) => response.data);

  public createCareerPageSubdomain = (
    careerPageSubdomain: ICareerPageSubdomain
  ): Promise<ICareerPageSubdomain> =>
    this.axios
      .post(
        `${this.baseUrl}listings/career-page/subdomain/`,
        careerPageSubdomain
      )
      .then((response) => response.data);

  public deleteCareerPageSubdomain = (
    careerPageSubdomain: ICareerPageSubdomain
  ): Promise<ICareerPageSubdomain> =>
    this.axios
      .delete(
        `${this.baseUrl}listings/career-page/subdomain/${careerPageSubdomain.id}/`
      )
      .then((response) => response.data);

  public getJobListing = (jobId: number): Promise<IJobListing> =>
    this.axios
      .get(`${this.baseUrl}listings/job/${jobId}/job-listing/`)
      .then((response) => response.data);

  public getPublicJobListing = (jobListingId: number): Promise<IJobListing> =>
    this.axios
      .get(`${this.baseUrl}listings/job-listing/${jobListingId}/public/`)
      .then((response) => response.data);

  public findUnitJobListings = (
    unitId: number,
    text: string = ''
  ): Promise<IJobListing[]> => {
    const params = new URLSearchParams();
    params.append('date', 'True');
    params.append('include_open', 'True');
    params.append('add_sub_unit_jobs', 'True');
    params.append('unit_id', unitId.toString());
    params.append('use_mojob_career_jobs_filter', 'True');
    params.append('use_mojob_feed_filter', 'False');

    if (text) {
      params.append('search', text);
    }

    const url = `${this.baseUrl}listings/?${params.toString()}`;

    return this.axios.get(url).then((response) => response.data);
  };

  public getUnitFeatureFlag = (unitId: number): Promise<IUnitFeatureFlag> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/unit-feature-flags/`)
      .then((response) => response.data);

  public updateJobListing = (jobListing: IJobListing): Promise<IJobListing> =>
    this.axios
      .put(`${this.baseUrl}listings/job-listing/${jobListing.id}/`, jobListing)
      .then((response) => response.data);

  public updateJobListingItem = (
    jobListingItem: IJobListingItem
  ): Promise<IJobListingItem> =>
    this.axios
      .put(
        `${this.baseUrl}listings/listing-item/${jobListingItem.id}/`,
        jobListingItem
      )
      .then((response) => response.data);

  public getImageFromUrl = (url: string): Promise<Blob | undefined> => {
    return this.axios
      .get(url, { responseType: 'blob' as 'json' })
      .then((response) => response.data);
  };
}
