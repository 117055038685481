import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import {
  loadVersionBrowser,
  urlBase64ToUint8Array,
} from '@mojob/mj-shared/mj-utils/web.push.utils';
import IPushNotificationStore from '@mojob/mj-shared/mj-store/push-notification.store';

@Component({})
export default class PushNotificationMixin extends Vue {
  @InjectReactive('pushNotificationStore')
  public pushNotificationStore!: IPushNotificationStore;

  public notificationsPermissionIsGranted: boolean = false;
  public notificationsPermissionIsDenied: boolean = false;

  public created() {
    if ('permissions' in navigator) {
      navigator.permissions
        .query({ name: 'notifications' })
        .then((notificationPerm: PermissionStatus) => {
          notificationPerm.onchange = () => {
            if (notificationPerm && notificationPerm.state === 'granted') {
              this.notificationsPermissionIsGranted = true;
              this.notificationsPermissionIsDenied = false;
            }
            if (notificationPerm && notificationPerm.state === 'denied') {
              this.notificationsPermissionIsDenied = true;
              this.notificationsPermissionIsGranted = false;
            }
            if (notificationPerm && notificationPerm.state === 'prompt') {
              this.notificationsPermissionIsGranted = false;
              this.notificationsPermissionIsDenied = false;
            }
            console.log(
              'User decided to change his settings. New permission: ' +
                notificationPerm.state
            );
          };
        });
    }
  }
  public async subscribeToPushNotifications() {
    const permission: NotificationPermission =
      await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notifications permission granted');
      this.configureWebPush();
    } else if (permission === 'denied') {
      console.log('Notifications permission denied');
    } else if (permission === 'default') {
      console.log('Notifications permission default');
    }
  }

  public getBrowserVersion(): {
    name: string;
    version: string;
  } {
    return loadVersionBrowser();
  }

  public handleNewPushSubscription() {
    // Saves the users' device id so that we can
    // send web push from the backend to the user
    const browser = this.getBrowserVersion();
    navigator.serviceWorker.ready.then((swReg: ServiceWorkerRegistration) => {
      const vapidPublicKey =
        'BAa_l1zhWuQNKxdVFKc83_ZCyXqy4dAD6PY0abJ8HsnRSmqpwZMyhINZicPl-eE3g-7lEGPPncVlSgWISWpaBAw';
      const convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
      return swReg.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidPublicKey,
        })
        .then(async (sub: PushSubscription) => {
          const endpointParts = sub.endpoint.split('/');
          const registration_id = endpointParts[endpointParts.length - 1];
          await this.pushNotificationStore.savePushDevice({
            browser: browser.name.toUpperCase(),
            p256dh: btoa(
              String.fromCharCode.apply(
                null,
                // @ts-ignore
                new Uint8Array(sub.getKey('p256dh'))
              )
            ),
            auth: btoa(
              String.fromCharCode.apply(
                null,
                // @ts-ignore
                new Uint8Array(sub.getKey('auth'))
              )
            ),
            name: '',
            application_id: 'web_push',
            registration_id,
          });
        });
    });
  }

  public configureWebPush() {
    const temp_this = this;
    // Will not run without service worker
    if (!('serviceWorker' in navigator)) {
      console.log('Could not find your service worker');
      return;
    }

    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration: ServiceWorkerRegistration) => {
        // Get subscription info from pushManager
        return serviceWorkerRegistration.pushManager.getSubscription();
      })
      .then((sub: PushSubscription | null) => {
        temp_this.handleNewPushSubscription();
        //
        // if (sub === null) {
        //   // Process when user enables notifications
        //   temp_this.handleNewPushSubscription();
        //   temp_this.handleDisplayPushNotification();
        // } else {
        //   console.log('--- Have already subscribed to notifications');
        //
        // }
      });
  }
}
