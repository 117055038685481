import BaseApi from './base.api';
import { AxiosTransformer } from 'axios';
import { NpsSurvey, NpsSurveyAnswer } from '../mj-models/nps.interface';

const removeAuthKeyTransformer: AxiosTransformer = (data, headers) => {
  const authKey = 'Authorization';
  delete headers.common[authKey];

  return data;
};

export default class NpsApi extends BaseApi {
  public getNpsSurvey = (npsSurveyId: string): Promise<NpsSurvey> => {
    return this.axios
      .get(`${this.baseUrl}processes/nps-survey/${npsSurveyId}/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);
  };

  public getNpsAnswer = (npsSurveyId: string): Promise<NpsSurveyAnswer> => {
    return this.axios
      .get(`${this.baseUrl}processes/nps-survey/${npsSurveyId}/answer/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);
  };

  public submitNpsAnswer = (
    answer: NpsSurveyAnswer
  ): Promise<NpsSurveyAnswer> => {
    return this.axios
      .post(
        `${this.baseUrl}processes/nps-survey/${answer.nps_survey_id}/answers/`,
        answer,
        {
          transformRequest: [
            removeAuthKeyTransformer,
            ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
          ],
        }
      )
      .then((response) => response.data);
  };

  public updateNpsAnswer = (
    answer: NpsSurveyAnswer
  ): Promise<NpsSurveyAnswer> => {
    return this.axios
      .put(
        `${this.baseUrl}processes/nps-survey/${answer.nps_survey_id}/answer/`,
        answer,
        {
          transformRequest: [
            removeAuthKeyTransformer,
            ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
          ],
        }
      )
      .then((response) => response.data);
  };
}
