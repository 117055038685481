import IUnit from '../mj-models/unit.interface';
import {
  IInsightsResponse,
  ICandidatesOverviewInsights,
  ITimeOverviewInsights,
  ISourcingChannelOverviewInsights,
  ICandidateInteractionsOverviewInsights,
  ICandidatesInsights,
  ICandidateInteractionsInsights,
  IHiringProcessInsights,
  ICandidateExperiencesInsight,
  ISatisfactionOverviewInsights,
  ISourcingChannelsChartInsights,
  ISourcingChannelsFunnelInsights,
  IJobFilter,
  ISourcingChannelsTableRowInsights,
  ISourcingChannelsInsights,
} from '../mj-models/insights.interface';
import BaseApi from './base.api';
import { removeAuthKeyTransformer } from './api.utils';
import { AxiosTransformer } from 'axios';

export default class AnalyticsApi extends BaseApi {
  public fetchUnitAnalyticsAccounts = (unitId: number): Promise<IUnit> =>
    this.axios
      .get(`${this.baseUrl}analytics/unit/${unitId}/accounts/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);

  public fetchCandidatesOverviewInsights = (
    query: string
  ): Promise<IInsightsResponse<ICandidatesOverviewInsights>> =>
    this.axios
      .get(`${this.baseUrl}analytics/insights/overview/candidates/${query}`)
      .then((response) => response.data);

  public fetchTimeOverviewInsights = (
    query: string
  ): Promise<IInsightsResponse<ITimeOverviewInsights>> =>
    this.axios
      .get(`${this.baseUrl}analytics/insights/overview/time/${query}`)
      .then((response) => response.data);

  public fetchSourcingChannelsOverviewInsights = (
    query: string
  ): Promise<IInsightsResponse<ISourcingChannelOverviewInsights>> =>
    this.axios
      .get(
        `${this.baseUrl}analytics/insights/overview/sourcing-channels/${query}`
      )
      .then((response) => response.data);

  public fetchCandidateInteractionsOverviewInsights = (
    query: string
  ): Promise<IInsightsResponse<ICandidateInteractionsOverviewInsights>> =>
    this.axios
      .get(
        `${this.baseUrl}analytics/insights/overview/candidate-interactions/${query}`
      )
      .then((response) => response.data);

  public fetchSatisfactionOverviewInsights = (
    query: string
  ): Promise<IInsightsResponse<ISatisfactionOverviewInsights>> =>
    this.axios
      .get(`${this.baseUrl}analytics/insights/overview/satisfaction/${query}`)
      .then((response) => response.data);

  public fetchCandidateProcessInsights = (
    query: string
  ): Promise<IInsightsResponse<ICandidatesInsights>> =>
    this.axios
      .get(`${this.baseUrl}analytics/insights/process/candidates/${query}`)
      .then((response) => response.data);

  public fetchHiringProcessInsights = (
    query: string
  ): Promise<IInsightsResponse<IHiringProcessInsights>> =>
    this.axios
      .get(`${this.baseUrl}analytics/insights/process/hiring-process/${query}`)
      .then((response) => response.data);

  public fetchCandidateInteractionsProcessInsights = (
    query: string
  ): Promise<IInsightsResponse<ICandidateInteractionsInsights>> =>
    this.axios
      .get(
        `${this.baseUrl}analytics/insights/process/candidate-interactions/${query}`
      )
      .then((response) => response.data);

  public fetchCandidateExperiencesProcessInsights = (
    query: string
  ): Promise<IInsightsResponse<ICandidateExperiencesInsight>> =>
    this.axios
      .get(
        `${this.baseUrl}analytics/insights/process/candidate-experiences/${query}`
      )
      .then((response) => response.data);

  public fetchSourcingChannelsInsights = (
    query: string
  ): Promise<IInsightsResponse<ISourcingChannelsInsights>> =>
    this.axios
      .get(
        `${this.baseUrl}analytics/insights/sourcing/sourcing-channels/${query}`
      )
      .then((response) => response.data);

  public fetchJobsFilterList = (unitId: number): Promise<IJobFilter[]> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/jobs/slim/?ql=is_template = False`)
      .then((response) => response.data);
}
