






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class MjContainer extends Vue {
  @Prop({ default: '', type: String }) public readonly type!: string;
}
