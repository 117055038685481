import {
  IApplication,
  ApplicationRate,
  IApplicationRating,
  ApplicationJobMove,
} from '../mj-models/application.interface';
import { action, observable } from 'mobx';
import ApplicationApi from '../mj-api/application.api';
import { IUserDocument } from '../mj-models/user.interface';
import ChatApi from '../mj-api/chat.api';
import { IMessage } from '../mj-models/ws.interface';
import { JobProcessAppQuestionAnswer } from '../mj-models/process.interface';
import { NotificationStore } from './notification.store';
import extractErrorFromDjangoResponse from '../mj-api/error.api';
import { MotimateCourseCompletion } from '../mj-models/motimate.interface';
import UnitApi from '../mj-api/unit.api';
import TagApi from '../mj-api/tag.api';
import { ITag, IUserTag } from '../mj-models/tags.interface';
import { IPage } from '../mj-models/utils.interface';
import { IUnitAttribute, IUserAttribute } from '../mj-models/business-settings.interface';
import { saveAnalyticsForSendApplication } from '../mj-utils/analytics.utils';
import IUnitStore from '../mj-store/unit.store';
import IJobStore from '../mj-store/job.store';
import { replaceAtId } from '../mj-utils/data.utils';

export default interface IApplicationStore {
  jobId?: number;
  applications: IApplication[];
  application: IApplication;
  applicantUserDocuments: IUserDocument[];
  applicationAnswers: JobProcessAppQuestionAnswer[];
  applicationRatings: IApplicationRating[];
  motimateCourseCompletion?: MotimateCourseCompletion;
  applicantUnitHistory: IApplication[];
  userTags: IUserTag[];
  availableTags: ITag[];
  allUnitTags: ITag[];
  loadingAvailableTags: boolean;
  appliedForJobListener?: () => void;
  moveApplicationsToAnotherJob: (movingApplications: ApplicationJobMove[]) => Promise<boolean>;
  createApplication: (jobId: number) => Promise<boolean>;
  deleteApplication: (jobId: number, applicationId: number) => Promise<boolean>;
  updatePitch: (value: string) => void;
  checkIfApplicationAlreadyExist: () => Promise<boolean>;
  checkIfApplicationAlreadyExistWithApplicationId: (applicationId: number) => Promise<boolean>;
  sendApplicationRating: (rate: ApplicationRate) => void;
  getApplicationRatings: () => void;
  getApplicationNotes: (unitRoomId: number) => Promise<IMessage[] | undefined>;
  getJobApplicationDetail: (
    jobId: number,
    applicationId: number
  ) => Promise<IApplication | undefined>;
  getApplicantUserDocuments: (applicationId: number) => Promise<IUserDocument[]>;
  getApplicationAnswers: (applicationId: number) => Promise<JobProcessAppQuestionAnswer[]>;
  resetStore: () => void;
  updateApplicationStatuses: (applications: IApplication[]) => void;
  sendMessagesToApplicants: (messages: IMessage[]) => Promise<IMessage[]>;
  getUserMotimateCourseCompletion: (
    motimateOrganizationId: number | string,
    userId: number
  ) => void;
  getLearnifierProjectParticipation: (applicationId: number) => Promise<any[]>;
  sendLearnifierLoginLink: (
    applicationId: number,
    projectId: number,
    participationId: number
  ) => Promise<any>;
  getApplicantUnitHistory: (unitId: number, userId: number) => Promise<IApplication[] | undefined>;
  createTag: (name: string, unitId: number) => Promise<ITag | undefined>;
  createUserTag: (tagId: number, userId: number, unitId: number) => Promise<IUserTag | undefined>;
  getUnitTags: (unitId: number, search: string) => Promise<ITag[]>;
  getUserTags: (unitId: number, userId: number) => Promise<IUserTag[]>;
  deleteUserTag: (userTag: IUserTag) => Promise<boolean>;
  updateTag: (tag: ITag) => Promise<ITag | undefined>;
  getUserTag: (userTagId: number) => Promise<IUserTag | undefined>;
  getApplicationAttributes: (applicationId: number) => Promise<IPage<IUnitAttribute> | undefined>;
  createApplicationAttribute: (
    userAttribute: IUserAttribute
  ) => Promise<IUserAttribute | undefined>;
  updateApplicationAttribute: (
    userAttribute: IUserAttribute
  ) => Promise<IPage<IUserAttribute> | undefined>;
  sendApplication: () => Promise<boolean>;
  goToChat: () => void;
  setNewApplicationAppMessage: (message: IMessage, onlyIfExistsBefore: boolean) => void;
}

export class ApplicationStore implements IApplicationStore {
  @observable public jobId?: number;
  @observable public applications: IApplication[] = [];
  @observable public applicationRatings: IApplicationRating[] = [];
  @observable public application: IApplication = {
    answers: [],
  };
  @observable public answers: JobProcessAppQuestionAnswer[] = [];
  @observable public applicantUserDocuments: IUserDocument[] = [];
  @observable public applicationAnswers: JobProcessAppQuestionAnswer[] = [];
  @observable public motimateCourseCompletion?: MotimateCourseCompletion | undefined;
  @observable public applicantUnitHistory: IApplication[] = [];
  @observable public userTags: IUserTag[] = [];
  @observable public availableTags: ITag[] = [];
  @observable public loadingAvailableTags: boolean = false;
  @observable public allUnitTags: ITag[] = [];
  @observable public appliedForJobListener?: () => void;

  public applicationApi: ApplicationApi;
  public chatApi: ChatApi;
  public unitApi: UnitApi;
  public router: any;
  public notificationStore: NotificationStore;
  public tagApi: TagApi;
  public unitStore: IUnitStore;
  public jobStore: IJobStore;

  constructor(
    applicationApi: ApplicationApi,
    chatApi: ChatApi,
    unitApi: UnitApi,
    router: any,
    notificationStore: NotificationStore,
    tagApi: TagApi,
    unitStore: IUnitStore,
    jobStore: IJobStore
  ) {
    this.applicationApi = applicationApi;
    this.chatApi = chatApi;
    this.unitApi = unitApi;
    this.router = router;
    this.notificationStore = notificationStore;
    this.tagApi = tagApi;
    this.unitStore = unitStore;
    this.jobStore = jobStore;
  }

  @action('CREATE APPLICATION')
  public createApplication = async (jobId: number): Promise<boolean> => {
    this.application = { ...this.application };
    try {
      this.application = await this.applicationApi.createApplication(jobId, this.application);
      if (this.appliedForJobListener) {
        this.appliedForJobListener();
      }
      return true;
    } catch (error) {
      this.notificationStore.showMessage(extractErrorFromDjangoResponse(error));
      return false;
    }
  };

  @action('DELETE APPLICATION')
  public deleteApplication = async (jobId: number, applicationId: number): Promise<boolean> =>
    await this.applicationApi.deleteApplication(jobId, applicationId).then((response) => response);

  @action('MOVE APPLICATION TO ANOTHER JOB')
  public moveApplicationsToAnotherJob = async (
    movingApplications: ApplicationJobMove[]
  ): Promise<boolean> => await this.applicationApi.moveApplicationsToAnotherJob(movingApplications);

  @action('CHECK IF APPLICATION ALREADY EXIST')
  public checkIfApplicationAlreadyExist = async (): Promise<boolean> => {
    return await this.applicationApi.checkIfApplicationExists(this.jobId!);
  };

  @action('CHECK IF APPLICATION ALREADY EXIST WITH APPLICATION ID')
  public checkIfApplicationAlreadyExistWithApplicationId = async (
    applicationId: number
  ): Promise<boolean> => {
    try {
      return await this.applicationApi.checkIfApplicationExistsWithApplicationId(applicationId);
    } catch (e) {
      return false;
    }
  };

  @action('UPDATE PITCH')
  public updatePitch = (value: string) => {
    this.application = { ...this.application };
  };

  @action('SEND APPLICATION RATING')
  public sendApplicationRating = async (rate: ApplicationRate) => {
    await this.applicationApi.sendApplicationRating(this.application!.id!, {
      application_id: this.application!.id!,
      rate,
    });
    this.getApplicationRatings();
  };

  @action('GET APPLICATION RATINGS')
  public getApplicationRatings = async () => {
    this.applicationRatings = await this.applicationApi.getApplicationRatings(
      this.application!.id!
    );
  };

  @action('GET MOTIMATE COURSE COMPLETION')
  public getUserMotimateCourseCompletion = async (
    motimateOrganizationId: number | string,
    userId: number
  ) => {
    try {
      this.motimateCourseCompletion = await this.applicationApi.getMotimateCourseCompletion(
        motimateOrganizationId,
        userId
      );
    } catch (e) {
      this.motimateCourseCompletion = undefined;
    }
  };

  @action('GET LEARNIFIER PROJECT PARTICIPATION')
  public getLearnifierProjectParticipation = async (applicationId: number): Promise<any[]> => {
    try {
      return await this.applicationApi.getLearnifierProjectParticipations(applicationId);
    } catch (e) {
      return [];
    }
  };

  @action('GET LEARNIFIER LOGIN LINK')
  public sendLearnifierLoginLink = async (
    applicationId: number,
    projectId: number,
    participationId: number
  ): Promise<any> => {
    try {
      const loginLink = await this.applicationApi.sendLearnifierLoginLink(
        applicationId,
        projectId,
        participationId
      );
      return loginLink && loginLink.link ? loginLink.link : '';
    } catch (e) {
      this.notificationStore.notifyAboutError(e);
      return '';
    }
  };

  @action('GET APPLICATION DETAIL')
  public getJobApplicationDetail = async (
    jobId: number,
    applicationId: number
  ): Promise<IApplication | undefined> => {
    try {
      return await this.applicationApi.getJobApplicationDetail(jobId, applicationId);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET APPLICANT USER DOCUMENTS')
  public getApplicantUserDocuments = async (applicationId: number): Promise<IUserDocument[]> => {
    try {
      const userDocuments = await this.applicationApi.getApplicantUserDocuments(applicationId);
      return userDocuments.map((val: IUserDocument) => {
        val.isDone = false;
        val.isLoading = false;
        val.deletable = false;
        return val;
      });
    } catch (error) {
      return [];
    }
  };

  @action('GET APPLICATION ANSWERS')
  public getApplicationAnswers = async (
    applicationId: number
  ): Promise<JobProcessAppQuestionAnswer[]> => {
    try {
      return await this.applicationApi.getApplicationAnswers(applicationId);
    } catch (error) {
      return [];
    }
  };

  @action('GET APPLICANT UNIT HISTORY')
  public getApplicantUnitHistory = async (
    unitId: number,
    userId: number
  ): Promise<IApplication[] | undefined> => {
    try {
      const page = await this.unitApi.getApplicantUnitHistory(unitId, userId);
      return page.results.filter(
        (application: IApplication) => application.source!.id !== this.jobId
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('RESET APPLICATION STORE')
  public resetStore = async () => {
    this.application = {
      answers: [],
    };
    this.applicantUserDocuments = [];
    this.applicationAnswers = [];
    this.applicationRatings = [];
    this.userTags = [];
    this.availableTags = [];
    this.motimateCourseCompletion = undefined;
  };

  @action('setNewApplicationAppMessage')
  public setNewApplicationAppMessage = (message: IMessage, onlyIfExistsBefore: boolean) => {
    const foundIdx: number = this.application.unit_room!.app_messages!.findIndex(
      (appMessage: IMessage) => appMessage.id === message.id
    );
    if (foundIdx !== -1) {
      this.application = {
        ...this.application,
        unit_room: {
          ...this.application.unit_room,
          app_messages: replaceAtId<IMessage>(this.application.unit_room!.app_messages!, message),
        },
      };
    } else {
      if (!onlyIfExistsBefore) {
        this.application = {
          ...this.application,
          unit_room: {
            ...this.application.unit_room,
            app_messages: [...this.application.unit_room!.app_messages!, message],
          },
        };
      }
    }
  };

  @action('UPDATE APPLICATION STATUSES')
  public updateApplicationStatuses = async (applications: IApplication[]) => {
    // check if updated application is selected application, if so - update status
    const applicationIndex = applications.findIndex((obj) => obj.id === this.application.id);
    if (applicationIndex > -1) {
      this.application = {
        ...this.application,
        status: applications[applicationIndex].status,
      };
    }
    await this.applicationApi.updateApplicationStatusChanges(this.jobId!, applications);
  };

  @action('CREATE TAG')
  public createTag = async (name: string, unitId: number): Promise<ITag | undefined> => {
    try {
      return await this.tagApi.createTag(name, unitId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
    }
  };

  @action('CREATE USER TAGS')
  public createUserTag = async (
    tagId: number,
    userId: number,
    unitId: number
  ): Promise<IUserTag | undefined> => {
    try {
      return await this.tagApi.createUserTag(unitId, userId, tagId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('GET UNIT TAGS')
  public getUnitTags = async (unitId: number, search: string): Promise<ITag[]> => {
    try {
      const unitTagPage = await this.tagApi.getUnitTags(unitId, search, 1, 50);
      return unitTagPage.results;
    } catch (error) {
      return [];
    }
  };

  @action('GET USER TAGS')
  public getUserTags = async (unitId: number, userId: number): Promise<IUserTag[]> => {
    try {
      const userTagPage = await this.tagApi.getUserTags(unitId, userId, 1, 50);
      return userTagPage.results;
    } catch (error) {
      return [];
    }
  };

  @action('DELETE USER TAGS')
  public deleteUserTag = async (userTag: IUserTag): Promise<boolean> => {
    try {
      await this.tagApi.deleteUserTags(userTag.id!);
      this.userTags = this.userTags.filter(
        (existingUserTag: IUserTag) => existingUserTag.id !== userTag.id
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  @action('GET USER TAGS')
  public getUserTag = async (userTagId: number): Promise<IUserTag | undefined> => {
    try {
      return await this.tagApi.getUserTag(userTagId);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE TAG')
  public updateTag = async (tag: ITag): Promise<ITag | undefined> => {
    try {
      return await this.tagApi.updateTag(tag);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('SEND MESSAGES TO APPLICANTS')
  public sendMessagesToApplicants = async (messages: IMessage[]): Promise<IMessage[]> => {
    try {
      return await this.chatApi.sendMessages(messages);
    } catch (error) {
      return [];
    }
  };

  @action('GET APPLICATION NOTES')
  public getApplicationNotes = async (unitRoomId: number): Promise<IMessage[] | undefined> => {
    try {
      return await this.chatApi.getApplicationNotes(unitRoomId);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET APPLICATION ATTRIBUTES')
  public getApplicationAttributes = async (
    applicationId: number
  ): Promise<IPage<IUnitAttribute> | undefined> => {
    try {
      return await this.applicationApi.getApplicationAttributes(applicationId);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE APPLICATION ATTRIBUTE')
  public createApplicationAttribute = async (
    userAttribute: IUserAttribute
  ): Promise<IUserAttribute | undefined> => {
    try {
      return await this.applicationApi.createApplicationAttribute(userAttribute);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE APPLICATION ATTRIBUTE')
  public updateApplicationAttribute = async (
    userAttribute: IUserAttribute
  ): Promise<IPage<IUserAttribute> | undefined> => {
    try {
      return await this.applicationApi.updateApplicationAttribute(userAttribute);
    } catch (error) {
      return undefined;
    }
  };

  @action('SEND APPLICATION')
  public async sendApplication() {
    const jobId = this.jobStore.job.id;
    if (jobId) {
      if (!this.application.id) {
        const successfulApplicationCreation = await this.createApplication(jobId);

        if (!successfulApplicationCreation) {
          return false;
        }
      }

      saveAnalyticsForSendApplication(jobId, this.unitStore.unitWithAnalyticsAttributes);

      this.goToChat();
      return true;
    }
    return false;
  }

  @action('GO TO CHAT')
  public async goToChat() {
    const application_id = this.application.id!.toString();
    const room_id = this.application.unit_room!.toString();
    this.router.push({
      name: 'chat',
      params: {
        application_id,
        room_id,
      },
    });
  }
}
