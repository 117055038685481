import BaseApi from './base.api';
import { Achievement, Checklist } from '../mj-models/checklist.interface';

export default class ChecklistApi extends BaseApi {
  public getChecklist = (userId: number): Promise<Checklist> =>
    this.axios
      .get(`${this.baseUrl}checklist/${userId}/`)
      .then(response => response.data);

  public registerAchievement = (
    achievement: Achievement
  ): Promise<Achievement> =>
    this.axios
      .post(`${this.baseUrl}checklist/register-achievement/`, achievement)
      .then(response => response.data);
}
