


















import { Component, Prop, Vue } from 'vue-property-decorator';

import MjDivider from '../../atoms/mj-divider/MjDivider.vue';
import MjTypographyBody from '../../atoms/mj-typography/MjTypographyBody.vue';

@Component({
  components: { MjDivider, MjTypographyBody },
  inheritAttrs: true,
})
export default class MjTextDivider extends Vue {
  @Prop({ default: false, type: Boolean }) public readonly vertical!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly thick!: boolean;
  @Prop({ default: '', type: String }) public readonly text!: string;
  @Prop({ default: '1.2em 0px 0px 0px', required: false, type: String })
  public readonly margin!: string;
}
