import { action, observable } from 'mobx';
import { IApplication } from '../mj-models/application.interface';
import ApplicationApi from '../mj-api/application.api';
import DynamicProcessApi from '../mj-api/dynamic-process.api';
import {
  JobProcessAppQuestionAnswer,
  JobProcessAppScreeningQuestion,
} from '../mj-models/process.interface';
import {
  BackRoute,
  VideoPlayerState,
  VideoRecordingAnswer,
} from '../mj-models/videointerview.interface';

export default interface IVideoInterviewStore {
  applicationId?: number;
  application?: IApplication;
  questions?: JobProcessAppScreeningQuestion[];
  answers?: JobProcessAppQuestionAnswer[];
  appId?: number;
  fetchApplicationDetail: (applicationId: number) => void;
  fetchVideoInterviewQuestions: (appId: number) => void;
  fetchVideoInterviewAnswers: (appId: number, applicationId: number) => void;
  uploadVideoRecordingAnswers: (
    videoRecordingAnswers: VideoRecordingAnswer[]
  ) => Promise<boolean>;

  videoPlayerState?: VideoPlayerState;

  videoRecordingAnswers: VideoRecordingAnswer[];
  currentVideoRecordingAnswer?: VideoRecordingAnswer;

  showExitModal: boolean;
  backRoute?: BackRoute;
  resetStore: () => void;
}

export class VideoInterviewStore implements IVideoInterviewStore {
  @observable public applicationId: number | undefined = undefined;
  @observable public application: IApplication | undefined = undefined;
  @observable public questions:
    | JobProcessAppScreeningQuestion[]
    | undefined = undefined;
  @observable public answers:
    | JobProcessAppQuestionAnswer[]
    | undefined = undefined;
  @observable public videoPlayerState: VideoPlayerState | undefined = undefined;
  @observable public videoRecordingAnswers: VideoRecordingAnswer[] = [];
  @observable public currentVideoRecordingAnswer:
    | VideoRecordingAnswer
    | undefined = undefined;
  @observable public showExitModal: boolean = false;
  @observable public backRoute?: BackRoute;
  @observable public appId?: number;
  public applicationApi: ApplicationApi;
  public dynamicProcessApi: DynamicProcessApi;
  public router: any;

  constructor(
    applicationApi: ApplicationApi,
    dynamicProcessApi: DynamicProcessApi,
    router: any
  ) {
    this.applicationApi = applicationApi;
    this.dynamicProcessApi = dynamicProcessApi;
    this.router = router;
  }

  @action('FETCH VIDEO INTERVIEW QUESTIONS')
  public fetchVideoInterviewQuestions = async (appId: number) => {
    try {
      const app = await this.dynamicProcessApi.getApp(appId);
      this.questions = app.question_apps;
    } catch (error) {}
  };

  @action('FETCH VIDEO INTERVIEW ANSWERS')
  public fetchVideoInterviewAnswers = async (
    appId: number,
    applicationId: number
  ) => {
    try {
      const pagedAnswers = await this.dynamicProcessApi.getAppQuestionAnswers(
        appId,
        applicationId
      );
      this.answers = pagedAnswers.results;
    } catch (error) {}
  };

  @action('FETCH APPLICATION DETAIL')
  public fetchApplicationDetail = async (applicationId: number) => {
    try {
      this.application = await this.applicationApi.getApplicationDetail(
        applicationId
      );
    } catch (error) {}
  };

  @action('UPLOAD VIDEO RECORDING ANSWERS')
  public uploadVideoRecordingAnswers = async (
    videoRecordingAnswers: VideoRecordingAnswer[]
  ): Promise<boolean> => {
    try {
      const answers: JobProcessAppQuestionAnswer[] = videoRecordingAnswers.map(
        (vidRecAnswer: VideoRecordingAnswer) => {
          return {
            ...vidRecAnswer.recorderAnswer,
            tempFile: undefined,
          };
        }
      );
      await this.dynamicProcessApi.deliverApp(this.appId!, answers);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  @action('RESET STORE')
  public resetStore() {
    this.applicationId = undefined;
    this.application = undefined;
    this.questions = undefined;
    this.answers = undefined;
    this.videoPlayerState = undefined;
    this.videoRecordingAnswers = [];
    this.currentVideoRecordingAnswer = undefined;
    this.showExitModal = false;
    this.backRoute = undefined;
  }
}
