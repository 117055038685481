











import { Component, Prop, Vue } from 'vue-property-decorator';
import Typography from 'material-components-vue/dist/typography/typography.min.js';
import MjTypographyBase from './MjTypographyBase.vue';

Vue.use(Typography);

@Component({
  inheritAttrs: false,
})
export default class MjTypographyHeader extends MjTypographyBase {
  @Prop({ required: true }) public readonly level!: number;
  @Prop({ required: false, type: String }) public readonly margin!: string;
  @Prop({ required: false }) public readonly noWrap?: boolean;
  @Prop({
    type: String,
    validator: (val: string) => {
      return ['center'].indexOf(val) !== -1;
    },
    required: false,
  })
  public readonly align!: string;

  constructor(props: any) {
    super(props);
  }

  get hTag() {
    return `h${this.level}`;
  }

  get propsClass() {
    return {
      'mj-typography': true,
      'mj-typography--no-margin': this.noMargin,
      'mj-typography--no-wrap': this.noWrap,
      'mj-typography--oblique': this.oblique,
      'mj-typography--align-center': this.align === 'center',
    };
  }
}
