















import { Component, Prop, Vue } from 'vue-property-decorator';
import MjJobInfoCard from '../../molecules/mj-job-info-card/MjJobInfoCard.vue';

@Component({
  inheritAttrs: false,
  components: { MjJobInfoCard },
})
export default class MjApplyJobInfo extends Vue {
  @Prop({ default: '', type: String }) public bgUrl!: string;
  @Prop({ default: '', type: String }) public jobName!: string;
  @Prop({ default: '', type: String }) public jobAddress!: string;
  @Prop({ default: '', type: String }) public unitLogoUrl!: string;
  @Prop({ default: '', type: String }) public unitName!: string;
}
