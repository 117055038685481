import { action, observable } from 'mobx';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import { IApplicationLead } from '../mj-models/application.interface';
import BlogApi from '../mj-api/blog.api';
import { IBlogListing, ICategory, IPostTag } from '../mj-models/blog.interface';
import INotificationStore from './notification.store';

export default interface IBusinessBlogStore {
  blogPage: IPage<IBlogListing>;
  blogPaginator: IPaginator;
  blogIsLoading: boolean;
  blogIsLoadingMore: boolean;
  editorBlogListing: IBlogListing;
  blogCategories: ICategory[];
  blogTags: IPostTag[];

  getBlogListings: (
    unitId: number,
    page: number,
    pageSize: number
  ) => Promise<IApplicationLead[]>;

  getBlogListing: (blogListingId: number) => Promise<IBlogListing | undefined>;

  updateBlogListing: (
    blogListing: IBlogListing
  ) => Promise<IBlogListing | undefined>;

  createBlogListing: (
      blogListing: IBlogListing
  ) => Promise<IBlogListing | undefined>;

  getBlogCategories: () => Promise<ICategory[]>;
  getBlogTags: () => Promise<IPostTag[]>;
  createBlogTag: (tag: IPostTag) => Promise<IPostTag | undefined>;
  createBlogCategory: (cat: ICategory) => Promise<ICategory | undefined>;
}
export class BusinessBlogStore implements IBusinessBlogStore {
  @observable public blogPage: IPage<IBlogListing> = {
    count: 0,
    next: '',
    prev: '',
    results: [],
  };
  @observable public blogPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public blogIsLoading: boolean = true;
  @observable public blogIsLoadingMore: boolean = false;
  @observable public editorBlogListing: IBlogListing = {};
  @observable public blogCategories: ICategory[] = [];
  @observable public blogTags: IPostTag[] = [];

  private blogApi: BlogApi;
  private notificationStore: INotificationStore;

  constructor(blogApi: BlogApi, notificationStore: INotificationStore) {
    this.blogApi = blogApi;
    this.notificationStore = notificationStore;
  }

  @action('CREATE BLOG TAG')
  public createBlogTag = async (
    tag: IPostTag
  ): Promise<IPostTag | undefined> => {
    try {
      return await this.blogApi.createBlogTag(tag);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE BLOG CATEGORY')
  public createBlogCategory = async (
    cat: ICategory
  ): Promise<ICategory | undefined> => {
    try {
      return await this.blogApi.createBlogCategory(cat);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE BLOG LISTING')
  public createBlogListing = async (
      blogListing: IBlogListing
  ): Promise<IBlogListing | undefined> => {
    try {
      return await this.blogApi.createBlogListing(blogListing);
    } catch (error) {
      const errorText = this.notificationStore.getTextFromErrorResponseData(
          error.response.data
      );
      this.notificationStore.showMessage(
          errorText ? errorText : error.response.data
      );
      return undefined;
    }
  };

  @action('UPDATE BLOG LISTING')
  public updateBlogListing = async (
    blogListing: IBlogListing
  ): Promise<IBlogListing | undefined> => {
    try {
      let blogListingToUpdate = { ...blogListing };
      if (!blogListing.blog_post!.og_graph) {
        blogListingToUpdate = {
          ...blogListingToUpdate,
          blog_post: {
            ...blogListingToUpdate.blog_post!,
            og_graph: {
              title: '',
              description: '',
            },
          },
        };
      }
      if (!blogListing.blog_post!.category) {
        blogListingToUpdate = {
          ...blogListingToUpdate,
          blog_post: {
            ...blogListingToUpdate.blog_post!,
            category: this.blogCategories[0],
          },
        };
      }
      return await this.blogApi.updateBlogListing(blogListingToUpdate);
    } catch (error) {
      const errorText = this.notificationStore.getTextFromErrorResponseData(
        error.response.data
      );
      this.notificationStore.showMessage(
        errorText ? errorText : error.response.data
      );
      return undefined;
    }
  };

  @action('GET BLOG LISTING')
  public getBlogListing = async (
    blogListingId: number
  ): Promise<IBlogListing | undefined> => {
    try {
      return await this.blogApi.getBlogListing(blogListingId);
    } catch (e) {
      return undefined;
    }
  };

  @action('GET BLOG CATEGORIES')
  public getBlogCategories = async (): Promise<ICategory[]> => {
    try {
      return await this.blogApi.getCategories();
    } catch (e) {
      return [];
    }
  };

  @action('GET BLOG TAGS')
  public getBlogTags = async (): Promise<IPostTag[]> => {
    try {
      return await this.blogApi.getTags();
    } catch (e) {
      return [];
    }
  };

  @action('GET BLOG LISTINGS')
  public getBlogListings = async (
    unitId: number,
    page: number,
    pageSize: number
  ): Promise<IBlogListing[]> => {
    try {
      this.blogPaginator.page = page;
      this.blogPaginator.itemsPerPage = pageSize;
      if (page === 1) {
        this.blogIsLoading = true;
        this.blogPage = await this.blogApi.getUnitBlogListings(
          unitId,
          page,
          pageSize
        );
      } else {
        this.blogIsLoadingMore = true;
        const newPage = await this.blogApi.getUnitBlogListings(
          unitId,
          page,
          pageSize
        );
        this.blogPage = {
          ...newPage,
          results: [...this.blogPage.results, ...newPage.results],
        };
      }
      this.blogPaginator.count = this.blogPage.count || 0;
      this.blogPaginator.maxPage = Math.ceil(
        this.blogPaginator.count! / this.blogPaginator.itemsPerPage
      );
      this.blogIsLoading = false;
      this.blogIsLoadingMore = false;
      return this.blogPage.results;
    } catch (error) {
      return [];
    }
  };
}
