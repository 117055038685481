import { Languages } from '../mj-models/user.interface';
import { IProcessStep } from '../mj-models/process.interface';

export function getTranslatedAction(action: number) {
  switch (action) {
    case 0:
      return 'generic__confirm_reject.message';
    case 1:
      return 'generic__confirm_move.message';
    case 2:
      return 'generic__add_note.message';
    case 3:
      return 'generic__send_message.message';
  }
}

export function getNextApplicationStep(
  currentProcessStep: IProcessStep,
  allProcessSteps: IProcessStep[],
  dropTo?: any
) {
  if (dropTo) {
    return dropTo;
  } else {
    const currentStep =
      typeof currentProcessStep === 'object'
        ? currentProcessStep
        : allProcessSteps.find((step: IProcessStep) => step.id === currentProcessStep);
    const nextStep = allProcessSteps.find(
      (step: IProcessStep) => step.position === currentStep!.position! + 1
    );
    return nextStep ? nextStep : currentStep;
  }
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  textArea.tabIndex = 0; // https://bugs.chromium.org/p/chromium/issues/detail?id=467043

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Could not copy text (fallback): ' + err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  // https://caniuse.com/mdn-api_clipboard_writetext
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  // using browser's Clipboard API
  navigator.clipboard.writeText(text).catch((err) => {
    console.error('Could not copy text: ' + err);
  });
}

export function openUrlAsPopup(url: string) {
  const params = 'resizable,scrollbars,status, width=800, height=600, left=100, top=100';

  window.open(url, 'popupwindow', params);
}

/*
gets browser language
prefers long (en-US) language strings than short (en) if possible
*/
export function getFirstBrowserLanguage(countryCodeOnly?: boolean): string {
  const nav: Navigator & { [key: string]: any } = window.navigator;

  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];

  let shortLanguage = null;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (const language of nav.languages) {
      if (!language.length) {
        continue;
      }

      if (!shortLanguage) {
        shortLanguage = language;
      }
      if (language && language.length > 2) {
        return countryCodeOnly ? language.trim().split(/-|_/)[0] : language.trim();
      }
    }
  }

  // support for other properties in browsers
  for (const key of browserLanguagePropertyKeys) {
    const language = nav[key];

    // in case of IE11, skip because its falsy
    if (!language || !language.length) {
      continue;
    }
    if (!shortLanguage) {
      shortLanguage = language;
    }
    if (language.length > 2) {
      return countryCodeOnly ? language.trim().split(/-|_/)[0] : language.trim();
    }
  }

  return shortLanguage;
}

/*
gets supported browser language
returns 'en' if user browser language is not supported by Mojob
*/
export function getSupportedBrowserLanguage(countryCodeOnly: boolean = true): string {
  const supportedLanguages: string[] = Object.values(Languages);
  const browserLang = getFirstBrowserLanguage(countryCodeOnly);
  return supportedLanguages.includes(browserLang) ? browserLang : Languages.en;
}
