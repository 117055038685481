import {
  IAppointment,
  IAppointmentSlot,
  IAppointmentsMicrosoftOutlookAccount,
} from '../mj-models/appointment.interface';
import { IPage } from '../mj-models/utils.interface';
import BaseApi from './base.api';

export default class AppointmentApi extends BaseApi {
  public getAppointments = (): Promise<IPage<IAppointment>> =>
    this.axios
      .get(`${this.baseUrl}appointments/`)
      .then((response) => response.data);

  public getAppointment = (appointmentId: number): Promise<IAppointment> =>
    this.axios
      .get(`${this.baseUrl}appointments/${appointmentId}/`)
      .then((response) => response.data);

  public fetchOutlookUnitEmployments = (
    unitId: number,
    searchTerm = '',
    pageSize = 300
  ): Promise<IPage<IAppointmentsMicrosoftOutlookAccount>> =>
    this.axios
      .get(
        `${this.baseUrl}appointments/unit/${unitId}/employments/search/?page_size=${pageSize}&search=${searchTerm}`
      )
      .then((response) => response.data);

  public getMicrosoftOutlookConnections = (
    unitId: number,
    searchTerm: string,
    pageSize = 25
  ): Promise<IPage<IAppointmentsMicrosoftOutlookAccount>> =>
    this.axios
      .get(
        `${this.baseUrl}appointments/unit/${unitId}/outlook-accounts/?page_size=${pageSize}&search=${searchTerm}`
      )
      .then((response) => response.data);

  public createMicrosoftOutlookConnections = (
    connection: IAppointmentsMicrosoftOutlookAccount
  ): Promise<IAppointmentsMicrosoftOutlookAccount> =>
    this.axios
      .post(
        `${this.baseUrl}appointments/unit/${connection.unit!
          .id!}/outlook-accounts/`,
        connection
      )
      .then((response) => response.data);

  public createAppointment = (
    appointment: IAppointment
  ): Promise<IAppointment> =>
    this.axios
      .post(`${this.baseUrl}appointments/`, appointment)
      .then((response) => response.data);

  public updateAppointment = (
    appointment: IAppointment
  ): Promise<IAppointment> =>
    this.axios
      .put(`${this.baseUrl}appointments/${appointment.id}/`, appointment)
      .then((response) => response.data);

  public deleteAppointment = (appointmentId: number): Promise<IAppointment> =>
    this.axios
      .delete(`${this.baseUrl}appointments/${appointmentId}/`)
      .then((response) => response.data);

  public getAppointmentSlots = (
    appointmentId: number
  ): Promise<IPage<IAppointmentSlot>> =>
    this.axios
      .get(`${this.baseUrl}appointments/${appointmentId}/slots/?page_size=100`)
      .then((response) => response.data);

  public getAvailableAppointmentSlots = (
    appointmentId: number
  ): Promise<IPage<IAppointmentSlot>> =>
    this.axios
      .get(`${this.baseUrl}appointments/${appointmentId}/available-slots/`)
      .then((response) => response.data);

  public getMyAppointmentSlots = (
    appointmentId: number
  ): Promise<IPage<IAppointmentSlot>> =>
    this.axios
      .get(`${this.baseUrl}appointments/my-slots/`)
      .then((response) => response.data);

  public getAppointmentSlot = (
    appointmentId: number,
    appointmentSlotId: number
  ): Promise<IAppointmentSlot> =>
    this.axios
      .get(
        `${this.baseUrl}appointments/${appointmentId}/slots/${appointmentSlotId}/`
      )
      .then((response) => response.data);

  public confirmAppointmentSlot = (
    appointmentSlot: IAppointmentSlot
  ): Promise<IAppointmentSlot> =>
    this.axios
      .post(
        `${this.baseUrl}appointments/slot/${appointmentSlot.id}/application/${appointmentSlot.application_id}/`,
        {}
      )
      .then((response) => response.data);

  public switchAppointmentSlot = (
    oldSlot: IAppointmentSlot,
    newSlot: IAppointmentSlot
  ): Promise<IAppointmentSlot> =>
    this.axios
      .get(
        `${this.baseUrl}appointments/application/${newSlot.application_id}/switch-slot/${oldSlot.id}/to/${newSlot.id}/`
      )
      .then((response) => response.data);

  public createAppointmentSlot = (
    appointmentSlot: IAppointmentSlot
  ): Promise<IAppointmentSlot> =>
    this.axios
      .post(
        `${this.baseUrl}appointments/${appointmentSlot.appointment_id}/slots/`,
        appointmentSlot
      )
      .then((response) => response.data);

  public cancelAppointmentSlot = (
    appointmentSlot: IAppointmentSlot
  ): Promise<IAppointmentSlot> =>
    this.axios
      .get(
        `${this.baseUrl}appointments/${appointmentSlot.appointment_id}/slots/${appointmentSlot.id}/cancel/`
      )
      .then((response) => response.data);

  public cancelAppointmentSlotForApplication = (
    appointmentSlot: IAppointmentSlot,
    applicationId: number
  ): Promise<IAppointmentSlot> =>
    this.axios
      .get(
        `${this.baseUrl}appointments/${appointmentSlot.appointment_id}/slots/${appointmentSlot.id}/application/${applicationId}/cancel/`
      )
      .then((response) => response.data);

  public updateAppointmentSlot = (
    appointmentSlot: IAppointmentSlot
  ): Promise<IAppointmentSlot> =>
    this.axios
      .put(
        `${this.baseUrl}appointments/${appointmentSlot.appointment_id}/slots/${appointmentSlot.id}/`,
        appointmentSlot
      )
      .then((response) => response.data);

  public deleteAppointmentSlot = (
    appointmentSlot: IAppointmentSlot
  ): Promise<void> =>
    this.axios
      .delete(
        `${this.baseUrl}appointments/${appointmentSlot.appointment_id}/slots/${appointmentSlot.id}/`
      )
      .then((response) => response.data);
}
