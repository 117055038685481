import { action, observable } from 'mobx';
import VueI18n from 'vue-i18n';
import PushNotificationsApi from '../mj-api/push-notifications.api';
import { IWebPushDevice } from '../mj-models/web-push.interface';
import { IUserNotificationSettings } from '../mj-models/user.interface';

export default interface IPushNotificationStore {
  isOpen: boolean;
  i18n: VueI18n | null;

  savePushDevice: (device: IWebPushDevice) => Promise<boolean>;
  getUserNotificationSettings: (
    userId: number
  ) => Promise<IUserNotificationSettings | undefined>;
  updateUserNotificationSettings: (
    notificationSettings: IUserNotificationSettings
  ) => Promise<IUserNotificationSettings | undefined>;
}

export class PushNotificationStore implements IPushNotificationStore {
  @observable public isOpen: boolean = false;

  public i18n: VueI18n | null = null;
  public pushNotificationsApi: PushNotificationsApi;

  constructor(pushNotificationsApi: PushNotificationsApi) {
    this.pushNotificationsApi = pushNotificationsApi;
  }

  @action('SHOW MESSAGE WITH CLOSE BUTTON')
  public savePushDevice = async (device: IWebPushDevice): Promise<boolean> => {
    try {
      await this.pushNotificationsApi.savePushDevice(device);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('GET USER NOTIFICATION SETTINGS')
  public getUserNotificationSettings = async (
    userId: number
  ): Promise<IUserNotificationSettings | undefined> => {
    try {
      return await this.pushNotificationsApi.getUserNotificationSettings(
        userId
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE USER NOTIFICATION SETTINGS')
  public updateUserNotificationSettings = async (
    notificationSettings: IUserNotificationSettings
  ): Promise<IUserNotificationSettings | undefined> => {
    try {
      return await this.pushNotificationsApi.updateUserNotificationSettings(
        notificationSettings
      );
    } catch (error) {
      return undefined;
    }
  };
}
