import { action, observable } from 'mobx';
import ReleaseApi from '../mj-api/releases.api';
import { IRelease, IReleasePlatform } from '../mj-models/release.interface';

export default interface IReleaseStore {
  getLatestPlatformRelease: (
    releasePlatform: string
  ) => Promise<IRelease | false>;
}

export class ReleaseStore implements IReleaseStore {
  public releaseApi: ReleaseApi;

  constructor(releaseApi: ReleaseApi) {
    this.releaseApi = releaseApi;
  }

  @action('GET REFERENCE SURVEY')
  public getLatestPlatformRelease = async (
    releasePlatform: string
  ): Promise<IRelease | false> => {
    try {
      return await this.releaseApi.getLatestRelease(releasePlatform);
    } catch (error) {
      return false;
    }
  };
}
