import { render, staticRenderFns } from "./MjAppStoreDownload.vue?vue&type=template&id=3ce2686f&scoped=true&"
import script from "./MjAppStoreDownload.vue?vue&type=script&lang=ts&"
export * from "./MjAppStoreDownload.vue?vue&type=script&lang=ts&"
import style0 from "./MjAppStoreDownload.vue?vue&type=style&index=0&id=3ce2686f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../apps/application-process/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce2686f",
  null
  
)

export default component.exports