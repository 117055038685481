import { action, observable } from 'mobx';
import {
  IManualCandidateDetails,
  IManualCandidateCreation,
  IManualCandidateCreationError,
  responseHasError,
} from '../mj-models/manual-candidate.interface';
import ManualCandidateApi from '../mj-api/manual-candidate.api';

export default interface IManualCandidateStore {
  manualCandidateDetails: IManualCandidateDetails | null;

  createManualCandidate: (
    data: IManualCandidateCreation
  ) => Promise<IManualCandidateDetails | IManualCandidateCreationError | false>;
  updateManualCandidate: (
    id: number,
    data: IManualCandidateCreation
  ) => Promise<IManualCandidateDetails | false>;
  getManualCandidateDetails: (
    id: number
  ) => Promise<IManualCandidateDetails | false>;
  resendInvitationEmail: (
    manualCandidateCreationId: number
  ) => Promise<IManualCandidateDetails | false>;
}

export class ManualCandidateStore implements IManualCandidateStore {
  @observable public manualCandidateDetails: IManualCandidateDetails | null =
    null;

  private manualCandidateApi: ManualCandidateApi;

  constructor(manualCandidateApi: ManualCandidateApi) {
    this.manualCandidateApi = manualCandidateApi;
  }

  @action('CREATE MANUAL CANDIDATE')
  public createManualCandidate = async (
    data: IManualCandidateCreation
  ): Promise<
    IManualCandidateDetails | IManualCandidateCreationError | false
  > => {
    try {
      const result = await this.manualCandidateApi.createManualCandidate(data);

      return result;
    } catch (error) {
      const body = (error as any).response.data;

      if (responseHasError(body)) {
        return body as IManualCandidateCreationError;
      }

      return false;
    }
  };

  @action('UPDATE MANUAL CANDIDATE')
  public updateManualCandidate = async (
    id: number,
    data: IManualCandidateCreation
  ): Promise<IManualCandidateDetails | false> => {
    try {
      const result = await this.manualCandidateApi.updateManualCandidate(
        id,
        data
      );

      return result;
    } catch (error) {
      return false;
    }
  };

  @action('GET MANUAL CANDIDATE DETAILS')
  public getManualCandidateDetails = async (
    id: number
  ): Promise<IManualCandidateDetails | false> => {
    try {
      const result = await this.manualCandidateApi.getManualCandidateDetails(
        id
      );

      return result;
    } catch (error) {
      return false;
    }
  };

  @action('RESEND INVITATION EMAIL')
  public resendInvitationEmail = async (
    manualCandidateCreationId: number
  ): Promise<IManualCandidateDetails | false> => {
    try {
      const result = await this.manualCandidateApi.resendInvitationEmail(
        manualCandidateCreationId
      );

      return result;
    } catch (error) {
      return false;
    }
  };
}
