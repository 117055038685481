import { action, observable } from 'mobx';
import {
  DaysFilterOptions,
  ICandidateExperiencesInsight,
  ICandidateInteractionsInsights,
  ICandidateInteractionsOverviewInsights,
  ICandidatesInsights,
  ICandidatesOverviewInsights,
  IHiringProcessInsights,
  IInsightsCardData,
  IInsightsResponse,
  IInsightsStoreFilter,
  IJobFilter,
  IProcessCardsOpenStates,
  ISatisfactionOverviewInsights,
  ISourcingCardsOpenStates,
  ISourcingChannelOverviewInsights,
  ITimeOverviewInsights,
  OverviewCardId,
  ProcessesCardId,
  SourcingCardId,
  ViewStateChip,
  ViewStates,
  IInsightsStoreApiFilter,
  IInsightsStoreURLFilter,
  ISourcingChannelsInsights,
} from '../mj-models/insights.interface';
import AnalyticsApi from '../mj-api/analytics.api';
import { TextFieldEvent } from '../mj-models/events.interface';
import moment from 'moment';

export default interface IBusinessAnalyticsStore {
  currentUnitId: number | null;
  currentJobId: number | null;
  query: string;
  unitsFilterOptions: TextFieldEvent[];
  selectedUnitIds: number[];
  selectedDaysFilterOption: TextFieldEvent | null;
  daysFilterOptions: TextFieldEvent[];
  rolesFilterOptions: TextFieldEvent[];
  rolesFilterSearchTerm: string;
  selectedRolesFilterOptions: number[] | null;
  jobsFilterOptions: TextFieldEvent[];
  jobsFilterSearchTerm: string;
  selectedJobsFilterOptions: number[] | null;
  viewStatesChips: ViewStateChip[];
  sourcingViewStateOpenCards: ISourcingCardsOpenStates;
  processesViewStateOpenCards: IProcessCardsOpenStates;
  sourcingChannelsInsights: IInsightsCardData<ISourcingChannelsInsights>;
  candidatesExperiencesInsights: IInsightsCardData<ICandidateExperiencesInsight>;
  candidatesInteractionsInsights: IInsightsCardData<ICandidateInteractionsInsights>;
  hiringProcessInsights: IInsightsCardData<IHiringProcessInsights>;
  candidatesInsights: IInsightsCardData<ICandidatesInsights>;
  satisfactionOverviewInsights: IInsightsCardData<ISatisfactionOverviewInsights>;
  candidateInteractionsOverviewInsights: IInsightsCardData<ICandidateInteractionsOverviewInsights>;
  sourcingChannelsOverviewInsights: IInsightsCardData<ISourcingChannelOverviewInsights>;
  daysToHireOverviewInsights: IInsightsCardData<number | null>;
  candidatesOverviewInsights: IInsightsCardData<ICandidatesOverviewInsights>;
  overviewCardsDataShouldBeUpdated: boolean;
  urlFiltersDictionary: IInsightsStoreURLFilter | null;

  closeAllProcessesCardsExcept: (cardId?: ProcessesCardId) => void;
  closeAllSourcingCardsExcept: (cardId?: SourcingCardId) => void;
  onLoadOverviewCardAgain: (cardId: OverviewCardId) => void;
  onLoadProcessCardAgain: (
    cardId: ProcessesCardId,
    resetOthers: boolean
  ) => void;
  onLoadSourcingCardAgain: (cardId: SourcingCardId) => void;
  updateAllOpenCardsData: () => void;
  resetAllProcessesCardsData: () => void;
  resetAllSourcingCardsData: () => void;
  handleLoadingAllWidgets: () => void;
  onViewStateChange: (openViewStateId: ViewStates) => void;
  onCandidatesOverviewInsightsLoad: () => void;
  onTimeOverviewInsightsLoad: () => void;
  onSourcingChannelsOverviewInsightsLoad: () => void;
  onCandidateInteractionsOverviewInsightsLoad: () => void;
  onSatisfactionOverviewInsightsLoad: () => void;
  getCandidatesOverviewInsights: () => Promise<
    IInsightsResponse<ICandidatesOverviewInsights> | undefined
  >;
  getTimeOverviewInsights: () => Promise<
    IInsightsResponse<ITimeOverviewInsights> | undefined
  >;
  getSourcingChannelsOverviewInsights: () => Promise<
    IInsightsResponse<ISourcingChannelOverviewInsights> | undefined
  >;
  getCandidateInteractionsOverviewInsights: () => Promise<
    IInsightsResponse<ICandidateInteractionsOverviewInsights> | undefined
  >;
  getCandidateProcessInsights: () => Promise<
    IInsightsResponse<ICandidatesInsights> | undefined
  >;
  getHiringProcessInsights: () => Promise<
    IInsightsResponse<IHiringProcessInsights> | undefined
  >;
  getCandidateInteractionsProcessInsights: () => Promise<
    IInsightsResponse<ICandidateInteractionsInsights> | undefined
  >;

  getCandidateExperiencesProcessInsights: () => Promise<
    IInsightsResponse<ICandidateExperiencesInsight> | undefined
  >;

  getSatisfactionOverviewInsights: () => Promise<
    IInsightsResponse<ISatisfactionOverviewInsights> | undefined
  >;

  getSourcingChannelsInsights: () => Promise<
    IInsightsResponse<ISourcingChannelsInsights> | undefined
  >;

  getJobsFilterList: (unitId: number) => Promise<IJobFilter[] | undefined>;
  updateQuery: () => void;
  runMethodsStackOnQueryInitialized: () => void;
  generateStringQueryFromDictionary: (
    filters: IInsightsStoreApiFilter
  ) => string;
  getSelectedFiltersDictionary: () => IInsightsStoreFilter;
  isCurrentViewState: (viewStateId: ViewStates) => boolean | undefined;
}

export class BusinessAnalyticsStore implements IBusinessAnalyticsStore {
  public analyticsApi: AnalyticsApi;

  public methodsToRunOnQueryInitialized: string[] = [];

  @observable public query: string = '';

  @observable public currentUnitId: number | null = null;
  @observable public currentJobId: number | null = null;

  @observable public selectedUnitIds: number[] = [];

  @observable public unitsFilterOptions: TextFieldEvent[] = [];
  @observable public jobsFilterOptions: TextFieldEvent[] = [];
  @observable public jobsFilterSearchTerm: string = '';
  @observable public rolesFilterOptions: TextFieldEvent[] = [];
  @observable public rolesFilterSearchTerm: string = '';
  @observable public daysFilterOptions: TextFieldEvent[] = [];

  @observable public selectedDaysFilterOption: TextFieldEvent | null = null;
  @observable public selectedRolesFilterOptions: number[] | null = null;
  @observable public selectedJobsFilterOptions: number[] | null = null;

  @observable public urlFiltersDictionary: IInsightsStoreURLFilter | null =
    null;

  @observable public viewStatesChips: ViewStateChip[] = [
    { id: ViewStates.OVERVIEW, value: true },
    { id: ViewStates.SOURCING, value: false },
    { id: ViewStates.PROCESSES, value: false },
  ];
  @observable public sourcingViewStateOpenCards: ISourcingCardsOpenStates = {
    [SourcingCardId.sourcingChannels]: false,
    [SourcingCardId.deviceStatistics]: false,
  };
  @observable public processesViewStateOpenCards: IProcessCardsOpenStates = {
    [ProcessesCardId.candidates]: false,
    [ProcessesCardId.hiringProcess]: false,
    [ProcessesCardId.candidatesInteractions]: false,
    [ProcessesCardId.candidatesExperience]: false,
  };

  @observable
  public sourcingChannelsInsights: IInsightsCardData<ISourcingChannelsInsights> =
    {
      data: {},
      loading: true,
      failed: false,
    };

  @observable
  public candidatesExperiencesInsights: IInsightsCardData<ICandidateExperiencesInsight> =
    {
      data: {},
      loading: true,
      failed: false,
    };

  @observable
  public candidatesInteractionsInsights: IInsightsCardData<ICandidateInteractionsInsights> =
    {
      data: {},
      loading: true,
      failed: false,
    };

  @observable
  public hiringProcessInsights: IInsightsCardData<IHiringProcessInsights> = {
    data: {},
    loading: true,
    failed: false,
  };

  @observable
  public candidatesInsights: IInsightsCardData<ICandidatesInsights> = {
    data: {},
    loading: true,
    failed: false,
  };

  @observable
  public satisfactionOverviewInsights: IInsightsCardData<ISatisfactionOverviewInsights> =
    {
      data: {},
      loading: true,
      failed: false,
    };

  @observable
  public candidateInteractionsOverviewInsights: IInsightsCardData<ICandidateInteractionsOverviewInsights> =
    {
      data: {},
      loading: true,
      failed: false,
    };

  @observable
  public sourcingChannelsOverviewInsights: IInsightsCardData<ISourcingChannelOverviewInsights> =
    {
      data: {},
      loading: true,
      failed: false,
    };

  @observable public daysToHireOverviewInsights: IInsightsCardData<
    number | null
  > = {
    data: 0,
    loading: true,
    failed: false,
  };

  @observable
  public candidatesOverviewInsights: IInsightsCardData<ICandidatesOverviewInsights> =
    {
      data: {},
      loading: true,
      failed: false,
    };
  @observable
  public overviewCardsDataShouldBeUpdated: boolean = false;

  constructor(analyticsApi: AnalyticsApi) {
    this.analyticsApi = analyticsApi;
  }

  // Public methods

  public isCurrentViewState(viewStateId: ViewStates): boolean | undefined {
    return this.viewStatesChips.find((x) => x.id === viewStateId)?.value;
  }

  @action('ON CANDIDATE OVERVIEW INSIGHTS LOAD')
  public async onCandidatesOverviewInsightsLoad() {
    const candidatesOverviewInsights =
      await this.getCandidatesOverviewInsights();

    if (candidatesOverviewInsights && candidatesOverviewInsights.data) {
      this.candidatesOverviewInsights = {
        data: candidatesOverviewInsights.data,
        loading: false,
        failed: false,
      };
    } else {
      this.candidatesOverviewInsights.failed = true;
    }
  }

  @action('ON TIME OVERVIEW INSIGHTS LOAD')
  public async onTimeOverviewInsightsLoad() {
    const timeInsights = await this.getTimeOverviewInsights();

    if (timeInsights) {
      const timeInterval = (timeInsights.data as ITimeOverviewInsights)
        .interval_to_hire;

      let daysToHire = null;
      if (timeInterval) {
        const days = moment.duration(timeInterval).asDays();
        if (days && days < 1) {
          daysToHire = Math.round(days * 10) / 10;
        } else {
          daysToHire = Math.floor(days);
        }
      }

      this.daysToHireOverviewInsights = {
        data: daysToHire,
        loading: false,
        failed: false,
      };
    } else {
      this.daysToHireOverviewInsights.failed = true;
    }
  }

  @action('ON SOURCING CHANNELS OVERVIEW INSIGHTS LOAD')
  public async onSourcingChannelsOverviewInsightsLoad() {
    const sourcingChannelsInsights =
      await this.getSourcingChannelsOverviewInsights();

    if (sourcingChannelsInsights && sourcingChannelsInsights.data) {
      this.sourcingChannelsOverviewInsights = {
        data: sourcingChannelsInsights.data,
        loading: false,
        failed: false,
      };
    } else {
      this.sourcingChannelsOverviewInsights.failed = true;
    }
  }

  @action('ON CANDIDATE INTERACTIONS OVERVIEW INSIGHTS LOAD')
  public async onCandidateInteractionsOverviewInsightsLoad() {
    const candidateInteractions =
      await this.getCandidateInteractionsOverviewInsights();

    if (candidateInteractions && candidateInteractions.data) {
      this.candidateInteractionsOverviewInsights = {
        data: candidateInteractions.data,
        loading: false,
        failed: false,
      };
    } else {
      this.candidateInteractionsOverviewInsights.failed = true;
    }
  }

  @action('ON SATISFACTION OVERVIEW INSIGHTS LOAD')
  public async onSatisfactionOverviewInsightsLoad() {
    const response = await this.getSatisfactionOverviewInsights();

    if (response && response.data) {
      this.satisfactionOverviewInsights = {
        data: response.data,
        loading: false,
        failed: false,
      };
    } else {
      this.satisfactionOverviewInsights.failed = true;
    }
  }

  @action('UPDATE ALL SOURCING CARDS DATA')
  public resetAllSourcingCardsData() {
    this.closeAllSourcingCardsExcept();

    this.sourcingChannelsInsights = {
      data: {},
      loading: true,
      failed: false,
    };
  }

  @action('UPDATE ALL PROCESSES CARDS DATA')
  public resetAllProcessesCardsData() {
    this.closeAllProcessesCardsExcept();

    this.candidatesInsights = {
      data: {},
      loading: true,
      failed: false,
    };

    this.hiringProcessInsights = {
      data: {},
      loading: true,
      failed: false,
    };

    this.candidatesInteractionsInsights = {
      data: {},
      loading: true,
      failed: false,
    };

    this.candidatesExperiencesInsights = {
      data: {},
      loading: true,
      failed: false,
    };
  }

  @action('UPDATE ALL OPEN CARDS DATA')
  public updateAllOpenCardsData() {
    if (this.isCurrentViewState(ViewStates.OVERVIEW)) {
      this.updateOverviewCardsData();
      this.resetAllSourcingCardsData();
      this.resetAllProcessesCardsData();
    } else if (this.isCurrentViewState(ViewStates.SOURCING)) {
      if (this.sourcingViewStateOpenCards[SourcingCardId.sourcingChannels]) {
        this.sourcingChannelsInsights = {
          ...this.sourcingChannelsInsights,
          loading: true,
          failed: false,
        };
        this.onSourcingChannelsInsightsLoad();
      }

      this.overviewCardsDataShouldBeUpdated = true;
      this.resetAllProcessesCardsData();
    } else if (this.isCurrentViewState(ViewStates.PROCESSES)) {
      let cardIdToUpdate = null;

      if (this.processesViewStateOpenCards[ProcessesCardId.candidates]) {
        cardIdToUpdate = ProcessesCardId.candidates;
      } else if (
        this.processesViewStateOpenCards[ProcessesCardId.hiringProcess]
      ) {
        cardIdToUpdate = ProcessesCardId.hiringProcess;
      } else if (
        this.processesViewStateOpenCards[ProcessesCardId.candidatesInteractions]
      ) {
        cardIdToUpdate = ProcessesCardId.candidatesInteractions;
      } else if (
        this.processesViewStateOpenCards[ProcessesCardId.candidatesExperience]
      ) {
        cardIdToUpdate = ProcessesCardId.candidatesExperience;
      }

      if (cardIdToUpdate) {
        this.onLoadProcessCardAgain(cardIdToUpdate, true);
      }

      this.overviewCardsDataShouldBeUpdated = true;
      this.resetAllSourcingCardsData();
    }
  }

  @action('ON VIEW STATE CHANGE')
  public onViewStateChange(openViewStateId: ViewStates) {
    if (
      openViewStateId === ViewStates.OVERVIEW &&
      this.overviewCardsDataShouldBeUpdated
    ) {
      this.updateOverviewCardsData();
      this.overviewCardsDataShouldBeUpdated = false;
    }
    this.viewStatesChips = this.viewStatesChips.map(
      (viewState: ViewStateChip) => {
        return {
          ...viewState,
          value: viewState.id === openViewStateId,
        };
      }
    );
  }

  @action('CLOSE ALL PROCESS CARDS EXCEPT')
  public closeAllProcessesCardsExcept(cardId?: ProcessesCardId): void {
    if (cardId) {
      if (cardId === ProcessesCardId.candidates) {
        if (Object.keys(this.candidatesInsights.data!).length === 0) {
          if (!!this.query) {
            this.onCandidateProcessInsightsLoad();
          } else {
            this.methodsToRunOnQueryInitialized = [
              'onCandidateProcessInsightsLoad',
            ];
          }
        }
      } else if (cardId === ProcessesCardId.hiringProcess) {
        if (Object.keys(this.hiringProcessInsights.data!).length === 0) {
          if (!!this.query) {
            this.onHiringProcessInsightsLoad();
          } else {
            this.methodsToRunOnQueryInitialized = [
              'onHiringProcessInsightsLoad',
            ];
          }
        }
      } else if (cardId === ProcessesCardId.candidatesInteractions) {
        if (
          Object.keys(this.candidatesInteractionsInsights.data!).length === 0
        ) {
          if (!!this.query) {
            this.onCandidateInteractionsProcessInsightsLoad();
          } else {
            this.methodsToRunOnQueryInitialized = [
              'onCandidateInteractionsProcessInsightsLoad',
            ];
          }
        }
      } else if (cardId === ProcessesCardId.candidatesExperience) {
        if (
          Object.keys(this.candidatesExperiencesInsights.data!).length === 0
        ) {
          if (!!this.query) {
            this.onCandidateExperiencesProcessInsightsLoad();
          } else {
            this.methodsToRunOnQueryInitialized = [
              'onCandidateExperiencesProcessInsightsLoad',
            ];
          }
        }
      }
    }
    this.processesViewStateOpenCards = {
      [ProcessesCardId.candidates]: cardId === ProcessesCardId.candidates,
      [ProcessesCardId.hiringProcess]: cardId === ProcessesCardId.hiringProcess,
      [ProcessesCardId.candidatesInteractions]:
        cardId === ProcessesCardId.candidatesInteractions,
      [ProcessesCardId.candidatesExperience]:
        cardId === ProcessesCardId.candidatesExperience,
    };
  }

  @action('CLOSE ALL SOURCING CARDS EXCEPT')
  public closeAllSourcingCardsExcept(cardId?: SourcingCardId) {
    if (cardId) {
      if (cardId === SourcingCardId.sourcingChannels) {
        if (Object.keys(this.sourcingChannelsInsights.data!).length === 0) {
          if (!!this.query) {
            this.onSourcingChannelsInsightsLoad();
          } else {
            this.methodsToRunOnQueryInitialized.push(
              'onSourcingChannelsInsightsLoad'
            );
          }
        }
      } else if (cardId === SourcingCardId.deviceStatistics) {
      }
    }
    this.sourcingViewStateOpenCards = {
      [SourcingCardId.sourcingChannels]:
        cardId === SourcingCardId.sourcingChannels,
      [SourcingCardId.deviceStatistics]:
        cardId === SourcingCardId.deviceStatistics,
    };
  }

  @action('LOAD OVERVIEW AGAIN')
  public onLoadOverviewCardAgain = (cardId: OverviewCardId) => {
    if (cardId === OverviewCardId.candidates) {
      this.candidatesOverviewInsights = {
        ...this.candidatesOverviewInsights,
        loading: true,
        failed: false,
      };
      this.onCandidatesOverviewInsightsLoad();
    } else if (cardId === OverviewCardId.time) {
      this.daysToHireOverviewInsights = {
        ...this.daysToHireOverviewInsights,
        loading: true,
        failed: false,
      };
      this.onTimeOverviewInsightsLoad();
    } else if (cardId === OverviewCardId.sourcingChannels) {
      this.sourcingChannelsOverviewInsights = {
        ...this.sourcingChannelsOverviewInsights,
        loading: true,
        failed: false,
      };
      this.onSourcingChannelsOverviewInsightsLoad();
    } else if (cardId === OverviewCardId.conversion) {
      this.sourcingChannelsOverviewInsights = {
        ...this.sourcingChannelsOverviewInsights,
        loading: true,
        failed: false,
      };
      this.onSourcingChannelsOverviewInsightsLoad();
    } else if (cardId === OverviewCardId.candidateInterations) {
      this.candidateInteractionsOverviewInsights = {
        ...this.candidateInteractionsOverviewInsights,
        loading: true,
        failed: false,
      };
      this.onCandidateInteractionsOverviewInsightsLoad();
    } else if (cardId === OverviewCardId.satisfaction) {
      this.satisfactionOverviewInsights = {
        ...this.satisfactionOverviewInsights,
        loading: true,
        failed: false,
      };
      this.onSatisfactionOverviewInsightsLoad();
    }
  };

  @action('LOAD PROCESS AGAIN')
  public onLoadProcessCardAgain(
    cardId: ProcessesCardId,
    resetOthers: boolean = false
  ) {
    if (cardId === ProcessesCardId.candidates) {
      this.candidatesInsights = {
        ...this.candidatesInsights,
        loading: true,
        failed: false,
      };
      this.onCandidateProcessInsightsLoad();
      if (resetOthers) {
        this.candidatesExperiencesInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.candidatesInteractionsInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.hiringProcessInsights = {
          data: {},
          loading: true,
          failed: false,
        };
      }
    } else if (cardId === ProcessesCardId.hiringProcess) {
      this.hiringProcessInsights = {
        ...this.hiringProcessInsights,
        loading: true,
        failed: false,
      };
      this.onHiringProcessInsightsLoad();
      if (resetOthers) {
        this.candidatesExperiencesInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.candidatesInteractionsInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.candidatesInsights = {
          data: {},
          loading: true,
          failed: false,
        };
      }
    } else if (cardId === ProcessesCardId.candidatesInteractions) {
      this.candidatesInteractionsInsights = {
        ...this.candidatesInteractionsInsights,
        loading: true,
        failed: false,
      };
      this.onCandidateInteractionsProcessInsightsLoad();
      if (resetOthers) {
        this.candidatesExperiencesInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.hiringProcessInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.candidatesInsights = {
          data: {},
          loading: true,
          failed: false,
        };
      }
    } else if (cardId === ProcessesCardId.candidatesExperience) {
      this.candidatesExperiencesInsights = {
        ...this.candidatesExperiencesInsights,
        loading: true,
        failed: false,
      };
      this.onCandidateExperiencesProcessInsightsLoad();
      if (resetOthers) {
        this.hiringProcessInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.candidatesInsights = {
          data: {},
          loading: true,
          failed: false,
        };
        this.candidatesInteractionsInsights = {
          data: {},
          loading: true,
          failed: false,
        };
      }
    }
  }

  @action('LOAD SOURCING CARD AGAIN')
  public onLoadSourcingCardAgain(cardId: SourcingCardId) {
    if (cardId === SourcingCardId.sourcingChannels) {
      this.sourcingChannelsInsights = {
        ...this.sourcingChannelsInsights,
        loading: true,
        failed: false,
      };
      this.onSourcingChannelsInsightsLoad();
    }
  }

  @action('GET CANDIDATES OVERVIEW INSIGHTS')
  public getCandidatesOverviewInsights = async (): Promise<
    IInsightsResponse<ICandidatesOverviewInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchCandidatesOverviewInsights(
        this.query
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET CANDIDATES OVERVIEW INSIGHTS')
  public getTimeOverviewInsights = async (): Promise<
    IInsightsResponse<ITimeOverviewInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchTimeOverviewInsights(this.query);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET SOURCING CHANNELS OVERVIEW INSIGHTS')
  public getSourcingChannelsOverviewInsights = async (): Promise<
    IInsightsResponse<ISourcingChannelOverviewInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchSourcingChannelsOverviewInsights(
        this.query
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET CANDIDATE INTERACTIONS OVERVIEW INSIGHTS')
  public getCandidateInteractionsOverviewInsights = async (): Promise<
    IInsightsResponse<ICandidateInteractionsOverviewInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchCandidateInteractionsOverviewInsights(
        this.query
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET SATISFACTION OVERVIEW INSIGHTS')
  public getSatisfactionOverviewInsights = async (): Promise<
    IInsightsResponse<ISatisfactionOverviewInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchSatisfactionOverviewInsights(
        this.query
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET CANDIDATE PROCESS INSIGHTS')
  public getCandidateProcessInsights = async (): Promise<
    IInsightsResponse<ICandidatesInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchCandidateProcessInsights(this.query);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET HIRING PROCESS INSIGHTS')
  public getHiringProcessInsights = async (): Promise<
    IInsightsResponse<IHiringProcessInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchHiringProcessInsights(this.query);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET CANDIDATE INTERACTIONS PROCESS INSIGHTS')
  public getCandidateInteractionsProcessInsights = async (): Promise<
    IInsightsResponse<ICandidateInteractionsInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchCandidateInteractionsProcessInsights(
        this.query
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET CANDIDATE EXPERIENCES PROCESS INSIGHTS')
  public getCandidateExperiencesProcessInsights = async (): Promise<
    IInsightsResponse<ICandidateExperiencesInsight> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchCandidateExperiencesProcessInsights(
        this.query
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET SOURCING CHANNELS FUNNEL INSIGHTS')
  public getSourcingChannelsInsights = async (): Promise<
    IInsightsResponse<ISourcingChannelsInsights> | undefined
  > => {
    try {
      return await this.analyticsApi.fetchSourcingChannelsInsights(this.query);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET JOBS FILTER LIST')
  public getJobsFilterList = async (
    unitId: number
  ): Promise<IJobFilter[] | undefined> => {
    try {
      return await this.analyticsApi.fetchJobsFilterList(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('HANDLE LOADING ALL WIDGETS')
  public handleLoadingAllWidgets() {
    // Show loading indicators on all widgets
    this.sourcingChannelsInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.candidatesExperiencesInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.candidatesInteractionsInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.hiringProcessInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.candidatesInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.satisfactionOverviewInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.candidateInteractionsOverviewInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.sourcingChannelsOverviewInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.daysToHireOverviewInsights = {
      data: 0,
      loading: true,
      failed: false,
    };
    this.candidatesOverviewInsights = {
      data: {},
      loading: true,
      failed: false,
    };
    this.updateAllOpenCardsData();
  }

  @action('GET SELECTED FILTERS DICTIONARY')
  public getSelectedFiltersDictionary(): IInsightsStoreFilter {
    let filters = {};
    let apiFilterDates = {};
    let urlFilterDates = {};

    if (this.selectedDaysFilterOption?.id) {
      let fromDate = new Date();
      let toDate: Date | undefined;

      switch (this.selectedDaysFilterOption?.id) {
        case DaysFilterOptions.LAST_7_DAYS:
          fromDate.setDate(fromDate.getDate() - 7);
          break;
        case DaysFilterOptions.LAST_2_WEEKS:
          fromDate.setDate(fromDate.getDate() - 14);
          break;
        case DaysFilterOptions.LAST_30_DAYS:
          fromDate.setDate(fromDate.getDate() - 30);
          break;
        case DaysFilterOptions.LAST_90_DAYS:
          fromDate.setDate(fromDate.getDate() - 90);
          break;
        case DaysFilterOptions.LAST_180_DAYS:
          fromDate.setDate(fromDate.getDate() - 180);
          break;
        case DaysFilterOptions.LAST_CALENDAR_YEAR:
          fromDate = new Date(new Date().getFullYear() - 1, 0, 1);
          toDate = new Date(new Date().getFullYear() - 1, 11, 31);
          break;
        case DaysFilterOptions.CURRENT_CALENDAR_YEAR:
          fromDate = new Date(new Date().getFullYear(), 0, 1);
          break;
        case DaysFilterOptions.ALL_TIME:
          fromDate = new Date(2005, 0, 1);
          break;
      }

      apiFilterDates = {
        from_date: moment(fromDate).format('yyyy-MM-DD'),
      };

      if (toDate) {
        apiFilterDates = {
          ...apiFilterDates,
          to_date: moment(toDate).format('yyyy-MM-DD'),
        };
      }
    }

    if (this.selectedUnitIds && this.selectedUnitIds.length > 0) {
      filters = {
        ...filters,
        unit_ids: this.selectedUnitIds.join(','),
      };
    } else {
      filters = {
        ...filters,
        unit_ids: this.unitsFilterOptions
          .map((filterOption: TextFieldEvent) => filterOption.id)
          .join(','),
      };
    }

    if (
      this.selectedRolesFilterOptions &&
      this.selectedRolesFilterOptions.length > 0
    ) {
      filters = {
        ...filters,
        position_function_ids: this.selectedRolesFilterOptions.join(','),
      };
    }

    if (
      this.selectedJobsFilterOptions &&
      this.selectedJobsFilterOptions.length > 0
    ) {
      filters = {
        ...filters,
        job_ids: this.selectedJobsFilterOptions.join(','),
      };
    }

    if (this.selectedDaysFilterOption && this.selectedDaysFilterOption.id) {
      urlFilterDates = {
        date_range: this.selectedDaysFilterOption.id,
      };
    }

    const apiFilter = { ...filters, ...apiFilterDates };
    const urlFilter = { ...filters, ...urlFilterDates };

    return {
      api_filter: apiFilter,
      url_filter: urlFilter,
    };
  }

  @action('UPDATE QUERY')
  public updateQuery(): void {
    const selectedFiltersDictionary = this.getSelectedFiltersDictionary();

    this.query = `${this.generateStringQueryFromDictionary({
      ...selectedFiltersDictionary.api_filter,
    })}`;

    this.urlFiltersDictionary = selectedFiltersDictionary.url_filter || null;
  }

  @action('RUN METHODS STACK ON QUERY INITIALIZED')
  public runMethodsStackOnQueryInitialized(): void {
    if (!!this.methodsToRunOnQueryInitialized) {
      this.methodsToRunOnQueryInitialized.forEach((methodName: string) => {
        // @ts-ignore
        this[methodName]();
      });

      this.methodsToRunOnQueryInitialized = [];
    }
  }

  @action('GENERATE STRING QUERY FROM DICTIONARY')
  public generateStringQueryFromDictionary(
    filters: IInsightsStoreApiFilter
  ): string {
    let query = '';

    Object.keys(filters).forEach((key: string) => {
      if (query === '') {
        query = '?';
      } else {
        query += '&';
      }
      // @ts-ignore
      const filterValue = filters[key];
      query += `${key}=${filterValue}`;
    });

    return query;
  }

  // Private methods

  @action('ON CANDIDATE PROCESS INSIGHTS LOAD')
  private async onCandidateProcessInsightsLoad() {
    const response = await this.getCandidateProcessInsights();

    if (response && response.data) {
      this.candidatesInsights = {
        data: response.data,
        loading: false,
        failed: false,
      };
    } else {
      this.candidatesInsights.failed = true;
    }
  }

  @action('ON HIRING PROCESS INSIGHTS LOAD')
  private async onHiringProcessInsightsLoad() {
    const response = await this.getHiringProcessInsights();

    if (response && response.data) {
      this.hiringProcessInsights = {
        data: response.data,
        loading: false,
        failed: false,
      };
    } else {
      this.hiringProcessInsights.failed = true;
    }
  }

  @action('ON CANDIDATE INTERACTIONS PROCESS INSIGHTS LOAD')
  private async onCandidateInteractionsProcessInsightsLoad() {
    const response = await this.getCandidateInteractionsProcessInsights();

    if (response && response.data) {
      this.candidatesInteractionsInsights = {
        data: response.data,
        loading: false,
        failed: false,
      };
    } else {
      this.candidatesInteractionsInsights.failed = true;
    }
  }

  @action('ON CANDIDATE EXPERIENCES PROCESS INSIGHTS LOAD')
  private async onCandidateExperiencesProcessInsightsLoad() {
    const response = await this.getCandidateExperiencesProcessInsights();

    if (response && response.data) {
      this.candidatesExperiencesInsights = {
        data: response.data,
        loading: false,
        failed: false,
      };
    } else {
      this.candidatesExperiencesInsights.failed = true;
    }
  }

  @action('ON SOURCING CHANNEL INSIGHTS LOAD')
  private async onSourcingChannelsInsightsLoad() {
    const response = await this.getSourcingChannelsInsights();

    if (response && response.data) {
      this.sourcingChannelsInsights = {
        data: response.data,
        loading: false,
        failed: false,
      };
    } else {
      this.sourcingChannelsInsights = {
        data: {},
        loading: true,
        failed: true,
      };
    }
  }

  @action('ON CANDIDATE EXPERIENCES PROCESS INSIGHTS LOAD')
  private updateOverviewCardsData() {
    this.candidatesOverviewInsights = {
      ...this.candidatesOverviewInsights,
      loading: true,
      failed: false,
    };
    this.onCandidatesOverviewInsightsLoad();

    this.daysToHireOverviewInsights = {
      ...this.daysToHireOverviewInsights,
      loading: true,
      failed: false,
    };
    this.onTimeOverviewInsightsLoad();

    this.sourcingChannelsOverviewInsights = {
      ...this.sourcingChannelsOverviewInsights,
      loading: true,
      failed: false,
    };
    this.onSourcingChannelsOverviewInsightsLoad();

    this.candidateInteractionsOverviewInsights = {
      ...this.candidateInteractionsOverviewInsights,
      loading: true,
      failed: false,
    };
    this.onCandidateInteractionsOverviewInsightsLoad();

    this.satisfactionOverviewInsights = {
      ...this.satisfactionOverviewInsights,
      loading: true,
      failed: false,
    };
    this.onSatisfactionOverviewInsightsLoad();
  }
}
