import BaseApi from './base.api';
import {
  ICampaignUrl,
  IJobBoardSpecificationTag,
  IJobSourcing,
} from '../mj-models/job-sourcing.interface';
import { IPage } from '../mj-models/utils.interface';
import {
  IFinnAdvertisement,
  IFinnAdvertisementValues,
} from '../mj-models/finn-advertisement.interface';

export default class JobSourcingApi extends BaseApi {
  public getJobSourcing = (jobId: number): Promise<IJobSourcing> =>
    this.axios
      .get(`${this.baseUrl}sourcing/jobs/${jobId}/job-sourcing/`)
      .then((response) => response.data);

  public updateJobSourcing = (jobSourcing: IJobSourcing) => {
    return this.axios
      .put<IJobSourcing>(
        `${this.baseUrl}sourcing/jobs/${jobSourcing.job_id}/job-sourcing/`,
        jobSourcing
      )
      .then((response) => response.data);
  };

  public getJobBoardSpecificationTags = () => {
    return this.axios
      .get<IPage<IJobBoardSpecificationTag>>(
        `${this.baseUrl}sourcing/board-specification-tags/`
      )
      .then((response) => response.data);
  };

  public createCampaignUrl(url: ICampaignUrl) {
    return this.axios
      .post<ICampaignUrl>(`${this.baseUrl}sourcing/campaign-urls/`, url)
      .then((response) => response.data);
  }

  public createFinnAdvertisement = (
    boardId: number
  ): Promise<IFinnAdvertisement> =>
    this.axios
      .get(`${this.baseUrl}finn/job-boards/${boardId}/recreate-finn/`)
      .then((response) => response.data);

  public getFinnAdvertisement = (
    unitId: number
  ): Promise<IFinnAdvertisementValues> =>
    this.axios
      .get(`${this.baseUrl}finn/values/${unitId}/`)
      .then((response) => response.data);

  public updateFinnAd = (
    finnAd: IFinnAdvertisement
  ): Promise<IFinnAdvertisement> =>
    this.axios
      .put(`${this.baseUrl}finn/ad/${finnAd.id!}/`, finnAd)
      .then((response) => response.data);

  public stopFinnAds = (finnAdId: number): Promise<string> =>
    this.axios
      .get(`${this.baseUrl}finn/ad/${finnAdId!}/stop-ads/`)
      .then((response) => response.data);

  public getJobCampaignUrl = (jobId: number): Promise<ICampaignUrl> =>
    this.axios
      .post(`${this.baseUrl}sourcing/campaign-urls/jobs/${jobId}/`)
      .then((response) => response.data);
}
