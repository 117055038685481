



















import Card from 'material-components-vue/dist/card/card.min.js';
import { Component, Prop, Vue } from 'vue-property-decorator';

import MjTypographyBody from '../../atoms/mj-typography/MjTypographyBody.vue';
import MjTypographyOverline from '../../atoms/mj-typography/MjTypographyOverline.vue';

Vue.use(Card);

@Component({
  inheritAttrs: false,
  components: { MjTypographyOverline, MjTypographyBody },
})
export default class MjJobInfoCard extends Vue {
  @Prop({ default: null, type: String }) public readonly logoUrl?: string;
  @Prop({ default: '', type: String }) public readonly jobName?: string;
  @Prop({ default: '', type: String }) public jobAddress!: string;
  @Prop({ default: '', type: String }) public readonly unitName?: string;
}
