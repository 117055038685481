import BaseApi from './base.api';
import { IPage } from '../mj-models/utils.interface';

export default class NotificationsApi extends BaseApi {
  public getNotifications = (
    unitId: number,
    page: number,
    filterOption: string = '',
    pageSize: number = 30
  ): Promise<IPage<any>> =>
    this.axios
      .get(
        `${
          this.baseUrl
        }notifications/unit/${unitId}/recruiter/feed/?page_size=${pageSize}&page=${page}${
          !!filterOption ? `&ql=notification_type = "${filterOption}"` : ''
        }`
      )
      .then((response) => response.data);
}
