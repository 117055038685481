import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class ScreenSizeMixin extends Vue {
  private previousWidth = window.innerWidth;

  private screenSize: IScreenSize = {
    width: window.innerWidth,
  };

  public get isMobile() {
    return this.screenSize && this.screenSize.width
      ? this.screenSize.width < 816
      : false;
  }

  public get isPad() {
    return this.screenSize.width >= 816 && this.screenSize.width < 1024;
  }

  public created() {
    window.addEventListener('resize', this.resizeHandler);
  }

  public destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  public resizeHandler() {
    /*
    Fires only if the resize was horizontal, in order to fix problems with UI on mobile devices

    On mobile they keyboard appeared from the bottom and fired the resize HTML event,
    because the website resized vertically.
    This resulted in an unnecessary re-render and destroyed an opened MjHistoryItem popup
    */
    if (this.previousWidth !== window.innerWidth) {
      this.screenSize = {
        width: window.innerWidth,
      };
    }
  }
}

interface IScreenSize {
  width: number;
}
