import { IJob } from './job.interface';
import { JobProcessAppNps } from './process.interface';
import IUnit from './unit.interface';
import { IUser } from './user.interface';

export interface NpsSurvey {
  id?: string;
  app_message_id?: number;
  unit?: IUnit;
  job?: IJob;
  nps_question?: JobProcessAppNps;
  applicant?: IUser;
  created_at?: string;
  updated_at?: string;
}

export interface NpsSurveyAnswer {
  id?: number;
  nps_survey_id?: string;
  score?: number;
  comment_text?: string;
  created_at?: string;
  updated_at?: string;
}

export enum NpsRespondentType {
  PROMOTER = 'PROMOTER',
  DETRACTOR = 'DETRACTOR',
  PASSIVE = 'PASSIVE',
}
