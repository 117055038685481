import {
  IBlogListing,
  ICategory,
  IPost,
  IPostTag,
} from '../mj-models/blog.interface';
import { IPage } from '../mj-models/utils.interface';
import BaseApi from './base.api';

const defaultPageSize = 6;

export default class BlogApi extends BaseApi {
  public getCategories = async () => {
    const categories: ICategory[] = await this.axios
      .get(`${process.env.VUE_APP_API_URL}blogs/categories/?page_size=1000`)
      .then((response: any) => {
        return response.data.results;
      });
    return [...categories];
  };

  public getTags = async () => {
    const tags: IPostTag[] = await this.axios
      .get(`${process.env.VUE_APP_API_URL}blogs/tags/?page_size=1000`)
      .then((response: any) => {
        return response.data.results;
      });
    return [...tags];
  };

  public getAllPosts = async (page: number = 1, pageSize = defaultPageSize) => {
    const posts: IPage<IPost> = await this.axios
      .get(
        `${process.env.VUE_APP_API_URL}blogs/?page=${page}&page_size=${pageSize}&ordering=-blog_post`
      )
      .then((response: any) => {
        return response.data;
      });
    return { ...posts };
  };

  public getUnitBlogListings = async (
    unitId: number,
    page: number = 1,
    pageSize = defaultPageSize
  ) => {
    const posts: IPage<IBlogListing> = await this.axios
      .get(
        `${process.env.VUE_APP_API_URL}blogs/?unit_id=${unitId}&page=${page}&page_size=${pageSize}&ordering=-blog_post`
      )
      .then((response: any) => {
        return response.data;
      });
    return { ...posts };
  };

  public getUnitBlogListing = async (
    subdomainSlug: string,
    categorySlug: string,
    titleSlug: string
  ) => {
    const posts: IPage<IBlogListing> = await this.axios
      .get(
        `${process.env.VUE_APP_API_URL}blogs/${subdomainSlug}/${categorySlug}/${titleSlug}`
      )
      .then((response: any) => {
        return response.data;
      });
    return { ...posts };
  };

  public getBlogListing = (blogListingId: number): Promise<IBlogListing> =>
    this.axios
      .get(`${this.baseUrl}blogs/${blogListingId}/`)
      .then((response) => response.data);

  public updateBlogListing = (
    blogListing: IBlogListing
  ): Promise<IBlogListing> =>
    this.axios
      .put(`${this.baseUrl}blogs/${blogListing.id}/`, blogListing)
      .then((response) => response.data);

  public createBlogListing = (
    blogListing: IBlogListing
  ): Promise<IBlogListing> =>
    this.axios
      .post(`${this.baseUrl}blogs/`, blogListing)
      .then((response) => response.data);

  public createBlogTag = (tag: IPostTag): Promise<IPostTag> =>
    this.axios
      .post(`${this.baseUrl}blogs/tags/`, tag)
      .then((response) => response.data);

  public createBlogCategory = (cat: ICategory): Promise<IPostTag> =>
    this.axios
      .post(`${this.baseUrl}blogs/categories/`, cat)
      .then((response) => response.data);

  public getCategorizedPosts = async (
    categoryId: number,
    page = 1,
    pageSize = defaultPageSize
  ) => {
    const posts: IPage<IPost> = await this.axios
      .get(
        `${process.env.VUE_APP_API_URL}blogs/?blog_post__category=${categoryId}&page_size=${pageSize}&page=${page}&ordering=-blog_post`
      )
      .then((response: any) => {
        return response.data;
      });
    return { ...posts };
  };

  /*
  DO NOT REMOVE

  Nuxt was giving a warning:
  WARN  Cannot stringify arbitrary non-POJOs BlogApi

  Nuxt uses @nuxt/devalue for serializing POJOs (Plain Old JavaScript Objects).
  However an instance of BlogApi is a non-POJO due to using ES6 class syntax.

  Example POJO:
  let pojo = new Object()
  let pojo2 = {field: 'val'}

  Example non-POJO:
  let nonPojo = new BlogApi(...)

  @nuxt/devalue doesn't handle serializing non-POJOs,
  therefore specifying a toJSON() method below explicitly has fixed the warning.
  */
  public toJSON() {
    return Object.getOwnPropertyNames(this).reduce((a, b) => {
      // @ts-ignore
      a[b] = this[b];
      return a;
    }, {});
  }
}
