import { IUser, IUserDocument } from './user.interface';

export interface IManualCandidateCreation {
  job_id: number;
  process_step_id: number;
  should_send_invitation_email: boolean;
  invitation_email_text?: string;
  user: IUser;
  user_document_ids: number[];
}

export interface IManualCandidateCreationError {
  detail: 'EMAIL_IN_USE';
}

export function responseHasError(object: any): object is IManualCandidateCreationError {
  return 'detail' in object;
}

export interface IManualCandidateDetails {
  id: number;
  author_id: number;
  author: IUser;
  application_id: number;
  job_id: number;
  process_step_id: number;
  manual_applicant: IUser;
  invitation_email_sent_at: string | null;
  invitation_email_opened_at: string | null;
  invitation_email_text: string;
  should_send_invitation_email: boolean;
  latest_json: IManualCandidateCreation;
  user_documents: IUserDocument[];
}
