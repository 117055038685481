import { MapBoundaryBox } from './job-feed.interface';
import { IJobListing } from './job-listing.interface';
import {
  IJobProcess,
  JobProcessAppScreeningQuestion,
} from './process.interface';
import IUnit, { Role } from './unit.interface';
import { IPoint, IUser } from './user.interface';

export enum ShareJobAction {
  onCopyJobListingLink = 'onCopyJobListingLink',
  onShareOnFacebook = 'onShareOnFacebook',
  onShareOnTwitter = 'onShareOnTwitter',
  onShareOnLinkedIn = 'onShareOnLinkedIn',
  onShareViaEmail = 'onShareViaEmail',
}

export enum MoreJobAction {
  onCreateManualCandidate = 'onCreateManualCandidate',
  onExportAll = 'onExportAll',
}

export interface IJobActionPermission {
  permission?: boolean;
  role?: Role;
  has_job_assignment?: boolean;
}

export interface IJobs {
  count: number;
  next: string;
  previous: null;
  results: IJob[];
}

export enum PostNewJobState {
  use_template,
  use_previous_posting,
  use_draft,
}

export enum JobOpeningFilterOption {
  ALL = 0,
  LIVE = 1,
  UNPUBLISHED = 2,
  COMPLETED = 3,
  DRAFT = 4,
}

export enum JobNotificationFilterOption {
  ALL = 0,
  NEW_APPLICATIONS = 1,
  STATE_UPDATES = 2,
  VIDEO_INTERVIEWS = 3,
  MESSAGES = 4,
  NOTES = 5,
  REFERENCE_SURVEY = 6,
  SCRIVE = 7,
}

export enum SalaryType {
  FIXED = 0,
  HOURLY = 1,
  COMMISSION = 2,
  HOURLY_COMMISSION = 3,
  FIXED_COMMISSION = 4,
  NOT_SPECIFIED = 6,
}

export enum RateType {
  RATE_UNDEFINED = 0,
  RATE_100_125 = 1,
  RATE_125_150 = 2,
  RATE_150_175 = 3,
  RATE_175_200 = 4,
  RATE_200_225 = 5,
  RATE_225_250 = 6,
}

export enum EmploymentType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  CONTRACTOR = 'CONTRACTOR',
  TEMPORARY = 'TEMPORARY',
  INTERN = 'INTERN',
  VOLUNTEER = 'VOLUNTEER',
  PER_DIEM = 'PER_DIEM',
  CALLING_HELP = 'CALLING_HELP',
  OTHER = 'OTHER', // or not specified
}

export enum JobEditorAction {
  PUBLISH = 'PUBLISH',
  DONE = 'DONE',
  SUBMIT_CHANGES = 'SUBMIT_CHANGES',
}

export interface IPositionFunction {
  [key: string]: any;
  parent_id?: number;
  id?: number;
  created_at?: string;
  updated_at?: string;
  name_en?: string;
  name_nb?: string;
  finn_occupation_code?: string;
  styrk_codes?: string[];
  children?: IPositionFunction[];
}

export const defaultJob = {} as IJob;

export interface IStaffingAssignment {
  id?: number;
  title?: string;
  internal_title?: string;
  unit?: IUnit;
  poster?: IUser;
  unit_id?: number;
  unit_logo_url?: string;
  unit_name?: string;
  created_at?: string;
}

export interface ITalentPool {
  id?: number;
  title?: string;
  internal_title?: string;
  unit?: IUnit;
  poster?: IUser;
  unit_id?: number;
  unit_logo_url?: string;
  unit_name?: string;
  created_at?: string;
}

export interface IJob {
  id?: number;
  title?: string;
  internal_title?: string;
  unit?: IUnit;
  unit_id?: number;
  unit_logo_url?: string;
  unit_favicon_url?: string;
  unit_name?: string;
  unit_display_name?: string;
  address?: string;
  image?: string;
  category?: number;
  position_function?: IPositionFunction;
  position_function_id?: number;
  image_thumbnail?: string;
  image_media_id?: number;
  is_published?: boolean;
  is_live?: boolean;
  is_unpublished?: boolean;
  is_completed?: boolean;
  is_draft?: boolean;
  is_template?: boolean;
  num_positions?: number;
  num_applicants?: number;
  num_new_applicants?: number;
  num_rejected_applicants?: number;
  num_hired_applicants?: number;
  num_waiting_list_applicants?: number;
  num_applicants_in_process?: number;
  num_unread_notifications?: number;
  language?: string;
  updated_at?: string;
  created_at?: string;
  due_date?: string | null;
  completed_date?: string;
  unpublished_date?: string;
  poster_id?: number;
  poster?: IUser;
  salary?: RateType;
  salary_amount?: number;
  salary_type?: SalaryType;
  currency?: string;
  lon?: number;
  lat?: number;
  apply_url?: string;
  questions?: JobProcessAppScreeningQuestion[];
  use_fast_apply?: boolean;
  publisher_is_draft?: boolean;
  job_assignments?: any[];
  publisher_published_at?: string;
  publisher_linked_id?: number | null;
  publisher_unpublished_at?: string;
  publisher_draft_id?: number;
  latest_activity?: string;
  last_application_move_at?: string;
  employment_type?: EmploymentType;
  country_code?: string;
  country_subdivision_code?: string;
  point?: IPoint;
  industry_id?: number;
  education_id?: number;
  og_graph_id?: number;
  job_listing_id?: number;
  has_webtemp_integration?: boolean;
  hide_for_not_assigned_users?: boolean;
  // Draft only fields
  publisher_linked_unpublished_at?: string;
  publisher_linked_is_live?: boolean;
  publisher_linked_is_unpublished?: boolean;
  publisher_linked_is_completed?: boolean;
  publisher_linked_last_application_move_at?: string;
}

export enum IPublicJobWithListingState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
}

export interface IPublicJobWithListing extends IJob {
  state?: IPublicJobWithListingState;
  og_graph?: IOgGraph;
  job_listing?: IJobListing;
  job_process?: IJobProcess;
}

export interface IAssignment {
  id?: number;
  job_id?: number;
  user: IUser;
  user_id: number;
  created_at?: string;
  updated_at?: string;
  enabled?: boolean;
  publisher_linked?: number;
  job?: number;
}

export interface IAssignmentEvent {
  status?: boolean;
  assignment?: IAssignment;
}

export interface IOgGraph {
  id?: number;
  title?: string;
  description?: string;
  copy_from_job_listing?: boolean;
  image_media_url?: string;
  image_media_id?: number;
}

export interface IJobsPage {
  jobs?: IJob[];
  isLastPage?: boolean;
}

export interface IJobSubscription {
  id?: string;
  user?: IUser;
  position_function?: IPositionFunction;
  position_function_id?: number;
  email?: string;
  keywords?: string;
  employment_type?: string;
  address?: string;
  map_boundary_box?: MapBoundaryBox;
}
