import { action, observable, computed } from 'mobx';
import UnitApi from '../mj-api/unit.api';
import IUnit, {
  ICareerPage,
  ICareerPageSubdomain,
  IUnitFeatureFlag,
} from '../mj-models/unit.interface';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import { IPoint, IUser } from '../mj-models/user.interface';
import INotificationStore from './notification.store';
import ListingApi from '../mj-api/listing.api';
import { IJobListing } from '../mj-models/job-listing.interface';
import {
  IReferenceQuestion,
  IReferenceSurvey,
} from '../mj-models/references.interface';
import { IGlobalSearch } from '../mj-models/search.interface';

export default interface IBusinessUnitStore {
  currentUnit: IUnit;
  availableUnitsPage: IPage<IUnit>;
  availableUnitsPaginator: IPaginator;
  availableUnitsIsLoading: boolean;
  availableUnitsSearch: string;
  didSelectNewRepresentedUnitIndicator: boolean;

  // Registration vars
  registrationUser: IUser;
  registrationEmail: string;
  registrationFirstName: string;
  registrationLastName: string;
  registrationPassword1: string;
  registrationPassword2: string;

  registrationUnitName: string;
  registrationCareerPageSubdomain: string;
  registrationUnitPoint?: IPoint;
  registrationUnitAddress: string;

  unitListings: IJobListing[];

  getUnit: (unitId: number) => Promise<IUnit | undefined>;
  getUnitEmployments: (
    pageSize?: number,
    page?: number,
    search?: string
  ) => Promise<boolean>;
  registerUnit: (unit: IUnit) => Promise<IUnit | undefined>;
  validateUnitInvitation: (
    unitId: number,
    token: string
  ) => Promise<
    { token: string; has_already_accepted_invitation: boolean } | undefined
  >;

  getCareerPage: (unitId: number) => Promise<ICareerPage | undefined>;
  createCareerPage: (
    careerPage: ICareerPage
  ) => Promise<ICareerPage | undefined>;
  updateCareerPage: (
    careerPage: ICareerPage
  ) => Promise<ICareerPage | undefined>;
  createCareerPageSubdomain: (
    careerPageSubdomain: ICareerPageSubdomain
  ) => Promise<ICareerPageSubdomain | undefined>;
  deleteCareerPageSubdomain: (
    careerPageSubdomain: ICareerPageSubdomain
  ) => Promise<boolean>;
  fetchUnitListings: (unitId: number) => Promise<boolean>;
  getUnitFeatureFlag: (unitId: number) => Promise<IUnitFeatureFlag | undefined>;
  doGlobalSearch: (
    id: number,
    searchText: string,
    pageSize: number
  ) => Promise<IGlobalSearch | undefined>;
  EMPTY_CAREER_PAGE: ICareerPage;
  resetStore(): void;
}
export class BusinessUnitStore implements IBusinessUnitStore {
  @observable public currentUnit: IUnit = {};
  @observable public availableUnitsPage: IPage<IUnit> = {
    count: 0,
    next: '',
    prev: '',
    results: [],
  };
  @observable public availableUnitsPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public availableUnitsIsLoading: boolean = true;
  @observable public availableUnitsSearch: string = '';
  @observable public didSelectNewRepresentedUnitIndicator: boolean = false;

  // Registration
  @observable public registrationUser!: IUser;
  @observable public registrationEmail: string = '';
  @observable public registrationFirstName: string = '';
  @observable public registrationLastName: string = '';
  @observable public registrationPassword1: string = '';
  @observable public registrationPassword2: string = '';

  @observable public registrationUnitName: string = '';
  @observable public registrationCareerPageSubdomain: string = '';
  @observable public registrationUnitPoint?: IPoint;
  @observable public registrationUnitAddress: string = '';

  @observable public unitListings: IJobListing[] = [];
  @observable public usersList: IJobListing[] = [];

  private unitApi: UnitApi;
  private listingApi: ListingApi;
  private notificationStore: INotificationStore;

  constructor(
    unitApi: UnitApi,
    listingApi: ListingApi,
    notificationStore: INotificationStore
  ) {
    this.unitApi = unitApi;
    this.notificationStore = notificationStore;
    this.listingApi = listingApi;
  }

  @action('GET UNIT')
  public getUnit = async (unitId: number): Promise<IUnit | undefined> => {
    try {
      return await this.unitApi.getUnit(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('REGISTER UNIT')
  public registerUnit = async (unit: IUnit): Promise<IUnit | undefined> => {
    try {
      return await this.unitApi.registerUnit(unit);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('VALIDATE UNIT INVITATION')
  public validateUnitInvitation = async (
    unitId: number,
    token: string
  ): Promise<
    { token: string; has_already_accepted_invitation: boolean } | undefined
  > => {
    try {
      return await this.unitApi.validateUnitInvitation(unitId, token);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET CAREER PAGE')
  public getCareerPage = async (
    unitId: number
  ): Promise<ICareerPage | undefined> => {
    try {
      return await this.listingApi.getCareerPage(unitId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('CREATE CAREER PAGE')
  public createCareerPage = async (
    careerPage: ICareerPage
  ): Promise<ICareerPage | undefined> => {
    try {
      return await this.listingApi.createCareerPage(careerPage);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('UPDATE CAREER PAGE')
  public updateCareerPage = async (
    careerPage: ICareerPage
  ): Promise<ICareerPage | undefined> => {
    try {
      return await this.listingApi.updateCareerPage(careerPage);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('CREATE CAREER PAGE SUBDOMAIN')
  public createCareerPageSubdomain = async (
    careerPageSubdomain: ICareerPageSubdomain
  ): Promise<ICareerPageSubdomain | undefined> => {
    try {
      return await this.listingApi.createCareerPageSubdomain(
        careerPageSubdomain
      );
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('DELETE CAREER PAGE SUBDOMAIN')
  public deleteCareerPageSubdomain = async (
    careerPageSubdomain: ICareerPageSubdomain
  ): Promise<boolean> => {
    try {
      await this.listingApi.deleteCareerPageSubdomain(careerPageSubdomain);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('FETCH UNIT EMPLOYMENTS')
  public getUnitEmployments = async (
    pageSize: number = 500,
    page: number = 1,
    search: string = ''
  ): Promise<boolean> => {
    try {
      this.availableUnitsIsLoading = true;
      this.availableUnitsSearch = search;
      this.availableUnitsPaginator.page = page;
      this.availableUnitsPaginator.itemsPerPage = pageSize;
      if (page === 1) {
        this.availableUnitsPage =
          await this.unitApi.getCurrentUserUnitEmployments(
            pageSize,
            page,
            search
          );
      } else {
        const newPage = await this.unitApi.getCurrentUserUnitEmployments(
          pageSize,
          page,
          search
        );
        this.availableUnitsPage = {
          ...newPage,
          results: [...this.availableUnitsPage.results, ...newPage.results],
        };
      }
      this.availableUnitsPaginator.count = this.availableUnitsPage.count || 0;
      this.availableUnitsPaginator.maxPage = Math.ceil(
        this.availableUnitsPaginator.count! /
          this.availableUnitsPaginator.itemsPerPage
      );
      this.availableUnitsIsLoading = false;
      return true;
    } catch (error) {
      this.availableUnitsIsLoading = false;
      return false;
    }
  };

  @action('RESET STORE')
  public resetStore() {
    this.currentUnit = {};
    this.availableUnitsPage = {
      count: 0,
      next: '',
      prev: '',
      results: [],
    };
    this.availableUnitsPaginator = {
      page: 1,
      itemsPerPage: 25,
      count: 0,
      maxPage: 1,
    };
    this.availableUnitsIsLoading = true;
    this.availableUnitsSearch = '';
  }

  @action('FETCH UNIT LISTINGS')
  public fetchUnitListings = async (unitId: number): Promise<boolean> => {
    try {
      this.unitListings = await this.listingApi.findUnitJobListings(unitId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('GET UNIT FEATURE FLAG')
  public getUnitFeatureFlag = async (
    unitId: number
  ): Promise<IUnitFeatureFlag | undefined> => {
    try {
      return await this.listingApi.getUnitFeatureFlag(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('DO GLOBAL SEARCH')
  public doGlobalSearch = async (
    unitId: number,
    searchText: string,
    pageSize: number
  ): Promise<IGlobalSearch | undefined> => {
    try {
      return await this.unitApi.doGlobalSearch(unitId, searchText, pageSize);
    } catch (e) {
      console.log(e);
    }
  };

  @computed
  public get EMPTY_CAREER_PAGE() {
    return {
      unit_id: this.currentUnit ? this.currentUnit.id! : undefined,
      global_background_rgba: '#FFFFFFFF',
      career_page_items: [
        {
          title_rich: '<h1></h1>',
          title: '',
          subtitle_rich: '<h3></h3>',
          subtitle: '',
          background_rgba: '#FFFFFF00',
          font_rgba: '#2E2E2EFF',
          position: 0,
          type: 0,
        },
        {
          background_rgba: '#FFFFFF00',
          font_rgba: '#2E2E2EFF',
          position: 1,
          type: 7,
        },
        {
          background_rgba: '#FFFFFF00',
          font_rgba: '#2E2E2EFF',
          position: 2,
          type: 6,
        },
      ],
    };
  }
}
