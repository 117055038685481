import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NewVersionMixin extends Vue {
  public updateExists: boolean = false;
  private refreshing: boolean = false;
  private registration: any = null;

  public newVersionAvailable(event: any) {
    this.registration = event.detail;
    this.updateExists = true;
  }

  public created() {
    document.addEventListener('swUpdated', this.newVersionAvailable);
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) {
        return;
      }
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  // Called when the user accepts the update
  public refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) {
      return;
    }
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }

  public onCancelApplyingNewVersion() {
    this.updateExists = false;
  }

  public destroyed() {
    window.removeEventListener('swUpdated', this.newVersionAvailable);
  }
}
