export interface MjMoreMenuListItems {
  text?: string;
  graphic?: string;
  primaryText?: string;
  secondaryText?: string;
  meta?: string;
  uppercase?: boolean;
  color?: string;
  action_name?: string;
}

export interface IColumn {
  id: number;
  title?: string;
  desc?: string;
  visible?: boolean;
  tiny?: boolean;
  main?: boolean;
  sortParam?: string;
  size?: ColumnSize;
  icon?: string;
  iconColor?: string;
  iconRotationDegree?: number;
  iconCentered?: boolean;
  doNotTranslateTitle?: boolean;
}

export enum IRowColumnTextType {
  body,
  header,
  processStatus,
  headerWithCircledIcon,
}

export interface Location {
  name?: string;
  path?: string;
  hash?: string;
  query?: any;
  params?: any;
  append?: boolean;
  replace?: boolean;
}

export interface IRowColumn {
  id: number;
  primaryText?: string;
  primaryTextType?: IRowColumnTextType;
  primaryTextLevel?: string;
  primaryTextExtraText?: string;
  primaryTextColor?: string;
  primaryTextClickable?: boolean;
  secondaryText?: string;
  secondaryTextType?: IRowColumnTextType;
  secondaryTextLevel?: number;
  secondaryTextColor?: string;
  avatar?: string;
  showManualCandidateBadge?: boolean;
  icon?: string;
  rating?: number;
  moreMenu?: boolean;
  moreMenuItems?: MjMoreMenuListItems;
  size?: ColumnSize;
  bold?: boolean;
  processStatusColor?: string;
  circledIcon?: string;
  circledIconBackgroundColor?: string;
  circledIconRotationDegree?: number;
  tooltip?: string;
}

export interface IRow {
  rowColumns: IRowColumn[];
  rawLocation?: Location;
}

export enum ColumnSize {
  small,
  medium,
  large,
  tiny,
}

export enum MobileDeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export enum MobileAppLink {
  BUSINESS_PLAYSTORE = 'https://play.google.com/store/apps/details?id=com.mojobinc.android.company',
  BUSINESS_APPSTORE = 'https://apps.apple.com/no/app/mojob-for-business/id1210052382',
  APPLICANT_PLAYSTORE = 'https://play.google.com/store/apps/details?id=com.mojobinc.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  APPLICANT_APPSTORE = 'https://apps.apple.com/no/app/mojob-for-job-seekers/id942880959',
}
