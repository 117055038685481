import { action, observable } from 'mobx';
import UnitApi from '../mj-api/unit.api';
import IUnit, { IUnitEmployment } from '../mj-models/unit.interface';
import INotificationStore from './notification.store';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import { IJob } from '../mj-models/job.interface';
import GDPRApi from '../mj-api/gdpr.api';
import {
  IGDPRSettings,
  PermissionExpirationPeriod,
  IUnitQuestionTemplate,
  IUnitMessageTemplate,
  IUnitAttribute,
  IUnitAttributeUsage,
  IUnitGalleryImage,
  IUnitReferenceQuestionTemplate,
  IUnitReasonForDeclineTemplate,
} from '../mj-models/business-settings.interface';
import { IGalleryParams } from '../mj-models/job-listing.interface';

export default interface IBusinessSettingsStore {
  isLoadingSelectedUnit: boolean;
  isLoadingUnitTree: boolean;
  unitWithChildren: IUnit | null;
  selectedUnit: IUnit | null;

  unitEmploymentsPage: IPage<IUnitEmployment>;
  unitEmploymentsPaginator: IPaginator;
  unitEmploymentsIsLoading: boolean;
  unitEmploymentsOrder: string;
  unitEmploymentsIsAsc: boolean;

  jobTemplatesPage: IPage<IJob>;
  jobTemplatesPaginator: IPaginator;
  jobTemplatesIsLoading: boolean;
  jobTemplatesOrder: string;
  jobTemplatesIsAsc: boolean;

  fetchQuestionsTemplates: (
    unitId: number
  ) => Promise<IPage<IUnitQuestionTemplate> | undefined>;
  createQuestionTemplate: (
    template: IUnitQuestionTemplate
  ) => Promise<IUnitQuestionTemplate | undefined>;
  updateQuestionTemplate: (
    template: IUnitQuestionTemplate
  ) => Promise<IPage<IUnitQuestionTemplate> | undefined>;
  deleteQuestionTemplate: (
    template: IUnitQuestionTemplate
  ) => Promise<any | undefined>;

  fetchMessagesTemplates: (
    unitId: number,
    term?: string
  ) => Promise<IPage<IUnitMessageTemplate> | undefined>;
  createMessageTemplate: (
    template: IUnitMessageTemplate
  ) => Promise<IUnitMessageTemplate | undefined>;
  updateMessageTemplate: (
    template: IUnitMessageTemplate
  ) => Promise<IPage<IUnitMessageTemplate> | undefined>;
  deleteMessageTemplate: (
    template: IUnitMessageTemplate
  ) => Promise<any | undefined>;

  fetchReferenceQuestionTemplates: (
    unitId: number,
    term?: string
  ) => Promise<IPage<IUnitReferenceQuestionTemplate> | undefined>;

  fetchAttributes: (
    unitId: number,
    term?: string
  ) => Promise<IPage<IUnitAttribute> | undefined>;
  createAttribute: (
    attribute: IUnitAttribute
  ) => Promise<IUnitAttribute | undefined>;
  updateAttribute: (
    attribute: IUnitAttribute
  ) => Promise<IPage<IUnitAttribute> | undefined>;
  deleteAttribute: (attribute: IUnitAttribute) => Promise<any | undefined>;
  getAttributeUsage: (
    attribute: IUnitAttribute
  ) => Promise<IUnitAttributeUsage | undefined>;
  bulkUpdateAttributes: (
    unitId: number,
    attributes: IUnitAttribute[]
  ) => Promise<IUnitAttribute[]>;

  fetchUnitWithChildren: (unitId: number) => Promise<IUnit | undefined>;
  updateUnit: (unit: IUnit) => Promise<IUnit | undefined>;
  createUnit: (unit: IUnit) => Promise<IUnit | undefined>;
  deleteUnit: (unit: IUnit) => Promise<boolean>;

  createUnitGDPRSettings: () => Promise<IGDPRSettings | boolean>;

  fetchUnitGDPRSettings: (
    gdprSettingsId: number
  ) => Promise<IGDPRSettings | boolean>;

  updateUnitGDPRSettings: (
    gdprSettings: IGDPRSettings
  ) => Promise<IGDPRSettings | boolean>;

  fetchUnitEmployments: (
    unitId: number,
    page: number,
    pageSize: number
  ) => Promise<IUnitEmployment[]>;

  fetchJobTemplates: (
    unitId: number,
    page: number,
    pageSize: number
  ) => Promise<IJob[]>;

  getFlattenedUnitTree: () => IUnit[];

  fetchReasonForDeclineTemplates: (
    unitId: number,
    term?: string
  ) => Promise<IPage<IUnitReasonForDeclineTemplate> | undefined>;

  createReasonForDeclineTemplate: (
    template: IUnitReasonForDeclineTemplate
  ) => Promise<IUnitReasonForDeclineTemplate | undefined>;

  updateReasonForDeclineTemplate: (
    template: IUnitReasonForDeclineTemplate
  ) => Promise<IPage<IUnitReasonForDeclineTemplate> | undefined>;
  deleteReasonForDeclineTemplate: (
    template: IUnitReasonForDeclineTemplate
  ) => Promise<any | undefined>;
}
export class BusinessSettingsStore implements IBusinessSettingsStore {
  @observable public isLoadingSelectedUnit: boolean = false;
  @observable public isLoadingUnitTree: boolean = false;
  @observable public unitWithChildren: IUnit | null = null;
  @observable public selectedUnit: IUnit | null = null;

  // TEAM - Unit employments
  @observable public unitEmploymentsPage: IPage<IUnitEmployment> = {
    count: 0,
    next: '',
    prev: '',
    results: [],
  };
  @observable public unitEmploymentsPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public unitEmploymentsIsLoading: boolean = true;
  @observable public unitEmploymentsOrder: string = 'updated_at';
  @observable public unitEmploymentsIsAsc: boolean = false;

  // Templates - Job templates
  @observable public jobTemplatesPage: IPage<IUnitEmployment> = {
    count: 0,
    next: '',
    prev: '',
    results: [],
  };
  @observable public jobTemplatesPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public jobTemplatesIsLoading: boolean = true;
  @observable public jobTemplatesOrder: string = 'updated_at';
  @observable public jobTemplatesIsAsc: boolean = false;

  private unitApi: UnitApi;
  private gdprApi: GDPRApi;
  private notificationStore: INotificationStore;

  constructor(
    unitApi: UnitApi,
    notificationStore: INotificationStore,
    gdprApi: GDPRApi
  ) {
    this.unitApi = unitApi;
    this.notificationStore = notificationStore;
    this.gdprApi = gdprApi;
  }

  @action('FETCH MESSAGES TEMPLATES')
  public fetchMessagesTemplates = async (
    unitId: number,
    term?: string
  ): Promise<IPage<IUnitMessageTemplate> | undefined> => {
    try {
      return await this.unitApi.fetchMessagesTemplates(unitId, term);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE MESSAGE TEMPLATE')
  public createMessageTemplate = async (
    template: IUnitMessageTemplate
  ): Promise<IUnitMessageTemplate | undefined> => {
    try {
      return await this.unitApi.createMessageTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE MESSAGE TEMPLATE')
  public updateMessageTemplate = async (
    template: IUnitMessageTemplate
  ): Promise<IPage<IUnitMessageTemplate> | undefined> => {
    try {
      return await this.unitApi.updateMessageTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('DELETE MESSAGE TEMPLATE')
  public deleteMessageTemplate = async (
    template: IUnitMessageTemplate
  ): Promise<any> => {
    try {
      return await this.unitApi.deleteMessageTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH QUESTION TEMPLATES')
  public fetchQuestionsTemplates = async (
    unitId: number
  ): Promise<IPage<IUnitQuestionTemplate> | undefined> => {
    try {
      return await this.unitApi.fetchQuestionsTemplates(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE QUESTION TEMPLATE')
  public createQuestionTemplate = async (
    template: IUnitQuestionTemplate
  ): Promise<IUnitQuestionTemplate | undefined> => {
    try {
      return await this.unitApi.createQuestionTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE QUESTION TEMPLATE')
  public updateQuestionTemplate = async (
    template: IUnitQuestionTemplate
  ): Promise<IPage<IUnitQuestionTemplate> | undefined> => {
    try {
      return await this.unitApi.updateQuestionTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('DELETE QUESTION TEMPLATE')
  public deleteQuestionTemplate = async (
    template: IUnitQuestionTemplate
  ): Promise<any> => {
    try {
      return await this.unitApi.deleteQuestionTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH MESSAGE TEMPLATES')
  public fetchMessageTemplates = async (
    unitId: number,
    searchPhrase?: string
  ): Promise<IPage<IUnitMessageTemplate> | undefined> => {
    try {
      return await this.unitApi.fetchMessageTemplates(unitId, searchPhrase);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH REFERENCE QUESTION TEMPLATES')
  public fetchReferenceQuestionTemplates = async (
    unitId: number,
    term?: string
  ): Promise<IPage<IUnitReferenceQuestionTemplate> | undefined> => {
    try {
      return await this.unitApi.fetchReferenceQuestionTemplates(unitId, term);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH ATTRIBUTES')
  public fetchAttributes = async (
    unitId: number,
    term?: string
  ): Promise<IPage<IUnitAttribute> | undefined> => {
    try {
      return await this.unitApi.fetchAttributes(unitId, term);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE ATTRIBUTE')
  public createAttribute = async (
    attribute: IUnitAttribute
  ): Promise<IUnitAttribute | undefined> => {
    try {
      return await this.unitApi.createAttribute(attribute);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE ATTRIBUTE')
  public updateAttribute = async (
    attribute: IUnitAttribute
  ): Promise<IPage<IUnitAttribute> | undefined> => {
    try {
      return await this.unitApi.updateAttribute(attribute);
    } catch (error) {
      return undefined;
    }
  };

  @action('DELETE ATTRIBUTE')
  public deleteAttribute = async (attribute: IUnitAttribute): Promise<any> => {
    try {
      return await this.unitApi.deleteAttribute(attribute);
    } catch (error) {
      return undefined;
    }
  };

  @action('BULK UPDATE ATTRIBUTES')
  public bulkUpdateAttributes = async (
    unitId: number,
    attributes: IUnitAttribute[]
  ): Promise<any> => {
    try {
      return await this.unitApi.bulkUpdateAttributes(unitId, attributes);
    } catch (error) {
      return [];
    }
  };

  @action('GET ATTRIBUTE USAGE')
  public getAttributeUsage = async (
    attribute: IUnitAttribute
  ): Promise<IUnitAttributeUsage | undefined> => {
    try {
      return await this.unitApi.getAttributeUsage(attribute);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH UNIT TREE')
  public fetchUnitWithChildren = async (
    unitId: number
  ): Promise<IUnit | undefined> => {
    try {
      return await this.unitApi.fetchAccessibleUnitTree(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE UNIT')
  public updateUnit = async (unit: IUnit): Promise<IUnit | undefined> => {
    try {
      return await this.unitApi.updateUnit(unit);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE UNIT')
  public createUnit = async (unit: IUnit): Promise<IUnit | undefined> => {
    try {
      return await this.unitApi.createUnit(unit);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('DELETE UNIT')
  public deleteUnit = async (unit: IUnit): Promise<boolean> => {
    try {
      await this.unitApi.deleteUnit(unit);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('GET FLATTENED UNIT TREE')
  public getFlattenedUnitTree = (): IUnit[] => {
    return this._flatten([this.unitWithChildren]);
  };

  @action('FETC UNIT EMPLOYMENTS')
  public fetchUnitEmployments = async (
    unitId: number,
    page: number,
    pageSize: number
  ): Promise<IUnitEmployment[]> => {
    try {
      this.unitEmploymentsIsLoading = true;
      this.unitEmploymentsPaginator.page = page;
      this.unitEmploymentsPaginator.itemsPerPage = pageSize;
      if (page === 1) {
        this.unitEmploymentsPage = await this.unitApi.fetchPagedUnitEmployments(
          unitId,
          pageSize,
          page,
          this.getUnitEmploymentOrderingParameters()
        );
      } else {
        const newPage = await this.unitApi.fetchPagedUnitEmployments(
          unitId,
          pageSize,
          page,
          this.getUnitEmploymentOrderingParameters()
        );
        this.unitEmploymentsPage = {
          ...newPage,
          results: [...this.unitEmploymentsPage.results, ...newPage.results],
        };
      }
      this.unitEmploymentsPaginator.count = this.unitEmploymentsPage.count || 0;
      this.unitEmploymentsPaginator.maxPage = Math.ceil(
        this.unitEmploymentsPaginator.count! /
          this.unitEmploymentsPaginator.itemsPerPage
      );
      this.unitEmploymentsIsLoading = false;
      return this.unitEmploymentsPage.results;
    } catch (error) {
      return [];
    }
  };

  @action('FETCH JOB TEMPLATES')
  public fetchJobTemplates = async (
    unitId: number,
    page: number,
    pageSize: number
  ): Promise<IJob[]> => {
    try {
      this.jobTemplatesIsLoading = true;
      this.jobTemplatesPaginator.page = page;
      this.jobTemplatesPaginator.itemsPerPage = pageSize;
      if (page === 1) {
        this.jobTemplatesPage = await this.unitApi.getUnitJobTemplates(
          unitId,
          pageSize,
          page,
          this.getJobTemplatesOrderingParameters(),
          ''
        );
      } else {
        const newPage = await this.unitApi.getUnitJobTemplates(
          unitId,
          pageSize,
          page,
          this.getJobTemplatesOrderingParameters(),
          ''
        );
        this.jobTemplatesPage = {
          ...newPage,
          results: [...this.jobTemplatesPage.results, ...newPage.results],
        };
      }
      this.jobTemplatesPaginator.count = this.jobTemplatesPage.count || 0;
      this.jobTemplatesPaginator.maxPage = Math.ceil(
        this.jobTemplatesPaginator.count! /
          this.jobTemplatesPaginator.itemsPerPage
      );
      this.jobTemplatesIsLoading = false;
      return this.jobTemplatesPage.results;
    } catch (error) {
      return [];
    }
  };

  @action('CREATE GDPR SETTIGNS')
  public createUnitGDPRSettings = async (): Promise<
    IGDPRSettings | boolean
  > => {
    try {
      const settings = await this.gdprApi.createUnitGDPRSettings({
        managers: [],
        permission_expiration_period: PermissionExpirationPeriod.NO_EXPIRATION,
      } as IGDPRSettings);
      return settings;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('FETCH GDPR SETTIGNS')
  public fetchUnitGDPRSettings = async (
    gdprSettingsId: number
  ): Promise<IGDPRSettings | boolean> => {
    try {
      const settings = await this.gdprApi.fetchUnitGDPRSettings(gdprSettingsId);
      return settings;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('UPDATE GDPR SETTIGNS')
  public updateUnitGDPRSettings = async (
    gdprSettings: IGDPRSettings
  ): Promise<IGDPRSettings | boolean> => {
    try {
      const settings = await this.gdprApi.updateUnitGDPRSettings(gdprSettings);
      return settings;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('FETCH REASON FOR DECLINE TEMPLATES')
  public fetchReasonForDeclineTemplates = async (
    unitId: number,
    term?: string
  ): Promise<IPage<IUnitReasonForDeclineTemplate> | undefined> => {
    try {
      return await this.unitApi.fetchReasonForDeclineTemplates(unitId, term);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE REASON FOR DECLINE TEMPLATE')
  public createReasonForDeclineTemplate = async (
    template: IUnitReasonForDeclineTemplate
  ): Promise<IUnitReasonForDeclineTemplate | undefined> => {
    try {
      return await this.unitApi.createReasonForDeclineTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE REASON FOR DECLINE TEMPLATE')
  public updateReasonForDeclineTemplate = async (
    template: IUnitReasonForDeclineTemplate
  ): Promise<IPage<IUnitReasonForDeclineTemplate> | undefined> => {
    try {
      return await this.unitApi.updateReasonForDeclineTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  @action('DELETE REASON FOR DECLINE TEMPLATE')
  public deleteReasonForDeclineTemplate = async (
    template: IUnitReasonForDeclineTemplate
  ): Promise<string | undefined> => {
    try {
      return await this.unitApi.deleteReasonForDeclineTemplate(template);
    } catch (error) {
      return undefined;
    }
  };

  private _flatten = (unitTreeArray: any[]) => {
    return unitTreeArray.reduce((unitArr, { children, ...rest }) => {
      unitArr.push(rest);
      if (children) {
        unitArr.push(...this._flatten(children));
      }
      return unitArr;
    }, []);
  };

  private getUnitEmploymentOrderingParameters() {
    let sortBy = `${this.unitEmploymentsIsAsc ? '' : '-'}${
      this.unitEmploymentsOrder
    }`;
    if (this.unitEmploymentsOrder === 'user__first_name') {
      sortBy = this.unitEmploymentsIsAsc
        ? `user__first_name, user__last_name`
        : `-user__first_name, -user__last_name`;
    }
    return sortBy;
  }

  private getJobTemplatesOrderingParameters() {
    return `${this.jobTemplatesIsAsc ? '' : '-'}${this.jobTemplatesOrder}`;
  }
}
