import BaseApi from './base.api';
import { IMessage } from '../mj-models/ws.interface';
import { AxiosTransformer } from 'axios';
import { removeAuthKeyTransformer } from './api.utils';
import { IJob, ITalentPool } from '../mj-models/job.interface';

export default class ChatApi extends BaseApi {
  public sendMessages = (messages: IMessage[]): Promise<IMessage[]> =>
    this.axios
      .post(`${this.baseUrl}chats/bulk-message/`, { messages })
      .then((response) => response.data);

  public editMessage = (message: IMessage): Promise<IMessage> =>
    this.axios
      .put(
        `${this.baseUrl}chats/message/${message.id}/${message.type}/`,
        message
      )
      .then((response) => response.data);

  public getMessage = (message: IMessage): Promise<IMessage> =>
    this.axios
      .get(`${this.baseUrl}chats/message/${message.id}/${message.type}/`)
      .then((response) => response.data);

  public deleteMessage = (message: IMessage): Promise<IMessage> =>
    this.axios
      .delete(`${this.baseUrl}chats/message/${message.id}/${message.type}/`)
      .then((response) => response.data);

  public getApplicationNotes = (unitRoomId: number): Promise<IMessage[]> =>
    this.axios
      .get(`${this.baseUrl}chats/unit-room/${unitRoomId}/note-messages/`)
      .then((response) => response.data);

  public fetchPublicJob = (jobId: number): Promise<IJob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/public/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);

  public fetchPublicTalentPool = (talentPoolId: number): Promise<ITalentPool> =>
    this.axios
      .get(`${this.baseUrl}talentpool/${talentPoolId}/public/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);

  public fetchPublicStaffingAssignment = (
    staffingAssignmentId: number
  ): Promise<ITalentPool> =>
    this.axios
      .get(
        `${this.baseUrl}staffing/assignments/${staffingAssignmentId}/public/`,
        {
          transformRequest: [
            removeAuthKeyTransformer,
            ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
          ],
        }
      )
      .then((response) => response.data);
}
