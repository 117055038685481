import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import VueI18n from 'vue-i18n';
import i18n from './i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { Route } from 'vue-router';
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';

const users = require('vue-moment');

Vue.config.productionTip = false;

// TODO how can we do custom directives and use them from separate files?

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted(el) {
    // Focus the element
    const inputElements = el.getElementsByTagName('input');
    if (inputElements && inputElements.length > 0) {
      // @ts-ignore
      inputElements.item(0).focus();
    }
    el.focus();
  },
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(users);
Vue.use(VueI18n);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);

const token: string | null = localStorage.getItem('id_token');
const axiosTokenKey = 'Authorization';
if (token !== undefined && token !== null) {
  axios.defaults.headers.common[axiosTokenKey] = `JWT ${token}`;
}

firebase.initializeApp({
  apiKey: 'AIzaSyAsUuKR1sxBAdBbjI_BYFlkkWcrsyc3GOk',
  authDomain: 'mojob-for-job-seekers.firebaseapp.com',
  databaseURL: 'https://mojob-for-job-seekers.firebaseio.com',
  projectId: 'mojob-for-job-seekers',
  storageBucket: 'mojob-for-job-seekers.appspot.com',
  messagingSenderId: '184334092735',
  appId: '1:184334092735:web:70241259d26fe92e',
});

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
    ],
  });
}

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');

router.beforeEach((to: Route, from: Route, next: any) => {
  if (from.query.msid && !to.query.msid) {
    if (to.path === from.path) {
      return;
    }
    next({ path: to.path, query: { msid: from.query.msid } });
  }
  next();
});
