import BaseApi from './base.api';
import {
  IFacebookAccount,
  IFacebookAd,
  IFacebookAdAccountData,
  IFacebookCity,
  IFacebookPage,
} from '../mj-models/facebook-ads.interface';

export default class FacebookApi extends BaseApi {
  public createFacebookAd = (facebookAd: IFacebookAd): Promise<IFacebookAd> =>
    this.axios
      .post(`${this.baseUrl}facebookads/ads/`, facebookAd)
      .then((response) => response.data);

  public updateFacebookAd = (facebookAd: IFacebookAd): Promise<IFacebookAd> =>
    this.axios
      .post(`${this.baseUrl}facebookads/ads/${facebookAd.id}/`, facebookAd)
      .then((response) => response.data);

  public getFacebookAd = (facebookAdId: number): Promise<IFacebookAd> =>
    this.axios
      .get(`${this.baseUrl}facebookads/ads/${facebookAdId}/`)
      .then((response) => response.data);

  public getFacebookSuggestedCities = (
    fbAccessToken: string,
    query: string
  ): Promise<IFacebookCity[]> =>
    this.axios
      .get(
        `${this.baseUrl}facebookads/suggested-cities/?access_token=${fbAccessToken}&query=${query}`
      )
      .then((response) => response.data);

  public getFacebookAccountData = (
    fbAccessToken: string
  ): Promise<IFacebookAccount> =>
    this.axios
      .get(`${this.baseUrl}facebookads/account/?access_token=${fbAccessToken}`)
      .then((response) => response.data);

  public getFacebookAdAccountData = (
    fbAccessToken: string,
    adAccountId: string
  ): Promise<IFacebookAdAccountData> =>
    this.axios
      .get(
        `${this.baseUrl}facebookads/ad-account/?access_token=${fbAccessToken}&ad_account_id=${adAccountId}`
      )
      .then((response) => response.data);

  public getFacebookPages = (
    fbAccessToken: string,
    fbUserId: string
  ): Promise<IFacebookPage[]> =>
    this.axios
      .get(
        `${this.baseUrl}facebookads/pages/${fbUserId}/?access_token=${fbAccessToken}`
      )
      .then((response) => response.data);

  public getFacebookPagesForAd = (fbAdId: number): Promise<IFacebookPage[]> =>
    this.axios
      .get(`${this.baseUrl}facebookads/pages-for-ad/${fbAdId}/`)
      .then((response) => response.data);
}
