import VueRouter from 'vue-router';

import { IProcessApp, IProcessStep } from '../mj-models/process.interface';

export function getStartLinkIndex(htmlWithLinks: string, startFromIndex: number = 0) {
  if (!!htmlWithLinks) {
    const linkTag = '<a href="';

    const indexOfFirstsLinkTag = htmlWithLinks.indexOf(linkTag, startFromIndex);

    return indexOfFirstsLinkTag > -1 ? indexOfFirstsLinkTag + linkTag.length : indexOfFirstsLinkTag;
  }

  return -1;
}

export function formatLinksByAddingHttps(htmlWithLinks: string) {
  let result = '';
  if (!!htmlWithLinks) {
    let startIndex = 0;
    let currentIndex = 0;

    while (getStartLinkIndex(htmlWithLinks, startIndex) !== -1) {
      currentIndex = getStartLinkIndex(htmlWithLinks, startIndex);

      result += htmlWithLinks.substring(startIndex, currentIndex);

      if (
        htmlWithLinks
          .substring(currentIndex, htmlWithLinks.indexOf('"', currentIndex))
          .indexOf('http') === -1
      ) {
        result += 'http://';
      }

      startIndex = currentIndex;
    }

    result += htmlWithLinks.substring(startIndex);
  }
  return result;
}

export function getSlugFromStepName(name: string) {
  return name.toLowerCase().replaceAll(' ', '_');
}

export function getAnchorLinkFromStep(jobId: number, router: VueRouter, step: IProcessStep) {
  const processRoute = router.resolve({
    name: 'business-job-editor-process',
    params: { jobId: jobId.toString() },
  });

  const stepSlug = getSlugFromStepName(step.name!);

  return `${processRoute.href}#${stepSlug}`;
}

export function getStepFromSlug(slug: string, steps: IProcessStep[]) {
  return steps.find((step) => getSlugFromStepName(step.name!) === slug);
}

export function getAppLink(app: IProcessApp) {
  const doesAppExist = app.id;

  return {
    name: 'business-job-editor-process-app',
    params: {
      stepId: app.process_step_id!.toString(),
      appId: doesAppExist ? app.id!.toString() : 'create',
    },
    // todo position
    query: doesAppExist ? null : { app_type: app.app_type },
  };
}

export function eraseQueryParameters(url: string) {
  return url.split('?')[0] || '';
}

export function getManualCandidateEditLocation(id: number, query?: Record<string, string>) {
  return {
    name: 'business-manual-candidate',
    params: {
      id,
    },
    query,
  };
}
