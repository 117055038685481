import BaseApi from './base.api';
import { IWebPushDevice } from '../mj-models/web-push.interface';
import { IUserNotificationSettings } from '../mj-models/user.interface';

export default class PushNotificationsApi extends BaseApi {
  public savePushDevice = (device: IWebPushDevice): Promise<IWebPushDevice> =>
    this.axios
      .post(`${this.baseUrl}users/devices/web-push/`, device)
      .then((response) => response.data);

  public getUserNotificationSettings = (
    userId: number
  ): Promise<IUserNotificationSettings> =>
    this.axios
      .get(`${this.baseUrl}notifications/user/${userId}/notification-settings/`)
      .then((response) => response.data);

  public updateUserNotificationSettings = (
    notificationSettings: IUserNotificationSettings
  ): Promise<IUserNotificationSettings> =>
    this.axios
      .put(
        `${this.baseUrl}notifications/user/${notificationSettings.user_id}/notification-settings/`,
        notificationSettings
      )
      .then((response) => response.data);
}
