import { MobileDeviceType } from '../mj-models/utility.interface';
import { action, observable } from 'mobx';
import { UpdatePhonePopupState } from '../mj-models/update-phone-popup.interface';

export default interface IUiStore {
  progressBarState: number;
  isUpdatePhonePopupOpen: UpdatePhonePopupState;
  isMobileBrowserPopupOpen: boolean;
  mobileDeviceType: MobileDeviceType | null;
  useMobileBrowserVersion: boolean;

  changeProgressBarState: (state: number) => void;
  changeUpdatePhonePopupState: (state: UpdatePhonePopupState) => void;
  changeMobileBrowserNavigatorPopupState: (state: boolean) => void;
  changeUseMobileBrowserState: (state: boolean) => void;
  getMobileDeviceType(): MobileDeviceType;
}

export class UiStore implements IUiStore {
  @observable public progressBarState: number;
  @observable public isUpdatePhonePopupOpen: UpdatePhonePopupState;
  @observable public isMobileBrowserPopupOpen: boolean;
  @observable public mobileDeviceType: MobileDeviceType | null;
  @observable public useMobileBrowserVersion: boolean;

  constructor() {
    this.progressBarState = 0;
    this.isUpdatePhonePopupOpen = UpdatePhonePopupState.HIDDEN;
    this.isMobileBrowserPopupOpen = false;
    this.useMobileBrowserVersion = false;
    this.mobileDeviceType = null;
  }

  @action('CHANGE PROGRESS BAR STATE')
  public changeProgressBarState = (state: number) => {
    this.progressBarState = state;
  };

  @action('CHANGE UPDATE PHONE POPUP STATE')
  public changeUpdatePhonePopupState = (state: UpdatePhonePopupState) => {
    this.isUpdatePhonePopupOpen = state;
  };

  @action('CHANGE MOBILE BROWSER NAVIGATOR POPUP STATE')
  public changeMobileBrowserNavigatorPopupState = (state: boolean) => {
    this.mobileDeviceType = state ? this.getMobileDeviceType() : null;
    this.isMobileBrowserPopupOpen = state;
  };

  @action('CHANGE USE MOBILE BROWSER STATE')
  public changeUseMobileBrowserState = (state: boolean) => {
    this.useMobileBrowserVersion = state;
  };

  public getMobileDeviceType(): MobileDeviceType {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/android/.test(userAgent.toLowerCase())) {
      return MobileDeviceType.ANDROID;
    }
    return MobileDeviceType.IOS;
  }
}
