export default function extractErrorFromDjangoResponse(error: any): any {
  if (typeof error === 'string' || error instanceof String) {
    return error as string;
  } else if (error.response) {
    const detailError = error.response.data.detail;
    if (error.response.data.non_field_errors) {
      const nonFieldError = error.response.data.non_field_errors[0];
      if (nonFieldError) {
        return nonFieldError;
      }
    } else if (detailError) {
      return detailError;
    } else if (error.response.data.password) {
      return error.response.data.password[0];
    } else {
      return JSON.stringify(error.response.data, null, '    ');
    }
  }
  return '';
}
