import NpsApi from '../mj-api/nps.api';
import { NpsSurvey, NpsSurveyAnswer } from '../mj-models/nps.interface';
import { action } from 'mobx';

export default interface INpsStore {
  getNpsSurveyAnswer: (surveyId: string) => Promise<NpsSurveyAnswer | false>;
  getNpsSurvey: (surveyId: string) => Promise<NpsSurvey | false>;
  submitNpsSurveyAnswer: (
    answer: NpsSurveyAnswer
  ) => Promise<NpsSurveyAnswer | false>;
  updateNpsSurveyAnswer: (
    answer: NpsSurveyAnswer
  ) => Promise<NpsSurveyAnswer | false>;
}

export class NpsStore implements INpsStore {
  public npsApi: NpsApi;

  constructor(npsApi: NpsApi) {
    this.npsApi = npsApi;
  }

  @action('GET NPS SURVEY')
  public getNpsSurvey = async (
    surveyId: string
  ): Promise<NpsSurvey | false> => {
    try {
      return await this.npsApi.getNpsSurvey(surveyId);
    } catch (error) {
      return false;
    }
  };

  @action('GET NPS SURVEY ANSWER')
  public getNpsSurveyAnswer = async (
    surveyId: string
  ): Promise<NpsSurveyAnswer | false> => {
    try {
      return await this.npsApi.getNpsAnswer(surveyId);
    } catch (error) {
      return false;
    }
  };

  @action('SUBMIT NPS SURVEY ANSWER')
  public submitNpsSurveyAnswer = async (
    answer: NpsSurveyAnswer
  ): Promise<NpsSurveyAnswer | false> => {
    try {
      return await this.npsApi.submitNpsAnswer(answer);
    } catch (error) {
      return false;
    }
  };

  @action('UPDATE NPS SURVEY ANSWER')
  public updateNpsSurveyAnswer = async (
    answer: NpsSurveyAnswer
  ): Promise<NpsSurveyAnswer | false> => {
    try {
      return await this.npsApi.updateNpsAnswer(answer);
    } catch (error) {
      return false;
    }
  };
}
