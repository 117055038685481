import { action, observable } from 'mobx';
import { IJob, PostNewJobState } from '../mj-models/job.interface';
import JobApi from '../mj-api/job.api';
import { IPage, IPaginator } from '../mj-models/utils.interface';

export default interface IPostNewJobStore {
  jobsPage: IPage<IJob>;
  jobsPaginator: IPaginator;
  searchValue: string;
  isLoading: boolean;
  isLoadingLoadMore: boolean;
  postNewJobState: PostNewJobState;
  getJobs: (searchValue: string, unitId: number) => Promise<boolean>;
}

export class PostNewJobStore implements IPostNewJobStore {
  @observable public jobsPage: IPage<IJob> = {
    count: 0,
    next: '',
    prev: '',
    results: [],
  };
  @observable public isLoading: boolean = false;
  @observable public isLoadingLoadMore: boolean = false;
  @observable public postNewJobState: PostNewJobState =
    PostNewJobState.use_template;
  @observable public jobsPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public searchValue: string = '';

  public jobApi: JobApi;

  constructor(jobApi: JobApi) {
    this.jobApi = jobApi;
  }

  @action('FETCH JOB AD TITLE SUGGESTIONS')
  public getJobs = async (
    searchValue: string,
    unitId: number
  ): Promise<boolean> => {
    try {
      const filterQueryParameters = this.getQueryParameterbyState();
      const jobsPage = await this.jobApi.getJobs(
        unitId,
        this.jobsPaginator,
        filterQueryParameters.ordering,
        filterQueryParameters.isTemplate,
        filterQueryParameters.isDraft,
        filterQueryParameters.isPublished,
        searchValue
      );
      if (this.jobsPaginator.page > 1) {
        jobsPage.results = [...this.jobsPage.results, ...jobsPage.results];
      }
      this.jobsPaginator.count = this.jobsPage.count;
      this.jobsPaginator.maxPage = Math.ceil(
        jobsPage.count / this.jobsPaginator.itemsPerPage
      );
      this.jobsPage = jobsPage;
      return true;
    } catch (error) {
      return false;
    }
  };

  private getQueryParameterbyState() {
    if (this.postNewJobState === PostNewJobState.use_template) {
      return {
        isTemplate: 'True',
        isDraft: 'True',
        isPublished: 'False',
        ordering: '-updated_at',
      };
    } else if (this.postNewJobState === PostNewJobState.use_previous_posting) {
      return {
        isTemplate: 'False',
        isDraft: 'False',
        isPublished: '',
        ordering: '-updated_at',
      };
    } else if (this.postNewJobState === PostNewJobState.use_draft) {
      return {
        isTemplate: 'False',
        isDraft: 'True',
        isPublished: 'False',
        ordering: '-updated_at',
      };
    } else {
      return {
        isTemplate: 'True',
        isDraft: 'True',
        isPublished: 'True',
        ordering: '-updated_at',
      };
    }
  }
}
