import BaseApi from './base.api';
import IUnit, {
  ICareerPage,
  IUnitEmployment,
} from '../mj-models/unit.interface';
import { IPage } from '../mj-models/utils.interface';
import { IApplication } from '../mj-models/application.interface';
import { RecManQuestions } from '../mj-models/process.interface';
import { ITalentPoolApplicant } from '../mj-models/talent-pool.interface';
import {
  IUnitQuestionTemplate,
  IUnitMessageTemplate,
  IUnitAttribute,
  IUnitAttributeUsage,
  IUnitReferenceQuestionTemplate,
  IUnitReasonForDeclineTemplate,
} from '../mj-models/business-settings.interface';
import { AxiosTransformer } from 'axios';
import { removeAuthKeyTransformer } from './api.utils';
import { IGlobalSearch } from '../mj-models/search.interface';

export default class UnitApi extends BaseApi {
  public registerUnit = (unit: IUnit): Promise<IUnit> =>
    this.axios
      .post(`${this.baseUrl}units/registration/`, unit)
      .then((response) => response.data);

  public validateUnitInvitation = (
    unitId: number,
    token: string
  ): Promise<{ token: string; has_already_accepted_invitation: boolean }> =>
    this.axios
      .post(
        `${this.baseUrl}units/${unitId}/employments/validate-invitation/`,
        {
          token,
        },
        {
          transformRequest: [
            removeAuthKeyTransformer,
            ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
          ],
        }
      )
      .then((response) => response.data);

  public fetchTalentPoolPage = (
    unitId: number,
    page: number,
    query: string,
    search: string,
    ordering: string,
    pageSize: number = 25
  ): Promise<IPage<ITalentPoolApplicant>> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/talent-pool/?page=${page}&ql=${query}&search=${search}&ordering=${ordering}&page_size=${pageSize}`
      )
      .then((response) => response.data);

  public fetchCareerPage = (unitId: number): Promise<ICareerPage> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/career-page/public/`)
      .then((response) => response.data);

  public getApplicantUnitHistory = (
    unitId: number,
    userId: number
  ): Promise<IPage<IApplication>> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/user/${userId}/history/`)
      .then((response) => response.data);

  public getUnitAssignedJobStatusCount = (unitId: number): Promise<any> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/job-status-count/`)
      .then((response) => response.data);

  public getUnit = (unitId: number): Promise<IUnit> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/`)
      .then((response) => response.data);

  public fetchUnitTree = (unitId: number): Promise<IUnit[]> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/tree/`)
      .then((response) => response.data);

  public fetchAccessibleUnitTree = (unitId: number): Promise<IUnit> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/accessible-unit-tree/`)
      .then((response) => response.data);

  public fetchEntireUnitTree = (unitId: number): Promise<IUnit> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/unit-tree/`)
      .then((response) => response.data);

  public fetchMessagesTemplates = (
    unitId: number,
    term?: string
  ): Promise<IPage<IUnitMessageTemplate>> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/message-templates/?page_size=500${
          term ? `&search=${term}` : ''
        }`
      )
      .then((response) => response.data);

  public createMessageTemplate = (
    template: IUnitMessageTemplate
  ): Promise<IUnitMessageTemplate> =>
    this.axios
      .post(
        `${this.baseUrl}units/${template.unit_id}/message-templates/`,
        template
      )
      .then((response) => response.data);

  public updateMessageTemplate = (
    template: IUnitMessageTemplate
  ): Promise<IPage<IUnitMessageTemplate>> =>
    this.axios
      .put(
        `${this.baseUrl}units/${template.unit_id}/message-templates/${template.id}/`,
        template
      )
      .then((response) => response.data);

  public deleteMessageTemplate = (
    template: IUnitMessageTemplate
  ): Promise<any> =>
    this.axios
      .delete(
        `${this.baseUrl}units/${template.unit_id}/message-templates/${template.id}/`
      )
      .then((response) => response.data);

  public fetchQuestionsTemplates = (
    unitId: number
  ): Promise<IPage<IUnitQuestionTemplate>> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/question-templates/?page_size=500`)
      .then((response) => response.data);

  public createQuestionTemplate = (
    template: IUnitQuestionTemplate
  ): Promise<IUnitQuestionTemplate> =>
    this.axios
      .post(
        `${this.baseUrl}units/${template.unit_id}/question-templates/`,
        template
      )
      .then((response) => response.data);

  public updateQuestionTemplate = (
    template: IUnitQuestionTemplate
  ): Promise<IPage<IUnitQuestionTemplate>> =>
    this.axios
      .put(
        `${this.baseUrl}units/${template.unit_id}/question-templates/${template.id}/`,
        template
      )
      .then((response) => response.data);

  public deleteQuestionTemplate = (
    template: IUnitQuestionTemplate
  ): Promise<any> =>
    this.axios
      .delete(
        `${this.baseUrl}units/${template.unit_id}/question-templates/${template.id}/`
      )
      .then((response) => response.data);

  public fetchMessageTemplates = (
    unitId: number,
    searchPhrase = ''
  ): Promise<IPage<IUnitMessageTemplate>> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/message-templates/${
          searchPhrase ? `?search=${searchPhrase}` : ''
        }`
      )
      .then((response) => response.data);

  public fetchReferenceQuestionTemplates = (
    unitId: number,
    term?: string
  ): Promise<IPage<IUnitReferenceQuestionTemplate>> =>
    this.axios
      .get(
        `${
          this.baseUrl
        }units/${unitId}/reference-question-templates/?page_size=500${
          term ? `&search=${term}` : ''
        }`
      )
      .then((response) => response.data);

  public fetchAttributes = (
    unitId: number,
    searchPhrase = ''
  ): Promise<IPage<IUnitAttribute>> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/attributes/${
          searchPhrase ? `?search=${searchPhrase}` : ''
        }`
      )
      .then((response) => response.data);

  public createAttribute = (
    attribute: IUnitAttribute
  ): Promise<IUnitAttribute> =>
    this.axios
      .post(`${this.baseUrl}units/${attribute.unit_id}/attributes/`, attribute)
      .then((response) => response.data);

  public updateAttribute = (
    attribute: IUnitAttribute
  ): Promise<IPage<IUnitAttribute>> =>
    this.axios
      .put(
        `${this.baseUrl}units/${attribute.unit_id}/attributes/${attribute.id}/`,
        attribute
      )
      .then((response) => response.data);

  public deleteAttribute = (attribute: IUnitAttribute): Promise<any> =>
    this.axios
      .delete(
        `${this.baseUrl}units/${attribute.unit_id}/attributes/${attribute.id}/`
      )
      .then((response) => response.data);

  public bulkUpdateAttributes = (
    unitId: number,
    attributes: IUnitAttribute[]
  ): Promise<IUnitAttribute[]> =>
    this.axios
      .put(`${this.baseUrl}units/${unitId}/attributes/bulk/`, attributes)
      .then((response) => response.data);

  public getAttributeUsage = (
    attribute: IUnitAttribute
  ): Promise<IUnitAttributeUsage> =>
    this.axios
      .get(
        `${this.baseUrl}units/${attribute.unit_id}/attributes/${attribute.id}/usage/`
      )
      .then((response) => response.data);

  public fetchUnitWithChildren = (unitId: number): Promise<IUnit> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/tree/`)
      .then((response) => response.data);

  public fetchUnitEmployments = (unitId: number): Promise<any> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/employments/?use_pagination=false`)
      .then((response) => response.data);

  public fetchPagedUnitEmployments = (
    unitId: number,
    pageSize: number,
    page: number,
    order: string
  ): Promise<IPage<IUnitEmployment>> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/employments/?page=${page}&page_size=${pageSize}&ordering=${order}`
      )
      .then((response) => response.data);

  public searchUnitEmployments = (
    unitId: number,
    term: string,
    pageSize: number
  ): Promise<any> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/employments/search/?search=${term}&page_size=${pageSize}`
      )
      .then((response) => response.data);

  public sendInvitation = (invitation: any): Promise<boolean> =>
    this.axios
      .post(
        `${this.baseUrl}units/${invitation.unit.id}/employments/`,
        invitation
      )
      .then((response) => response.data);

  public resendInvitation = (
    unitId: number,
    assignmentId: number
  ): Promise<boolean> =>
    this.axios
      .post(
        `${this.baseUrl}units/${unitId}/employments/${assignmentId}/resend-invitation/`,
        {}
      )
      .then((response) => response.data);

  public getRecManAttributes = (unitId: number): Promise<RecManQuestions> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/recman/candidate-attributes/`)
      .then((response) => response.data);

  public getLearnifierProjects = (unitId: number): Promise<any> =>
    this.axios
      .get(`${this.baseUrl}integrations/units/${unitId}/learnifier-projects/`)
      .then((response) => response.data);

  public getPlandayDepartments = (
    unitId: number,
    limit: number,
    offset: number
  ): Promise<any> =>
    this.axios
      .get(
        `${this.baseUrl}integrations/units/${unitId}/planday/departments/?limit=${limit}&offset=${offset}`
      )
      .then((response) => response.data);

  public getPlandayEmployeeGroups = (
    unitId: number,
    limit: number,
    offset: number
  ): Promise<any> =>
    this.axios
      .get(
        `${this.baseUrl}integrations/units/${unitId}/planday/employee-groups/?limit=${limit}&offset=${offset}`
      )
      .then((response) => response.data);

  public getCurrentUserUnitEmployments = (
    pageSize: number,
    page: number,
    search: string
  ): Promise<IPage<IUnit>> =>
    this.axios
      .get(
        `${this.baseUrl}units/current-user/employed-units/?page_size=${pageSize}&page=${page}&search=${search}`
      )
      .then((response) => response.data);

  public updateUnit = (unit: IUnit): Promise<IUnit> =>
    this.axios
      .put(`${this.baseUrl}units/${unit.id!}/`, unit)
      .then((response) => response.data);

  public createUnit = (unit: IUnit): Promise<IUnit> =>
    this.axios
      .post(`${this.baseUrl}units/`, unit)
      .then((response) => response.data);

  public deleteUnit = (unit: IUnit): Promise<boolean> =>
    this.axios
      .delete(`${this.baseUrl}units/${unit.id!}/`)
      .then((response) => response.data);

  public deleteUnitEmployment = (
    unitId: number,
    employmentId: number
  ): Promise<boolean> =>
    this.axios
      .delete(`${this.baseUrl}units/${unitId}/employments/${employmentId}/`)
      .then((response) => response.data);

  public resendUnitEmploymentInvitation = (
    unitId: number,
    employmentId: number
  ): Promise<boolean> =>
    this.axios
      .delete(
        `${this.baseUrl}units/${unitId}/employments/${employmentId}/resend-invitation/`
      )
      .then((response) => response.data);

  public updateUnitEmployment = (
    unitEmployment: IUnitEmployment
  ): Promise<IUnitEmployment> =>
    this.axios
      .put(
        `${this.baseUrl}units/${unitEmployment.unit!.id!}/employments/${
          unitEmployment.id
        }/`,
        unitEmployment
      )
      .then((response) => response.data);

  public getUnitJobTemplates = (
    unitId: number,
    pageSize: number,
    page: number,
    ordering: string,
    search: ''
  ): Promise<any> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/jobs/?ql=&search=${search}&page_size=${pageSize}&page=${page}&ordering=${ordering}&is_template=True&is_draft=True`
      )
      .then((response) => response.data);

  public fetchReasonForDeclineTemplates = (
    unitId: number,
    term?: string
  ): Promise<IPage<IUnitReasonForDeclineTemplate>> =>
    this.axios
      .get(
        `${
          this.baseUrl
        }units/${unitId}/reason-for-decline-templates/?page_size=500${
          term ? `&search=${term}` : ''
        }`
      )
      .then((response) => response.data);

  public createReasonForDeclineTemplate = (
    template: IUnitReasonForDeclineTemplate
  ): Promise<IUnitReasonForDeclineTemplate> =>
    this.axios
      .post(
        `${this.baseUrl}units/${template.unit_id}/reason-for-decline-templates/`,
        template
      )
      .then((response) => response.data);

  public updateReasonForDeclineTemplate = (
    template: IUnitReasonForDeclineTemplate
  ): Promise<IPage<IUnitReasonForDeclineTemplate>> =>
    this.axios
      .put(
        `${this.baseUrl}units/${template.unit_id}/reason-for-decline-templates/${template.id}/`,
        template
      )
      .then((response) => response.data);

  public deleteReasonForDeclineTemplate = (
    template: IUnitReasonForDeclineTemplate
  ): Promise<string | undefined> =>
    this.axios
      .delete(
        `${this.baseUrl}units/${template.unit_id}/reason-for-decline-templates/${template.id}/`
      )
      .then((response) => response.data);

  public doGlobalSearch = (
    unitId: number,
    searchText: string,
    pageSize: number
  ): Promise<IGlobalSearch> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/global-search/?search_query_string=${searchText}*&page_size=${pageSize}&page=1`
      )
      .then((response) => response.data);
}
