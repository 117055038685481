import { action, observable } from 'mobx';
import VueI18n from 'vue-i18n';

import {
  SnackbarAction,
  SnackbarContent,
} from '../mj-models/notification.interface';

export default interface INotificationStore {
  errors: any[];
  isOpen: boolean;
  hasDismiss: boolean;
  snackbarTimeoutMs: number;
  showSnackbarOnTop: boolean;
  snackbarBackgroundColor: string | null;
  i18n: VueI18n | null;
  closeNotification: () => void;
  showMessage: (message: string, timeoutMs?: number, icon?: string) => void;
  showMessageWithCloseButton: (message: string, icon?: string) => void;
  showMessageWithAction: (
    message: string,
    actionName: string,
    action: () => void,
    icon?: string
  ) => void;
  showMessageWithActions: (
    message: string,
    actions: SnackbarAction[],
    icon?: string
  ) => void;
  snack: SnackbarContent;
  notifyAboutError: (error: any) => void;
  getTextFromError: (error: any) => string;
  getTextFromErrorResponseData: (error: any) => string;
}

export class NotificationStore implements INotificationStore {
  @observable public isOpen: boolean = false;
  @observable public hasDismiss: boolean = false;
  @observable public errors: any[] = [];
  @observable public snack: SnackbarContent = {
    message: '',
    actions: [],
    textColor: 'white',
  };
  @observable public snackbarTimeoutMs: number = 5000;
  @observable public showSnackbarOnTop: boolean = false;
  @observable public snackbarBackgroundColor: string | null = null;

  public i18n: VueI18n | null = null;

  @action('CLOSE NOTIFICATION')
  public closeNotification = () => {
    this.isOpen = false;
    this.hasDismiss = false;
    this.snack = {
      message: '',
      actions: [],
      textColor: 'white',
      icon: undefined,
    };
  };

  @action('SHOW MESSAGE WITH CLOSE BUTTON')
  public showMessageWithCloseButton = (message: string, icon?: string) => {
    this.hasDismiss = true;
    this.showMessage(message, 5000, icon);
  };

  @action('SHOW MESSAGE')
  public showMessage = (
    message: string,
    timeoutMs: number = 5000,
    icon?: string
  ) => {
    this.snackbarTimeoutMs = timeoutMs;
    this.isOpen = false;
    this.isOpen = true;
    this.snack = {
      message,
      actions: [],
      textColor: 'white',
      icon,
    };
  };

  @action('SHOW MESSAGE WITH ACTION')
  public showMessageWithAction = (
    message: string,
    actionName: string,
    notificationAction: () => void,
    icon?: string
  ) => {
    this.showMessageWithActions(
      message,
      [{ name: actionName, action: notificationAction }],
      icon
    );
  };

  @action('SHOW MESSAGE WITH ACTIONS')
  public showMessageWithActions = (
    message: string,
    actions: SnackbarAction[],
    icon?: string
  ) => {
    this.closeNotification();
    setTimeout(() => {
      this.snackbarTimeoutMs = 5000;
      this.isOpen = true;
      this.snack = {
        message,
        actions,
        textColor: 'white',
        icon,
      };
    }, 0);
  };

  @action('NOTIFY ABOUT ERROR')
  public notifyAboutError = (error: any) => {
    if (typeof error === 'string' || error instanceof String) {
      this.showMessage(error as string);
    } else if (error.response) {
      const detailError = error.response.data.detail;
      if (error.response.status === 500) {
        this.showMessage(
          `${this.i18n!.t('generic__something_went_wrong__contact_support')}`
        );
      } else if (error.response.data.non_field_errors) {
        const nonFieldError = error.response.data.non_field_errors[0];
        if (nonFieldError) {
          this.showMessage(nonFieldError);
        }
      } else if (detailError) {
        this.showMessage(detailError);
      } else if (error.response.data.password) {
        this.showMessage(error.response.data.password[0]);
      } else {
        const errorText = this.getTextFromErrorResponseData(
          error.response.data
        );
        this.showMessage(errorText ? errorText : error.response.data);
      }
    }
  };

  @action('GET TEXT FROM ERROR')
  public getTextFromError = (error: any): string => {
    if (typeof error === 'string' || error instanceof String) {
      return error as string;
    } else if (error.response) {
      const detailError = error.response.data.detail;
      if (error.response.data.non_field_errors) {
        const nonFieldError = error.response.data.non_field_errors[0];
        if (nonFieldError) {
          return JSON.stringify(nonFieldError);
        }
      } else if (detailError) {
        return JSON.stringify(detailError);
      } else if (error.response.data.password) {
        return error.response.data.password[0];
      } else {
        return this.getTextFromErrorResponseData(error.response.data);
      }
    }
    return '';
  };

  public getTextFromErrorResponseData(data: any): string {
    // An attempt to make Django responses more user friendly
    // instead of hard coding error response localization keys
    // for each field of a model.
    try {
      let errorText: string = '';
      const dataKeys: string[] = Object.keys(data).map((key: string) =>
        key.toString()
      );
      if (dataKeys.length < 10) {
        errorText = `${this.i18n!.t('generic__error_simple')}:`;
        for (let i = 0; i < 1; i++) {
          errorText += `\n• "${dataKeys[i]}":`;
          try {
            for (const errorDetail of data[dataKeys[i]]) {
              if (
                typeof errorDetail === 'string' ||
                errorDetail instanceof String
              ) {
                errorText += ` ${errorDetail}`;
              } else {
                const errorDetailKeys = Object.keys(errorDetail).map(
                  (key: string) => key.toString()
                );
                if (errorDetailKeys.length < 10) {
                  for (const errorKey of errorDetailKeys) {
                    errorText += `\n - "${errorKey}":`;
                    for (const errorDepthDetail of errorDetail[errorKey]) {
                      errorText += ` ${errorDepthDetail}`;
                    }
                  }
                }
              }
            }
          } catch (e) {
            return this.getTextFromErrorResponseData(data[dataKeys[i]]);
          }
        }
      }
      if (!errorText) {
        errorText = `${this.i18n!.t('generic__something_went_wrong')}`;
      }
      return errorText;
    } catch (error) {
      return `Something went wrong`;
    }
  }
}
