import { IApplication } from './application.interface';
import {
  IAppointment,
  IAppointmentSlot,
  OutlookIntegrationError,
} from './appointment.interface';
import {
  BaseProcessAppStaffingAssignmentInvitationResponse,
  IProcessApp,
  JobProcessAppChoices,
  JobProcessAppHistoryMessageChoices,
} from './process.interface';
import { IScriveDocument, IScriveParty } from './scrive.interface';
import IUnit from './unit.interface';
import { IUser } from './user.interface';
import { ChatRoom } from './ws-chat.interface';

export class WsCommand {
  public command: string;

  constructor(command: string) {
    this.command = command;
  }
}

export enum ExportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED_WITH_ERRORS = 'SUCCEEDED_WITH_ERRORS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum ExportAction {
  update_candidate = 'update_candidate',
  insert_candidate = 'insert_candidate',
  insert_candidate_profile_picture = 'insert_candidate_profile_picture',
  insert_education = 'insert_education',
  insert_experience = 'insert_experience',
  insert_file = 'insert_file',
  insert_log = 'insert_log',
  insert_video_interview = 'insert_video_interview',
  insert_motimate_courses = 'insert_motimate_courses',
  insert_attribute = 'insert_attribute',

  create_learnifier_user = 'create_learnifier_user',
  created_project_participation = 'created_project_participation',
  activate_project_participation = 'activate_project_participation',

  create_planday_employee = 'create_planday_employee',
}

export interface ExportDataExportItem {
  action?: string;
  message?: any;
  datetime?: string;
  user_id?: number;
  process_app_question_answer_id?: number;
  app_message_id?: number;
  note_message_id?: number;
  user_document_id?: number;
  user_experience_id?: number;
  user_education_id?: number;
  media_id?: number;
  response_json?: any;
  project_id?: number;
}

export interface ExportDataErrorItem {
  action?: string;
  error?: any;
  datetime?: string;
}

export interface ExportData {
  errors?: ExportDataErrorItem[];
  exports?: ExportDataExportItem[];
  error_text?: string;
  recipient_emails?: string[];
  parties: IScriveParty[];
}

export interface IMessage {
  id?: number;
  text?: string;
  type?:
    | 'Message'
    | 'VideoQuestionMessage'
    | 'StatusChangeMessage'
    | 'AppliedMessage'
    | 'NoteMessage'
    | 'AppointmentMessage'
    | 'NewAppMessage'
    | string;
  status?: number;
  author?: IUser;
  author_name?: string;
  room?: number;
  unit_room?: ChatRoom;
  company?: number;
  unit?: IUnit;
  is_waiting?: boolean;
  is_read?: boolean;
  application?: number | IApplication;
  application_id?: number;
  appointment?: IAppointment;
  chosen_appointment_slot?: IAppointmentSlot;
  applicant_name?: string;
  applicant_email?: string;
  created_at?: string;
  original_created_at?: string;
  process_app?: IProcessApp;
  num_video_answers?: number;
  num_video_questions?: number;
  num_screening_answers?: number;
  num_screening_questions?: number;
  num_provided_reference_contacts?: number;
  num_submitted_reference_surveys?: number;
  num_requested_contacts?: number;
  reference_survey_id?: string;
  nps_survey_id?: string;
  nps_survey_answer_score?: number | null;
  nps_survey_answer_comment_text?: string | null;
  image_id?: number;
  image_url?: string;
  image_thumbnail?: string;
  image_name?: string;
  attachment_id?: number | null;
  attachment_name?: string;
  attachment_url?: string;
  edited?: boolean;
  edited_by?: IUser;
  process_app_type?: JobProcessAppChoices;
  history_action?: JobProcessAppHistoryMessageChoices;
  new_appointment_slot_start_at?: string;
  // Custom variable
  is_editing?: boolean;
  exported_to_recman?: boolean;
  exported_to_recman_at?: string;
  recman_export_data?: ExportData;
  export_data?: ExportData;
  updated_at?: string;
  app_type?: JobProcessAppChoices;
  process_app_id?: number;
  has_screening_answers?: boolean;
  has_video_answers?: boolean;
  is_exported_to_recman?: boolean;
  is_exported_to_motimate?: boolean;
  learnifier_user_id?: string;
  planday_employee_id?: number;
  has_chosen_appointment_slot?: boolean;
  chosen_appointment_slot_start_at?: string;
  chosen_appointment_slot_error?: OutlookIntegrationError;
  seen?: boolean;
  sent?: boolean;
  expanded?: boolean;
  mentioned_users?: IUser[];
  is_new_application?: boolean;
  // Scrive notification variables:
  scrive_document_title?: string;
  scrive_party_name?: string;
  scrive_party_role?: string;
  scrive_deadline?: string;
  reason_for_decline?: string;
  staffing_assignment_invitation_response?: BaseProcessAppStaffingAssignmentInvitationResponse;
  staffing_assignment_work_schedule?: string;
  staffing_assignment_salary?: string;
  staffing_assignment_num_shifts?: number;
  staffing_assignment_description?: string;
}
