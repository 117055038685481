/* tslint:disable:max-classes-per-file */
import { IMessage, WsCommand } from './ws.interface';
import { IApplication } from './application.interface';
import { IUser } from './user.interface';

export enum ChatCommand {
  JOIN = 'join',
  LOAD = 'load',
  LOAD_MORE = 'load_more',
  SEND = 'send',
  LEAVE = 'leave',
  EDIT_MESSAGE = 'edit_message',
  DELETE_MESSAGE = 'delete_message',
  UNIT_ROOM_ACTIVITY = 'unit_room_activity',
  APPLICATION_DELETED = 'application_deleted',
}

export interface ChatRoom {
  id?: number;
  application?: IApplication;
  app_messages?: IMessage[];
}

export interface UnitRoomActivity {
  id?: number;
  created_at?: string;
  is_connected?: boolean;
  last_seen_at?: string;
  unit_room_id?: number;
  updated_at?: string;
  user?: IUser;
}

export class ChatCommandJoin extends WsCommand {
  public unit_room: ChatRoom;

  constructor(unit_room: ChatRoom) {
    super(ChatCommand.JOIN);
    this.unit_room = unit_room;
  }
}

export class ChatCommandLoad extends WsCommand {
  public title?: string;
  public unit_room: ChatRoom;
  public messages: IMessage[] = [];
  public previous: number = 0;
  public next: number = 0;
  public reached_end: boolean = false;

  constructor(unit_room: ChatRoom) {
    super(ChatCommand.LOAD);
    this.unit_room = unit_room;
  }
}

export class ChatCommandLoadMore extends WsCommand {
  public unit_room: ChatRoom;
  public previous: number = 0;
  public next: number = 0;
  public reached_end: boolean = false;
  public messages: IMessage[] = [];

  constructor(previous: number, next: number, unit_room: ChatRoom) {
    super(ChatCommand.LOAD_MORE);
    this.previous = previous;
    this.next = next;
    this.unit_room = unit_room;
  }
}

export class ChatCommandSend extends WsCommand {
  public unit_room: ChatRoom;
  public message: IMessage;

  constructor(unit_room: ChatRoom, message: IMessage) {
    super(ChatCommand.SEND);
    this.message = message;
    this.unit_room = unit_room;
  }
}

export class ChatCommandEditMessage extends WsCommand {
  public unit_room: ChatRoom;
  public message: IMessage;

  constructor(unit_room: ChatRoom, message: IMessage) {
    super(ChatCommand.EDIT_MESSAGE);
    this.message = message;
    this.unit_room = unit_room;
  }
}

export class ChatCommandDeleteMessage extends WsCommand {
  public unit_room: ChatRoom;
  public message_id: number;

  constructor(unit_room: ChatRoom, message_id: number) {
    super(ChatCommand.DELETE_MESSAGE);
    this.message_id = message_id;
    this.unit_room = unit_room;
  }
}

export class ChatCommandLeave extends WsCommand {
  public unit_room: ChatRoom;

  constructor(unit_room: ChatRoom) {
    super(ChatCommand.LEAVE);
    this.unit_room = unit_room;
  }
}

export class ChatCommandUnitRoomActivity extends WsCommand {
  public unit_room: ChatRoom;
  public unit_room_activities: UnitRoomActivity[];

  constructor(unit_room: ChatRoom, unit_room_activities: UnitRoomActivity[]) {
    super('unit_room_activity');
    this.unit_room_activities = unit_room_activities;
    this.unit_room = unit_room;
  }
}

export interface IUpdatedAppMessageWrapper {
  message: IMessage;
  onlyIfExistsBefore: boolean;
}
