import IUnit from './unit.interface';
import { IJob } from './job.interface';
import { IMediaImage } from './media.interface';

export enum ListingType {
  JOB = 'JOB',
  CAREER_PAGE = 'CAREER_PAGE',
  BLOG = 'BLOG',
}

export interface IJobListing {
  id?: number;
  job?: IJob;
  job_id?: number;
  job_listing_items?: IJobListingItem[];
  publisher_is_draft?: boolean;
  publisher_is_published?: boolean;
  publisher_modified_at?: string;
  publisher_published_at?: number;
  created_at?: string;
  updated_at?: string;
  global_background_rgba?: string;
  global_text_style?: string;
  global_divider_status?: boolean;
  publisher_linked?: number;
  job_listing_header?: IJobListingItem;
}

export enum IJobListingFreeTextImageType {
  text_on_the_left = 'text_on_the_left',
  text_on_the_right = 'text_on_the_right',
}

export enum MediaWidthOptions {
  WIDTH_FULL = 'WIDTH_FULL',
  WIDTH_COLUMN = 'WIDTH_COLUMN',
}

export enum ImageStylingOptions {
  CROP = 'CROP',
  FIT = 'FIT',
  EXPAND = 'EXPAND',
  RESET = 'RESET',
}

export interface IGobiBubbleSettings {
  id?: string; // <-- Use for just single story
  wrap?: boolean;
  stories: Array<{ id: string; title: string }>; // <-- Use for multiple stories
  playerOptions?: Array<{ autoStart: boolean }>;
  animatedBubble: boolean;
  floating_button: boolean;
}

export interface IJobListingItem {
  id?: number;
  job_listing_id?: number;
  blog_listing_id?: number;
  career_page_id?: number;
  background_image_media_id?: number;
  image_media_id?: number;
  background_image_url?: string;
  background_image_thumbnail?: string;
  image_url?: string;
  image_thumbnail?: string;
  created_at?: string;
  updated_at?: string;
  type?: LISTING_ITEM_TYPE;
  position?: number;
  background_rgba?: string;
  font_rgba?: string;
  title_rich?: string;
  title?: string;
  subtitle?: string;
  subtitle_rich?: string;
  youtube_video_id?: string;
  vimeo_video_id?: string;
  autoplay?: false;
  full_width?: false;
  free_text_rich?: string;
  free_text_image_type?: IJobListingFreeTextImageType;
  job_listing?: number;
  career_page?: number;
  blog_listing?: number;
  image_media?: number;
  background_image_media?: number;
  row_of_items_items?: RowOfItemsItem[];
  show_job_details_company?: boolean;
  show_job_details_position_type?: boolean;
  show_job_details_salary?: boolean;
  show_job_details_due_date?: boolean;
  show_job_details_location?: boolean;
  show_footer_company_logo?: boolean;
  show_footer_company_name?: boolean;
  image_width_option?: MediaWidthOptions;
  image_styling_option?: ImageStylingOptions;
  gobi_bubble_settings?: IGobiBubbleSettings;
}

export interface RowOfItemsItem {
  id?: number;
  listing_item_id?: number;
  image_media_id?: number;
  image_url?: string;
  image_thumbnail?: string;
  created_at?: string;
  updated_at?: string;
  headline_rich?: string;
  description_rich?: string;
}

export interface IListingJobSpecificationFeature {
  icon: string;
  title: string;
  info: string;
}

export interface IUnsplashPhotoInfo {
  width?: number;
  height?: number;
  urls?: {
    small?: string;
    raw?: string;
  };
  color?: string;
}

export interface IGalleryImage extends IUnsplashPhotoInfo {
  id?: number;
  unit_id?: number;
  unit_name?: string;
  image?: IMediaImage;
}

export interface IVideoId {
  youtubeId?: string | null;
  vimeoId?: string | null;
}

export interface ISuperButtonAction {
  id?: LISTING_SUPER_BUTTON_ACTION_ID;
  icon?: string;
  visible?: boolean;
  isMenuOpen?: boolean;
  atRightSide?: boolean;
}

export interface IGalleryParams {
  searchValue: string;
  page: number;
}

export const ACCEPTED_IMAGE_FORMATS = '.png,.jpg,.jpeg,.webp,.bmp,.tiff,.gif';

export enum LISTING_ITEM_TYPE {
  LISTING_ITEM_HEADER = 0,
  LISTING_ITEM_ABOUT_JOB = 1,
  LISTING_ITEM_IMAGE = 2,
  LISTING_ITEM_VIDEO = 3,
  LISTING_ITEM_FREE_TEXT = 4,
  LISTING_ITEM_FREE_TEXT_IMAGE = 5,
  LISTING_ITEM_FOOTER = 6,
  LISTING_ITEM_JOBS = 7,
  LISTING_ITEM_ABOUT_OFFICE = 8,
  LISTING_ITEM_GOBI_STORIES = 9,
  LISTING_ITEM_MAP = 10,
  LISTING_ITEM_ROW_OF_ITEMS = 11,
  LISTING_ITEM_CTA = 12,
  LISTING_ITEM_HIRING_TEAM = 13,
}

export enum LISTING_NEW_ITEM_TYPE {
  TEXT = 0,
  IMAGE = 1,
  VIDEO = 2,
  TEXT_AND_IMAGE = 3,
  MAP = 4,
  CTA = 5,
  ROW_OF_ITEMS = 6,
  GOBI_STORY = 7,
}

export enum LISTING_SUPER_BUTTON_ACTION_ID {
  IMAGE = 0,
  REMOVE_IMAGE = 1,
  BLOCK_COLOR = 2,
  TEXT_COLOR = 3,
  ALIGN = 4,
  VIDEO = 5,
  VIDEO_SETTINGS = 6,
  DUPLICATE = 7,
  DELETE = 8,
  RESET = 9,
  JOB_DETAILS_MORE = 10,
  FOOTER_MORE = 11,
  MEDIA_WIDTH = 12,
  GOBI_SETTINGS = 13,
}

export const EMPTY_LISTING: IJobListing = {
  job_listing_items: [
    {
      background_image_url: '',
      background_image_thumbnail: '',
      image_url: '',
      image_thumbnail: '',
      type: LISTING_ITEM_TYPE.LISTING_ITEM_HEADER,
      position: 0,
      background_rgba: '#FFFFFF00',
      font_rgba: '#2E2E2EFF',
      title_rich: '',
      title: '',
      subtitle: '',
      subtitle_rich: '',
      youtube_video_id: '',
      vimeo_video_id: '',
      autoplay: false,
      full_width: false,
      free_text_rich: '',
      free_text_image_type: IJobListingFreeTextImageType.text_on_the_left,
      career_page: undefined,
      blog_listing: undefined,
      image_media: undefined,
      background_image_media: undefined,
    },
    {
      background_image_url: '',
      background_image_thumbnail: '',
      image_url: '',
      image_thumbnail: '',
      type: LISTING_ITEM_TYPE.LISTING_ITEM_ABOUT_JOB,
      position: 1,
      background_rgba: '#FFFFFF00',
      font_rgba: '#2E2E2EFF',
      title_rich: '',
      title: '',
      subtitle: '',
      subtitle_rich: '',
      youtube_video_id: '',
      vimeo_video_id: '',
      autoplay: false,
      full_width: false,
      free_text_rich: '',
      free_text_image_type: IJobListingFreeTextImageType.text_on_the_left,
      career_page: undefined,
      blog_listing: undefined,
      image_media: undefined,
      background_image_media: undefined,
    },
    {
      background_image_url: '',
      background_image_thumbnail: '',
      image_url: '',
      image_thumbnail: '',
      type: LISTING_ITEM_TYPE.LISTING_ITEM_ABOUT_OFFICE,
      position: 2,
      background_rgba: '#FFFFFF00',
      font_rgba: '#2E2E2EFF',
      title_rich: '',
      title: '',
      subtitle: '',
      subtitle_rich: '',
      youtube_video_id: '',
      vimeo_video_id: '',
      autoplay: false,
      full_width: false,
      free_text_rich: '',
      free_text_image_type: IJobListingFreeTextImageType.text_on_the_left,
      career_page: undefined,
      blog_listing: undefined,
      image_media: undefined,
      background_image_media: undefined,
    },
    {
      background_image_url: '',
      background_image_thumbnail: '',
      image_url: '',
      image_thumbnail: '',
      type: LISTING_ITEM_TYPE.LISTING_ITEM_FOOTER,
      position: 3,
      background_rgba: '#FFFFFF00',
      font_rgba: '#2E2E2EFF',
      title_rich: '',
      title: '',
      subtitle: '',
      subtitle_rich: '',
      youtube_video_id: '',
      vimeo_video_id: '',
      autoplay: false,
      full_width: false,
      free_text_rich: '',
      free_text_image_type: IJobListingFreeTextImageType.text_on_the_left,
      career_page: undefined,
      blog_listing: undefined,
      image_media: undefined,
      background_image_media: undefined,
    },
  ],
  global_background_rgba: '#FFFFFFFF',
  global_text_style: 'sans',
  global_divider_status: false,
  publisher_is_draft: true,
  publisher_is_published: false,
};

export const LISTING_ITEM_HEADER = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_HEADER,
  subtitle: '',
  subtitleRich: '',
  titleRich: '',
  title: '',
  backgroundImage: '',
  backgroundImageUrl: '',
  backgroundImageThumbnail: '',
  backgroundImageMediaId: '',
};

export const LISTING_ITEM_ABOUT_JOB = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_ABOUT_JOB,
  subtitle: '',
  subtitleRich: '',
  titleRich: '',
  title: '',
  image: '',
  imageUrl: '',
  imageThumbnail: '',
  imageMediaId: '',
};

export const LISTING_ITEM_IMAGE = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_IMAGE,
  title: '',
  titleRich: '',
  image: '',
  imageUrl: '',
  imageThumbnail: '',
  imageMediaId: '',
};

export const LISTING_ITEM_VIDEO = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_VIDEO,
  youtubeVideoId: '',
  vimeoVideoId: '',
  titleRich: '',
  title: '',
};

export const LISTING_ITEM_FREE_TEXT = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_FREE_TEXT,
  freeTextRich: '',
  freeText: '',
};

export const LISTING_ITEM_FREE_TEXT_IMAGE = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_FREE_TEXT_IMAGE,
  subtitle: '',
  subtitleRich: '',
  titleRich: '',
  title: '',
  image: '',
  imageUrl: '',
  imageThumbnail: '',
  imageMediaId: '',
  freeTextType: '',
  freeTextRich: '',
};

export const LISTING_ITEM_FOOTER = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_FOOTER,
};

export const LISTING_ITEM_JOBS = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_JOBS,
  titleRich: '',
  title: '',
};

export const LISTING_ITEM_ABOUT_OFFICE = {
  type: LISTING_ITEM_TYPE.LISTING_ITEM_ABOUT_OFFICE,
  titleRich: '',
  title: '',
  subtitle: '',
  subtitleRich: '',
};
