import {
  IManualCandidateDetails,
  IManualCandidateCreation,
  IManualCandidateCreationError,
} from '../mj-models/manual-candidate.interface';
import BaseApi from './base.api';

export default class ManualCandidateApi extends BaseApi {
  public createManualCandidate = (
    data: IManualCandidateCreation
  ): Promise<IManualCandidateDetails | IManualCandidateCreationError> =>
    this.axios
      .post(`${this.baseUrl}users/manual-candidate/`, data)
      .then((response) => response.data);

  public updateManualCandidate = (
    id: number,
    data: IManualCandidateCreation
  ): Promise<IManualCandidateDetails> =>
    this.axios
      .put(`${this.baseUrl}users/manual-candidate/${id}/`, data)
      .then((response) => response.data);

  public getManualCandidateDetails = (
    id: number
  ): Promise<IManualCandidateDetails> =>
    this.axios
      .get(`${this.baseUrl}users/manual-candidate/${id}/`)
      .then((response) => response.data);

  public resendInvitationEmail = (
    manualCandidateCreationId: number
  ): Promise<IManualCandidateDetails> =>
    this.axios
      .post(
        `${this.baseUrl}users/manual-candidate/${manualCandidateCreationId}/resend-invitation/`
      )
      .then((response) => response.data);
}
