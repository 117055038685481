import { action, observable } from 'mobx';
import JobApi from '../mj-api/job.api';
import UnitApi from '../mj-api/unit.api';
import { IJob, IOgGraph, JobEditorAction } from '../mj-models/job.interface';
import INotificationStore from './notification.store';
import {
  IJobListing,
  IJobListingItem,
} from '../mj-models/job-listing.interface';
import ListingApi from '../mj-api/listing.api';
import { IJobProcess } from '../mj-models/process.interface';
import JobSourcingApi from '../mj-api/job-sourcing.api';
import {
  ICampaignUrl,
  IJobBoardSpecificationTag,
  IJobSourcing,
  SourcingChannelErrors,
} from '../mj-models/job-sourcing.interface';
import {
  IFinnAdvertisement,
  IFinnAdvertisementValues,
} from '../mj-models/finn-advertisement.interface';
import { adjustSourcingChannelBoards } from '../mj-utils/data.utils';
import { IVueI18n } from 'vue-i18n';
import { IArbetsformedlingen } from '../mj-models/arbetsformedlingen.interface';
import IntegrationApi from '../mj-api/integration.api';

export default interface IBusinessJobEditorStore {
  job: IJob;
  jobListing: IJobListing;
  jobProcess: IJobProcess;
  jobSourcing: IJobSourcing | null;
  jobOgGraph: IOgGraph | null;
  sourcingChannelErrors: SourcingChannelErrors;
  sourcingChannelSpecificationTags: IJobBoardSpecificationTag[];
  lastJobEditorUpdate: Date | null;
  pageChangeIndicator: boolean;
  globalIsLoading: boolean;
  globalLoadingText: string;
  isUpdatingJob: boolean;
  isFirstDraftOpen: boolean;
  didPublishJobIndicator: boolean;
  newJobEditorGlobalAction: {
    action: JobEditorAction | null;
  };
  i18n: IVueI18n | null;
  getJobDetail(jobId: number): Promise<IJob | undefined>;
  getJobListing(jobId: number): Promise<IJobListing | undefined>;
  getImageFromUrl(url: string): Promise<Blob | undefined>;
  updateJobListing(jobListing: IJobListing): Promise<IJobListing | undefined>;
  updateJobListingItem(
    jobListingItem: IJobListingItem
  ): Promise<IJobListingItem | undefined>;
  getJobSourcing(jobId: number): Promise<IJobSourcing | false>;
  resetStore(): void;
  updateJobSourcing(jobSourcing: IJobSourcing): Promise<IJobSourcing | false>;
  setSourcingChannelError(
    boardId: number,
    error: string,
    detail?: string
  ): Promise<void>;
  getSourcingChannelSpecificationTags(): Promise<
    IJobBoardSpecificationTag[] | false
  >;
  createChannelCampaignUrl(url: ICampaignUrl): Promise<ICampaignUrl | false>;
  createJobCampaignUrl(jobId: number): Promise<ICampaignUrl | false>;
  createFinnAdvertisement(
    boardId: number
  ): Promise<IFinnAdvertisement | undefined>;
  createArbetsformedlingenAdvertisement(
    boardId: number
  ): Promise<IArbetsformedlingen | undefined>;
  getFinnAdvertisementValues(
    unitId: number
  ): Promise<IFinnAdvertisementValues | undefined>;
  updateFinnAd(
    finnAd: IFinnAdvertisement
  ): Promise<IFinnAdvertisement | string>;
  updateArbetsformedlingenAd(
    arbetsformedlingen: IArbetsformedlingen
  ): Promise<IArbetsformedlingen | string>;
  stopFinnAds(finnAdId: number): Promise<string>;
  getOgGraph(ogGraphId: number): Promise<IOgGraph | false>;
  saveOgGraph(ogGraph: IOgGraph): Promise<IOgGraph | false>;
}

export class BusinessJobEditorStore implements IBusinessJobEditorStore {
  @observable public job: IJob = {};
  @observable public jobListing: IJobListing = {};
  @observable public jobSourcing: IJobSourcing | null = null;
  @observable public jobOgGraph: IOgGraph | null = null;
  @observable public lastJobEditorUpdate: Date | null = null;
  @observable public sourcingChannelErrors: SourcingChannelErrors = {};
  @observable
  public sourcingChannelSpecificationTags: IJobBoardSpecificationTag[] = [];
  @observable public pageChangeIndicator: boolean = false;
  @observable public jobProcess: IJobProcess = {};
  @observable public globalIsLoading: boolean = false;
  @observable public globalLoadingText: string = '';
  @observable public isUpdatingJob: boolean = false;
  @observable public didPublishJobIndicator: boolean = false;
  @observable public isFirstDraftOpen: boolean = false;
  // Using object so that deep watcher will be called
  // even if two of the same action is fired consecutively
  @observable public newJobEditorGlobalAction: {
    action: JobEditorAction | null;
  } = { action: null };

  public jobApi: JobApi;
  public unitApi: UnitApi;
  public listingApi: ListingApi;
  public jobSourcingApi: JobSourcingApi;
  public integrationApi: IntegrationApi;
  public notificationStore: INotificationStore;

  public i18n: IVueI18n | null = null;

  constructor(
    jobApi: JobApi,
    unitApi: UnitApi,
    notificationStore: INotificationStore,
    listingApi: ListingApi,
    jobSourcingApi: JobSourcingApi,
    integrationApi: IntegrationApi
  ) {
    this.jobApi = jobApi;
    this.unitApi = unitApi;
    this.notificationStore = notificationStore;
    this.listingApi = listingApi;
    this.jobSourcingApi = jobSourcingApi;
    this.integrationApi = integrationApi;
  }

  @action('GET JOB DETAIL')
  public getJobDetail = async (jobId: number): Promise<IJob | undefined> => {
    try {
      return await this.jobApi.getJobDetail(jobId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('GET JOB LISTING')
  public getJobListing = async (
    jobId: number
  ): Promise<IJobListing | undefined> => {
    try {
      return await this.listingApi.getJobListing(jobId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('UPDATE JOB LISTING')
  public updateJobListing = async (
    jobListing: IJobListing
  ): Promise<IJobListing | undefined> => {
    try {
      return await this.listingApi.updateJobListing(jobListing);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('UPDATE JOB LISTING')
  public updateJobListingItem = async (
    jobListingItem: IJobListingItem
  ): Promise<IJobListingItem | undefined> => {
    try {
      return await this.listingApi.updateJobListingItem(jobListingItem);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET IMAGE FROM URL')
  public getImageFromUrl = async (url: string): Promise<Blob | undefined> => {
    try {
      return (await this.listingApi.getImageFromUrl(url)) as Blob;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('GET JOB SOURCING')
  public getJobSourcing = async (
    jobId: number
  ): Promise<IJobSourcing | false> => {
    try {
      const sourcing = await this.jobSourcingApi.getJobSourcing(jobId);

      if (sourcing.job_boards) {
        // all boards have no errors on initial load
        sourcing.job_boards.map((board) => {
          this.sourcingChannelErrors[board.id] = { error: '', detail: '' };
        });

        // we need to update some sourcing channel fields on the frontend
        sourcing.job_boards = adjustSourcingChannelBoards(
          sourcing.job_boards,
          this.i18n,
          this.job.unit_display_name || this.job.unit_name!,
          this.job.unit_logo_url || ''
        );
      }
      return sourcing;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('RESET STORE')
  public resetStore() {
    this.job = {};
    this.jobListing = {};
    this.jobSourcing = {};
    this.sourcingChannelErrors = {};
    this.lastJobEditorUpdate = null;
    this.jobProcess = {};
  }

  @action('UPDATE JOB SOURCING')
  public updateJobSourcing = async (
    jobSourcing: IJobSourcing
  ): Promise<IJobSourcing | false> => {
    try {
      const sourcing = await this.jobSourcingApi.updateJobSourcing(jobSourcing);

      // we need to update some sourcing channel fields on the frontend
      if (sourcing.job_boards) {
        sourcing.job_boards = adjustSourcingChannelBoards(
          sourcing.job_boards,
          this.i18n,
          this.job.unit_display_name || this.job.unit_name!,
          this.job.unit_logo_url || ''
        );
      }

      return sourcing;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('SET SOURCING CHANNEL ERROR')
  public setSourcingChannelError = async (
    boardId: number,
    error: string,
    detail: string = ''
  ) => {
    this.sourcingChannelErrors = {
      ...this.sourcingChannelErrors,
      [boardId]: { error, detail },
    };
    return;
  };

  @action('GET SOURCING CHANNEL SPECIFICATION TAGS')
  public getSourcingChannelSpecificationTags = async () => {
    try {
      // takes only 25 tags due to pagination, but for now it's enough
      const page = await this.jobSourcingApi.getJobBoardSpecificationTags();
      return page.results;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('CREATE CHANNEL CAMPAIGN')
  public createChannelCampaignUrl = async (url: ICampaignUrl) => {
    try {
      return await this.jobSourcingApi.createCampaignUrl(url);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('CREATE JOB CAMPAIGN')
  public createJobCampaignUrl = async (jobId: number) => {
    try {
      return await this.jobSourcingApi.getJobCampaignUrl(jobId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  /**
   * This will create new finn ad and fill it
   * with job info connected to the job sourcing board
   * @param boardId
   */
  @action('CREATE FINN AD')
  public createFinnAdvertisement = async (
    boardId: number
  ): Promise<IFinnAdvertisement | undefined> => {
    try {
      return await this.jobSourcingApi.createFinnAdvertisement(boardId);
    } catch (error) {
      return undefined;
    }
  };

  /**
   * This will create new Arbetsformedlingen ad and fill it
   * with job info
   * @param boardId
   */
  @action('CREATE ARBETSFORMEDLINGEN AD')
  public createArbetsformedlingenAdvertisement = async (
    boardId: number
  ): Promise<IArbetsformedlingen | undefined> => {
    try {
      return await this.integrationApi.createArbetsformedlingenAdvertisement(
        boardId
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET FINN ADVERTISEMENT VALUES')
  public getFinnAdvertisementValues = async (
    unitId: number
  ): Promise<IFinnAdvertisementValues | undefined> => {
    try {
      return await this.jobSourcingApi.getFinnAdvertisement(unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE FINN ADVERTISEMENT')
  public updateFinnAd = async (
    finnAd: IFinnAdvertisement
  ): Promise<IFinnAdvertisement | string> => {
    try {
      return await this.jobSourcingApi.updateFinnAd(finnAd);
    } catch (error) {
      return this.notificationStore.getTextFromError(error);
    }
  };

  @action('UPDATE ARBETSFORMEDLINGEN ADVERTISEMENT')
  public updateArbetsformedlingenAd = async (
    arbetsformedlingen: IArbetsformedlingen
  ): Promise<IArbetsformedlingen | string> => {
    try {
      return await this.integrationApi.updateArbetsformedlingenAd(
        arbetsformedlingen
      );
    } catch (error) {
      return this.notificationStore.getTextFromError(error);
    }
  };

  @action('STOP FINN ADVERTISEMENT')
  public stopFinnAds = async (finnAdId: number): Promise<string> => {
    try {
      return await this.jobSourcingApi.stopFinnAds(finnAdId);
    } catch (error) {
      return this.notificationStore.getTextFromError(error);
    }
  };

  @action('GET OG GRAPH')
  public getOgGraph = async (ogGraphId: number) => {
    try {
      return await this.jobApi.getOgGraph(ogGraphId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('CREATE CHANNEL CAMPAIGN')
  public saveOgGraph = async (ogGraph: IOgGraph) => {
    try {
      return await this.jobApi.saveOgGraph(ogGraph);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };
}
