import { action, observable } from 'mobx';
import { MjWebsocketService } from '../mj-services/mj-websocket.service';
import { IMessage, WsCommand } from '../mj-models/ws.interface';
import {
  ChatCommand,
  ChatCommandDeleteMessage,
  ChatCommandEditMessage,
  ChatCommandJoin,
  ChatCommandLoad,
  ChatCommandLoadMore,
  ChatCommandSend,
  ChatCommandUnitRoomActivity,
  ChatRoom,
  IUpdatedAppMessageWrapper,
  UnitRoomActivity,
} from '../mj-models/ws-chat.interface';
import { compareDate } from '../mj-utils/data.utils';
import { JobProcessAppChoices } from '../mj-models/process.interface';
import ChatApi from '../mj-api/chat.api';
import { IAppointmentSlot } from '../mj-models/appointment.interface';
import {
  IJob,
  IStaffingAssignment,
  ITalentPool,
} from '../mj-models/job.interface';
import { IApplication } from '../mj-models/application.interface';

export default interface IWsChatStore {
  applicationId?: number;
  notes: IMessage[];
  messages: IMessage[];
  chatRoomJob: IJob;
  chatRoomTalentPool: ITalentPool;
  chatRoomStaffingAssignment: IStaffingAssignment;
  chatRoom: ChatRoom;
  loading: boolean;
  uploadLoading: boolean;
  unitRoomActivities: UnitRoomActivity[];
  currentUserId?: number;
  isUnansweredAppPopUpSeen?: boolean;
  updatedAppMessageWrapper: IUpdatedAppMessageWrapper | null;

  isStaffingDialogOpen: boolean;
  isStaffingDialogLoading: boolean;
  staffingDialogMessage: IMessage | null;
  staffingDialogState: 'accept' | 'decline' | null;

  editMessage: (message: IMessage) => Promise<boolean>;
  deleteMessage: (message: IMessage) => Promise<boolean>;
  applicationWasDeleted?: () => void;

  connect(roomId: number, applicationId: number): void;
  retryConnection(): void;
  disconnect(): void;
  sendMessage(message: string, image_id?: number, attachment_id?: number): void;
  sendNoteMessage(
    message: string,
    image_id?: number,
    attachment_id?: number
  ): void;
  loadMore(): void;
  restart(): void;
  markMessageAppAsDelivered(message: IMessage): void;
  updateConfirmedSlotMessage(slot: IAppointmentSlot): void;
  markNpsMessageAppAsDelivered(message: IMessage, score: number): void;
  markInterviewMessageAsCancelled(canceledSlot: IAppointmentSlot): void;

  setMessageIsEditing(messageId: number, isEditing: boolean): void;

  markSdworxExportAsComplete(message: IMessage, exportData: any): void;
}

export class WsChatStore extends MjWebsocketService implements IWsChatStore {
  @observable public notes: IMessage[] = [];
  @observable public messages: IMessage[] = [];
  @observable public chatRoom: ChatRoom = {};
  @observable public chatRoomJob: IJob = {};
  @observable public chatRoomTalentPool: ITalentPool = {};
  @observable public chatRoomStaffingAssignment: IStaffingAssignment = {};
  @observable public loading: boolean = false;
  @observable public uploadLoading: boolean = false;
  @observable public unitRoomActivities: UnitRoomActivity[] = [];
  @observable public currentUserId?: number;
  @observable public isUnansweredAppPopUpSeen?: boolean = false;
  @observable
  public updatedAppMessageWrapper: IUpdatedAppMessageWrapper | null = null;
  @observable
  public applicationWasDeleted?: () => void = undefined;
  @observable public isStaffingDialogOpen: boolean = false;
  @observable public staffingDialogMessage: IMessage | null = null;
  @observable public staffingDialogState: 'accept' | 'decline' | null = null;
  @observable public isStaffingDialogLoading: boolean = false;

  public applicationId?: number;

  private roomId?: number;
  private previous: number = 0;
  private next: number = 0;
  private reachedEnd: boolean = false;

  constructor(url: string, private chatApi: ChatApi) {
    super(url);
  }

  @action('CONNECT')
  public connect(roomId: number, applicationId: number) {
    this.messages = [];
    this.chatRoom = {};
    this.roomId = roomId;
    this.applicationId = applicationId;
    this.connectWebsocket(this.url);
    this.loading = true;
  }

  @action('DISCONNECT')
  public disconnect() {
    this.messages = [];
    this.disconnectWebsocket();
  }

  @action('RETRY CONNECTION')
  public retryConnection() {
    this.retryWebsocketConnection();
  }

  @action('UPDATE MESSAGE ON APP DELIVERY')
  public markMessageAppAsDelivered = (message: IMessage) => {
    this.messages = this.messages.map((oldMessage) => {
      if (oldMessage.id !== message.id || !message.process_app) {
        return oldMessage;
      }
      if (
        message.process_app.app_type ===
        JobProcessAppChoices.PROCESS_APP_SCREENING_QUESTIONS
      ) {
        return {
          ...oldMessage,
          num_screening_answers: oldMessage.num_screening_questions,
        };
      }
      return oldMessage;
    });
  };

  @action('DISPLAY APPOINTMENT NOTIFICATION AS CONFIRMED')
  public updateConfirmedSlotMessage = (slot: IAppointmentSlot) => {
    // find the message with the confirmed appointment and update it
    this.messages = this.messages.map((message) => {
      if (
        slot &&
        slot.appointment &&
        message &&
        message.process_app &&
        message.process_app.onsite_interview_app &&
        message.process_app.onsite_interview_app.appointment &&
        message.process_app.onsite_interview_app.appointment.id ===
          slot.appointment.id
      ) {
        return { ...message, chosen_appointment_slot: { ...slot } };
      }
      return message;
    });
  };

  @action('UPDATE NPS APP MESSAGE ON APP DELIVERY')
  public markNpsMessageAppAsDelivered = (message: IMessage, score: number) => {
    this.messages = this.messages.map((oldMessage) => {
      if (oldMessage.id !== message.id || !message.process_app) {
        return oldMessage;
      }
      if (
        message.process_app.app_type === JobProcessAppChoices.PROCESS_APP_NPS
      ) {
        return {
          ...oldMessage,
          nps_survey_answer_score: score,
        };
      }
      return oldMessage;
    });
  };

  @action('UPDATE INTERVIEW APP MESSAGE ON CANCEL')
  public markInterviewMessageAsCancelled = (canceledSlot: IAppointmentSlot) => {
    this.messages = this.messages.map((message) => {
      if (
        message.chosen_appointment_slot?.id &&
        message.chosen_appointment_slot.id === canceledSlot.id
      ) {
        return { ...message, chosen_appointment_slot: undefined };
      }
      return message;
    });
  };

  @action('MARK MESSAGE AS SDWORX COMPLETED')
  public markSdworxExportAsComplete = (
    updatedMessage: IMessage,
    exportData: any
  ) => {
    this.messages = this.messages.map((message) => {
      if (message.id && message.id === updatedMessage.id) {
        return { ...message, export_data: exportData };
      }
      return message;
    });
  };

  @action('SET MESSAGE IS EDITING')
  public setMessageIsEditing = (messageId: number, isEditing: boolean) => {
    this.messages = this.messages.map((message: IMessage) => {
      if (messageId !== message.id) {
        return message;
      }
      return {
        ...message,
        is_editing: isEditing,
      };
    });
  };

  @action('DELETE MESSAGE')
  public deleteMessage = async (message: IMessage): Promise<boolean> => {
    try {
      await this.chatApi.deleteMessage(message);
      this.messages = this.messages.filter(
        (m: IMessage) => m.id !== message.id
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('EDIT MESSAGE')
  public editMessage = async (message: IMessage): Promise<boolean> => {
    try {
      const updatedMessage = await this.chatApi.editMessage(message);

      if (this.isNote(updatedMessage)) {
        this.notes = this.notes.map((m: IMessage) => {
          if (m.id !== message.id) {
            return m;
          }
          return { ...updatedMessage };
        });
      } else {
        this.messages = this.messages.map((m: IMessage) => {
          if (m.id !== message.id) {
            return m;
          }
          return { ...updatedMessage };
        });
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  @action('SEND MESSAGE')
  public sendMessage(
    message: string,
    image_id?: number,
    attachment_id?: number
  ) {
    this.sendCommand(
      new ChatCommandSend(
        { id: this.roomId! },
        {
          text: message,
          type: 'Message',
          application: this.applicationId || 0,
          created_at: new Date().toISOString(),
          image_id,
          attachment_id,
        }
      )
    );
  }

  @action('SEND NOTE MESSAGE')
  public sendNoteMessage(
    message: string,
    image_id?: number,
    attachment_id?: number
  ) {
    this.sendCommand(
      new ChatCommandSend(
        { id: this.roomId! },
        {
          text: message,
          type: 'NoteMessage',
          application: this.applicationId || 0,
          created_at: new Date().toISOString(),
          image_id,
          attachment_id,
        }
      )
    );
  }

  @action('LOAD MORE')
  public loadMore() {
    if (this.reachedEnd) {
      return;
    }
    this.loading = true;
    this.sendCommand(
      new ChatCommandLoadMore(this.previous, this.next, { id: this.roomId! })
    );
  }

  @action('RESTART')
  public async restart() {
    this.disconnect();
    this.retryConnection();
  }

  protected handleOnOpen(event: any) {
    if (this.socket$ && this.roomId) {
      this.sendCommand(new ChatCommandJoin({ id: this.roomId! }));
    }
  }

  protected handleCommand(command: WsCommand) {
    if (command.command === ChatCommand.LOAD) {
      this.handleCommandLoad(command as ChatCommandLoad);
    } else if (command.command === ChatCommand.SEND) {
      this.handleCommandSend(command as ChatCommandSend);
    } else if (command.command === ChatCommand.LOAD_MORE) {
      this.handleCommandLoadMore(command as ChatCommandLoadMore);
    } else if (command.command === ChatCommand.EDIT_MESSAGE) {
      this.handleCommandEditMessage(command as ChatCommandEditMessage);
    } else if (command.command === ChatCommand.DELETE_MESSAGE) {
      this.handleCommandDeleteMessage(command as ChatCommandDeleteMessage);
    } else if (command.command === ChatCommand.UNIT_ROOM_ACTIVITY) {
      this.handleUnitRoomActivity(command as ChatCommandUnitRoomActivity);
    } else if (
      command.command === ChatCommand.APPLICATION_DELETED &&
      this.applicationWasDeleted
    ) {
      this.applicationWasDeleted();
    }
  }

  private async handleCommandLoad(command: ChatCommandLoad) {
    const messages: IMessage[] = [];
    const notes: IMessage[] = [];

    command.messages.forEach((message: IMessage) => {
      if (this.isNote(message)) {
        notes.push(message);
      } else {
        messages.push(message);
      }
    });
    this.messages = this._sortMessages(messages);
    // COnitnue here
    if (
      command.unit_room &&
      command.unit_room.application &&
      command.unit_room.application.source_id
    ) {
      if (command.unit_room.application.source_type === 'TALENT_POOL') {
        const publicTalentPool = await this.chatApi.fetchPublicTalentPool(
          command.unit_room.application.source_id
        );
        this.chatRoomTalentPool = {
          ...publicTalentPool,
          unit: {
            id: publicTalentPool.unit_id,
            name: publicTalentPool.unit_name,
            display_name: publicTalentPool.unit_name,
            logo_url: publicTalentPool.unit_logo_url,
          },
        };
      } else if (
        command.unit_room.application.source_type === 'STAFFING_ASSIGNMENT'
      ) {
        const publicStaffingAssignment =
          await this.chatApi.fetchPublicStaffingAssignment(
            command.unit_room.application.source_id
          );
        this.chatRoomStaffingAssignment = {
          ...publicStaffingAssignment,
          unit: {
            id: publicStaffingAssignment.unit_id,
            name: publicStaffingAssignment.unit_name,
            display_name: publicStaffingAssignment.unit_name,
            logo_url: publicStaffingAssignment.unit_logo_url,
          },
        };
      } else {
        const publicJob = await this.chatApi.fetchPublicJob(
          command.unit_room.application.source_id
        );
        this.chatRoomJob = {
          ...publicJob,
          unit: {
            id: publicJob.unit_id,
            name: publicJob.unit_name,
            display_name: publicJob.unit_display_name,
            logo_url: publicJob.unit_logo_url,
          },
        };
      }
    }
    // commenting out, as this has caused:
    // https://mojob.myjetbrains.com/youtrack/issue/M-9716

    // this.notes = [...notes];

    this.previous = command.previous;
    this.next = command.next;
    this.reachedEnd = command.reached_end;
    this.chatRoom = command.unit_room;
    this.loading = false;
    if (this.isLastMessageScriveApp(this.messages)) {
      // It might happen that the webhook from Scrive about updates
      // arrive in after fetching the application data with the app messages
      // and before the chat websocket has finished loading.
      // Thus we get a mismatch between what is shown in app preview and in the
      // chat app message.
      // To solve this we force similar scrive app status on app preview and in chat
      // by checking if the last message when websocket has finished loading is a scrive
      // app message and updating the app preview with the same app message id.
      this.updatedAppMessageWrapper = {
        message: { ...this.messages[messages.length - 1] },
        onlyIfExistsBefore: true,
      };
    }
  }

  private isLastMessageScriveApp(messages: IMessage[]): boolean {
    if (messages && messages.length > 0) {
      return this.isAppMessageScriveAppMessage(messages[messages.length - 1]);
    }
    return false;
  }

  private isNote(message: IMessage): boolean {
    return (
      message.type === 'NoteMessage' ||
      (message.type === 'NewAppMessage' &&
        message?.process_app?.app_type === 'JOB_PROCESS_APP_NOTE_MESSAGE')
    );
  }

  private isHistoryMessage(message: IMessage): boolean {
    return (
      message.type === 'NewAppMessage' &&
      message?.process_app?.app_type === 'JOB_PROCESS_APP_HISTORY_MESSAGE'
    );
  }

  private isAppMessageButNotHistoryMessage(message: IMessage): boolean {
    return (
      message.type === 'NewAppMessage' &&
      message?.process_app?.app_type !== 'JOB_PROCESS_APP_HISTORY_MESSAGE'
    );
  }

  private isAppMessageScriveAppMessage(message: IMessage): boolean {
    return (
      message.type === 'NewAppMessage' &&
      message?.process_app?.app_type === 'JOB_PROCESS_APP_SCRIVE'
    );
  }

  private isStaffingAppMoveMessage(message: IMessage): boolean {
    return (
      typeof message.application !== 'number' &&
      message.application?.source_type === 'STAFFING_ASSIGNMENT' &&
      message.type === 'NewAppMessage' &&
      message?.process_app?.app_type === 'JOB_PROCESS_APP_MOVE'
    );
  }

  private getHistoryMessageReferenceAppId(message: IMessage): number | null {
    if (
      message &&
      message.process_app &&
      message.process_app.history_message_app &&
      message.process_app.history_message_app.reference_process_app_id
    ) {
      return message.process_app.history_message_app.reference_process_app_id;
    }
    return null;
  }

  private async updateReferencedMessageAfterNewHistoryMessage(
    messageForUpdate: IMessage
  ) {
    if (!messageForUpdate.id) {
      return;
    }
    const updatedMessage = await this.chatApi.getMessage(messageForUpdate);
    if (updatedMessage) {
      this.messages = this.messages.map((oldMessage: IMessage) =>
        oldMessage.id === updatedMessage.id ? updatedMessage : oldMessage
      );
      this.updatedAppMessageWrapper = {
        message: { ...updatedMessage },
        onlyIfExistsBefore: false,
      };
    }
  }

  private handleCommandSend(command: ChatCommandSend) {
    if (this.isNote(command.message)) {
      this.notes = [...this.notes, command.message];
    } else {
      const oldMessages = this.messages.slice(0);
      if (this.isHistoryMessage(command.message)) {
        // The message connected to the history appointment history message
        // will be updated with created_at = now() in the database.
        // This is to get the most relevant app message at the bottom of the chat.
        // this will be reflected on next chat reload.
        // In the meanwhile(Before next chat reload)
        // we update the created at manually here.
        const referencedAppointmentAppId: number | null =
          this.getHistoryMessageReferenceAppId(command.message);
        if (referencedAppointmentAppId) {
          const foundIdx = oldMessages.findIndex(
            (message: IMessage) =>
              message.process_app &&
              message.process_app.id === referencedAppointmentAppId
          );
          if (foundIdx !== -1) {
            this.updateReferencedMessageAfterNewHistoryMessage(
              oldMessages[foundIdx]
            );
          }
        }
      } else if (this.isAppMessageButNotHistoryMessage(command.message)) {
        this.updatedAppMessageWrapper = {
          message: { ...command.message },
          onlyIfExistsBefore: false,
        };
      }

      const existingMessageIdx = oldMessages.findIndex(
        (m: IMessage) => m.id === command.message.id
      );
      if (existingMessageIdx !== -1) {
        oldMessages[existingMessageIdx] = command.message;
      } else {
        oldMessages.push(command.message);
      }
      this.messages = this._sortMessages(oldMessages);
      this.updateMessagesSeenValue();

      // Add updating process_step from message, just for staffing. Think mb need update for all sources
      if (this.isStaffingAppMoveMessage(command.message)) {
        this.chatRoom = {
          ...this.chatRoom,
          application: {
            ...this.chatRoom.application,
            process_step:
              typeof command.message.application !== 'number'
                ? command.message.application?.process_step
                : this.chatRoom.application?.process_step,
          } as IApplication,
        };
      }
    }
  }

  private handleCommandLoadMore(command: ChatCommandLoadMore) {
    const oldMessages = this.messages.slice(0);
    oldMessages.push(...command.messages);
    this.messages = this._sortMessages(oldMessages);
    this.previous = command.previous;
    this.next = command.next;
    this.reachedEnd = command.reached_end;
    this.updateMessagesSeenValue();
    this.loading = false;
  }

  private handleCommandEditMessage(command: ChatCommandEditMessage) {
    if (command.message) {
      if (command.message.type === 'NoteMessage') {
        const noteIndex = this.notes.findIndex(
          (x) => x.id === command.message.id
        );
        if (noteIndex > -1) {
          const notesClone = [...this.notes];
          notesClone[noteIndex] = { ...command.message };

          this.notes = notesClone;
        }
      } else {
        this.messages = this.messages.map((m: IMessage) => {
          if (m.id !== command.message.id) {
            return m;
          }
          return { ...command.message };
        });
        this.updateMessagesSeenValue();
      }
    }
  }

  private handleCommandDeleteMessage(command: ChatCommandDeleteMessage) {
    if (command.message_id) {
      if (
        this.notes.find((x) => x.id === command.message_id)?.type ===
        'NoteMessage'
      ) {
        this.notes = this.notes.filter(
          (m: IMessage) => m.id !== command.message_id
        );
      } else {
        this.messages = this.messages.filter(
          (m: IMessage) => m.id !== command.message_id
        );
        this.updateMessagesSeenValue();
      }
    }
  }

  private isLastMessage(message: IMessage): boolean {
    const validated = this._validateMessage(message);
    if (!validated) {
      return false;
    }
    const applicantId = this.chatRoom.application!.applicant!.id;
    const isCurrentUserApplicant =
      this.currentUserId === this.chatRoom.application!.applicant!.id!;
    // Check if message is the last message as we should only show
    // seen for last message
    const tempReversedMessages = this.messages.slice().reverse();
    if (isCurrentUserApplicant) {
      // If the user is applicant check if the message is the last
      // message from the applicant
      const lastMessageFromApplicant = tempReversedMessages.find(
        (reversedMessage: IMessage) => {
          return (
            reversedMessage.author && reversedMessage.author.id === applicantId
          );
        }
      );
      return (
        !!lastMessageFromApplicant && lastMessageFromApplicant.id === message.id
      );
    } else {
      // If the user is business user check if the message is
      // the last message sent from anyone else than the applicant
      const lastMessageFromBusinessUser = tempReversedMessages.find(
        (reversedMessage: IMessage) => {
          return (
            reversedMessage.author && reversedMessage.author.id !== applicantId
          );
        }
      );
      return (
        !!lastMessageFromBusinessUser &&
        lastMessageFromBusinessUser.id === message.id
      );
    }
  }

  private isMessageSeen(message: IMessage): boolean {
    const validated = this._validateMessage(message);
    if (!validated) {
      return false;
    }
    const applicantId = this.chatRoom.application!.applicant!.id;
    const messageCreatedAt: Date = new Date(message.created_at!);
    const isApplicantAuthor =
      message.author!.id! === this.chatRoom.application!.applicant!.id!;
    const isCurrentUserApplicant =
      this.currentUserId === this.chatRoom.application!.applicant!.id!;

    const isLastMessage = this.isLastMessage(message);
    if (!isLastMessage) {
      return false;
    }

    if (isCurrentUserApplicant) {
      // Chat is opened by the applicant so we check if
      // the applicant has written the message and that
      // any business user has seen the message
      const businessUserHasSeenMessageIdx = this.unitRoomActivities.findIndex(
        (activity: UnitRoomActivity) => {
          if (!activity.user) {
            return false;
          }
          const activityLastSeenAt: Date = new Date(activity.last_seen_at!);
          const isBusinessUser = activity.user!.id !== applicantId;
          // Business user has chat room activity after message was created or
          // a business user is currently in chat room.
          return (
            (isBusinessUser && activityLastSeenAt > messageCreatedAt) ||
            (isBusinessUser && activity.is_connected)
          );
        }
      );
      return isApplicantAuthor && businessUserHasSeenMessageIdx !== -1;
    } else {
      // Chat is opened by a business user so we check if
      // the applicant has NOT written the message and that
      // the applicant has seen the message
      const applicantHasSeenMessageIdx = this.unitRoomActivities.findIndex(
        (activity: UnitRoomActivity) => {
          if (!activity.user) {
            return false;
          }
          const activityLastSeenAt: Date = new Date(activity.last_seen_at!);
          const isApplicant = activity.user!.id === applicantId;
          // Business user has chat room activity after message was created or
          // a business user is currently in chat room.
          return (
            (isApplicant && activityLastSeenAt > messageCreatedAt) ||
            (isApplicant && activity.is_connected)
          );
        }
      );
      return !isApplicantAuthor && applicantHasSeenMessageIdx !== -1;
    }
  }

  private _validateMessage(message: IMessage): boolean {
    // Check that we have the current user info
    if (!this.currentUserId) {
      return false;
    }
    // Check if we have any messages
    if (!this.messages || !this.messages.length) {
      return false;
    }

    // Check if message object contains necessary author info
    if (!message.author) {
      return false;
    }
    // Check if unit room contains necessary applicant info
    if (
      !this.chatRoom ||
      !this.chatRoom.application ||
      !this.chatRoom.application.applicant
    ) {
      return false;
    }

    // Check if message is of type note as we don't need to display
    // seen info on those messages
    if (
      message.type === 'NoteMessage' ||
      (message.type === 'NewAppMessage' &&
        message.process_app &&
        message.process_app.app_type ===
          JobProcessAppChoices.PROCESS_APP_NOTE_MESSAGE)
    ) {
      return false;
    }

    return true;
  }

  private updateMessagesSeenValue() {
    this.messages = this.messages.map((message: IMessage) => {
      const seen: boolean = this.isMessageSeen(message);
      return {
        ...message,
        seen,
        sent: !seen && this.isLastMessage(message),
      };
    });
  }

  private handleUnitRoomActivity(command: ChatCommandUnitRoomActivity) {
    this.unitRoomActivities = command.unit_room_activities;
    this.updateMessagesSeenValue();
  }

  private _sortMessages(messages: IMessage[]): IMessage[] {
    return messages.sort((a, b) =>
      compareDate(
        new Date(this.getMessageCreatedAt(a)),
        new Date(this.getMessageCreatedAt(b))
      )
    );
  }

  private getMessageCreatedAt(message: IMessage): string {
    return message.created_at!;
  }
}
