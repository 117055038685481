


import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: true,
})
export default class MjTypographyBase extends Vue {
  @Prop({ default: false, type: Boolean }) public readonly noMargin!: boolean;
  @Prop({ required: false, type: Boolean }) public readonly oblique?: boolean;
  @Prop({ type: String }) public readonly title?: string; // tooltips

  @Prop({
    type: Number,
    validator: (val: number) => {
      return [1, 2, 3, 4].indexOf(val) !== -1;
    },
    required: false,
  })
  public readonly level!: number;
  @Prop({
    type: String,
    default: 'p',
  })
  public readonly tag!: string;
  @Prop({
    type: String,
    default: null,
  })
  public readonly color!: string;
}
