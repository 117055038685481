import {
  IApplicantLead,
  IUser,
  IUserDocument,
  IUsernameUpdate,
  SocialAccount,
} from '../mj-models/user.interface';
import ITermsAndConditions from '../mj-models/terms.interface';
import BaseApi from './base.api';
import { IApplication } from '../mj-models/application.interface';
import { IPage } from '../mj-models/utils.interface';

export default class UserApi extends BaseApi {
  public loginWithEmail = (email: string, password: string) =>
    this.axios
      .post(`${this.baseUrl}users/token-auth/`, {
        username: email,
        password,
      })
      .then((res) => this._continueLogin(res));

  public loginWithMicrosoft = (): Promise<boolean> =>
    this.axios.post(`${this.baseUrl}users/rest-auth/microsoft/login/`);

  public connectWithMicrosoft = (
    access_token: string,
    refresh_token: string,
    expires_at: string
  ): Promise<boolean> =>
    this.axios.post(`${this.baseUrl}users/rest-auth/microsoft/connect/`, {
      access_token,
      refresh_token,
      expires_at,
    });

  public loginWithGoogle = (access_token: string, id_token: string): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}users/rest-auth/google/login/`, {
        access_token,
        id_token,
      })
      .then((res) => this._continueLogin(res));

  public loginWithFacebook = (access_token: string, id_token: string): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}users/rest-auth/facebook/login/`, {
        access_token,
      })
      .then((res) => this._continueLogin(res));

  public updateUser = async (user: IUser): Promise<IUser> =>
    this.axios.put(`${this.baseUrl}users/${user.id}/`, user).then((response) => response.data);

  public getUser = async (): Promise<IUser> =>
    this.axios.get(`${this.baseUrl}users/current-user/`).then((response) => response.data);

  public validateAuthLinkToken = async (
    authLinkToken: string
  ): Promise<{ token: string; id: number; last_password_change: string }> =>
    this.axios
      .post(`${this.baseUrl}users/validate_token/`, {
        token: authLinkToken,
      })
      .then((response) => response.data);

  public getSeekerApplications = (): Promise<IPage<IApplication>> =>
    this.axios.get(`${this.baseUrl}users/applications/`).then((response) => response.data);

  public checkIfUserExist = async (email: string): Promise<IUser> =>
    this.axios
      .get(`${this.baseUrl}users/exists/?username=${email}`)
      .then((response) => response.data);

  public createUser = async (user: IUser): Promise<IUser> =>
    this.axios.post(`${this.baseUrl}users/`, user).then((response) => response.data);

  public sendPhoneVerificationCode = async (
    recaptcha_token: string,
    country_code?: string,
    phone_number?: string
  ) =>
    this.axios
      .post(`${this.baseUrl}users/send-phone-verification-code/`, {
        recaptcha_token,
        country_code,
        phone_number,
      })
      .then((response) => response.data);

  public verifyPhoneVerificationCode = async (verificationCode: string) =>
    this.axios
      .post(`${this.baseUrl}users/verify-phone-verification-code/`, {
        verification_code: verificationCode,
      })
      .then((response) => response.data);

  public createApplicantLead = async (applicantLead: IApplicantLead) =>
    this.axios
      .post(`${this.baseUrl}users/applicant-leads/${applicantLead.job_id}/`, applicantLead)
      .then((response) => response.data);

  public retrieveRecaptchaSiteKey = async () =>
    this.axios
      .get(`${this.baseUrl}users/retrieve-recaptcha-site-key/`)
      .then((response) => response.data);

  public retrieveMagicToken = async (): Promise<string> =>
    this.axios.get(`${this.baseUrl}users/magic-token/`).then((response) => response.data.token);

  public getUserDocuments = async (): Promise<IUserDocument[]> =>
    this.axios.get(`${this.baseUrl}users/documents/`).then((response) => response.data);

  public uploadUserDocument = async (formData: FormData): Promise<IUserDocument[]> =>
    this.axios
      .post(`${this.baseUrl}users/documents/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);

  public deleteUserDocument = async (documentId: number): Promise<IUserDocument[]> =>
    this.axios
      .delete(`${this.baseUrl}users/documents/${documentId}/`)
      .then((response) => response.data);

  public parseCV = async (formData: FormData): Promise<IUserDocument> =>
    this.axios
      .post(`${this.baseUrl}users/cv-parser/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);

  public sendResetPassword = async (email: string) =>
    this.axios
      .post(`${this.baseUrl}users/send-reset-password/`, {
        email: email || '',
      })
      .then((response) => response.data);
  public fetchNotAcceptedTerms = (): Promise<ITermsAndConditions[]> =>
    this.axios.get(`${this.baseUrl}users/terms/not-agreed/`).then((response) => response.data);
  public acceptTerms = async (terms: number[]) =>
    this.axios
      .post(`${this.baseUrl}users/terms/accept/`, {
        terms,
      })
      .then((response) => response.data);

  public changeUserPassword = async (password: string): Promise<string> =>
    this.axios
      .put(`${this.baseUrl}users/update-password/`, { password })
      .then((response) => response.data);

  public changeUserUsername = async (usernameUpdate: IUsernameUpdate): Promise<IUsernameUpdate> =>
    this.axios
      .put(`${this.baseUrl}users/${usernameUpdate.id}/update-username/`, usernameUpdate)
      .then((response) => response.data);

  public deleteUserAccount = async (id: number): Promise<string> =>
    this.axios.delete(`${this.baseUrl}users/${id}/`).then((response) => response.data);
  public resetPassword = async (password: string, token: string): Promise<{ token: string }> =>
    this.axios
      .post(`${this.baseUrl}users/reset-password/`, {
        password: password || '',
        token: token || '',
      })
      .then((response) => response.data);

  public acceptInvitation = async (
    password: string,
    token: string,
    baseApplicationId: number
  ): Promise<{ token: string }> =>
    this.axios
      .post(`${this.baseUrl}users/accept-manual-invitation/`, {
        password: password || '',
        token: token || '',
        a_id: baseApplicationId,
      })
      .then((response) => response.data);

  public fetchSocialAccounts = (unitId: number): Promise<IPage<SocialAccount>> =>
    this.axios
      .get(`${this.baseUrl}users/unit/${unitId}/rest-auth/social-accounts/`)
      .then((response) => response.data);

  public getIntercomHash = (): Promise<string> =>
    this.axios
      .get(`${this.baseUrl}users/current-user/intercom-hash/`)
      .then((response) => response.data);

  public logoutUser() {
    const authKey = 'Authorization';
    delete this.axios.defaults.headers.common[authKey];
    localStorage.removeItem('id_token');
  }

  public setToken(token: string) {
    const authKey = 'Authorization';
    this.axios.defaults.headers.common[authKey] = `JWT ${token}`;
    localStorage.setItem('id_token', token);
    return true;
  }

  private _continueLogin(response: any) {
    const authKey = 'Authorization';
    if (response.data.token) {
      this.axios.defaults.headers.common[authKey] = `JWT ${response.data.token}`;
      localStorage.setItem('id_token', response.data.token);
    } else if (response.data.access) {
      this.axios.defaults.headers.common[authKey] = `JWT ${response.data.access}`;
      localStorage.setItem('id_token', response.data.access);
    } else {
      this.axios.defaults.headers.common[authKey] = `JWT `;
      localStorage.setItem('id_token', '');
    }
    return true;
  }
}
