import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class SystemTypeMixin extends Vue {
  private get getDevicePlatform() {
    const platform = navigator.platform.toLowerCase();

    const isiOS =
      platform.includes('iphone') ||
      platform.includes('ipad') ||
      platform.includes('ipod');

    const isAndroid = platform.includes('android');

    return {
      isiOS,
      isAndroid,
      other: !isiOS && !isAndroid,
    };
  }
}
