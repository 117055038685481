
















































































import { Component, Mixins, Prop } from 'vue-property-decorator';

import MjButton from '../../atoms/mj-button/MjButton.vue';
import MjTypographyBody from '../../atoms/mj-typography/MjTypographyBody.vue';
import MjTypographyHeader from '../../atoms/mj-typography/MjTypographyHeader.vue';
import AppStoreIcon from '../../icons/AppStoreIcon.vue';
import GooglePlayIcon from '../../icons/GooglePlayIcon.vue';
import { SystemTypeMixin } from '../../mixins';
import MjAppStoreDownload from '../../molecules/mj-app-store-download/MjAppStoreDownload.vue';
import MjGooglePlayDownload from '../../molecules/mj-google-play-download/MjGooglePlayDownload.vue';
import MjTextDivider from '../../molecules/mj-text-divider/MjTextDivider.vue';
import MjContainer from '../../templates/mj-container/MjContainer.vue';
import MjGridCell from '../../templates/mj-grid-layout/MjGridCell.vue';

@Component({
  inheritAttrs: false,
  components: {
    MjTypographyBody,
    MjTypographyHeader,
    MjButton,
    MjContainer,
    MjGridCell,
    MjGooglePlayDownload,
    MjAppStoreDownload,
    GooglePlayIcon,
    AppStoreIcon,
    MjTextDivider,
  },
})
export default class MjBrowserRedirect extends Mixins(SystemTypeMixin) {
  @Prop({ default: false, type: Boolean }) private isMobile?: boolean;
  @Prop({ default: true, type: Boolean })
  private showDownloadMobileApp?: boolean;
  @Prop({ default: '', type: String }) private unitName?: string;
  @Prop({ default: '', type: String }) private customTitle?: string;
  @Prop({ default: '', type: String }) private customDescription?: string;

  private downloadChrome() {
    window.open('https://www.google.com/chrome/', '_blank');
  }
}
