import { action, observable } from 'mobx';
import UnitApi from '../mj-api/unit.api';
import JobApi from '../mj-api/job.api';
import ChecklistApi from '../mj-api/checklist.api';
import NotificationsApi from '../mj-api/notifications.api';
import { Achievement, Checklist } from '../mj-models/checklist.interface';
import { IWsNotification } from '../mj-models/ws-notification.interface';
import { IJobs, JobOpeningFilterOption } from '../mj-models/job.interface';

export default interface IBusinessDashboardStore {
  jobOpenings?: IJobs;
  jobOpeningsLoading: boolean;
  numLiveTotal: number;
  numCompletedTotal: number;
  numUnpublishedTotal: number;
  jobStatusCountLoading: boolean;
  notificationsAreLoading: boolean;
  isTheLastNotificationsPage: boolean;
  notifications: IWsNotification[];
  notificationsPage: number;
  fetchJobOpenings: (unitId: number) => void;
  fetchJobStatusCount: (unitId: number) => void;
  jobOpeningsFilterQuery: string;
  setJobOpeningsFilter: (filter: JobOpeningFilterOption) => void;
  jobOpeningsPage: number;
  getChecklist: (userId: number) => void;
  checkList?: Checklist;
  registerAchievement: (achievement: Achievement) => void;
  getRecruiterNotifications(unitId: number, filterOption?: string): void;
}

export class BusinessDashboardStore implements IBusinessDashboardStore {
  @observable public checkList?: Checklist = undefined;
  @observable public jobOpenings?: IJobs = undefined;
  @observable public jobOpeningsLoading = true;
  @observable public jobOpeningsPage = 1;
  @observable public numLiveTotal = 0;
  @observable public numCompletedTotal = 0;
  @observable public numUnpublishedTotal = 0;
  @observable public jobStatusCountLoading = true;
  @observable public notificationsAreLoading: boolean = false;
  @observable public notifications: IWsNotification[] = [];
  @observable public notificationsPage: number = 1;
  @observable public isTheLastNotificationsPage: boolean = false;

  public jobOpeningsFilterQuery = '';

  public unitApi: UnitApi;
  public jobApi: JobApi;
  public checklistApi: ChecklistApi;
  public notificationsApi: NotificationsApi;

  constructor(
    unitApi: UnitApi,
    jobApi: JobApi,
    checklistApi: ChecklistApi,
    notificationsApi: NotificationsApi
  ) {
    this.unitApi = unitApi;
    this.jobApi = jobApi;
    this.checklistApi = checklistApi;
    this.notificationsApi = notificationsApi;
  }

  @action('GET NOTIFICATIONS')
  public getRecruiterNotifications = async (
    unitId: number,
    filterOption?: string
  ) => {
    try {
      if (!this.isTheLastNotificationsPage) {
        const notificationsPage = await this.notificationsApi.getNotifications(
          unitId,
          this.notificationsPage,
          filterOption
        );
        this.notifications = [
          ...this.notifications,
          ...notificationsPage.results,
        ];
        this.isTheLastNotificationsPage = notificationsPage.next === null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action('GET CHECKLIST')
  public getChecklist = async (userId: number) => {
    try {
      this.checkList = undefined;
      this.checkList = await this.checklistApi.getChecklist(userId);
    } catch (error) {
      console.log(error);
    }
  };

  @action('REGISTER ACHIEVEMENT')
  public registerAchievement = async (achievement: Achievement) => {
    try {
      await this.checklistApi.registerAchievement(achievement);
      if (this.checkList) {
        const foundAchievement = this.checkList.achievements.findIndex(
          (a: Achievement) => a.name === achievement.name
        );
        if (foundAchievement === -1) {
          this.checkList = {
            ...this.checkList,
            achievements: [...this.checkList.achievements, achievement],
          };
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action('FETCH JOB OPENINGS OF A UNIT')
  public fetchJobOpenings = async (unitId: number) => {
    try {

      // if it's the first page or next page exists
      if (this.jobOpenings === undefined || this.jobOpenings.results.length === 0 || this.jobOpenings.next !== null) {
        this.jobOpeningsLoading = true;

        const oldJobs = !!this.jobOpenings ? [...this.jobOpenings.results] : [];

        const newOpenings = await this.jobApi.fetchBusinessDashboardJobOpenings(
          unitId,
          this.jobOpeningsPage,
          this.jobOpeningsFilterQuery
        );

        // append new results to old results
        this.jobOpenings = {
          ...newOpenings,
          ...{ ...(this.jobOpenings && this.jobOpenings) },
          results: [...oldJobs, ...newOpenings.results],
        };

        this.jobOpeningsPage++;
        this.jobOpeningsLoading = false;
      }
    } catch (error) {
      this.jobOpeningsLoading = false;
      console.log(error);
    }
  };

  @action('FETCH JOB STATUS COUNT OF A UNIT')
  public fetchJobStatusCount = async (unitId: number) => {
    this.jobStatusCountLoading = true;
    try {
      const res = await this.unitApi.getUnitAssignedJobStatusCount(unitId);
      this.numLiveTotal = res.num_live_total;
      this.numCompletedTotal = res.num_completed_total;
      this.numUnpublishedTotal = res.num_unpublished_total;
    } catch (error) {
      this.numLiveTotal = 0;
      this.numCompletedTotal = 0;
      this.numUnpublishedTotal = 0;
      console.log(error);
    }
    this.jobStatusCountLoading = false;
  };

  @action('SET FETCHING JOB OPENINGS FILTER')
  public setJobOpeningsFilter = (filter: JobOpeningFilterOption) => {
    // reset infinite scrolling
    this.jobOpeningsPage = 1;
    this.jobOpenings!.results = [];

    switch (+JobOpeningFilterOption[filter]) {
      case JobOpeningFilterOption.ALL: {
        this.jobOpeningsFilterQuery = '';
        break;
      }
      case JobOpeningFilterOption.LIVE: {
        this.jobOpeningsFilterQuery =
          'is_live = True and is_unpublished = False and is_completed = False and publisher_is_draft = False';
        break;
      }
      case JobOpeningFilterOption.UNPUBLISHED: {
        this.jobOpeningsFilterQuery =
          'is_live = False and is_unpublished = True and is_completed = False and publisher_is_draft = False';
        break;
      }
      case JobOpeningFilterOption.COMPLETED: {
        this.jobOpeningsFilterQuery =
          'is_live = False and is_unpublished = False and is_completed = True and publisher_is_draft = False';
        break;
      }
      case JobOpeningFilterOption.DRAFT: {
        this.jobOpeningsFilterQuery =
          'is_live = False and is_unpublished = False and is_completed = False and publisher_is_draft = True';
        break;
      }
    }
  };
}
