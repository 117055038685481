




















import Button from 'material-components-vue/dist/button/button.min.js';
import Icon from 'material-components-vue/dist/icon/icon.min.js';
import { Component, Prop, Vue } from 'vue-property-decorator';

Vue.use(Button);
Vue.use(Icon);

@Component({
  inheritAttrs: true,
})
export default class MjButton extends Vue {
  @Prop({ default: false, type: Boolean }) public readonly raised!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly unelevated!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly outlined!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly dense!: boolean;
  @Prop({ default: '', type: String }) public readonly href!: string;
  @Prop({ default: '', type: String }) public readonly minWidth!: string;
  @Prop({ default: 'button', type: String }) public readonly type!: string;
  @Prop({ default: false, type: Boolean }) public readonly positive!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly negative!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly neutral!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly secondary!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly short!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly cta!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly google!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly facebook!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly microsoft!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly ghost!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly text!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly textClean!: boolean;
  @Prop({ default: false, type: Boolean }) public readonly noMargin!: boolean;

  get classes() {
    return {
      'mj-button--positive': this.positive,
      'mj-button--negative': this.negative,
      'mj-button--neutral': this.neutral,
      'mj-button--secondary': this.secondary,
      'mj-button--cta': this.cta,
      'mj-button--ghost': this.ghost,
      'mj-button--text': this.text,
      'mj-button--text-clean': this.textClean,
      'mj-button--google': this.google,
      'mj-button--facebook': this.facebook,
      'mj-button--microsoft': this.microsoft,
      'mj-button--nomargin': this.noMargin,
      'mj-button--short': this.short,
    };
  }
}
