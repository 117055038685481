import { action, observable } from 'mobx';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import JobApi from '../mj-api/job.api';
import { IApplicationLead } from '../mj-models/application.interface';

export default interface IBusinessLeadsStore {
  applicationLeadsPage: IPage<IApplicationLead>;
  applicationLeadsPaginator: IPaginator;
  applicationLeadsIsLoading: boolean;
  applicationLeadsOrder: string;
  applicationLeadsIsAsc: boolean;

  getApplicationLeads: (
    jobId: number,
    page: number,
    pageSize: number
  ) => Promise<IApplicationLead[]>;
}
export class BusinessLeadsStore implements IBusinessLeadsStore {
  @observable public applicationLeadsPage: IPage<IApplicationLead> = {
    count: 0,
    next: '',
    prev: '',
    results: [],
  };
  @observable public applicationLeadsPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public applicationLeadsIsLoading: boolean = true;
  @observable public applicationLeadsOrder: string = 'id';
  @observable public applicationLeadsIsAsc: boolean = true;

  private jobApi: JobApi;

  constructor(jobApi: JobApi) {
    this.jobApi = jobApi;
  }

  @action('GET APPLICATION LEADS')
  public getApplicationLeads = async (
    jobId: number,
    page: number,
    pageSize: number
  ): Promise<IApplicationLead[]> => {
    try {
      this.applicationLeadsIsLoading = true;
      this.applicationLeadsPaginator.page = page;
      this.applicationLeadsPaginator.itemsPerPage = pageSize;
      if (page === 1) {
        this.applicationLeadsPage = await this.jobApi.getApplicationLeads(
          jobId,
          pageSize,
          page,
          this.getOrderingParameters()
        );
      } else {
        const newPage = await this.jobApi.getApplicationLeads(
          jobId,
          pageSize,
          page,
          this.getOrderingParameters()
        );
        this.applicationLeadsPage = {
          ...newPage,
          results: [...this.applicationLeadsPage.results, ...newPage.results],
        };
      }
      this.applicationLeadsPaginator.count =
        this.applicationLeadsPage.count || 0;
      this.applicationLeadsPaginator.maxPage = Math.ceil(
        this.applicationLeadsPaginator.count! /
          this.applicationLeadsPaginator.itemsPerPage
      );
      this.applicationLeadsIsLoading = false;
      return this.applicationLeadsPage.results;
    } catch (error) {
      return [];
    }
  };

  private getOrderingParameters() {
    return `${this.applicationLeadsIsAsc ? '' : '-'}${
      this.applicationLeadsOrder
    }`;
  }
}
