import { IAttribute } from './attribute.interface';

export enum Languages {
  en = 'en',
  nb = 'nb',
  sv = 'sv',
  fi = 'fi',
  uk = 'uk',
}

export interface IApplicantLead {
  phone: string;
  email: string;
  job_id: number;
}

export interface IPoint {
  type?: string;
  coordinates?: number[];
}

export interface IUserEducation {
  id?: number;
  school: string;
  degree: string;
  description: string;
  is_current: boolean;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  isOpen: false;
  isValid?: boolean;
  education_attribute_id?: number;
  education_attribute?: IAttribute;
  education_type_attribute?: IAttribute;
  educational_institution_attribute_id?: number;
  educational_institution_attribute?: IAttribute;
}

export interface IUserExperience {
  id?: number;
  company: string;
  position: string;
  city: string;
  description: string;
  is_current: boolean;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  isOpen: false;
  isValid?: boolean;
  experience_attribute_id?: number;
  experience_attribute?: IAttribute;
  place_of_work_attribute_id?: number;
  place_of_work_attribute?: IAttribute;
}

export interface IUserProfile {
  id?: number;
  profile_picture_thumbnail?: string;
  profile_picture_media_id?: number;
  profile_picture_url?: string;
  date_of_birth?: string;
  gender?: GenderOptions;
  address?: string;
  registered_on_web?: boolean;
  has_set_profile_picture?: boolean;
  phone: IPhone;
  bio?: string;
  language?: Languages;
  facebook_profile_url?: string;
  twitter_profile_url?: string;
  instagram_profile_url?: string;
  linkedin_profile_url?: string;
  point?: IPoint;
  is_dummy?: boolean;
}

export enum GenderOptions {
  female = 0,
  male = 1,
  unknown = 2,
  prefer_not_to_say = 3,
}

export interface IPhone {
  id?: number;
  created_at?: string;
  updated_at?: string;
  verified: boolean;
  country_code: string;
  phone_number: string;
  session_info?: string;
}

export interface CountryCodeOption {
  id: string;
  text: string;
  iso_code: string;
  country_code: string;
  is_group_header: boolean;
}

export interface IUser {
  id?: number;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  username?: string;
  password?: string;
  user_profile: IUserProfile;
  token?: string;
  experiences: IUserExperience[];
  educations: IUserEducation[];
  repeat_password?: string;
  email?: string;
  last_seen_at?: string;
}

export interface IUserDocument {
  id?: number;
  file?: string;
  user_id?: number;
  filename?: string;
  parsed_cv?: IUser;
  created_at?: string;
  updated_at?: string;

  // Client variables
  isLoading?: boolean;
  isDone?: boolean;
  deletable?: boolean;
}

export interface IUsernameUpdate {
  id?: number;
  username?: string;
}

export interface SocialAccountExtraData {
  id?: string;
  email?: string;
  verified_email?: boolean;
  name?: string;
  given_name?: string;
  family_name?: string;
  picture?: string;
  locale?: string;
  hd?: string;
  userPrincipalName?: string;
  jobTitle?: string;
  mail?: string;
  mobilePhone?: string;
  officeLocation?: string;
  preferredLanguage?: string;
  surname?: string;
  givenName?: string;
  displayName?: string;
}

export interface SocialAccount {
  id?: number;
  user_id?: number;
  provider?: string;
  uid?: string;
  last_login?: string;
  date_joined?: string;
  extra_data?: SocialAccountExtraData;

  // Custom variable;
  selected?: boolean;
}

export interface IUserNotificationSettings {
  id?: number;
  user_id?: number;
  receive_browser_push?: boolean;
  receive_mobile_push?: boolean;
  new_applications?: boolean;
  messages_from_applicants?: boolean;
  internal_notes_in_assigned_jobs?: boolean;
  application_status_updates?: boolean;
  one_way_video_interview_delivered?: boolean;
  interview_scheduling_updates?: boolean;
  reference_check_updates?: boolean;
  esigning_updates?: boolean;
}
