/* tslint:disable:max-classes-per-file */

import VueRouter from 'vue-router';

export interface IFacebookAnalyticsConfig {
  router?: any;
  ignoreRoutes: string[];
  disabled: boolean;
  debug: boolean;
  id?: string;
}

export class FacebookAnalyticsConfig implements IFacebookAnalyticsConfig {
  public router = {};
  public ignoreRoutes: string[] = [];
  public id: string = '';
  public disabled = true;
  public debug = true;
}

let config: FacebookAnalyticsConfig = new FacebookAnalyticsConfig();

export interface IFacebookAnalyticsEvent {
  name: string;
  event_data: string;
  event_custom_data?: any;
  app_id: string;
}

export class FacebookAnalyticsEvent implements IFacebookAnalyticsEvent {
  public allowed_events = ['track', 'trackCustom'];
  public name: string;
  public event_data: string;
  public event_custom_data: any;
  public app_id: string;

  public constructor(
    name: string,
    event_data: string,
    app_id: string,
    event_custom_data?: any
  ) {
    if (this.allowed_events.indexOf(name) === -1) {
      throw new Error(
        '[Vue Facebook Pixel]: Created invalid facebook analytics event'
      );
    }
    this.name = name;
    this.event_data = event_data;
    this.event_custom_data = event_custom_data;
    this.app_id = app_id;
  }
}

declare global {
  interface Window {
    fbq: any;
  }
}
// Private functions
const _fbqEnabled = () => {
  if (typeof window.fbq === 'undefined') {
    if (config.debug) {
      console.log(
        '[Vue Facebook Pixel]: `window.fbq` is not defined, skipping'
      );
    }
    return false;
  }
  return true;
};

const fire = (event: FacebookAnalyticsEvent) => {
  if (!_fbqEnabled()) {
    console.log('[Vue Facebook Pixel] FBQ was not loaded');
    return;
  }
  if (config.debug) {
    console.groupCollapsed(`[Vue Facebook Pixel] Track event "${event.name}"`);
    console.log(`With event data: ${event.event_data}`);
    console.log(`With custom data: ${event.event_custom_data}`);
    console.groupEnd();
  }
  if (config.disabled) {
    if (config.debug) {
      console.log(`[Vue Facebook Pixel] Not firing when disabled`);
    }
    return;
  }
  if (event.event_custom_data) {
    window.fbq(event.name, event.event_data, event.event_custom_data);
  } else {
    window.fbq(event.name, event.event_data);
  }
  return;
};

const install = (Vue: any, init_config: FacebookAnalyticsConfig) => {
  config = init_config;

  // These objects may contain different providers as well,
  // or might be empty:
  if (!Vue.fb_pixel) {
    Vue.fb_pixel = {};
  }

  if (!Vue.prototype.$fb_pixel) {
    Vue.prototype.$fb_pixel = {};
  }

  // Setting values for both Vue and component instances
  // Usage:
  // 1. `Vue.fb_pixel.fbq.init()`
  // 2. `this.$fb_pixel.fbq.init()`

  Vue.fb_pixel.fbq = { fire };
  Vue.prototype.$fb_pixel.fbq = { fire };

  if (config.debug) {
    console.groupCollapsed(`[Vue Facebook Pixel] Track event "init"`);
    console.log(`With event data: "${config.id}"`);
    console.groupEnd();
  }
  if (config.disabled) {
    if (config.debug) {
      console.log(`[Vue Facebook Pixel] Not firing when disabled`);
    }
  } else {
    if (!_fbqEnabled()) {
      console.log('[Vue Facebook Pixel] FBQ was not loaded');
      return;
    }
    window.fbq('init', process.env.VUE_APP_FACEBOOK_APP_ID);
    if (config.id) {
      window.fbq('addPixelId', config.id);
    }
    window.fbq('track', 'PageView');
  }
  // Support for Vue-Router:
  if (typeof (config.router !== 'undefined') && config.router) {
    const ignoreRoutes = config.ignoreRoutes;
    const router = config.router! as VueRouter;

    router.afterEach(({ path, name }) => {
      if (name && typeof (name !== 'undefined')) {
        if (ignoreRoutes.indexOf(name) !== -1) {
          if (config.debug) {
            console.log(
              `[Vue Facebook Pixel] Ignored event for route: "${name}"`
            );
          }
          return;
        }
      }
      const fire_event = new FacebookAnalyticsEvent(
        'track',
        'PageView',
        process.env.VUE_APP_FACEBOOK_APP_ID!,
        null
      );
      fire(fire_event);
      return;
    });
  }
};

export default { install };
