import { action, observable } from 'mobx';
import { MjWebsocketService } from '../mj-services/mj-websocket.service';
import { WsCommand } from '../mj-models/ws.interface';
import {
  ApplicationChangedCommand,
  ApplicationCommand,
  ApplicationJoinCommand,
  ApplicationLeaveCommand,
} from '../mj-models/ws-applicant.interface';
import ApplicationApi from '../mj-api/application.api';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import INotificationStore from './notification.store';
import { IWsNotification } from '../mj-models/ws-notification.interface';
import {
  ApplicationBulkDelete,
  IApplication,
  IApplicationExport,
  IBoardApplications,
  DetailedRatingsOfApplications,
  ScreeningViewState,
  IBoardStepApplications,
  IBoardStepState,
  IApplicationWebtempExportResponse,
} from '../mj-models/application.interface';
import { MjFilterData } from '../mj-models/filters.inteface';
import IDynamicProcessStore from './dynamic-process.store';
import { IProcessStep } from '../mj-models/process.interface';
import axios, { CancelTokenSource } from 'axios';
import { replaceAtId, RequestQueue } from '../mj-utils/data.utils';
import { MjFilterParsedQuery } from '../mj-utils/screening.filter.utils';

interface IApplicationTableData {
  loading: boolean;

  applications: IApplication[];
  selectedApplications: IApplication[];

  paginator: IPaginator;
  sortQuery: string;
  isAsc?: boolean;
}

interface IApplicationBoardData {
  applications: IBoardApplications;
  selectedApplications: IApplication[];

  currentStepId: number | null;
  viewportVisibleStepIds: number[];
}

export default interface IWsApplicantStore {
  lastApplicationChange?: IApplication;
  jobId?: number;
  screeningViewLoadedJobId?: number;
  jobWebsocketLoadedJobId?: number;
  loading: boolean;
  currentFilterData: MjFilterData[];
  searchValue: string;
  filterParsedQueries: MjFilterParsedQuery[];

  board: IApplicationBoardData;
  table: IApplicationTableData;

  screeningViewState: ScreeningViewState;
  i18n: any;

  detailedRatingsOfApplications: DetailedRatingsOfApplications;

  applicationStepChangeListener?: () => void;
  onMojobLogoClickedFromSendNewAppListener?: (deleteApp: boolean) => void;
  initializeBoardLazyLoading: (steps: IProcessStep[]) => void;
  fetchBoardApplications: (steps: IProcessStep[]) => void;

  fetchTableApplications: () => void;
  setNumberOfAffectedApplicants: (changed: number) => void;
  notifyOnSendingMessage: (affected: number, userName: string, type: string) => void;
  notifyOnApplicantsMove: (applicantName: string, applicationStep: string) => void;

  handleNewNotfication: (notification: IWsNotification, showingApplicationId: number) => void;

  setUpdatedApplication: (updatedApplication: IApplication) => void;
  getLocalApplicationFromId: (applicationId: number) => IApplication | undefined;

  connectToWebsocket(jobId: number): Promise<void>;
  isWebsocketConnected(): boolean;
  fetchApplications(jobId: number): Promise<void>;
  retryConnection(): void;
  disconnect(): void;
  resetToDefaults(): void;
  toggleAll(val: boolean): void;
  resetNotificationCounter(applicationId: number): void;
  sortByProp(prop: string): void;
  toggleTableState(state: ScreeningViewState): void;
  applicationSelected(application: IApplication): void;
  allBoardColumnApplicationsSelected(stepId: number): void;
  boardColumnApplicationsSort(stepId: number, sortParametersQuery: string): void;
  setCurrentFilterData(filterData: MjFilterData[]): void;
  getBoardColumnApplications(stepId: number, page: number, isLoadMore: boolean): any;
  onHandleBoardLoadMore(stepId: number): Promise<void>;
  onHandleBoardLoadAllStepApplications(stepId: number, pageSize: number): Promise<void>;
  onHandleReloadBoardColumn(stepId: number): Promise<void>;
  onPrintCv(application: IApplication): Promise<Blob | undefined>;
  onExportToWebtemp(applicationId: number): Promise<IApplicationWebtempExportResponse | undefined>;
  onExportToExcel(applicationIds: number[], jobId: number): Promise<IApplicationExport | undefined>;
  bulkDeleteApplications(
    jobId: number,
    applicationsBulkDelete: ApplicationBulkDelete[]
  ): Promise<ApplicationBulkDelete[]>;
  getApplicationRating(applicationId: number): Promise<boolean>;
  loadVisibleBoardSteps(visibleStepIds: number[]): Promise<void>;
  getApplications(args: {
    searchValue: string;
    stepId?: number;
    jobId: number;
    page: number;
    query: MjFilterParsedQuery[];
    pageSize: number;
    cancelTokenSource?: CancelTokenSource;
    sort?: string;
  }): Promise<IPage<IApplication> | undefined>;
  cleanup(): void;
}

/* tslint:disable:one-line */
export class WsApplicantStore extends MjWebsocketService implements IWsApplicantStore {
  @observable public jobId?: number;
  @observable public screeningViewLoadedJobId?: number;
  @observable public jobWebsocketLoadedJobId?: number;
  @observable public currentFilterData: MjFilterData[] = [];
  @observable public filterParsedQueries: MjFilterParsedQuery[] = [];

  @observable public searchValue: string = '';

  @observable public screeningViewState: ScreeningViewState = ScreeningViewState.table;
  @observable public lastApplicationChange?: IApplication;

  // Applicant page global loading indicator
  @observable public loading: boolean = false;

  @observable public board: IApplicationBoardData = {
    applications: {},
    selectedApplications: [],

    currentStepId: null,
    viewportVisibleStepIds: [],
  };
  @observable public table: IApplicationTableData = {
    loading: false,

    applications: [],
    selectedApplications: [],

    paginator: {
      page: 1,
      itemsPerPage: 25,
      count: 0,
      maxPage: 1,
    },

    sortQuery: 'process_step__position,-num_unread_notifications,-created_at',
  };

  @observable
  public applicationStepChangeListener?: () => void;
  @observable
  public detailedRatingsOfApplications: DetailedRatingsOfApplications = {};
  @observable
  public onMojobLogoClickedFromSendNewAppListener?: (deleteApp: boolean) => void;

  public i18n: any;

  public notificationStore: INotificationStore;
  public dynamicProcessStore: IDynamicProcessStore;
  private applicationApi: ApplicationApi;

  private numOfAffectedApplicants: number = 0;
  private numOfChangedApplicantsAwaiting: number = 0;

  // when we have chain of multiple requests for one search. We need to process only last one
  private expectedTableUpdateRequest: number = 0;
  private currentTableUpdateRequest: number = 0;

  private BOARD_STEP_DEFAULT_PAGE_SIZE = 15;

  private requestQueue = new RequestQueue<IPage<IApplication> | null>();

  constructor(
    dynamicProcessStore: IDynamicProcessStore,
    notificationStore: INotificationStore,
    applicationApi: ApplicationApi,
    url: string
  ) {
    super(url);
    this.applicationApi = applicationApi;
    this.notificationStore = notificationStore;
    this.dynamicProcessStore = dynamicProcessStore;
  }

  @action('IS WEBSOCKET CONNECTED')
  public isWebsocketConnected(): boolean {
    return this.isConnected();
  }

  @action('SET NUMBER OF AFFECTED APPLICANTS')
  public setNumberOfAffectedApplicants(affected: number): void {
    this.numOfAffectedApplicants = affected;
    this.numOfChangedApplicantsAwaiting = affected;
  }

  @action('NOTIFY ON SENDING MESSAGE')
  public notifyOnSendingMessage(affected: number, userName: string, type: string) {
    if (affected === 1) {
      if (type === 'NoteMessage') {
        this.notificationStore.showMessageWithCloseButton(
          this.i18n &&
            this.i18n
              .t('dashboard_notification__sent_note', {
                name: userName,
              })
              .toString()
        );
      } else if (type === 'NewAppMessage') {
        this.notificationStore.showMessageWithCloseButton(
          this.i18n &&
            this.i18n
              .t('dashboard_notification__sent_app', {
                name: userName,
              })
              .toString()
        );
      } else {
        this.notificationStore.showMessageWithCloseButton(
          this.i18n &&
            this.i18n
              .t('dashboard_notification__sent_message', {
                name: userName,
              })
              .toString()
        );
      }
    } else {
      if (type === 'NoteMessage') {
        this.notificationStore.showMessageWithCloseButton(
          this.i18n &&
            this.i18n
              .t('dashboard_notification__sent_multiple_notes', {
                number: affected,
              })
              .toString()
        );
      } else if (type === 'NewAppMessage') {
        this.notificationStore.showMessageWithCloseButton(
          this.i18n &&
            this.i18n
              .t('dashboard_notification__sent_multiple_app', {
                number: affected,
              })
              .toString()
        );
      } else {
        this.notificationStore.showMessageWithCloseButton(
          this.i18n &&
            this.i18n
              .t('dashboard_notification__sent_multiple_messages', {
                number: affected,
              })
              .toString()
        );
      }
    }
  }

  @action('NOTIFY ON APPLICANTS MOVE')
  public notifyOnApplicantsMove(applicantName: string, applicationStep: string): void {
    this.numOfChangedApplicantsAwaiting--;

    if (this.numOfChangedApplicantsAwaiting === 0) {
      this.notificationStore.showMessageWithCloseButton(
        this.getNotificationMessage(applicantName, applicationStep)
      );

      this.numOfAffectedApplicants = 0;
      if (this.applicationStepChangeListener) {
        this.applicationStepChangeListener();
      }
    }
  }

  @action('SET FILTER DATA')
  public setCurrentFilterData(filterData: MjFilterData[]) {
    this.currentFilterData = filterData;
  }

  @action('INITIALIZE BOARD LAZY LOADING')
  public initializeBoardLazyLoading(steps: IProcessStep[]) {
    this.board = {
      ...this.board,
      applications: steps.reduce((accumulator, step) => {
        return {
          ...accumulator,
          [step.id!]: {
            ...this.board.applications[step.id!],
            state: IBoardStepState.HIDDEN,
          } as IBoardStepApplications,
        };
      }, {} as IBoardApplications),
    };
  }

  @action('FETCH APPLICATIONS')
  public async fetchApplications(jobId: number): Promise<void> {
    this.jobId = jobId;

    if (!this.jobId) {
      return;
    }
    if (this.screeningViewState === ScreeningViewState.table) {
      await this.fetchTableApplications();
      await this.fetchBoardApplications();
    } else {
      await this.fetchBoardApplications();
      await this.fetchTableApplications();
    }
  }

  @action('CONNECT TO WEBSOCKET')
  public async connectToWebsocket(jobId: number): Promise<void> {
    this.jobId = jobId;
    if (jobId) {
      this.connectWebsocket(this.url);
    }
  }

  @action('LOAD VISIBLE BOARD STEPS')
  public async loadVisibleBoardSteps(visibleStepIds: number[]) {
    this.board = {
      ...this.board,
      viewportVisibleStepIds: visibleStepIds,
    };
    await this.fetchBoardApplications();
  }

  @action('FETCH BOARD APPLICATIONS')
  public fetchBoardApplications = async () => {
    const initialPage: number = 1;
    if (!this.jobId) {
      return;
    }

    for (const stepInQueue of this.requestQueue.queue) {
      if (!this.board.applications[stepInQueue.key]) {
        continue;
      }

      // cancel request - this step is not visible in the viewport
      if (!this.board.viewportVisibleStepIds.includes(stepInQueue.key!)) {
        this.board.applications[stepInQueue.key].cancelTokenSource?.cancel();

        this.requestQueue.remove(stepInQueue.key);

        this.board = {
          ...this.board,
          applications: {
            ...this.board.applications,
            [stepInQueue.key]: {
              ...this.board.applications[stepInQueue.key],
              state: IBoardStepState.HIDDEN,
            },
          },
        };
      }
    }

    for (const stepId of this.board.viewportVisibleStepIds) {
      if (!this.board.applications[stepId]) {
        continue;
      }

      // skip - this step is already loaded
      if (this.board.applications[stepId].state === IBoardStepState.FINISHED) {
        continue;
      }

      // skip - this step is already in queue
      if (this.requestQueue.queue.some((q) => q.key === stepId)) {
        continue;
      }

      this.board = {
        ...this.board,
        applications: {
          ...this.board.applications,
          [stepId]: {
            ...this.board.applications[stepId],
            state: IBoardStepState.LOADING,
            applications: [],
          },
        },
      };
      // Add await before this line in order to make
      // columns load synchronously instead of at the same time.
      this.requestQueue.enqueue(
        () => this.getBoardColumnApplications(stepId, initialPage, false),
        stepId
      );
    }
  };

  @action('ON HANDLE BOARD LOAD ALL STEP APPLICATIONS')
  public async onHandleBoardLoadAllStepApplications(
    stepId: number,
    pageSize: number
  ): Promise<void> {
    if (this.jobId) {
      this.board = {
        ...this.board,
        applications: {
          ...this.board.applications,
          [stepId]: {
            ...this.board.applications[stepId],
            state: IBoardStepState.LOADING,
            applications: [],
            pageSize,
          },
        },
      };
      const sortQuery = this.board.applications[stepId].sortQuery;
      const applicationsInfo = await this.applicationApi.getApplications({
        jobId: this.jobId,
        searchValue: this.searchValue,
        stepId,
        query: this.filterParsedQueries,
        page: 1,
        pageSize,
        cancelTokenSource: this.board.applications[stepId].cancelTokenSource,
        sort: sortQuery,
      });

      this.board = {
        ...this.board,
        applications: {
          ...this.board.applications,
          [stepId]: {
            ...this.board.applications[stepId],
            page: 1,
            applications: applicationsInfo.results,
            totalApplicationsCount: applicationsInfo.count,
            isLastPage: applicationsInfo.next === null,
            state: IBoardStepState.FINISHED,
            sortQuery,
          },
        },
      };
    }
  }

  @action('ON HANDLE BOARD LOAD MORE')
  public async onHandleBoardLoadMore(stepId: number): Promise<void> {
    if (!this.board.applications[stepId].isLastPage) {
      this.board = {
        ...this.board,
        applications: {
          ...this.board.applications,
          [stepId]: {
            ...this.board.applications[stepId],
            state: IBoardStepState.LOADING,
            page: this.board.applications[stepId].page! + 1,
          },
        },
      };
      await this.getBoardColumnApplications(
        stepId,
        this.board.applications[Number(stepId)]!.page!,
        true
      );
    }
    return;
  }

  @action('ON HANDLE RELOAD BOARD COLUMN')
  public async onHandleReloadBoardColumn(stepId: number): Promise<void> {
    this.board = {
      ...this.board,
      applications: {
        ...this.board.applications,
        [stepId]: {
          ...this.board.applications[stepId],
          state: IBoardStepState.LOADING,
          applications: [],
        },
      },
    };
    await this.getBoardColumnApplications(stepId, 1, false);
    return;
  }

  @action('GET BOARD COLUMN APPLICATIONS')
  public async getBoardColumnApplications(
    stepId: number,
    page: number,
    isLoadMore: boolean = false
  ) {
    try {
      if (this.jobId) {
        const { cancelTokenSource } = this.board.applications[stepId];

        if (cancelTokenSource) {
          // cancel previous request
          cancelTokenSource.cancel();
          this.board = {
            ...this.board,
            applications: {
              ...this.board.applications,
              [stepId]: {
                ...this.board.applications[stepId],
                cancelTokenSource: axios.CancelToken.source(),
              },
            },
          };
        }
        const pageSize =
          this.board.applications[stepId].pageSize || this.BOARD_STEP_DEFAULT_PAGE_SIZE;
        const sortQuery =
          this.board.applications[stepId].sortQuery || '-num_unread_notifications,-created_at';

        const applicationsInfo = await this.applicationApi.getApplications({
          jobId: this.jobId,
          searchValue: this.searchValue,
          stepId,
          query: this.filterParsedQueries,
          page,
          pageSize,
          cancelTokenSource: this.board.applications[stepId].cancelTokenSource,
          sort: sortQuery,
        });
        this.board = {
          ...this.board,
          applications: {
            ...this.board.applications,
            [stepId]: {
              ...this.board.applications[stepId],
              page,
              applications: isLoadMore
                ? [...this.board.applications![stepId].applications!, ...applicationsInfo.results]
                : applicationsInfo.results,
              totalApplicationsCount: applicationsInfo.count,
              isLastPage: applicationsInfo.next === null,
              state: IBoardStepState.FINISHED,
              sortQuery,
            },
          },
        };
        return applicationsInfo;
      }
    } catch (e) {
      return null;
    }

    return null;
  }

  @action('FETCH TABLE APPLICATIONS')
  public fetchTableApplications = async () => {
    if (!this.jobId) {
      return;
    }
    this.table = {
      ...this.table,
      loading: true,
    };
    this.expectedTableUpdateRequest++;

    try {
      const tableApplicationsData = await this.applicationApi.getApplications({
        jobId: this.jobId,
        searchValue: this.searchValue,
        stepId: undefined,
        query: this.filterParsedQueries,
        page: this.table.paginator.page,
        pageSize: this.table.paginator.itemsPerPage,
        cancelTokenSource: undefined,
        sort: this.getTableOrderingParameters(),
      });
      this.currentTableUpdateRequest++;
      if (this.expectedTableUpdateRequest === this.currentTableUpdateRequest) {
        this.table = {
          ...this.table,
          paginator: {
            ...this.table.paginator,
            count: tableApplicationsData.count,
            maxPage: Math.ceil(tableApplicationsData.count / this.table.paginator.itemsPerPage),
          },
          applications: tableApplicationsData.results,
          loading: false,
        };
        this.expectedTableUpdateRequest = 0;
        this.currentTableUpdateRequest = 0;
      }
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      this.table = {
        ...this.table,
        loading: false,
      };
      this.expectedTableUpdateRequest = 0;
      this.currentTableUpdateRequest = 0;
    }
  };

  @action('GET APPLICATIONS')
  public getApplications = async (args: {
    searchValue: string;
    stepId?: number;
    jobId: number;
    page: number;
    query: MjFilterParsedQuery[];
    pageSize: number;
    cancelTokenSource?: CancelTokenSource;
    sort?: string;
  }): Promise<IPage<IApplication> | undefined> => {
    try {
      return await this.applicationApi.getApplications({
        jobId: args.jobId,
        searchValue: args.searchValue,
        stepId: args.stepId,
        query: args.query,
        page: args.page,
        pageSize: args.pageSize,
        sort: args.sort,
        cancelTokenSource: args.cancelTokenSource,
      });
    } catch (e) {
      console.log(e);
      return undefined;
    }
  };

  @action('DISCONNECT')
  public disconnect() {
    this.table = {
      ...this.table,
      applications: [],
    };
    this.board = {
      ...this.board,
      applications: {},
    };
    this.sendLeaveCommand();
    this.disconnectWebsocket();
  }

  @action('RETRY CONNECTION')
  public retryConnection() {
    this.loading = true;
    this.retryWebsocketConnection();
    this.loading = false;
  }

  @action('RESET NOTIFICATION COUNTER')
  public resetNotificationCounter(applicationId: number) {
    Object.keys(this.board.applications).map((stepId) => {
      const numStepId: number = Number(stepId);
      this.board = {
        ...this.board,
        applications: {
          ...this.board.applications,
          [numStepId]: {
            ...this.board.applications[numStepId],
            applications: this.board.applications[numStepId].applications?.map((application) =>
              application.id === applicationId
                ? { ...application, num_unread_notifications: 0 }
                : application
            ),
          },
        },
      };
    });

    this.table = {
      ...this.table,
      applications: [...this.table.applications].map((application) =>
        application.id === applicationId
          ? { ...application, num_unread_notifications: 0 }
          : application
      ),
    };
  }

  @action('RESET TO DEFAULTS')
  public resetToDefaults() {
    this.table = {
      ...this.table,
      applications: [...this.table.applications],
    };
  }

  @action('TOGGLE ALL APPLICATIONS')
  public toggleAll(val: boolean) {
    let applicationsWithoutRejected: IApplication[];

    if (this.screeningViewState === ScreeningViewState.table) {
      applicationsWithoutRejected = this.table.applications.filter(
        (application) => application.process_step!.is_failure !== true
      );
      this.table = {
        ...this.table,
        selectedApplications: val ? applicationsWithoutRejected : [],
      };
      return;
    }

    applicationsWithoutRejected = (
      Object.values(this.board.applications) as IBoardStepApplications[]
    )
      .reduce((acc, current) => acc.concat(...(current.applications || [])), [] as IApplication[])
      .filter((application) => application.process_step!.is_failure !== true);

    this.board = {
      ...this.board,
      selectedApplications: val ? applicationsWithoutRejected : [],
    };
  }

  @action('SORT BY PROP')
  public async sortByProp(prop: string) {
    this.table = {
      ...this.table,
      sortQuery: prop,
      isAsc: this.table.sortQuery !== prop ? this.table.isAsc : !this.table.isAsc,
    };
    await this.fetchTableApplications();
  }

  @action('APPLICATION SELECTED')
  public applicationSelected(application: IApplication) {
    let tempArray;
    if (this.screeningViewState === ScreeningViewState.table) {
      tempArray = [...this.table.selectedApplications];
    } else {
      tempArray = [...this.board.selectedApplications];
    }
    const applicantIndex = tempArray.findIndex((e) => e.id === application.id);
    if (applicantIndex === -1) {
      tempArray.push(JSON.parse(JSON.stringify(application)));
    } else {
      tempArray.splice(applicantIndex, 1);
    }
    if (this.screeningViewState === ScreeningViewState.table) {
      this.table = {
        ...this.table,
        selectedApplications: [...tempArray],
      };
    } else {
      this.board = {
        ...this.board,
        selectedApplications: [...tempArray],
      };
    }
  }

  @action('ALL BOARD COLUMN APPLICATIONS SELECTED')
  public async allBoardColumnApplicationsSelected(stepId: number) {
    if (this.screeningViewState === ScreeningViewState.board) {
      await this.onHandleBoardLoadAllStepApplications(
        stepId,
        this.board.applications[stepId].totalApplicationsCount || 500
      );
      this.board = {
        ...this.board,
        selectedApplications: [...this.board.applications[stepId]!.applications!],
      };
    }
  }

  @action('BOARD COLUMN APPLICATIONS SORT')
  public async boardColumnApplicationsSort(stepId: number, sortParametersQuery: string) {
    if (this.screeningViewState === ScreeningViewState.board) {
      this.board = {
        ...this.board,
        applications: {
          ...this.board.applications,
          [stepId]: {
            ...this.board.applications[stepId],
            sortQuery: sortParametersQuery,
          },
        },
      };
      await this.onHandleBoardLoadAllStepApplications(
        stepId,
        this.board.applications[stepId].pageSize || this.BOARD_STEP_DEFAULT_PAGE_SIZE
      );
    }
  }

  @action('SWITCH TABLE / BOARD')
  public toggleTableState(prop: ScreeningViewState) {
    this.screeningViewState = prop;
  }

  public addNotificationToApplicationList(
    notification: IWsNotification,
    applications: IApplication[]
  ) {
    const applicantIndex = applications.findIndex(
      (application) => application.id === notification.application
    );
    if (applicantIndex === -1) {
      return applications.slice();
    }

    const newApplications = applications.slice();

    const applicationToUpdate = newApplications[applicantIndex];

    const oldNumUnreadNotifications = applicationToUpdate.num_unread_notifications
      ? applicationToUpdate.num_unread_notifications
      : 0;
    newApplications[applicantIndex] = {
      ...newApplications[applicantIndex],
      num_unread_notifications: oldNumUnreadNotifications! + 1,
    };

    return newApplications;
  }

  @action('HANDLE NEW NOTIFICATION')
  public handleNewNotfication = (notification: IWsNotification, showingApplicationId: number) => {
    if (
      !notification.read &&
      notification.application &&
      notification.job &&
      notification.read === false
    ) {
      // Mark notification as seen and read is done in chat
      // when opening chat
      if (notification.job === this.jobId && showingApplicationId !== notification.application) {
        // Only increment if same job is selected and
        // we are not inside chat with applicant
        Object.keys(this.board.applications).map((stepId) => {
          const numStepId: number = Number(stepId);
          if (this.board.applications[numStepId].applications !== undefined) {
            this.board = {
              ...this.board,
              applications: {
                ...this.board.applications,
                [numStepId]: {
                  ...this.board.applications[numStepId],
                  applications: this.addNotificationToApplicationList(
                    notification,
                    this.board.applications[numStepId].applications!
                  ),
                },
              },
            };
          }
        });
        if (this.table.applications !== undefined) {
          this.table = {
            ...this.table,
            applications: this.addNotificationToApplicationList(
              notification,
              this.table.applications
            ),
          };
        }
      }
    }
  };

  @action('SET UPDATED APPLICATION')
  public async setUpdatedApplication(updatedApplication: IApplication) {
    if (this.board && this.board.applications) {
      Object.keys(this.board.applications).map((stepId: string) => {
        const numStepId: number = Number(stepId);
        if (this.board.applications[numStepId].applications !== undefined) {
          const foundApplication: IApplication | undefined = this.board.applications[
            numStepId
          ].applications!.find(
            (application: IApplication) => application.id === updatedApplication.id
          );
          if (foundApplication) {
            this.board = {
              ...this.board,
              applications: {
                ...this.board.applications,
                [numStepId]: {
                  ...this.board.applications[numStepId],
                  applications: replaceAtId<IApplication>(
                    this.board.applications[numStepId].applications!,
                    {
                      ...updatedApplication,
                    }
                  ),
                },
              },
            };
          }
        }
      });
    }
    if (this.table.applications !== undefined) {
      this.table = {
        ...this.table,
        applications: replaceAtId<IApplication>(this.table.applications, {
          ...updatedApplication,
        }),
      };
    }
  }

  @action('ON PRINT CV')
  public async onPrintCv(application: IApplication): Promise<Blob | undefined> {
    try {
      return this.applicationApi.getApplicationPdf(application.source!.id!, application.id!);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  }

  @action('ON EXPORT TO WEBTEMP')
  public async onExportToWebtemp(
    applicationId: number
  ): Promise<IApplicationWebtempExportResponse | undefined> {
    try {
      return await this.applicationApi.exportApplicantToWebtemp(applicationId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  }

  @action('ON EXPORT TO EXCEL')
  public async onExportToExcel(
    applicationIds: number[],
    jobId: number
  ): Promise<IApplicationExport | undefined> {
    try {
      return await this.applicationApi.createApplicationExportXlsx(jobId, {
        applications: applicationIds,
        job_id: jobId,
      });
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  }

  @action('ON EXPORT TO EXCEL')
  public async bulkDeleteApplications(
    jobId: number,
    applicationsBulkDelete: ApplicationBulkDelete[]
  ): Promise<ApplicationBulkDelete[]> {
    try {
      return await this.applicationApi.onBulkDeleteApplications(jobId, applicationsBulkDelete);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return [];
    }
  }

  @action('ON GET APPLICATION RATING')
  public async getApplicationRating(applicationId: number) {
    try {
      const ratings = await this.applicationApi.getApplicationRatings(applicationId);

      const updatedDetails = { ...this.detailedRatingsOfApplications };
      updatedDetails[applicationId] = ratings;
      this.detailedRatingsOfApplications = updatedDetails;
      return true;
    } catch {
      return false;
    }
  }

  @action('GET LOCAL APPLICATION FROM ID')
  public getLocalApplicationFromId = (applicationId: number): IApplication | undefined => {
    if (this.screeningViewState === ScreeningViewState.board) {
      const stepIdToGet = Object.keys(this.board.applications).find((stepId: string) => {
        if (!this.board.applications[+stepId].applications) {
          return false;
        }
        return (
          this.board.applications[+stepId]?.applications?.findIndex(
            (application) => application.id === applicationId
          ) !== -1
        );
      });
      if (!stepIdToGet) {
        return undefined;
      }

      return this.board.applications[+stepIdToGet].applications!.find(
        (application: IApplication) => application.id === applicationId
      );
    } else {
      return this.table.applications.find(
        (application: IApplication) => application.id === applicationId
      );
    }
  };

  public cleanup() {
    this.disconnect();
    this.searchValue = '';
    this.currentFilterData = [];
    this.filterParsedQueries = [];
    this.screeningViewLoadedJobId = undefined;
    this.table = {
      ...this.table,
      selectedApplications: [],
      paginator: {
        page: 1,
        itemsPerPage: 25,
        count: 0,
        maxPage: 1,
      },
    };
    this.board = {
      ...this.board,
      selectedApplications: [],
    };
  }

  protected handleOnOpen(event: any) {
    if (this.socket$) {
      this.sendJoinCommand();
    }
  }

  protected handleCommand(command: WsCommand) {
    this.loading = true;
    if (command.command === ApplicationCommand.APPLICATION_CHANGED) {
      this.handleApplicationChangedCommand(command as ApplicationChangedCommand);
      this.notifyOnApplicantsMove(
        (command as any).application.applicant.full_name,
        (command as any).application.process_step.name
      );
    }

    this.loading = false;
  }

  private getNotificationMessage(applicantName: string, applicationStep: string): string {
    if (this.numOfAffectedApplicants === 1) {
      if (this.isApplicationRejected(applicationStep)) {
        return (
          this.i18n &&
          this.i18n
            .t('dashboard_notification__rejected_applicant', {
              name: applicantName,
            })
            .toString()
        );
      } else {
        return (
          this.i18n &&
          this.i18n
            .t('dashboard_notification__moved_applicant', {
              name: applicantName,
              step: applicationStep,
            })
            .toString()
        );
      }
    }
    if (this.isApplicationRejected(applicationStep)) {
      return (
        this.i18n &&
        this.i18n
          .t('dashboard_notification__rejected_multiple_applicants', {
            number: this.numOfAffectedApplicants,
          })
          .toString()
      );
    } else {
      return (
        this.i18n &&
        this.i18n
          .t('dashboard_notification__moved_multiple_applicants', {
            number: this.numOfAffectedApplicants,
            step: applicationStep,
          })
          .toString()
      );
    }
  }

  private isApplicationRejected(applicationStep: string) {
    return applicationStep.toLocaleLowerCase().indexOf('rejected') !== -1;
  }

  private getTableOrderingParameters() {
    return `${this.table.isAsc || this.table.isAsc === undefined ? '' : '-'}${
      this.table.sortQuery
    }`;
  }

  private handleApplicationChangedCommand(command: ApplicationChangedCommand) {
    this.lastApplicationChange = { ...command.application };
    this.removeOldApplicantCardFromBoardApplications(command!.application!.id!);
    this.addMovedApplicantCardToBoardApplications(
      command!.application!.process_step!.id!,
      JSON.parse(JSON.stringify(command.application))
    );

    const tableApplicationIndex = this.table.applications.findIndex(
      (application) => application.id === command!.application!.id!
    );

    const copyOfTableApplications = this.table.applications.slice(0);

    if (tableApplicationIndex !== -1) {
      copyOfTableApplications[tableApplicationIndex] = {
        ...command!.application!,
      };
      this.table = {
        ...this.table,
        applications: copyOfTableApplications,
      };
    }
  }

  private sendJoinCommand() {
    this.sendCommand(new ApplicationJoinCommand(this.jobId!));
  }

  private sendLeaveCommand() {
    this.sendCommand(new ApplicationLeaveCommand(this.jobId!));
  }

  private addMovedApplicantCardToBoardApplications(stepId: number, application: IApplication) {
    if (
      stepId &&
      stepId in this.board.applications &&
      this.board.applications[stepId].applications !== undefined
    ) {
      this.board = {
        ...this.board,
        applications: {
          ...this.board.applications,
          [stepId]: {
            ...this.board.applications[stepId],
            applications: [application, ...this.board.applications[stepId]!.applications!],
            totalApplicationsCount: this.board.applications[stepId]!.totalApplicationsCount! + 1,
          },
        },
      };
    }
  }

  private removeOldApplicantCardFromBoardApplications(applicationId: number) {
    const stepIdToUpdate = Object.keys(this.board.applications).find((stepId) => {
      if (!this.board.applications[+stepId].applications) {
        return false;
      }

      return (
        this.board.applications[+stepId]?.applications?.findIndex(
          (application) => application.id === applicationId
        ) !== -1
      );
    });

    if (!stepIdToUpdate) {
      return;
    }

    const updatedApplications: IApplication[] = [
      ...this.board.applications[+stepIdToUpdate].applications!,
    ].filter((application) => application.id !== applicationId);

    this.board = {
      ...this.board,
      applications: {
        ...this.board.applications,
        [stepIdToUpdate]: {
          ...this.board.applications[+stepIdToUpdate],
          applications: updatedApplications,
          totalApplicationsCount:
            this.board.applications[+stepIdToUpdate].totalApplicationsCount! - 1,
        },
      },
    };
  }
}
