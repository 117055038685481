import { ACCEPTED_IMAGE_FORMATS } from '../mj-models/job-listing.interface';

export function imageHasAcceptedFormat(fileName: string) {
  const availableFormats = ACCEPTED_IMAGE_FORMATS.split(',');

  const fileExtension = fileName.substring(
    fileName.lastIndexOf('.'),
    fileName.length
  );

  return availableFormats.includes(fileExtension.toLowerCase());
}

export function downloadArrayBufferAsPdf(
  arrayBuffer: ArrayBuffer,
  filename: string
) {
  const a = document.createElement('a');

  const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
}
