import { action, computed, observable } from 'mobx';
import {
  IJob,
  IJobActionPermission,
  IJobs,
  IPositionFunction,
  IJobSubscription,
} from '../mj-models/job.interface';
import JobApi from '../mj-api/job.api';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import { JobProcessAppQuestionAnswer } from '../mj-models/process.interface';
import { Languages } from '../mj-models/user.interface';
import { Suggestion } from '../mj-models/events.interface';
import { MjFilterData } from '../mj-models/filters.inteface';
import { IApplicationExport } from '../mj-models/application.interface';
import INotificationStore from './notification.store';
import ApplicationApi from '../mj-api/application.api';

export default interface IJobStore {
  jobs: IJobs;
  publishedJobs: IJob[];
  job: IJob;
  jobId?: number;
  isLoading: boolean;
  tableJobsPaginator: IPaginator;
  currentFilterData: MjFilterData[];
  searchValue: string;
  currentUnitId: number;
  query: string;
  sortProp: string;
  isAsc: boolean;
  jobAssignments: any[];
  fetchPublicJob: (jobId: number) => Promise<boolean>;
  getJobDetail: (jobId: number) => Promise<IJob | undefined>;
  createJob: (job: IJob) => Promise<IJob | undefined>;
  fetchJobs: () => Promise<boolean>;
  fetchJobsPlain: (
    page: number,
    pageSize: number,
    qlQuery: string,
    searchValue: string,
    unitId: number
  ) => Promise<IJobs | undefined>;
  fetchJobAssignments: (jobId: number) => Promise<any>;
  sortByProp: (prop: string) => void;
  unpublish: (jobId: number) => Promise<boolean>;
  publishJob: (jobId: number) => Promise<boolean>;
  submitChanges: (jobId: number) => Promise<boolean>;
  delete: (jobId: number) => Promise<boolean>;
  clone: (jobId: number) => Promise<IJob | false>;
  cloneWithUnitId: (jobId: number, unitId: number) => Promise<IJob | undefined>;
  addAssignment: (event: { assignment: any; job: any }) => Promise<boolean>;
  removeAssignment: (event: { assignment: any; job: any }) => Promise<boolean>;
  fetchJobAdTitleSuggestions: (
    term: string,
    language: Languages
  ) => Promise<Suggestion[] | false>;
  fetchPositionFunctionSuggestions: (
    term: string,
    language: Languages,
    pageSize: number
  ) => Promise<Suggestion[] | false>;
  fetchApplicationVideoInterviewAnswers: (
    applicationId: number
  ) => Promise<JobProcessAppQuestionAnswer[]>;
  updateJob: (job: IJob) => Promise<IJob | undefined>;
  hasJobActionPermission: (
    jobId: number
  ) => Promise<IJobActionPermission | null>;
  fetchJobSubscriptions: (
    email: string
  ) => Promise<IPage<IJobSubscription> | undefined>;
  unSubscribeJobSubscription: (
    subscriptionId: string
  ) => Promise<boolean>;
  onExportAllToExcel(jobId: number): Promise<IApplicationExport | undefined>;
}

export class JobStore implements IJobStore {
  // @ts-ignore
  @observable public jobs: IJobs = {};
  @observable public job: IJob = {};
  @observable public jobId?: number;
  @observable public isLoading: boolean = false;
  @observable public currentUnitId: number = 0;
  @observable public jobAssignments: any[] = [];
  @observable public query: string = '';
  @observable public isAsc: boolean = true;
  @observable public sortProp: string =
    '-is_live,-is_unpublished,-is_completed,-latest_activity';
  @observable public tableJobsPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public currentFilterData: MjFilterData[] = [];
  @observable public searchValue: string = '';

  public jobApi: JobApi;
  public router: any;
  public applicationApi: ApplicationApi;
  public notificationStore: INotificationStore;

  constructor(
    jobApi: JobApi,
    applicationApi: ApplicationApi,
    notificationStore: INotificationStore,
    router: any
  ) {
    this.jobApi = jobApi;
    this.applicationApi = applicationApi;
    this.notificationStore = notificationStore;
    this.router = router;
  }

  @computed
  public get publishedJobs(): IJob[] {
    return this.jobs.results.filter((j) => j.is_published);
  }

  @action('FETCH PUBLIC JOB')
  public fetchPublicJob = async (jobId: number): Promise<boolean> => {
    try {
      this.job = await this.jobApi.fetchPublicJob(jobId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('FETCH JOB ASSIGNMENTS')
  public fetchJobAssignments = async (
    jobId: number,
    pageSize = 100
  ): Promise<boolean> => {
    try {
      this.jobAssignments = await this.jobApi.getJobAssignments(
        jobId,
        pageSize
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('ADD JOB ASSIGNMENT')
  public addAssignment = async (event: {
    assignment: any;
    job: any;
  }): Promise<boolean> => {
    try {
      await this.jobApi.addAssignment(event);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('REMOVE JOB ASSIGNMENT')
  public removeAssignment = async (event: {
    assignment: any;
    job: any;
  }): Promise<boolean> => {
    try {
      await this.jobApi.removeAssignment(event);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('GET JOB DETAIL')
  public getJobDetail = async (jobId: number): Promise<IJob | undefined> => {
    try {
      return await this.jobApi.getJobDetail(jobId);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE JOB')
  public createJob = async (job: IJob): Promise<IJob | undefined> => {
    try {
      return await this.jobApi.createJob(job);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH JOBS')
  public fetchJobs = async (): Promise<boolean> => {
    try {
      let sortBy = this.isAsc ? `${this.sortProp}` : `-${this.sortProp}`;
      if (this.sortProp === 'status') {
        sortBy = this.isAsc
          ? `-is_live,-is_unpublished,-is_completed`
          : `-is_completed,-is_unpublished,-is_live`;
      }
      this.isLoading = true;
      const jobsPage = await this.jobApi.fetchJobs(
        this.currentUnitId,
        this.query,
        this.tableJobsPaginator,
        sortBy,
        this.searchValue
      );
      this.jobs = jobsPage;
      this.tableJobsPaginator = {
        ...this.tableJobsPaginator,
        count: this.jobs.count,
        maxPage: Math.ceil(
          jobsPage.count / this.tableJobsPaginator.itemsPerPage
        ),
      };
      this.isLoading = false;
      return true;
    } catch (error) {
      this.isLoading = false;
      return false;
    }
  };

  @action('FETCH JOBS PLAIN')
  public fetchJobsPlain = async (
    page: number,
    pageSize: number,
    qlQuery: string,
    searchValue: string,
    unitId: number
  ): Promise<IJobs | undefined> => {
    try {
      return await this.jobApi.fetchJobs(
        unitId,
        qlQuery,
        {
          page,
          itemsPerPage: pageSize,
          count: 0,
          maxPage: 0,
        },
        '-created_at',
        searchValue
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('PUBLISH JOB')
  public publishJob = async (jobId: number): Promise<boolean> => {
    try {
      await this.jobApi.publishJob(jobId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('SUBMIT CHANGES TO JOB')
  public submitChanges = async (jobId: number): Promise<boolean> => {
    try {
      await this.jobApi.submitChanges(jobId);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('UNPUBLISH')
  public unpublish = async (jobId: number): Promise<boolean> => {
    try {
      const unpublishedJob = await this.jobApi.unpublish(jobId);
      if (this.jobs.results) {
        this.jobs = {
          ...this.jobs,
          results: this.jobs.results.map((job: IJob) => {
            return job.publisher_draft_id !== jobId
              ? { ...job }
              : { ...unpublishedJob, is_unpublished: true };
          }),
        };
      }
      this.isLoading = false;
      return true;
    } catch (error) {
      this.isLoading = false;
      return false;
    }
  };

  @action('DELETE')
  public delete = async (jobId: number): Promise<boolean> => {
    try {
      await this.jobApi.delete(jobId);
      if (this.jobs.results) {
        this.jobs = {
          ...this.jobs,
          results: this.jobs.results.filter((job: IJob) => job.id !== jobId),
        };
      }
      this.isLoading = false;
      return true;
    } catch (error) {
      this.isLoading = false;
      return false;
    }
  };

  @action('CLONE')
  public clone = async (jobId: number): Promise<IJob | false> => {
    try {
      const clonedJob = await this.jobApi.clone(jobId, this.currentUnitId);
      if (clonedJob) {
        this.jobs = {
          ...this.jobs,
          results: [clonedJob, ...this.jobs.results],
        };
        this.isLoading = false;
        return clonedJob;
      } else {
        this.isLoading = false;
        return false;
      }
    } catch (error) {
      this.isLoading = false;
      return false;
    }
  };

  @action('CLONE WITH UNITID')
  public cloneWithUnitId = async (
    jobId: number,
    unitId: number
  ): Promise<IJob | undefined> => {
    try {
      return await this.jobApi.clone(jobId, unitId);
    } catch (error) {
      return undefined;
    }
  };

  @action('SORT BY PROP')
  public sortByProp(prop: string) {
    this.sortProp !== prop
      ? (this.sortProp = prop)
      : (this.isAsc = !this.isAsc);
    this.fetchJobs();
  }

  @action('FETCH JOB AD TITLE SUGGESTIONS')
  public fetchJobAdTitleSuggestions = async (
    term: string,
    language = Languages.en,
    pageSize = 25
  ): Promise<Suggestion[] | false> => {
    try {
      const suggestions = await this.jobApi.fetchJobAdTitleSuggestions(
        term,
        pageSize
      );

      if (suggestions && suggestions.results) {
        return [
          ...suggestions.results.map((suggestion: any) => {
            const { id } = suggestion;
            const text =
              language === Languages.en
                ? suggestion.title_en
                : suggestion.title_nb;

            return { id, text } as Suggestion;
          }),
        ];
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  @action('FETCH JOB CATEGORY SUGGESTIONS')
  public fetchPositionFunctionSuggestions = async (
    term: string,
    language = Languages.en,
    pageSize = 100
  ): Promise<Suggestion[] | false> => {
    try {
      const positionFunctionPage: IPage<IPositionFunction> =
        await this.jobApi.fetchJobPositionFunctionSuggestions(
          term,
          pageSize,
          language
        );

      if (positionFunctionPage && positionFunctionPage.results) {
        const childPositionFunctions: Suggestion[] = [];
        positionFunctionPage.results.forEach(
          (parentPositionFunction: IPositionFunction) => {
            parentPositionFunction.children!.forEach(
              (childPositionFunction: IPositionFunction) => {
                const { id } = childPositionFunction;
                const nameLangKey: string = `name_${language}`;
                const parentName = parentPositionFunction[nameLangKey];
                const childName = childPositionFunction[nameLangKey];
                // Have to concat the parent id and the child ID in order to
                // have unique ids.
                childPositionFunctions.push({
                  id,
                  text: `${parentName} - ${childName}`,
                } as Suggestion);
              }
            );
          }
        );
        return childPositionFunctions;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  @action('FETCH APPLICATION VIDEO INTERVIEW ANSWERS')
  public fetchApplicationVideoInterviewAnswers = async (
    applicationId: number
  ): Promise<JobProcessAppQuestionAnswer[]> => {
    try {
      return await this.jobApi.fetchApplicationVideoInterviewAnswers(
        applicationId
      );
    } catch (error) {
      return [];
    }
  };

  @action('UPDATE JOB')
  public updateJob = async (job: IJob): Promise<IJob | undefined> => {
    try {
      return await this.jobApi.patchJob(job);
    } catch (error) {
      return undefined;
    }
  };

  @action('HAS JOB ACTION PERMISSION')
  public hasJobActionPermission = async (
    jobId: number
  ): Promise<IJobActionPermission | null> => {
    try {
      return await this.jobApi.checkHasJobActionPermission(jobId);
    } catch (error) {
      return null;
    }
  };

  @action('ON EXPORT ALL TO EXCEL')
  public async onExportAllToExcel(
    jobId: number
  ): Promise<IApplicationExport | undefined> {
    try {
      return await this.applicationApi.createApplicationExportXlsx(jobId, {
        export_all: true,
      });
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  }

  @action('FETCH JOB SUBSCRIPTIONS')
  public fetchJobSubscriptions = async (
    email: string
  ): Promise<IPage<IJobSubscription> | undefined> => {
    try {
      return await this.jobApi.fetchJobSubscriptions(email);
    } catch (error) {
      return;
    }
  };

  @action('UNSUBSCRIBE FROM JOB SUBSCRIPTION')
  public unSubscribeJobSubscription = async (
    subscriptionId: string
  ): Promise<boolean> => {
    try {
      await this.jobApi.unSubscribeJobSubscription(subscriptionId);
      return true;
    } catch (error) {
      return false;
    }
  };
}
