









import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import MjApplyJobInfo from '@mojob/mj-components/src/organisms/mj-apply-job-info/MjApplyJobInfo.vue';
import router, { applyRoutes } from '@/router';
import { autorun } from 'mobx';
import VueAnalytics from 'vue-analytics';
import FacebookPixel from '@mojob/mj-shared/mj-services/mj-facebook-pixel/pixel.service';
import VueGtm from 'vue-gtm';
import IJobStore from '@mojob/mj-shared/mj-store/job.store';
import IApplicationStore from '@mojob/mj-shared/mj-store/application.store';
import IUserStore from '@mojob/mj-shared/mj-store/user.store';
import INotificationStore from '@mojob/mj-shared/mj-store/notification.store';
import IMediaStore from '@mojob/mj-shared/mj-store/media.store';
import IUiStore from '@mojob/mj-shared/mj-store/ui.store';
import { NewVersionMixin } from '@mojob/mj-components/src/mixins';
import MjBrowserRedirect from '@mojob/mj-components/src/organisms/mj-browser-redirect/MjBrowserRedirect.vue';
import VueIntercom from 'vue-intercom';
import IVideoInterviewStore from '@mojob/mj-shared/mj-store/video-interview.store';
import IWsNotificationStore from '@mojob/mj-shared/mj-store/ws-notification.store';
import IWsChatStore from '@mojob/mj-shared/mj-store/ws-chat.store';
import Deps from '@mojob/mj-shared/mj-di/app.di';
import IWsApplicantStore from '@mojob/mj-shared/mj-store/ws-applicant.store';
import IAppointmentStore from '@mojob/mj-shared/mj-store/appointment.store';
import IMaiveStore from '@mojob/mj-shared/mj-store/maive.store';
import IDynamicProcessStore from '@mojob/mj-shared/mj-store/dynamic-process.store';
import IUnitStore from '@mojob/mj-shared/mj-store/unit.store';
import IPostNewJobStore from '@mojob/mj-shared/mj-store/postNewJob.store';
import ITalentPoolStore from '@mojob/mj-shared/mj-store/talentPool.store';
import IReferenceStore from '@mojob/mj-shared/mj-store/reference.store';
import INpsStore from '@mojob/mj-shared/mj-store/nps.store';
import IReleaseStore from '@mojob/mj-shared/mj-store/release.store';
import IAttributeStore from '@mojob/mj-shared/mj-store/attribute.store';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Route } from 'vue-router';
import { getQueryParamValue } from '@mojob/mj-shared/mj-utils/analytics.utils';
import IIntegrationStore from '@mojob/mj-shared/mj-store/integration.store';

Vue.use(VueIntercom, { appId: `${process.env.VUE_APP_INTERCOM_ID}` });

@Component({
  components: {
    MjApplyJobInfo,
    MjBrowserRedirect,
  },
})
export default class App extends NewVersionMixin {
  public get modalState() {
    return this.isModalOpen;
  }
  @ProvideReactive('jobStore') public jobStore!: IJobStore;
  @ProvideReactive('applicationStore')
  public applicationStore!: IApplicationStore;
  @ProvideReactive('userStore') public userStore!: IUserStore;
  @ProvideReactive('notificationStore')
  public notificationStore!: INotificationStore;
  @ProvideReactive('mediaStore') public mediaStore!: IMediaStore;
  @ProvideReactive('uiStore') public uiStore!: IUiStore;
  @ProvideReactive('videoInterviewStore')
  public videoInterviewStore!: IVideoInterviewStore;
  @ProvideReactive('wsNotificationStore')
  public wsNotificationStore!: IWsNotificationStore;
  @ProvideReactive('wsChatStore')
  public wsChatStore!: IWsChatStore;
  @ProvideReactive('wsApplicantStore')
  public wsApplicantStore!: IWsApplicantStore;
  @ProvideReactive('maiveStore')
  public maiveStore!: IMaiveStore;
  @ProvideReactive('unitStore')
  public unitStore!: IUnitStore;
  @ProvideReactive('dynamicProcessStore')
  public dynamicProcessStore!: IDynamicProcessStore;

  @ProvideReactive('appointmentsStore')
  public appointmentsStore: IAppointmentStore;
  @ProvideReactive('postNewJobStore')
  public postNewJobStore!: IPostNewJobStore;
  @ProvideReactive('talentPoolStore')
  public talentPoolStore!: ITalentPoolStore;
  @ProvideReactive('referenceStore')
  public referenceStore!: IReferenceStore;
  @ProvideReactive('npsStore')
  public npsStore!: INpsStore;
  @ProvideReactive('releaseStore')
  public releaseStore!: IReleaseStore;
  @ProvideReactive('integrationStore')
  public integrationStore!: IIntegrationStore;
  @ProvideReactive('attributeStore')
  public attributeStore!: IAttributeStore;

  public isModalOpen: boolean = false;
  // When this var is set to true, the app will apply
  // service-worker updates on route changes.
  public updateAppOnRouteChange: boolean = false;

  constructor() {
    super();
    const deps = new Deps(
      this.$http,
      router,
      undefined,
      undefined,
      undefined,
      undefined,
      firebase.auth()
    );
    this.jobStore = deps.jobStore;
    this.applicationStore = deps.applicationStore;
    this.userStore = deps.userStore;
    this.notificationStore = deps.notificationStore;
    this.mediaStore = deps.mediaStore;
    this.videoInterviewStore = deps.videoInterviewStore;
    this.uiStore = deps.uiStore;
    this.appointmentsStore = deps.appointmentsStore;
    this.wsNotificationStore = deps.wsNotificationStore;
    this.wsChatStore = deps.wsChatStore;
    this.wsApplicantStore = deps.wsApplicantStore;
    this.maiveStore = deps.maiveStore;
    this.dynamicProcessStore = deps.dynamicProcessStore;
    this.unitStore = deps.unitStore;
    this.postNewJobStore = deps.postNewJobStore;
    this.talentPoolStore = deps.talentPoolStore;
    this.referenceStore = deps.referenceStore;
    this.npsStore = deps.npsStore;
    this.releaseStore = deps.releaseStore;
    this.integrationStore = deps.integrationStore;
    this.attributeStore = deps.attributeStore;
    // Setup analytics
    autorun(async (reaction) => {
      const gtmInitiated = this.$gtm && this.$gtm.dataLayer();
      // Initialize our analytics
      if (!gtmInitiated) {
        Vue.use(VueGtm, {
          id: `${process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID}`,
          // defaults to true. Plugin can be disabled by setting
          // this to false for Ex: enabled: !!GDPR_Cookie (optional)
          enabled: true,
          // Whether or not display console logs debugs (optional)
          debug: false,
          // Whether or not to load the GTM Script (Helpful if you are including GTM manually,
          // but need the dataLayer functionality in your components) (optional)
          loadScript: true,
          // Pass the router instance to automatically sync with router (optional)
          vueRouter: router,
          // If router, you can exclude some routes name (case insensitive) (optional)
          ignoredViews: [],
        });
      }

      const updatedJob = this.jobStore.job;
      if (updatedJob && updatedJob.id && updatedJob.unit_id) {
        if (gtmInitiated) {
          const dataLayer = this.$gtm.dataLayer();
          // Set utm_source query parameter if document.referrer is empty
          let referrer = document.referrer;
          const utmSource = getQueryParamValue('utm_source');
          if (!referrer && utmSource) {
            referrer = utmSource;
          }
          dataLayer.push({
            jobId: updatedJob.id!.toString(),
            unitId: updatedJob.unit_id.toString(),
            document_referrer: referrer,
            app_version: 'old',
            app_platform: 'WEB',
            app_name: 'mojob-web-dsm/application-process',
          });
          // Function to add config/events to datalayer
          function createEvent(command: string, ...args: any[]) {
            // eslint-disable-next-line prefer-rest-params
            dataLayer.push(arguments);
          }
          createEvent('event', 'log_application_form_viewed');
        }
        // Initialize Unit analytics
        const unitWAnalyticsAttributes = await this.unitStore.fetchUnitAnalyticsAttributes(
          updatedJob.unit_id
        );
        reaction.dispose();
        if (unitWAnalyticsAttributes && unitWAnalyticsAttributes.id) {
          this.unitStore.unitWithAnalyticsAttributes = unitWAnalyticsAttributes!;
          const enableAnalytics = process.env.VUE_APP_ENVIRONMENT === 'production';
          if (unitWAnalyticsAttributes.ga_id) {
            Vue.use(VueAnalytics, {
              id: unitWAnalyticsAttributes.ga_id,
              router,
              debug: {
                sendHitTask: enableAnalytics,
              },
            });
          }
          Vue.use(FacebookPixel, {
            router,
            ignoreRoutes: [],
            id: unitWAnalyticsAttributes.facebook_pixel_id
              ? unitWAnalyticsAttributes.facebook_pixel_id
              : undefined,
            disabled: !enableAnalytics,
            debug: !enableAnalytics,
          });
          if (unitWAnalyticsAttributes.linkedin_pixel_id) {
            this.injectLinkedinPixel(unitWAnalyticsAttributes.linkedin_pixel_id);
          }
          if (unitWAnalyticsAttributes.xandr_pixel_id) {
            this.injectXandrPixel(unitWAnalyticsAttributes.xandr_pixel_id);
          }
          if (unitWAnalyticsAttributes.ontame_pixel_id) {
            this.injectOntameScript(unitWAnalyticsAttributes.ontame_pixel_id);
          }
        }
      }
    });
  }

  public checkBrowser() {
    if (this.isInternetExplorerOlderThanEdge()) {
      this.isModalOpen = true;
      this.$forceUpdate();
    }
  }

  public closeModal(val: boolean) {
    this.isModalOpen = false;
    this.$forceUpdate();
  }

  public isInternetExplorerOlderThanEdge() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // old ie
      return true;
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // ie11
      return true;
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // edge
      return false;
    }
    return false;
  }

  public mounted() {
    this.checkBrowser();
    this.userStore.i18n = this.$i18n;
    this.dynamicProcessStore.i18n = this.$i18n;
    this.notificationStore.i18n = this.$i18n;
    this.applicationStore.appliedForJobListener = this.onApplicationDelivered;
  }

  public created() {
    const userStore = this.userStore;
    this.$http.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        if (401 === err.response.status) {
          // if you ever get an unauthorized, logout the user
          userStore.logout();
          const isApplicationProcess = applyRoutes.filter(
            (route: any) => route.name === router.currentRoute.name
          );
          if (isApplicationProcess.length === 0) {
            if (
              !router.currentRoute.query.redirect_uri &&
              !router.currentRoute.fullPath.includes('auth') &&
              !router.currentRoute.fullPath.includes('reset-password')
            ) {
              router
                .push({
                  name: 'dashboard-login',
                  query: {
                    ...router.currentRoute.query,
                    redirect_uri: router.currentRoute.fullPath,
                  },
                })
                .catch((error: any) => {});
            } else {
              router
                .push({
                  name: 'dashboard-login',
                })
                .catch((error: any) => {});
            }
          } else {
            router.push({ name: 'login' }).catch((error: any) => {});
          }
        }
        return Promise.reject(err);
      }
    );
  }

  private setupSnackbar() {
    this.notificationStore.showSnackbarOnTop = true;
    this.notificationStore.snackbarTimeoutMs = 10000;
    this.notificationStore.snackbarBackgroundColor = '#46BBB3';
  }

  private resetSnackbar() {
    this.notificationStore.showSnackbarOnTop = false;
    this.notificationStore.snackbarTimeoutMs = 5000;
    this.notificationStore.snackbarBackgroundColor = '#4f4f4f';
  }

  private showUpdateSnackbar() {
    this.setupSnackbar();
    this.updateAppOnRouteChange = true;
    this.notificationStore.showMessageWithActions(
      this.$t('update_snackbar_text') as string,
      [
        {
          name: '',
          action: () => {
            this.updateAppOnRouteChange = false;
            this.refreshApp();
            this.resetSnackbar();
          },
          iconKey: 'done',
        },
        {
          name: '',
          action: () => {
            this.resetSnackbar();
          },
          iconKey: 'clear',
        },
      ],
      'mojob'
    );
    // Reset snackbar styles to default after the snackbar is timedout
    setTimeout(() => {
      this.resetSnackbar();
    }, this.notificationStore.snackbarTimeoutMs);
  }

  @Watch('updateExists', { immediate: true, deep: true })
  private onUpdateExists(newVal: boolean) {
    if (newVal) {
      this.showUpdateSnackbar();
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  private onRouteChange(toRoute: Route, fromRoute: Route) {
    if (this.updateAppOnRouteChange && toRoute.name !== 'dashboard-login-email') {
      this.refreshApp();
      return;
    }
  }

  private injectLinkedinPixel(linkedinPartnerId: string) {
    try {
      window._linkedin_inject_insight = (linkedin_partner_id: string) => {
        window._linkedin_partner_id = linkedin_partner_id;
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
        (() => {
          const headTag = document.getElementsByTagName('head')[0];
          const scriptTag = document.createElement('script');
          scriptTag.type = 'text/javascript';
          scriptTag.async = true;
          scriptTag.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
          if (headTag && scriptTag) {
            headTag!.insertBefore(scriptTag!, headTag!.firstChild!);
          }
        })();
      };
      window._linkedin_inject_insight(linkedinPartnerId);
    } catch (error) {
      console.log(error);
    }
  }

  private injectXandrPixel(xandrPixelId: string) {
    try {
      window._xandr_inject = (xandr_pixel_id: string) => {
        window.actionQueue = window.actionQueue || [];
        (() => {
          const headTag = document.getElementsByTagName('head')[0];
          const scriptTag = document.createElement('script');
          scriptTag.async = true;
          scriptTag.type = 'text/javascript';
          scriptTag.src = 'https://acdn.adnxs.com/dmp/up/pixie.js';
          scriptTag.onload = () => {
            window.pixie('init', xandr_pixel_id);
            window.pixie('event', 'PageView');
          };
          if (headTag && scriptTag) {
            headTag!.insertBefore(scriptTag!, headTag!.firstChild!);
            window.n = window.pixie = (e: any, a: any, p?: any) => {
              window.actionQueue.push({
                action: e,
                actionValue: a,
                params: p,
              });
            };
            try {
              const pageViewImg = new Image();
              pageViewImg.height = 1;
              pageViewImg.width = 1;
              pageViewImg.src = `https://ib.adnxs.com/pixie?pi=${xandr_pixel_id}&e=PageView&script=0`;
            } catch (error) {
              console.log(error);
            }
          }
        })();
      };
      window._xandr_inject(xandrPixelId);
    } catch (error) {
      console.log(error);
    }
  }

  private injectOntameScript(tenantId: string) {
    try {
      window._snowplow_inject = () => {
        // Global Snowplow Namespace setup
        window.GlobalSnowplowNamespace = window.GlobalSnowplowNamespace || [];
        window.GlobalSnowplowNamespace.push('ontame');
        // @ts-ignore
        // tslint:disable-next-line
        window.ontame = function () {
          (window.ontame.q = window.ontame.q || []).push(arguments);
        };

        window.ontame.q = window.ontame.q || [];

        const headTag = document.getElementsByTagName('head')[0];
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.type = 'text/javascript';
        scriptTag.src = 'https://cdn.ontame.io/3.4.0/skYkappS.js';
        scriptTag.onload = () => {
          window.ontame('newTracker', 'clj', 'collector.ontame.io', {
            appId: tenantId,
            cookieName: 'ontame_',
            cookieSameSite: 'Lax',
            discoverRootDomain: true,
            cookieLifetime: 86400 * 365,
          });
          window.ontame('trackPageView');
        };

        if (headTag && scriptTag) {
          headTag.insertBefore(scriptTag, headTag.firstChild);
        }
      };
      window._snowplow_inject();
    } catch (error) {
      console.log(error);
    }
  }

  private onApplicationDelivered() {
    try {
      if (this.applicationStore.application) {
        if (this.$gtm.enabled()) {
          const dataLayer = this.$gtm.dataLayer();
          // dataLayer.push({
          //   mj_application_id: this.applicationStore.application.id,
          // });
          // dataLayer.push({
          //   event: 'GAEvent',
          //   eventCategory: 'applied_for_job',
          //   eventAction: router.currentRoute.fullPath,
          // });
          const job = this.jobStore.job;
          // Set utm_source query parameter if document.referrer is empty
          let referrer = document.referrer;
          const utmSource = getQueryParamValue('utm_source');
          if (!referrer && utmSource) {
            referrer = utmSource;
          }
          dataLayer.push({
            applicationId: this.applicationStore.application.id!.toString(),
            jobId: job.id!.toString(),
            unitId: job.unit_id!.toString(),
            document_referrer: referrer,
            app_version: 'old',
            app_platform: 'WEB',
            app_name: 'mojob-web-dsm/application-process',
          });
          // Function to add config/events to datalayer
          function createEvent(command: string, ...args: any[]) {
            // eslint-disable-next-line prefer-rest-params
            dataLayer.push(arguments);
          }
          createEvent('event', 'log_application_delivered');
        }
        if (typeof window !== 'undefined' && window.ontame) {
          const job = this.jobStore.job;
          window.ontame('trackStructEvent', {
            category: 'job',
            action: 'jobApplication',
            label: job.id!.toString(),
            property: this.applicationStore.application.id!.toString(),
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}
