import { action, observable } from 'mobx';
import FacebookApi from '../mj-api/facebook.api';
import {
  IFacebookAccount,
  IFacebookAd,
  IFacebookAdAccountData,
  IFacebookCity,
  IFacebookFormData,
  IFacebookPage,
} from '../mj-models/facebook-ads.interface';

export default interface IFacebookStore {
  facebookFormData: IFacebookFormData | null;

  getFacebookAd: (fbAdId: number) => Promise<IFacebookAd | undefined>;
  getFacebookSuggestedCities: (
    fbAccessToken: string,
    query: string
  ) => Promise<IFacebookCity[] | undefined>;

  getFacebookAccountData: (
    fbAccessToken: string
  ) => Promise<IFacebookAccount | undefined>;
  getFacebookAdAccountData: (
    fbAccessToken: string,
    adAccountId: string
  ) => Promise<IFacebookAdAccountData | undefined>;
  getFacebookPages: (
    fbAccessToken: string,
    fbUserId: string
  ) => Promise<IFacebookPage[] | undefined>;
  getFacebookPagesForAd: (
    fbAdId: number
  ) => Promise<IFacebookPage[] | undefined>;

  createFacebookAd: (fbAd: IFacebookAd) => Promise<IFacebookAd | undefined>;
  updateFacebookAd: (fbAd: IFacebookAd) => Promise<IFacebookAd | undefined>;
}

export class FacebookStore implements IFacebookStore {
  public facebookApi: FacebookApi;

  @observable
  public facebookFormData: IFacebookFormData | null = null;

  constructor(facebookApi: FacebookApi) {
    this.facebookApi = facebookApi;
  }

  @action('GET FACEBOOK AD')
  public getFacebookAd = async (
    fbAdId: number
  ): Promise<IFacebookAd | undefined> => {
    try {
      return await this.facebookApi.getFacebookAd(fbAdId);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET FACEBOOK ACCOUNT DATA')
  public getFacebookAccountData = async (
    fbAccessToken: string
  ): Promise<IFacebookAccount | undefined> => {
    try {
      return await this.facebookApi.getFacebookAccountData(fbAccessToken);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET FACEBOOK AD ACCOUNT DATA')
  public getFacebookAdAccountData = async (
    fbAccessToken: string,
    adAccountId: string
  ): Promise<IFacebookAdAccountData | undefined> => {
    try {
      return await this.facebookApi.getFacebookAdAccountData(
        fbAccessToken,
        adAccountId
      );
    } catch (error) {
      return undefined;
    }
  };

  @action('GET FACEBOOK PAGES')
  public getFacebookPages = async (
    fbAccessToken: string,
    fbUserId: string
  ): Promise<IFacebookPage[] | undefined> => {
    try {
      return await this.facebookApi.getFacebookPages(fbAccessToken, fbUserId);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET FACEBOOK PAGES FOR AD')
  public getFacebookPagesForAd = async (
    fbAdId: number
  ): Promise<IFacebookPage[] | undefined> => {
    try {
      return await this.facebookApi.getFacebookPagesForAd(fbAdId);
    } catch (error) {
      return undefined;
    }
  };

  @action('CREATE FACEBOOK AD')
  public createFacebookAd = async (
    fbAd: IFacebookAd
  ): Promise<IFacebookAd | undefined> => {
    try {
      return await this.facebookApi.createFacebookAd(fbAd);
    } catch (error) {
      return undefined;
    }
  };

  @action('UPDATE FACEBOOK AD')
  public updateFacebookAd = async (
    fbAd: IFacebookAd
  ): Promise<IFacebookAd | undefined> => {
    try {
      return await this.facebookApi.updateFacebookAd(fbAd);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET FACEBOOK SUGGESTED CITITES')
  public getFacebookSuggestedCities = async (
    fbAccessToken: string,
    query: string
  ): Promise<IFacebookCity[] | undefined> => {
    try {
      return await this.facebookApi.getFacebookSuggestedCities(
        fbAccessToken,
        query
      );
    } catch (error) {
      return undefined;
    }
  };
}
