import BaseApi from './base.api';
import { AxiosTransformer } from 'axios';
import { IRelease, IReleasePlatform } from '../mj-models/release.interface';

const removeAuthKeyTransformer: AxiosTransformer = (data, headers) => {
  const authKey = 'Authorization';
  delete headers.common[authKey];

  return data;
};

export default class ReleaseApi extends BaseApi {
  public getLatestRelease = (
    platformSlug: string
  ): Promise<IRelease> => {
    return this.axios
      .get(`${this.baseUrl}releases/${platformSlug}/latest/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);
  };
}
