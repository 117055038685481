import { IApplication } from './application.interface';
import { IAppointment } from './appointment.interface';
import { IAttribute } from './attribute.interface';
import { IAutomationRule } from './automation-rules.interface';
import {
  IUnitAttribute,
  IUnitReasonForDeclineTemplate,
} from './business-settings.interface';
import { IScriveIntegration } from './integration.interface';
import { IMediaFile } from './media.interface';
import { IReferenceQuestion } from './references.interface';
import { IUser } from './user.interface';
import { WithLocalId } from './utils.interface';

export interface JobProcessApplicationMove {
  id?: number;
  application_id?: number;
  application?: IApplication;
  process_step_id?: number;
  user_author_id?: number;
  app_author_id?: number;
  created_at?: string;
  updated_at?: string;
  app_overrides?: IProcessApp[];
  delay_in_ms?: number;
  completed?: boolean;
  completed_at?: string;
  hide_step_name?: boolean;
}

export interface JobProcessAppMessage {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  text: string;
  attachment_id?: number;
  attachment_name?: string;
  attachment_url?: string;
}

export interface JobProcessAppReasonForDecline {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  internal_title?: string;
  message_text: string;
}

export interface JobProcessAppMotimate {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  external_id?: string;
  motimate_group_id?: number;
  motimate_position_id?: number;
  name: string;
  position_name?: string;
  motimate_organisation_id?: number;
}

export interface JobProcessAppHistoryMessage {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  user_author_id?: number;
  reference_process_app_id?: number;
  action?: JobProcessAppHistoryMessageChoices;
  new_appointment_slot_start_at?: string;
  reference_contact_author_id?: string;
  reference_contact_identification?: string;
  scrive_party_id?: string;
  scrive_party_name?: string;
  scrive_party_email?: string;
  scrive_party_role?: string;
  scrive_party_is_candidate?: boolean;
  scrive_document_title?: string;
}

export enum JobProcessAppMoveTypes {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export interface JobProcessAppMove {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  process_step_id?: number;
  process_step?: IProcessStep;
  type?: JobProcessAppMoveTypes;
  hide_step_name?: boolean;
}

export interface JobProcessAppNoteMessage {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  text: string;
  attachment_id?: number | null;
  attachment_name?: string;
  attachment_url?: string;
  mentioned_users?: IUser[];
}

export interface JobProcessAppOnSiteInterview {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  appointment?: IAppointment | null;
}

export enum QuestionType {
  MULTI_CHOICE = 0,
  FREE_TEXT = 1,
  SINGLE_CHOICE = 2,
  YES_NO = 3,
  ONE_WAY_VIDEO = 4,
  FILE_UPLOAD = 5,
  FREE_TEXT_SINGLE_LINE = 6,
  SCALE = 7,
}

export enum JobProcessAppHistoryMessageChoices {
  PROCESS_APP_ON_SITE_INTERVIEW_CHANGED = 'JOB_PROCESS_APP_ON_SITE_INTERVIEW_CHANGED',
  PROCESS_APP_ON_SITE_INTERVIEW_ACCEPTED = 'JOB_PROCESS_APP_ON_SITE_INTERVIEW_ACCEPTED',
  PROCESS_APP_ON_SITE_INTERVIEW_CANCELLED = 'JOB_PROCESS_APP_ON_SITE_INTERVIEW_CANCELLED',
  PROCESS_APP_REFERENCE_SUBMITTED_SURVEY = 'JOB_PROCESS_APP_REFERENCE_SUBMITTED_SURVEY',
  PROCESS_APP_REFERENCE_REQUEST_PHONE_CALL = 'JOB_PROCESS_APP_REFERENCE_REQUEST_PHONE_CALL',
  PROCESS_APP_REFERENCE_DECLINE_SURVEY = 'JOB_PROCESS_APP_REFERENCE_DECLINE_SURVEY',
  PROCESS_APP_REFERENCE_REPORT_READY = 'JOB_PROCESS_APP_REFERENCE_REPORT_READY',
  PROCESS_APP_VIDEO_INTERVIEW_DELIVERED = 'JOB_PROCESS_APP_VIDEO_INTERVIEW_DELIVERED',
  PROCESS_APP_SCRIVE_RECIPIENT_SIGNED = 'JOB_PROCESS_APP_SCRIVE_RECIPIENT_SIGNED',
  PROCESS_APP_SCRIVE_RECIPIENT_REJECTED = 'JOB_PROCESS_APP_SCRIVE_RECIPIENT_REJECTED',
  PROCESS_APP_SCRIVE_DOCUMENT_SEALED = 'JOB_PROCESS_APP_SCRIVE_DOCUMENT_SEALED',
  PROCESS_APP_SCRIVE_DOCUMENT_CANCELED = 'JOB_PROCESS_APP_SCRIVE_DOCUMENT_CANCELED',
  PROCESS_APP_SCRIVE_DOCUMENT_TIMEDOUT = 'JOB_PROCESS_APP_SCRIVE_DOCUMENT_TIMEDOUT',
  BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_ACCEPTED = 'BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_ACCEPTED',
  BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_DECLINED = 'BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_DECLINED',
  BASE_PROCESS_APP_STAFFING_ASSIGNMENT_SPOT_SCHEDULE_CANCELLATION = 'BASE_PROCESS_APP_STAFFING_ASSIGNMENT_SPOT_SCHEDULE_CANCELLATION',
  BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_EXPIRED = 'BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_EXPIRED',
  BASE_PROCESS_APP_STAFFING_ASSIGNMENT_SPOT_CLOSED = 'BASE_PROCESS_APP_STAFFING_ASSIGNMENT_SPOT_CLOSED',
  BASE_PROCESS_APP_STAFFING_ASSIGNMENT_CHANGED = 'BASE_PROCESS_APP_STAFFING_ASSIGNMENT_CHANGED',
  BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_SENT = 'BASE_PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION_SENT',
}

export interface AnswerSettingsData {
  type: 'scale' | 'alternatives' | 'file-multiple';
  data: any;
}

export interface Alternative extends BaseAlternative {
  id?: number;
  is_correct?: boolean;
  answer_text_translation_key?: string;
  is_new?: boolean;
  created_at?: string;
  updated_at?: string;
  recman_attribute_checkbox_id?: string;
}

export type LocalAlternative = Alternative & WithLocalId;

export interface AlternativeSuggestion {
  answer_text_translation_key?: string;
  is_correct?: boolean;
  answer_text: string;
}

export enum NeedChoices {
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL',
  NOT_NEEDED = 'NOT_NEEDED',
}

export interface BaseQuestion {
  id?: number;
  created_at?: string;
  updated_at?: string;
  type: QuestionType;
  alternatives: Alternative[];
  attribute?: IAttribute;
  question_text: string;
  is_required?: boolean;
  max_video_duration?: number;
  scale_from?: number;
  scale_to?: number;
  allow_multiple_files_upload?: boolean;
  recman_attribute_id?: string;
  collection_type_validity_date_range?: NeedChoices;
  collection_type_obtained_date_range?: NeedChoices;
  collection_type_files?: NeedChoices;
}

export interface BaseAnswer {
  created_at?: string;
  updated_at?: string;
  type: QuestionType;
  question_text: string;
  answer_text: string;
  opt_out?: boolean;
  opt_out_reason?: string;
  attributes?: IAttribute[];
}

export interface BaseAlternative extends Orderable {
  created_at?: string;
  updated_at?: string;
  answer_text: string;
  is_correct?: boolean;
}

export interface Orderable {
  position?: number;
}

// view-only properties
export interface CollapsibleTile {
  uuid?: string;
  expanded?: boolean;
  error?: string;
  is_new?: boolean;
}

export interface JobProcessAppScreeningQuestion
  extends BaseQuestion,
    CollapsibleTile {
  [key: string]: unknown;

  deleted?: boolean;
  process_app_id?: number;

  unit_attribute_id?: number | null;
  unit_attribute?: IUnitAttribute;

  reason_for_decline_id?: number | null;
  reason_for_decline_feedback_message?: string | null;
  reason_for_decline?: IUnitReasonForDeclineTemplate;
}

export interface JobProcessAppQuestionAnswer {
  question_id?: number;
  question_text?: string;
  answer_text?: string;
  type?: QuestionType;
  id?: number;
  application_id?: number;
  alternatives?: Alternative[];
  alternative_ids?: number[];
  attribute_ids?: number[];
  question?: JobProcessAppScreeningQuestion;
  tempFile?: any;
  file_ids?: number[];
  files?: IMediaFile[];
}

export interface ScreeningQuestionSuggestion {
  [key: string]: unknown;
  created_at?: string;
  updated_at?: string;
  uuid: string;

  type?: QuestionType;
  question_text_translation_key?: string;
  question_text?: string;
  is_required?: boolean;
  alternatives?: AlternativeSuggestion[];
  max_video_duration?: number;
  recman_attribute_id?: string;
  scale_from?: number;
  scale_to?: number;
  allow_multiple_files_upload?: boolean;
}

export enum JobProcessAppCvChoices {
  PROCESS_APP_CV_CHOICE_OPTIONAL = 'JOB_PROCESS_APP_CV_CHOICE_OPTIONAL',
  PROCESS_APP_CV_CHOICE_REQUIRED = 'JOB_PROCESS_APP_CV_CHOICE_REQUIRED',
  PROCESS_APP_CV_CHOICE_NOT_NEEDED = 'JOB_PROCESS_APP_CV_CHOICE_NOT_NEEDED',
}

export interface JobProcessAppCv {
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;

  // personal information
  email: JobProcessAppCvChoices;
  name: JobProcessAppCvChoices;
  birth_date: JobProcessAppCvChoices;
  gender: JobProcessAppCvChoices;
  location: JobProcessAppCvChoices;
  photo: JobProcessAppCvChoices;
  phone_number: JobProcessAppCvChoices;

  // professional information
  education: JobProcessAppCvChoices;
  experience: JobProcessAppCvChoices;
  cv_file_attached: JobProcessAppCvChoices;

  // additional information
  about_me: JobProcessAppCvChoices;
  social_media_contact: JobProcessAppCvChoices;
}

export interface JobProcessAppLearnifier {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  learnifier_integration_id?: number;
  projects?: number[];
}

export interface JobProcessAppPlanday {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  planday_integration_id?: number;
  employee_groups?: number[];
  department_id?: number;
  department_name?: string;
}
export interface JobProcessAppSDWorx {
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  sdworx_integration_id?: number;
  sdworx_integration_process_id?: number;
  sdworx_integration_process_name?: string;
}

export enum ScriveAppStatus {
  DOCUMENT_PENDING = 'DOCUMENT_PENDING',
  DOCUMENT_TIMEDOUT = 'DOCUMENT_TIMEDOUT',
  DOCUMENT_REJECTED = 'DOCUMENT_REJECTED',
  DOCUMENT_CANCELED = 'DOCUMENT_CANCELED',
  DOCUMENT_SEALED = 'DOCUMENT_SEALED',
  DOCUMENT_HAS_ERROR = 'DOCUMENT_HAS_ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum JobProcessAppScriveRecipientRoleChoices {
  signing_party = 'signing_party',
  approver = 'approver',
  viewer = 'viewer',
}
export enum JobProcessAppScriveRecipientSignOrderChoices {
  after_candidate = 'after_candidate',
  before_candidate = 'before_candidate',
}

export enum JobProcessAppScriveRecipientAuthenticationType {
  standard = 'standard',
  sms_pin = 'sms_pin',
  dk_mitid = 'dk_mitid',
  dk_mitid_nemid = 'dk_mitid/nemid',
  dk_nemid_cpr = 'dk_nemid_cpr',
  dk_nemid_cvr = 'dk_nemid_cvr',
  dk_nemid_pid = 'dk_nemid_pid',
  fi_tupas = 'fi_tupas',
  nl_idin = 'nl_idin',
  no_bankid = 'no_bankid',
  se_bankid = 'se_bankid',
  verimi = 'verimi',
  onfido_document_check = 'onfido_document_check',
  onfido_document_and_photo_check = 'onfido_document_and_photo_check',
  freja = 'freja',
}

export interface JobProcessAppScriveRecipient {
  id?: string;
  created_at?: string;
  updated_at?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  personal_number?: string;
  role?: string;
  sign_order?: string;
  is_candidate?: boolean;
  authentication_to_view?: string;
  authentication_to_sign?: string;
}

export interface JobProcessAppScrive {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  title?: string;
  signing_invitation_email?: string;
  signing_confirmation_email?: string;
  days_to_sign?: number;
  days_to_remind?: number;
  scrive_file_id?: string;
  scrive_file_name?: string;
  file_id?: number;
  file?: IMediaFile;
  attachment_ids?: number[];
  attachments?: IMediaFile[];
  recipients?: JobProcessAppScriveRecipient[];
  use_mojob_integration?: boolean;
  scrive_integration?: IScriveIntegration;
}

export interface ProcessAppStaffingAssignmentInvitation {
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  attachment_id?: number;
  attachment_name?: string;
  attachment_url?: string;
  message_text?: string;
}

export interface JobProcessAppRecman {
  [key: string]: unknown;
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;

  recman_api_key?: string;
  recman_corporation_id: string;
  recman_department_id?: string;
  recman_company_id?: string;
  recman_project_id?: string;
  candidate_recman_pipeline_id?: number;
  candidate_recman_pipeline?: {
    category_id?: string;
    project_id?: string;
    user_id?: string;
  };

  // personal information
  email?: boolean;
  name?: boolean;
  birth_date?: boolean;
  location?: boolean;
  photo?: boolean;
  phone_number?: boolean;

  // professional information
  education?: boolean;
  experience?: boolean;
  cv_file_attached?: boolean;

  // additional information
  recman_attributes?: boolean;
  about_me?: boolean;
  social_media_contact?: boolean;
  notes?: boolean;
  video_interviews?: boolean;
  motimate_courses?: boolean;

  reference_survey?: boolean;
}

export interface JobProcessAppReference {
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  num_contacts?: number;
  num_days_to_provide_contacts?: number;
  contact_email_text?: string;
  reference_questions?: IReferenceQuestion[];
}

export interface JobProcessAppNps {
  id?: number;
  process_app_id?: number;
  created_at?: string;
  updated_at?: string;
  survey_delay_in_days?: number;
  message_text?: string;
  question_text?: string;
  toggled?: boolean;
}

export interface BaseProcessAppStaffingAssignmentInvitationResponse {
  id?: number;
  created_at?: string;
  updated_at?: string;

  process_app_staffing_assignment_invitation_id?: number;
  app_message_id?: number;
  staffing_assignment_spot_id?: number;
  staffing_assignment_schedule_id?: number;
  has_accepted_invitation?: boolean;
  has_declined_invitation?: boolean;
}

// TODO: Remove this one
export enum STEPS {
  application = 0,
  process = 1,
  waiting_list = 4,
  rejected = 3,
  hired = 2,
}

export enum APP_PREVIEW_TYPES {
  screeningQuestionsAsked = 1,
  screeningQuestionsDelivered = 2,
  oneWayVideoDelivered = 3,
  interviewInvitation = 4,
  exportedToMotimate = 5,
  exportedToRecMan = 6,
  exportedToLearnifier = 7,
  exportedToPlanday = 8,
  viewReferenceContacts = 9,
  viewReferenceSurveyReport = 10,
  downloadSealedScriveDocument = 11,
  restartTimedoutScriveDocument = 12,
  editAndSendNewScriveDocument = 13,
}

export enum APP_PREVIEW_STATES {
  table = 1,
  board = 2,
  profile = 3,
}

export enum APP_ICONS {
  PROCESS_APP_CHOICE_CV = 'assignment_ind',
  PROCESS_APP_SCREENING_QUESTIONS = 'ballot',
  PROCESS_APP_VIDEO_INTERVIEW = 'movie',
  PROCESS_APP_NOTE_MESSAGE = 'description',
  PROCESS_APP_MESSAGE = 'message',
  PROCESS_APP_MOTIMATE = 'school',
  PROCESS_APP_ON_SITE_INTERVIEW = 'calendar_today',
  PROCESS_APP_DELAY_WINDOW = 'access_time',
  PROCESS_APP_RECMAN_INTEGRATION = 'import_export',
  PROCESS_APP_REFERENCE = 'how_to_reg',
  PROCESS_APP_NPS = 'star_half',
  PROCESS_APP_REASON_FOR_DECLINE = 'not_interested',
  PROCESS_APP_SCRIVE = 'assignment_turned_in',
}

export enum APP_COLORS {
  PROCESS_APP_CHOICE_CV = 'gray',
  PROCESS_APP_SCREENING_QUESTIONS = 'red',
  PROCESS_APP_VIDEO_INTERVIEW = 'red',
  PROCESS_APP_NOTE_MESSAGE = 'yellow',
  PROCESS_APP_MESSAGE = 'green',
  PROCESS_APP_MOTIMATE = 'green',
  PROCESS_APP_ON_SITE_INTERVIEW = 'red',
  PROCESS_APP_DELAY_WINDOW = 'gray-light',
  PROCESS_APP_REFERENCE = 'red',
  PROCESS_APP_NPS = 'red',
  PROCESS_APP_REASON_FOR_DECLINE = 'yellow',
  PROCESS_APP_AUTOMATION_RULE = 'green',
  PROCESS_APP_SCRIVE = 'red',
}

export interface IBaseProcess {
  id?: number;
  job_id?: number;
  steps?: IProcessStep[];
  created_at?: string;
  updated_at?: string;
  use_external_process?: boolean;
  external_process_url?: string;
  publisher_modified_at?: string;
  publisher_published_at?: string;
}

export interface IJobProcess {
  id?: number;
  job_id?: number;
  base_process?: IBaseProcess;
  created_at?: string;
  updated_at?: string;
}

export interface IProcessStep {
  id?: number;
  position?: number;
  name?: string;
  color?: string;
  is_failure?: boolean;
  is_waiting_list?: boolean;
  is_success?: boolean;
  apps?: IProcessApp[];
  publisher_linked_id?: number;
  publisher_draft_id?: number;
  hide_step_name?: boolean;
}

export enum JobProcessAppChoices {
  PROCESS_APP_CHOICE_CV = 'JOB_PROCESS_APP_CHOICE_CV',
  PROCESS_APP_SCREENING_QUESTIONS = 'JOB_PROCESS_APP_SCREENING_QUESTIONS',
  PROCESS_APP_VIDEO_INTERVIEW = 'JOB_PROCESS_APP_VIDEO_INTERVIEW',
  PROCESS_APP_NOTE_MESSAGE = 'JOB_PROCESS_APP_NOTE_MESSAGE',
  PROCESS_APP_MESSAGE = 'JOB_PROCESS_APP_MESSAGE',
  PROCESS_APP_MOTIMATE = 'JOB_PROCESS_APP_MOTIMATE',
  PROCESS_APP_ON_SITE_INTERVIEW = 'JOB_PROCESS_APP_ON_SITE_INTERVIEW',
  PROCESS_APP_DELAY_WINDOW = 'JOB_PROCESS_APP_DELAY_WINDOW',
  PROCESS_APP_MOVE = 'JOB_PROCESS_APP_MOVE',
  PROCESS_APP_RECMAN_INTEGRATION = 'JOB_PROCESS_APP_RECMAN_INTEGRATION',
  PROCESS_APP_LEARNIFIER = 'JOB_PROCESS_APP_LEARNIFIER',
  PROCESS_APP_PLANDAY = 'JOB_PROCESS_APP_PLANDAY',
  PROCESS_APP_HISTORY_MESSAGE = 'JOB_PROCESS_APP_HISTORY_MESSAGE',
  PROCESS_APP_REFERENCE = 'JOB_PROCESS_APP_REFERENCE',
  PROCESS_APP_NPS = 'JOB_PROCESS_APP_NPS',
  PROCESS_APP_REASON_FOR_DECLINE = 'JOB_PROCESS_APP_REASON_FOR_DECLINE',
  PROCESS_APP_AUTOMATION_RULE = 'JOB_PROCESS_APP_AUTOMATION_RULE',
  PROCESS_APP_SCRIVE = 'JOB_PROCESS_APP_SCRIVE',
  PROCESS_APP_INTERVIEW_KIT = 'PROCESS_APP_INTERVIEW_KIT', // The only new one
  PROCESS_APP_SDWORX = 'JOB_PROCESS_APP_SDWORX',
  PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION = 'PROCESS_APP_STAFFING_ASSIGNMENT_INVITATION',
}

export interface IProcessApp {
  id?: number;
  process_step_id?: number;
  process_step?: IProcessStep;
  application_step_when_sent?: IProcessStep;
  application_step_when_sent_id?: number;
  position?: number;
  app_type?: JobProcessAppChoices;
  question_apps?: JobProcessAppScreeningQuestion[];
  automation_rule_apps?: IAutomationRule[];
  message_app?: JobProcessAppMessage;
  note_message_app?: JobProcessAppNoteMessage;
  history_message_app?: JobProcessAppHistoryMessage;
  cv_app?: JobProcessAppCv;
  motimate_app?: JobProcessAppMotimate;
  move_app?: JobProcessAppMove;
  onsite_interview_app?: JobProcessAppOnSiteInterview;
  recman_app?: JobProcessAppRecman;
  learnifier_app?: JobProcessAppLearnifier;
  planday_app?: JobProcessAppPlanday;
  sdworx_app?: JobProcessAppSDWorx;
  reference_app?: JobProcessAppReference;
  nps_app?: JobProcessAppNps;
  publisher_linked?: IProcessApp;
  reason_for_decline_app?: JobProcessAppReasonForDecline;
  scrive_app?: JobProcessAppScrive;
  staffing_assignment_invitation_app?: ProcessAppStaffingAssignmentInvitation;
  // Custom var
  overridden?: boolean;
  toggled?: boolean;
}

export interface RecManQuestions {
  questions: ScreeningQuestionSuggestion[];
}
