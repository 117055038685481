import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const applyRoutes = [
  {
    path: '',
    name: 'login',
    component: () => import('@/pages/mj-apply-login-page/MjApplyLoginPage.vue'),
  },
  {
    path: 'login-email',
    name: 'login-email',
    component: () =>
      import(
        '@/pages/mj-apply-login-with-email-page/MjApplyLoginWithEmailPage.vue'
      ),
  },
  {
    path: 'confirm-privacy-terms',
    name: 'confirm-privacy-terms',
    component: () =>
      import(
        '@/pages/mj-apply-confirm-privacy-terms-page/MjApplyConfirmPrivacyTermsPage.vue'
      ),
  },
  {
    path: 'confirm-application-success',
    name: 'confirm-application-success',
    component: () =>
      import(
        '@/pages/mj-apply-confirm-application-success-page/MjApplyConfirmApplicationSuccessPage.vue'
      ),
  },
  {
    path: 'edit-resume',
    name: 'edit-resume',
    component: () =>
      import('@/pages/mj-apply-edit-resume-page/MjApplyEditResumePage.vue'),
  },
  {
    path: 'edit-questions',
    name: 'edit-questions',
    component: () =>
      import(
        '@/pages/mj-apply-edit-questions-page/MjApplyEditQuestionsPage.vue'
      ),
  },
];

const applicationRoutes = {
  path: 'application/:application_id',
  name: 'application-detail',
  component: () =>
    import(
      '@/pages/mj-application-detail-home-page/MjApplicationDetailHomePage.vue'
    ),
  children: [
    {
      path: 'app/:app_id',
      name: 'job-process-app',
      component: () =>
        import(
          '@/pages/mj-job-process-app/MjJobProcessApp.vue'
          /* webpackChunkName: "job-process-app" */
        ),
      children: [
        {
          path: 'reference-survey/:reference_survey_id',
          name: 'reference-survey',
          component: () =>
            import('@/pages/mj-reference-survey/MjReferenceSurvey.vue'),
        },
        {
          path: 'video-interview',
          name: 'video-interview-landing',
          component: () =>
            import(
              '@/pages/mj-video-interview/MjVideoInterview.vue'
              /* webpackChunkName: "video-interview" */
            ),
          children: [
            {
              path: 'introduction',
              name: 'video-interview-introduction',
              component: () =>
                import(
                  '@/pages/mj-video-interview/mj-video-interview-introduction/MjVideoInterviewIntroduction.vue'
                ),
            },
            {
              path: 'record',
              name: 'video-interview-record',
              component: () =>
                import(
                  '@/pages/mj-video-interview/mj-video-interview-record/MjVideoInterviewRecord.vue'
                ),
            },
            {
              path: 'watch',
              name: 'video-interview-watch',
              component: () =>
                import(
                  '@/pages/mj-video-interview/mj-video-interview-watch/MjVideoInterviewWatch.vue'
                ),
            },
            {
              path: 'success',
              name: 'video-interview-success',
              component: () =>
                import(
                  '@/pages/mj-video-interview/mj-video-interview-success/MjVideoInterviewSuccess.vue'
                ),
            },
          ],
        },
      ],
    },
    {
      path: 'chat/:room_id',
      name: 'chat',
      component: /* webpackChunkName: "chat" */ () =>
        import('@/pages/mj-application-chat-page/MjApplicationChatPage.vue'),
    },
  ],
};

const dashboardRoutes = [
  {
    path: 'applications',
    name: 'applications',
    component: () =>
      import(
        '@/pages/mj-application-dashboard-applications-page/MjApplicationDashboardApplicationsPage.vue'
      ),
  },
  {
    path: 'profile',
    name: 'profile',
    component: () =>
      import(
        '@/pages/mj-application-dashboard-profile-page/MjApplicationDashboardProfilePage.vue'
      ),
  },
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import(
        '@/pages/mj-application-dashboard-settings-page/MjApplicationDashboardSettingsPage.vue'
      ),
  },
  applicationRoutes,
];

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/nps-survey/:nps_survey_id/answer/',
      name: 'nps-survey-page',
      component: () =>
        import(
          '@/pages/nps-survey-page/MjNpsSurveyPage.vue'
          /* webpackChunkName: "nps-survey" */
        ),
    },
    {
      path: '/',
      name: 'home-page',
      component: () =>
        import(
          '@/pages/mj-home-page/MjHomePage.vue'
          /* webpackChunkName: "applicant-home-page" */
        ),
      children: [
        {
          path: '',
          name: 'applicant-landing-page',
          redirect: '/auth/login',
          // TODO: Remove redirect and create list of jobs to apply for
        },
        {
          path: '/reset-password/:token',
          name: 'reset-password',
          component: () =>
            import('@/pages/mj-reset-password-page/MjResetPasswordPage.vue'),
        },
        {
          path: '/accept-invitation/:token',
          name: 'accept-invitation',
          component: () =>
            import(
              '@/pages/mj-accept-invitation-page/MjAcceptInvitationPage.vue'
            ),
        },
        {
          path: '/auth',
          name: 'dashboard-login-homepage',
          component: () =>
            import(
              '@/pages/mj-application-dashboard-login-homepage/MjApplicationDashboardLoginHomePage.vue'
            ),
          redirect: '/auth/login',
          children: [
            {
              path: 'login',
              name: 'dashboard-login',
              component: () =>
                import(
                  '@/pages/mj-application-dashboard-login-page/MjApplicationDashboardLoginPage.vue'
                ),
            },
            {
              path: 'login-email',
              name: 'dashboard-login-email',
              component: () =>
                import(
                  '@/pages/mj-application-dashboard-login-with-email-page/MjApplicationDashboardLoginWithEmailPage.vue'
                ),
            },
          ],
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          redirect: 'dashboard/applications',
          component: () =>
            import(
              '@/pages/mj-application-dashboard-page/MjApplicationDashboardPage.vue'
              /* webpackChunkName: "applicant-dashboard" */
            ),
          children: dashboardRoutes,
        },
      ],
    },
    {
      path: '/:job_id',
      name: 'apply',
      component: () =>
        import(
          '@/pages/mj-apply-home-page/MjApplyHomePage.vue'
          /* webpackChunkName: "applicant-apply-pages" */
        ),
      children: applyRoutes,
    },
  ],
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});
