import { action, observable } from 'mobx';
import MaiveApi from '../mj-api/maive.api';
import IMaiveFunnelSteps, {
  IMaiveApplicationScore,
} from '../mj-models/maive.interface';

export default interface IMaiveStore {
  // Job
  funnelSteps: IMaiveFunnelSteps;
  viewsChartSeries: any;
  leadsChartSeries: any;
  applicationsChartSeries: any;
  qualifiedCandidatesChartSeries: any;
  source_table_data?: any;
  fetchFunnelStepViews: (jobId: number) => void;
  fetchFunnelStepLeads: (jobId: number) => void;
  fetchFunnelStepApplications: (jobId: number) => void;
  fetchFunnelStepQualifiedCandidates: (jobId: number) => void;
  fetchViewsJobChart: (jobId: number, dateAttribute: string) => void;
  fetchLeadsJobChart: (jobId: number, dateAttribute: string) => void;
  fetchApplicationsJobChart: (jobId: number, dateAttribute: string) => void;
  fetchQualifiedCandidatesJobChart: (
    jobId: number,
    dateAttribute: string
  ) => void;
  fetchSourceTableData: (jobId: number) => void;
  currentDashboardJobId: number;
  // Career page
  currentDashboardUnitId: number;
  fetchViewsCareerPageChart: (
    unitId: number,
    dateAttribute: string,
    dimension: string
  ) => void;
  careerPageViewsChartSeries: any;
  fetchJobApplicationScores: (jobId: number) => void;
  maiveApplicationScores: IMaiveApplicationScore[];
}

export class MaiveStore implements IMaiveStore {
  @observable public funnelSteps: IMaiveFunnelSteps = {
    leads: undefined,
    views: undefined,
    applications: undefined,
    qualifiedCandidates: undefined,
  };
  @observable public viewsChartSeries: any = {
    WEEKLY: null,
    DAILY: null,
    HOURLY: null,
  };
  @observable public leadsChartSeries: any = {
    WEEKLY: null,
    DAILY: null,
    HOURLY: null,
  };
  @observable public applicationsChartSeries: any = {
    WEEKLY: null,
    DAILY: null,
    HOURLY: null,
  };
  @observable public qualifiedCandidatesChartSeries: any = {
    WEEKLY: null,
    DAILY: null,
    HOURLY: null,
  };
  @observable public careerPageViewsChartSeries: any = {
    sourceWEEKLY: null,
    sourceDAILY: null,
    sourceHOURLY: null,
    countryWEEKLY: null,
    countryDAILY: null,
    countryHOURLY: null,
    deviceCategoryWEEKLY: null,
    deviceCategoryDAILY: null,
    deviceCategoryHOURLY: null,
  };
  @observable public currentDashboardJobId!: number;
  @observable public currentDashboardUnitId!: number;
  @observable public source_table_data?: any;
  @observable public maiveApplicationScores: IMaiveApplicationScore[] = [];
  public maiveApi: MaiveApi;

  constructor(maiveApi: MaiveApi) {
    this.maiveApi = maiveApi;
  }

  @action('FETCH FUNNEL-STEP VIEWS')
  public fetchFunnelStepViews = async (jobId: number) => {
    try {
      const views = await this.maiveApi.fetchFunnelStepViews(jobId);
      this.funnelSteps.views = views.value;
    } catch (error) {
      this.funnelSteps.views = '?';
    }
  };
  @action('FETCH FUNNEL-STEP LEADS')
  public fetchFunnelStepLeads = async (jobId: number) => {
    try {
      const leads = await this.maiveApi.fetchFunnelStepLeads(jobId);
      this.funnelSteps.leads = leads.value;
    } catch (error) {
      this.funnelSteps.leads = '?';
    }
  };
  @action('FETCH FUNNEL-STEP APPLICATIONS')
  public fetchFunnelStepApplications = async (jobId: number) => {
    try {
      const applications = await this.maiveApi.fetchFunnelStepApplications(
        jobId
      );
      this.funnelSteps.applications = applications.value;
    } catch (error) {
      this.funnelSteps.applications = '?';
    }
  };
  @action('FETCH FUNNEL-STEP QUALIFIED CANDIDATES')
  public fetchFunnelStepQualifiedCandidates = async (jobId: number) => {
    try {
      const qual_candidates = await this.maiveApi.fetchFunnelStepQualifiediCandidates(
        jobId
      );
      this.funnelSteps.qualifiedCandidates = qual_candidates.value;
    } catch (error) {
      this.funnelSteps.qualifiedCandidates = '?';
    }
  };

  @action('FETCH VIEWS JOB CHART')
  public fetchViewsJobChart = async (jobId: number, dateAttribute: string) => {
    try {
      const params = new URLSearchParams();
      params.append('date_attribute', dateAttribute);
      this.viewsChartSeries[dateAttribute] = JSON.stringify(
        await this.maiveApi.fetchViewsJobChart(jobId, params)
      );
    } catch (error) {
      this.viewsChartSeries[dateAttribute] = null;
    }
  };

  @action('FETCH LEADS JOB CHART')
  public fetchLeadsJobChart = async (jobId: number, dateAttribute: string) => {
    try {
      const params = new URLSearchParams();
      params.append('date_attribute', dateAttribute);
      this.leadsChartSeries[dateAttribute] = JSON.stringify(
        await this.maiveApi.fetchLeadsJobChart(jobId, params)
      );
    } catch (error) {
      this.leadsChartSeries[dateAttribute] = null;
    }
  };

  @action('FETCH APPLICATIONS JOB CHART')
  public fetchApplicationsJobChart = async (
    jobId: number,
    dateAttribute: string
  ) => {
    try {
      const params = new URLSearchParams();
      params.append('date_attribute', dateAttribute);
      this.applicationsChartSeries[dateAttribute] = JSON.stringify(
        await this.maiveApi.fetchApplicationsJobChart(jobId, params)
      );
    } catch (error) {
      this.applicationsChartSeries[dateAttribute] = null;
    }
  };

  @action('FETCH QUALIFIED CANDIDATES JOB CHART')
  public fetchQualifiedCandidatesJobChart = async (
    jobId: number,
    dateAttribute: string
  ) => {
    try {
      const params = new URLSearchParams();
      params.append('date_attribute', dateAttribute);
      this.qualifiedCandidatesChartSeries[dateAttribute] = JSON.stringify(
        await this.maiveApi.fetchQualifiedCandidatesJobChart(jobId, params)
      );
    } catch (error) {
      this.qualifiedCandidatesChartSeries[dateAttribute] = null;
    }
  };

  @action('FETCH SOURCE TABLE DATA')
  public fetchSourceTableData = async (jobId: number) => {
    try {
      this.source_table_data = await this.maiveApi.fetchSourceTableData(jobId);
    } catch (error) {
      this.source_table_data = [];
    }
  };

  // Career page

  @action('FETCH VIEWS CAREER PAGE CHART')
  public fetchViewsCareerPageChart = async (
    jobId: number,
    dateAttribute: string,
    dimension: string
  ) => {
    let dataLiteral = dimension + dateAttribute;
    dataLiteral = dataLiteral.split(':').slice(-1)[0];
    try {
      const params = new URLSearchParams();
      params.append('date_attribute', dateAttribute);
      params.append('dimension', dimension);
      this.careerPageViewsChartSeries[dataLiteral] = JSON.stringify(
        await this.maiveApi.fetchViewsCareerPageChart(jobId, params)
      );
    } catch (error) {
      this.careerPageViewsChartSeries[dataLiteral] = null;
    }
  };

  // AI

  @action('FETCH JOB APPLICATION SCORES')
  public fetchJobApplicationScores = async (jobId: number) => {
    try {
      this.maiveApplicationScores = await this.maiveApi.fetchJobApplicationScores(
        jobId
      );
    } catch (error) {}
  };
}
