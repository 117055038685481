/* tslint:disable:no-console */

import { register } from 'register-service-worker';

if (
    process.env.VUE_APP_ENVIRONMENT === 'production' ||
    process.env.VUE_APP_ENVIRONMENT === 'staging'
) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered(registration: ServiceWorkerRegistration) {
      console.log('Service worker has been registered.');
      setInterval(async () => {
        await registration.update();
      }, 60000); // every minute
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log('New content is available; please refresh.');
      document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline() {
      console.log(
          'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
