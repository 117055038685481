/* tslint:disable:max-classes-per-file */

import { IMessage, WsCommand } from './ws.interface';
import { IProcessStep } from './process.interface';

export enum NotificationCommand {
  JOIN = 'join',
  LOAD_APPLICANT_APPLICATIONS = 'load_applicant_applications',
  LOAD_RECRUITER_APPLICANTS = 'load_recruiter_applicants',
  SEND = 'send',
  MARK_UNIT_ROOM_READ_RECRUITER = 'mark_unit_room_read_recruiter',
}

export class NotificationCommandJoin extends WsCommand {
  constructor() {
    super(NotificationCommand.JOIN);
  }
}

export class NotificationCommandLoadApplicant extends WsCommand {
  public notifications: IWsNotification[] = [];
  public unseen?: number;
  public unread?: number;

  constructor() {
    super(NotificationCommand.LOAD_APPLICANT_APPLICATIONS);
  }
}

export class NotificationCommandLoadRecruiter extends WsCommand {
  public notifications: IWsNotification[] = [];
  public unseen?: number;
  public unread?: number;
  public unit?: number;

  constructor(unitId: number) {
    super(NotificationCommand.LOAD_RECRUITER_APPLICANTS);
    this.unit = unitId;
  }
}

export class NotificationCommandSend extends WsCommand {
  public notification!: IWsNotification;
  public unit?: number;

  constructor(unit: number) {
    super(NotificationCommand.SEND);
    this.unit = unit;
  }
}

export class NotificationCommandMarkUnitRoomReadRecruiter extends WsCommand {
  public unseen?: number;
  public unread?: number;
  public unit: number;
  public unit_room: number;

  constructor(unitId: number, unitRoomId: number) {
    super(NotificationCommand.MARK_UNIT_ROOM_READ_RECRUITER);
    this.unit = unitId;
    this.unit_room = unitRoomId;
  }
}

export interface NotificationExtraData {
  actor_name?: string;
  actor_picture?: string;
  job_title?: string;
  unit_name?: string;
  category_name?: string;
  message?: IMessage;
  application_status?: number;
  process_step?: IProcessStep;
  actor_email?: string;
}

export interface IWsNotification {
  id?: number;
  seen?: boolean;
  read?: boolean;
  recipient?: number;
  application?: number;
  data: NotificationExtraData;
  job?: number;
  unit_room_id?: number;
  unit_room?: number;
  created_at?: string;
  actor?: number;
  unit?: number;
  type?: 'Chat' | string;
}
