import { action, observable } from 'mobx';
import AppointmentApi from '../mj-api/appointment.api';
import {
  IAppointment,
  IAppointmentSlot,
  IAppointmentsMicrosoftOutlookAccount,
} from '../mj-models/appointment.interface';

export default interface IAppointmentStore {
  appointment?: IAppointment;
  availableSlots: IAppointmentSlot[];
  confirmedSlot?: IAppointmentSlot;
  oldSlot?: IAppointmentSlot;
  getAvailableSlots: () => Promise<IAppointmentSlot[] | Error>;
  confirmAppointmentSlot: (slot: IAppointmentSlot) => Promise<boolean>;
  switchAppointmentSlot: (
    oldSlot: IAppointmentSlot,
    newSlot: IAppointmentSlot
  ) => Promise<boolean>;
  getAppointment: (appointmentId: number) => Promise<IAppointment | Error>;
  fetchOutlookUnitEmployments: (
    unitId: number,
    searchTerm?: string
  ) => Promise<IAppointmentsMicrosoftOutlookAccount[] | false>;
}

export class AppointmentStore implements IAppointmentStore {
  @observable public appointment?: IAppointment;
  @observable public availableSlots: IAppointmentSlot[] = [];
  @observable public confirmedSlot?: IAppointmentSlot;
  @observable public oldSlot?: IAppointmentSlot;

  public appointmentApi: AppointmentApi;

  constructor(appointmentApi: AppointmentApi) {
    this.appointmentApi = appointmentApi;
  }

  @action('GET AVAILABLE SLOTS')
  public getAvailableSlots = async (): Promise<any> => {
    const availableSlotsPage = await this.appointmentApi.getAvailableAppointmentSlots(
      this.appointment!.id!
    );
    this.availableSlots = availableSlotsPage.results;
    return availableSlotsPage.results;
  };

  @action('CONFIRM APPOINTMENT SLOT')
  public confirmAppointmentSlot = async (
    slot: IAppointmentSlot
  ): Promise<boolean> => {
    try {
      this.confirmedSlot = await this.appointmentApi.confirmAppointmentSlot(
        slot
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('SWITCH APPOINTMENT SLOT')
  public switchAppointmentSlot = async (
    oldSlot: IAppointmentSlot,
    newSlot: IAppointmentSlot
  ): Promise<boolean> => {
    try {
      this.confirmedSlot = await this.appointmentApi.switchAppointmentSlot(
        oldSlot,
        newSlot
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('GET APPOINTMENT')
  public getAppointment = async (appointmentId: number) => {
    this.appointment = await this.appointmentApi.getAppointment(appointmentId);
    return this.appointment;
  };

  @action('FETCH OUTLOOK UNIT EMPLOYMENTS')
  public fetchOutlookUnitEmployments = async (
    unitId: number,
    searchTerm = ''
  ): Promise<IAppointmentsMicrosoftOutlookAccount[] | false> => {
    try {
      const response = await this.appointmentApi.fetchOutlookUnitEmployments(
        unitId,
        searchTerm
      );
      return response.results;
    } catch (error) {
      return false;
    }
  };
}
