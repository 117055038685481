import { action, observable } from 'mobx';
import { Route } from 'vue-router';
import {
  IGlobalSearch,
  IGlobalSearchChip,
  IGlobalSearchChipState,
} from '../mj-models/search.interface';

export default interface IBusinessNavigationStore {
  currentPageName: string;
  isInJobEditor: boolean;
  isInReferenceSurveyReport: boolean;
  isInSendNewApp: boolean;
  globalSearchText: string;
  globalSearchChips: IGlobalSearchChip[];
  globalSearch: IGlobalSearch;
  setCurrentPageName: (currentPageName: string) => void;
  routeHistory: Array<{ toRoute: Route; fromRoute?: Route }>;
  getJobEditorBackRoute: () => Route | null;
  getReferenceSurveyBackRoute: () => Route | null;
}
export class BusinessNavigationStore implements IBusinessNavigationStore {
  @observable public currentPageName: string = '';
  @observable public isInJobEditor: boolean = false;
  @observable public isInReferenceSurveyReport: boolean = false;
  @observable public isInSendNewApp: boolean = false;
  @observable public globalSearchText: string = '';
  @observable public globalSearch: IGlobalSearch = {
    jobs: [],
    applications: [],
  };
  @observable public globalSearchChips: IGlobalSearchChip[] = [
    {
      id: IGlobalSearchChipState.ALL,
      value: true,
    },
    {
      id: IGlobalSearchChipState.JOBS,
      value: false,
    },
    {
      id: IGlobalSearchChipState.APPLICATIONS,
      value: false,
    },
  ];
  @observable public routeHistory: Array<{
    toRoute: Route;
    fromRoute: Route;
  }> = [];

  constructor() {}

  @action('SET CURRENT PAGE NAME')
  public setCurrentPageName = (currentPageName: string): void => {
    this.currentPageName = currentPageName;
  };

  @action('GET JOB EDITOR BACK ROUTE')
  public getJobEditorBackRoute = (): Route | null => {
    for (const routes of this.routeHistory.reverse()) {
      if (
        routes.toRoute &&
        !routes.toRoute!.path.includes('editor') &&
        !routes.toRoute!.path.includes('create')
      ) {
        return routes.toRoute;
      }
    }
    return null;
  };

  @action('GET REFERENCE SURVEY BACK ROUTE')
  public getReferenceSurveyBackRoute = (): Route | null => {
    for (const routes of this.routeHistory.reverse()) {
      if (
        routes.toRoute &&
        !routes.toRoute!.path.includes('reference-survey')
      ) {
        return routes.toRoute;
      }
    }
    return null;
  };
}
