import { WsCommand } from '../mj-models/ws.interface';

export abstract class MjWebsocketService {
  protected socket$?: WebSocket;
  protected unit_connection?: number;
  protected hasLoadedNotifications: boolean = false;
  protected _RETRY_TIMER: number = 12 * 1000;
  protected url: string;

  constructor(url: string) {
    this.url = url;
  }

  public retryWebsocketConnection() {
    if (this.socket$ && this.socket$.readyState !== this.socket$.OPEN) {
      // Code 1000 means we
      // initiated the disconnect programmatically
      setTimeout(() => {
        this.socket$ = undefined;
        this.connectWebsocket(this.url!);
      }, this._RETRY_TIMER);
    }
  }

  protected connectWebsocket(url: string) {
    this.url = url;
    const access_token = localStorage.getItem('id_token');
    if (!access_token) {
      return;
    }
    this.disconnectWebsocket();
    this.socket$ = new WebSocket(`${url}?access_token=${access_token}`);
    this.socket$.onopen = (e) => this.handleOnOpen(e);
    this.socket$.onclose = (e) => this.handleOnClose(e);
    this.socket$.onerror = (e) => this.handleOnError(e);
    this.socket$.onmessage = (e) => this.handleOnMessage(e);
  }

  protected abstract handleOnOpen(event: any): any;

  protected handleOnMessage(event: any) {
    const message = event.data;
    const command: WsCommand =
      typeof message != null ? JSON.parse(message) : null;
    if (command && command.command) {
      this.handleCommand(command);
    }
  }

  protected abstract handleCommand(command: WsCommand): void;

  protected handleOnClose(event: any) {
    if (event.code !== 1000) {
      // Code 1000 means we
      // initiated the disconnect programmatically
      this.retryWebsocketConnection();
    }
    this.hasLoadedNotifications = false;
    this.unit_connection = undefined;
  }

  protected handleOnError(event: any) {
    console.log(event);
  }

  protected disconnectWebsocket() {
    if (this.socket$ != null) {
      if (this.socket$.readyState === this.socket$.CLOSED) {
        this.hasLoadedNotifications = false;
        this.unit_connection = undefined;
        this.socket$.onclose = null;
        this.socket$ = undefined;
      } else if (this.socket$.readyState === this.socket$.CONNECTING) {
        this.hasLoadedNotifications = false;
        this.unit_connection = undefined;
        this.socket$.onclose = null;
        this.socket$ = undefined;
      } else {
        try {
          this.hasLoadedNotifications = false;
          this.unit_connection = undefined;
          this.socket$.onclose = null;
          this.socket$.close();
          this.socket$ = undefined;
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  protected sendCommand(data: any): void {
    if (this.socket$ && this.socket$.readyState === this.socket$.OPEN) {
      this.socket$.send(JSON.stringify(data));
    }
  }

  protected isConnected(): boolean {
    return !!this.socket$ && this.socket$.readyState === this.socket$.OPEN;
  }

}
