import { IPage } from '../mj-models/utils.interface';
import {
  IReferenceAnswer,
  IReferenceContact,
  IReferenceQuestion,
  IReferenceSurvey,
} from '../mj-models/references.interface';
import BaseApi from './base.api';
import { AxiosTransformer } from 'axios';

const removeAuthKeyTransformer: AxiosTransformer = (data, headers) => {
  const authKey = 'Authorization';
  delete headers.common[authKey];

  return data;
};

export default class ReferenceApi extends BaseApi {
  public getSurvey = (referenceSurveyId: string): Promise<IReferenceSurvey> =>
    this.axios
      .get(`${this.baseUrl}references/survey/${referenceSurveyId}/`)
      .then((response) => response.data);

  public updateSurvey = (
    surveyId: string,
    referenceContacts: IReferenceContact[]
  ): Promise<IReferenceContact> =>
    this.axios
      .patch(`${this.baseUrl}references/survey/${surveyId}/`, {
        reference_contacts: referenceContacts,
      })
      .then((response) => response.data);

  public getReferenceSurveyQuestions = (
    referenceSurveyId: string
  ): Promise<IPage<IReferenceQuestion>> =>
    this.axios
      .get(
        `${this.baseUrl}references/survey/${referenceSurveyId}/questions/?page_size=100`
      )
      .then((response) => response.data);

  public getReferenceSurveyReport = (
    referenceSurveyId: string
  ): Promise<IReferenceQuestion[]> =>
    this.axios
      .get(`${this.baseUrl}references/survey/${referenceSurveyId}/report/`)
      .then((response) => response.data);

  public patchReferenceSurvey = (
    referenceSurveyId: string,
    survey: IReferenceSurvey
  ): Promise<IReferenceSurvey> =>
    this.axios
      .patch(`${this.baseUrl}references/survey/${referenceSurveyId}/`, survey)
      .then((response) => response.data);

  public patchReferenceContact = (
    referenceContact: IReferenceContact
  ): Promise<IReferenceContact> =>
    this.axios
      .patch(
        `${this.baseUrl}references/reference-contact/${referenceContact.id}/`,
        referenceContact
      )
      .then((response) => response.data);

  public callSurvey = (surveyId: string, contactId: string): Promise<''> => {
    return this.axios
      .get(
        `${this.baseUrl}references/survey/${surveyId}/reference-contact/${contactId}/request-call/`,
        {
          transformRequest: [
            removeAuthKeyTransformer,
            ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
          ],
        }
      )
      .then((response) => response.data);
  };

  public declineSurvey = (surveyId: string, contactId: string): Promise<''> => {
    return this.axios
      .get(
        `${this.baseUrl}references/survey/${surveyId}/reference-contact/${contactId}/decline/`,
        {
          transformRequest: [
            removeAuthKeyTransformer,
            ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
          ],
        }
      )
      .then((response) => response.data);
  };

  public submitSurvey = (
    surveyId: string,
    answers: IReferenceAnswer[]
  ): Promise<IReferenceAnswer[]> => {
    return this.axios
      .post(`${this.baseUrl}references/survey/${surveyId}/submit/`, answers, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);
  };

  public submitReferenceContactToHubspot = (
    formId: string,
    referenceContact: IReferenceContact
  ): Promise<IReferenceAnswer[]> => {
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/1593007/${formId}`;

    const data = {
      submittedAt: `${Date.now()}`,
      fields: [
        {
          name: 'email',
          value: referenceContact.email,
        },
        {
          name: 'firstname',
          value: referenceContact.first_name,
        },
        {
          name: 'lastname',
          value: referenceContact.last_name,
        },
        {
          name: 'phone',
          value: referenceContact.phone_number,
        },
        {
          name: 'company',
          value: referenceContact.company,
        },
        {
          name: 'jobtitle',
          value: referenceContact.position,
        },
      ],
      // context: {
      //   pageUri: pageUrl,
      //   pageName: 'Ebook page',
      // },
    };

    return this.axios
      .post(url, data, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);
  };

  public getReferenceSurveyReportPdf = (
    referenceSurveyId: string
  ): Promise<ArrayBuffer> =>
    this.axios
      .get(
        `${process.env.VUE_APP_API_URL}references/survey/${referenceSurveyId}/report/pdf-export/`,
        { responseType: 'arraybuffer' }
      )
      .then((response) => response.data);
}
