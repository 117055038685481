import BaseApi from './base.api';
import { ITag, IUserTag } from '../mj-models/tags.interface';
import { IPage } from '../mj-models/utils.interface';

export default class TagApi extends BaseApi {
  public createTag = (tagName: string, unitId: number): Promise<ITag> =>
    this.axios
      .post(`${this.baseUrl}tags/unit/${unitId}/`, {
        name: tagName,
        unit_id: unitId,
      })
      .then((response) => response.data);

  public updateTag = (tag: ITag): Promise<ITag> =>
    this.axios
      .put(`${this.baseUrl}tags/${tag.id!}/`, tag)
      .then((response) => response.data);

  public createUserTag = (
    unitId: number,
    userId: number,
    tagId: number
  ): Promise<IUserTag> =>
    this.axios
      .post(`${this.baseUrl}tags/unit/${unitId}/user/${userId}/`, {
        user_id: userId,
        tag_id: tagId,
      })
      .then((response) => response.data);

  public getUnitTags = (
    unitId: number,
    search: string,
    page: number = 1,
    pageSize: number = 50
  ): Promise<IPage<ITag>> =>
    this.axios
      .get(
        `${this.baseUrl}tags/unit/${unitId}/?page_size=${pageSize}&page=${page}&search=${search}`
      )
      .then((response) => response.data);

  public getUserTags = (
    unitId: number,
    userId: number,
    page: number,
    pageSize: number = 25
  ): Promise<IPage<IUserTag>> =>
    this.axios
      .get(
        `${this.baseUrl}tags/unit/${unitId}/user/${userId}/?page_size=${pageSize}&page=${page}`
      )
      .then((response) => response.data);

  public deleteUserTags = (userTagId: number): Promise<boolean> =>
    this.axios
      .delete(`${this.baseUrl}tags/user-tag/${userTagId}/`)
      .then((response) => response.data);

  public getUserTag = (userTagId: number): Promise<IUserTag> =>
    this.axios
      .get(`${this.baseUrl}tags/user-tag/${userTagId}/`)
      .then((response) => response.data);
}
