import { action, observable } from 'mobx';
import JobApi from '../mj-api/job.api';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import UnitApi from '../mj-api/unit.api';
import { ITalentPoolApplicant } from '../mj-models/talent-pool.interface';
import { MjFilterData } from '../mj-models/filters.inteface';
import { ITag } from '../mj-models/tags.interface';

export default interface ITalentPoolStore {
  isTalentPoolLoading: boolean;
  talentPoolApplicants: ITalentPoolApplicant[];
  talentPoolPaginator: IPaginator;
  currentUnitId: number | null;
  currentFilterData: MjFilterData[];
  allUnitTags: ITag[];
  searchValue: string;
  sortProp: string;
  isAsc: boolean;

  fetchTalentPoolPage: () => void;
  setCurrentFilterData(query: string, filterData: MjFilterData[]): void;
  sortByProp(prop: string): void;
}

export class TalentPoolStore implements ITalentPoolStore {
  @observable public isTalentPoolLoading: boolean = false;
  @observable public talentPoolApplicants: ITalentPoolApplicant[] = [];
  @observable public talentPoolPaginator: IPaginator = {
    page: 1,
    itemsPerPage: 25,
    count: 0,
    maxPage: 1,
  };
  @observable public currentUnitId: number | null = null;
  @observable public currentFilterData: MjFilterData[] = [];
  @observable public searchValue: string = '';
  @observable public isAsc: boolean = true;
  @observable public sortProp: string = '';
  @observable public allUnitTags: ITag[] = [];

  public jobApi: JobApi;
  public unitApi: UnitApi;

  private query: string = '';

  constructor(jobApi: JobApi, unitApi: UnitApi) {
    this.jobApi = jobApi;
    this.unitApi = unitApi;
  }

  @action('FETCH TALENT POOL PAGE')
  public async fetchTalentPoolPage() {
    try {
      if (this.currentUnitId) {
        this.isTalentPoolLoading = true;
        const talentPoolPage: IPage<ITalentPoolApplicant> = await this.unitApi.fetchTalentPoolPage(
          this.currentUnitId!,
          this.talentPoolPaginator.page,
          this.query,
          this.searchValue,
          this.getTableOrderingParameters(),
          this.talentPoolPaginator.itemsPerPage
        );

        this.talentPoolPaginator.count = talentPoolPage.count || 0;

        this.talentPoolPaginator.maxPage = Math.ceil(
          talentPoolPage.count! / this.talentPoolPaginator.itemsPerPage
        );
        this.talentPoolApplicants = talentPoolPage.results;
      }
    } catch (error) {}

    this.isTalentPoolLoading = false;
  }

  @action('SET FILTER DATA')
  public setCurrentFilterData(query: string, filterData: MjFilterData[]) {
    this.query = query;
    this.currentFilterData = filterData;
  }

  @action('SORT BY PROP')
  public sortByProp(prop: string) {
    this.sortProp !== prop
      ? (this.sortProp = prop)
      : (this.isAsc = !this.isAsc);

    this.fetchTalentPoolPage();
  }

  private getTableOrderingParameters() {
    return `${this.isAsc ? '' : '-'}${this.sortProp}`;
  }
}
