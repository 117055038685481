





























































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class AppStoreIcon extends Vue {}
