import {
  ICreateRecmanProject,
  IRecmanCompany,
  IRecmanDepartment,
  IRecmanPipeline,
  IRecmanProject,
  IRecmanUser,
  IScriveIntegration,
} from '../mj-models/integration.interface';
import BaseApi from './base.api';
import { IArbetsformedlingen } from '../mj-models/arbetsformedlingen.interface';
import { IPage } from '../mj-models/utils.interface';
import { IScriveDocument } from '../mj-models/scrive.interface';

export default class IntegrationApi extends BaseApi {
  public getRecmanDepartments = (unitId: number): Promise<Record<string, IRecmanDepartment>> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/recman/departments/`)
      .then((response) => response.data);

  public getRecmanCompanies = (
    unitId: number,
    recmanDepartmentId: string
  ): Promise<Record<string, IRecmanCompany>> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/recman/departments/${recmanDepartmentId}/companies/`)
      .then((response) => response.data);

  public getRecmanProjects = (
    unitId: number,
    recmanCompanyId: string
  ): Promise<Record<string, IRecmanProject>> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/recman/companies/${recmanCompanyId}/projects/`)
      .then((response) => response.data);

  public getRecmanPipelines = (unitId: number): Promise<Record<string, IRecmanPipeline>> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/recman/pipelines/`)
      .then((response) => response.data);

  public getRecmanUsers = (unitId: number): Promise<Record<string, IRecmanUser>> =>
    this.axios
      .get(`${this.baseUrl}units/${unitId}/recman/users/`)
      .then((response) => response.data);

  public createRecmanProject = (
    unitId: number,
    recmanCompanyId: string,
    project: ICreateRecmanProject
  ): Promise<{ project_id: string; message: string }> =>
    this.axios
      .post(
        `${this.baseUrl}units/${unitId}/recman/companies/${recmanCompanyId}/projects/create/`,
        project
      )
      .then((response) => response.data);

  public createArbetsformedlingenAdvertisement = (boardId: number): Promise<IArbetsformedlingen> =>
    this.axios
      .get(`${this.baseUrl}integrations/job-boards/${boardId}/recreate-arbetsformedlingen/`)
      .then((response) => response.data);

  public updateArbetsformedlingenAd = (
    arbetsformedlingen: IArbetsformedlingen
  ): Promise<IArbetsformedlingen> =>
    this.axios
      .put(
        `${this.baseUrl}integrations/arbetsformedlingen/${arbetsformedlingen.id!}/`,
        arbetsformedlingen
      )
      .then((response) => response.data);

  public arbetsformedlingenCopyInfoFromjob = (
    arbetsformedlingenId: number
  ): Promise<IArbetsformedlingen> =>
    this.axios
      .get(
        `${
          this.baseUrl
        }integrations/arbetsformedlingen/${arbetsformedlingenId!}/copy-info-from-job/`
      )
      .then((response) => response.data);

  public getScriveIntegrations = (unitId: number): Promise<IPage<IScriveIntegration>> =>
    this.axios
      .get(`${this.baseUrl}integrations/units/${unitId}/scrive-integrations/`)
      .then((response) => response.data);

  public restartScriveDocument = (
    appMessageId: number,
    scriveDocumentId: string
  ): Promise<IScriveDocument> =>
    this.axios
      .get(
        `${this.baseUrl}integrations/app-messages/${appMessageId}/documents/${scriveDocumentId}/restart/`
      )
      .then((response) => response.data);

  public exportApplicantToSDWorxFromMessage = (
    messageId: number,
    norwegianIdNumber: string
  ): Promise<any> =>
    this.axios
      .post(`${this.baseUrl}integrations/message/${messageId!}/export-to-sdworx/`, {
        norwegian_id_number: norwegianIdNumber,
      })
      .then((response) => response.data);
}
