import {
  ICreateRecmanProject,
  IRecmanCompany,
  IRecmanDepartment,
  IRecmanPipeline,
  IRecmanProject,
  IRecmanUser,
  IScriveIntegration,
} from '../mj-models/integration.interface';
import { action, observable } from 'mobx';
import IntegrationApi from '../mj-api/integration.api';
import { IPage } from '../mj-models/utils.interface';
import { IScriveDocument } from '../mj-models/scrive.interface';
import { IArbetsformedlingen } from '../mj-models/arbetsformedlingen.interface';
import { IMessage } from '../mj-models/ws.interface';

export interface IRecmanData {
  departments: IRecmanDepartment[] | null;
  companies: IRecmanCompany[] | null;
  projects: IRecmanProject[] | null;
  pipelines: IRecmanPipeline[] | null;
  users: IRecmanUser[] | null;
  departmentsLoading: boolean;
  companiesLoading: boolean;
  projectsLoading: boolean;
  pipelinesLoading: boolean;
  usersLoading: boolean;
}

export interface IScriveData {
  integrations: IScriveIntegration[];
  isIntegrationsLoading: boolean;
}

export default interface IIntegrationStore {
  recmanData: IRecmanData | null;
  scriveData: IScriveData;

  getRecmanDepartments: (unitId: number) => Promise<boolean>;

  getRecmanCompanies: (unitId: number, recmanDepartmentId: string) => Promise<boolean>;

  getRecmanProjects: (unitId: number, recmanCompanyId: string) => Promise<boolean>;

  getRecmanPipelines: (unitId: number) => Promise<boolean>;

  getRecmanUsers: (unitId: number) => Promise<boolean>;

  createRecmanProject: (
    unitId: number,
    recmanCompanyId: string,
    project: ICreateRecmanProject
  ) => Promise<boolean>;

  getScriveIntegrations: (unitId: number) => Promise<IPage<IScriveIntegration> | undefined>;

  restartScriveDocument: (
    appMessageId: number,
    scriveDocumentId: string
  ) => Promise<IScriveDocument | undefined>;

  arbetsformedlingenCopyInfoFromJob: (
    arbetsformedlingenId: number
  ) => Promise<IArbetsformedlingen | undefined>;

  exportApplicantToSDWorxFromMessage: (
    message: IMessage,
    norwegian_id_number: string
  ) => Promise<any>;
}

export class IntegrationStore implements IIntegrationStore {
  public integrationApi: IntegrationApi;

  @observable public recmanData: IRecmanData = {
    departments: null,
    companies: null,
    projects: null,
    pipelines: null,
    users: null,
    departmentsLoading: false,
    companiesLoading: false,
    projectsLoading: false,
    pipelinesLoading: false,
    usersLoading: false,
  };

  @observable public scriveData: IScriveData = {
    integrations: [],
    isIntegrationsLoading: false,
  };

  constructor(integrationApi: IntegrationApi) {
    this.integrationApi = integrationApi;
  }

  @action('ARBETSFORMEDLINGEN COPY INFO FROM JOB')
  public arbetsformedlingenCopyInfoFromJob = async (
    arbetsformedlingenId: number
  ): Promise<IArbetsformedlingen | undefined> => {
    try {
      return await this.integrationApi.arbetsformedlingenCopyInfoFromjob(arbetsformedlingenId);
    } catch (error) {
      return undefined;
    }
  };

  @action('GET RECMAN DEPARTMENTS')
  public getRecmanDepartments = async (unitId: number) => {
    this.recmanData.departmentsLoading = true;

    try {
      this.recmanData.departments = Object.values(
        await this.integrationApi.getRecmanDepartments(unitId)
      );

      return true;
    } catch (error) {
      return false;
    } finally {
      this.recmanData.departmentsLoading = false;
    }
  };

  @action('GET RECMAN COMPANIES')
  public getRecmanCompanies = async (unitId: number, recmanDepartmentId: string) => {
    this.recmanData.companiesLoading = true;

    try {
      this.recmanData.companies = Object.values(
        await this.integrationApi.getRecmanCompanies(unitId, recmanDepartmentId)
      );

      return true;
    } catch (error) {
      return false;
    } finally {
      this.recmanData.companiesLoading = false;
    }
  };

  @action('GET RECMAN PROJECTS')
  public getRecmanProjects = async (unitId: number, recmanCompanyId: string) => {
    this.recmanData.projectsLoading = true;

    try {
      this.recmanData.projects = Object.values(
        await this.integrationApi.getRecmanProjects(unitId, recmanCompanyId)
      );

      return true;
    } catch (error) {
      return false;
    } finally {
      this.recmanData.projectsLoading = false;
    }
  };

  @action('EXPORT CANDIDATE TO SDWORX')
  public exportApplicantToSDWorxFromMessage = async (
    message: IMessage,
    norwegian_id_number: string
  ) => {
    try {
      return this.integrationApi.exportApplicantToSDWorxFromMessage(
        message.id!,
        norwegian_id_number
      );
    } catch (error) {
      return false;
    }
  };

  @action('GET RECMAN PIPELINES')
  public getRecmanPipelines = async (unitId: number) => {
    this.recmanData.pipelinesLoading = true;

    try {
      this.recmanData.pipelines = Object.values(
        await this.integrationApi.getRecmanPipelines(unitId)
      );

      return true;
    } catch (error) {
      return false;
    } finally {
      this.recmanData.pipelinesLoading = false;
    }
  };

  @action('GET RECMAN USERS')
  public getRecmanUsers = async (unitId: number) => {
    this.recmanData.usersLoading = true;

    try {
      this.recmanData.users = Object.values(await this.integrationApi.getRecmanUsers(unitId));

      return true;
    } catch (error) {
      return false;
    } finally {
      this.recmanData.usersLoading = false;
    }
  };

  @action('CREATE RECMAN PROJECT')
  public createRecmanProject = async (
    unitId: number,
    recmanCompanyId: string,
    project: ICreateRecmanProject
  ) => {
    try {
      const response = await this.integrationApi.createRecmanProject(
        unitId,
        recmanCompanyId,
        project
      );
      console.log(response);

      return true;
    } catch (error) {
      return false;
    }
  };

  @action('GET SCRIVE INTEGRATIONS')
  public getScriveIntegrations = async (
    unitId: number
  ): Promise<IPage<IScriveIntegration> | undefined> => {
    this.scriveData.isIntegrationsLoading = true;
    try {
      const response = await this.integrationApi.getScriveIntegrations(unitId);
      this.scriveData.isIntegrationsLoading = false;
      return response;
    } catch (error) {
      this.scriveData.isIntegrationsLoading = false;
      return undefined;
    }
  };

  @action('RESTART SCRIVE DOCUMENT')
  public restartScriveDocument = async (
    appMessageId: number,
    scriveDocumentId: string
  ): Promise<IScriveDocument | undefined> => {
    try {
      return await this.integrationApi.restartScriveDocument(appMessageId, scriveDocumentId);
    } catch (error) {
      return undefined;
    }
  };
}
