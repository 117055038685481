import { AxiosStatic } from 'axios';

export default class BaseApi {
  public axios!: AxiosStatic;
  protected baseUrl: string;

  constructor(url: string, axios: AxiosStatic, setAuthHeader: boolean = true) {
    this.baseUrl = url;
    this.axios = axios;

    // Need to add this check because of axios instances created in
    // nuxt framework does not support transformrequest as axios instances
    // created in Vue apps do.
    if (!setAuthHeader) {
      return;
    }

    const authorizationKey: string = 'Authorization';
    if (!this.axios.defaults.headers.common[authorizationKey]) {
      if (typeof window !== 'undefined') {
        // Nuxt async data is on server side, and thus does not have localstorage
        const token: string | null = localStorage.getItem('id_token');
        if (token !== undefined && token !== null) {
          this.axios.defaults.headers.common[authorizationKey] = `JWT ${token}`;
        }
      }
    }
  }
}
