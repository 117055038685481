import { AxiosTransformer } from 'axios';

import { IAutomationRule } from '../mj-models/automation-rules.interface';
import { IMotimateGroup } from '../mj-models/motimate.interface';
import {
  BaseProcessAppStaffingAssignmentInvitationResponse,
  IJobProcess,
  IProcessApp,
  IProcessStep,
  JobProcessAppQuestionAnswer,
  JobProcessApplicationMove,
} from '../mj-models/process.interface';
import { IBulkTags } from '../mj-models/tags.interface';
import { IPage } from '../mj-models/utils.interface';
import { removeAuthKeyTransformer } from './api.utils';
import BaseApi from './base.api';

export default class DynamicProcessApi extends BaseApi {
  public fetchJobProcess = (jobId: number): Promise<IJobProcess> =>
    this.axios
      .get(`${this.baseUrl}processes/jobs/${jobId}/processes/`)
      .then((response) => response.data);

  public fetchPublicJobProcess = (jobId: number): Promise<IJobProcess> =>
    this.axios
      .get(`${this.baseUrl}processes/jobs/${jobId}/processes/public/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);

  public updateJobProcess = (process: IJobProcess): Promise<IJobProcess> =>
    this.axios
      .put(
        `${this.baseUrl}processes/jobs/${process.job_id}/processes/`,
        process
      )
      .then((response) => response.data);

  public patchJobProcess = (process: IJobProcess): Promise<IJobProcess> =>
    this.axios
      .patch(
        `${this.baseUrl}processes/jobs/${process.job_id}/processes/`,
        process
      )
      .then((response) => response.data);

  public createJobProcess = (process: IJobProcess): Promise<IJobProcess> =>
    this.axios
      .post(`${this.baseUrl}processes/`, process)
      .then((response) => response.data);

  public getApp = (appId: number): Promise<IProcessApp> =>
    this.axios
      .get(`${this.baseUrl}processes/apps/${appId}/`)
      .then((response) => response.data);

  public updateApp = (app: any): Promise<IProcessApp> =>
    this.axios
      .put(`${this.baseUrl}processes/apps/${app.id}/`, app)
      .then((response) => response.data);

  public addApp = (app: any): Promise<any> =>
    this.axios
      .put(`${this.baseUrl}processes/apps/`, app)
      .then((response) => response.data);

  public deleteApp = (appId: number): Promise<any> =>
    this.axios
      .delete(`${this.baseUrl}processes/apps/${appId}/`)
      .then((response) => response.data);

  public getStep = (stepId: number): Promise<IProcessStep> =>
    this.axios
      .get(`${this.baseUrl}processes/step/${stepId}/`)
      .then((response) => response.data);

  public deleteStep = (stepId: number): Promise<any> =>
    this.axios.delete(`${this.baseUrl}processes/step/${stepId}/`);

  public patchStep = (stepId: number, value: any): Promise<IProcessStep> =>
    this.axios
      .patch(`${this.baseUrl}processes/step/${stepId}/`, value)
      .then((response) => response.data);

  public getAppQuestionAnswers = (
    appId: number,
    applicationId: number
  ): Promise<IPage<JobProcessAppQuestionAnswer>> =>
    this.axios
      .get(
        `${this.baseUrl}processes/apps/${appId}/application/${applicationId}/question-answers/`
      )
      .then((response) => response.data);

  public deliverApp = (
    appId: number,
    answers:
      | JobProcessAppQuestionAnswer[]
      | BaseProcessAppStaffingAssignmentInvitationResponse[]
  ): Promise<any> =>
    this.axios
      .post(`${this.baseUrl}processes/apps/${appId}/deliver/`, answers)
      .then((response) => response.data);

  public moveApplications = (
    jobId: number,
    applicationMoves: JobProcessApplicationMove[]
  ): Promise<any> =>
    this.axios
      .post(
        `${this.baseUrl}processes/jobs/${jobId}/application-move/`,
        applicationMoves
      )
      .then((response) => response.data);

  public updateDelayedApplicationMove = (delayedApplicationMoveUpdate: {
    id: number;
    delayed_move_stopped: boolean;
  }): Promise<any> =>
    this.axios
      .put(
        `${this.baseUrl}processes/application-move/${delayedApplicationMoveUpdate.id}/update-delayed-move/`,
        delayedApplicationMoveUpdate
      )
      .then((response) => response.data);

  public addTagToApplications = (userTags: IBulkTags): Promise<any> =>
    this.axios
      .post(`${this.baseUrl}tags/user-tag/bulk-create/`, userTags)
      .then((response) => response.data);

  public getMagicToken = async (): Promise<any> =>
    this.axios
      .get(`${this.baseUrl}users/magic-token/`)
      .then((response) => response.data);

  public fetchMotimateGroups = (motimateOrganisationId: number): Promise<any> =>
    this.axios
      .get(
        `${this.baseUrl}motimate/organization/${motimateOrganisationId}/groups/`
      )
      .then((response) => response.data);

  // Used for motimate Public API
  public fetchMotimateGroupsPublicApi = (
    motimateOrganisationId: number
  ): Promise<{ data: IMotimateGroup[] }> =>
    this.axios
      .get(
        `${this.baseUrl}motimate/organization/${motimateOrganisationId}/public-api/groups/`
      )
      .then((response) => response.data);

  public fetchMotimatePositionsPublicApi = (
    motimateOrganisationId: number
  ): Promise<{ data: IMotimateGroup[] }> =>
    this.axios
      .get(
        `${this.baseUrl}motimate/organization/${motimateOrganisationId}/public-api/positions/`
      )
      .then((response) => response.data);

  public cloneApp = (appId: number): Promise<IProcessApp> =>
    this.axios
      .get(`${this.baseUrl}processes/apps/${appId}/clone/`)
      .then((response) => response.data);

  public createAutomationRule = (
    automationRule: IAutomationRule
  ): Promise<IAutomationRule> =>
    this.axios
      .post(
        `${this.baseUrl}processes/apps/${automationRule.process_app_id}/automation-rule/`,
        automationRule
      )
      .then((response) => response.data);

  public fetchJobProcessSteps = (jobId: number): Promise<IJobProcess> =>
    this.axios
      .get(`${this.baseUrl}processes/jobs/${jobId}/processes/steps/`)
      .then((response) => response.data);

  public getBaseUrl = () => this.baseUrl;
}
