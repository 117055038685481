import {
  IMaiveApplicationScore,
  IMaiveFunnelStep,
} from '../mj-models/maive.interface';
import BaseApi from './base.api';

export default class MaiveApi extends BaseApi {
  public fetchFunnelStepViews = (jobId: number): Promise<IMaiveFunnelStep> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/funnel-step/views/`)
      .then((response) => response.data);
  public fetchFunnelStepLeads = (jobId: number): Promise<IMaiveFunnelStep> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/funnel-step/leads/`)
      .then((response) => response.data);
  public fetchFunnelStepApplications = (
    jobId: number
  ): Promise<IMaiveFunnelStep> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/funnel-step/applications/`)
      .then((response) => response.data);
  public fetchFunnelStepQualifiediCandidates = (
    jobId: number
  ): Promise<IMaiveFunnelStep> =>
    this.axios
      .get(
        `${this.baseUrl}maive/job/${jobId}/funnel-step/qualified-candidates/`
      )
      .then((response) => response.data);
  public fetchViewsJobChart = (
    jobId: number,
    dateAttribute: URLSearchParams
  ): Promise<any[]> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/chart/views/`, {
        params: dateAttribute,
      })
      .then((response) => response.data);
  public fetchLeadsJobChart = (
    jobId: number,
    dateAttribute: URLSearchParams
  ): Promise<any[]> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/chart/leads/`, {
        params: dateAttribute,
      })
      .then((response) => response.data);
  public fetchApplicationsJobChart = (
    jobId: number,
    dateAttribute: URLSearchParams
  ): Promise<any[]> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/chart/applications/`, {
        params: dateAttribute,
      })
      .then((response) => response.data);
  public fetchQualifiedCandidatesJobChart = (
    jobId: number,
    dateAttribute: URLSearchParams
  ): Promise<any[]> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/chart/qualified-candidates/`, {
        params: dateAttribute,
      })
      .then((response) => response.data);
  public fetchSourceTableData = (jobId: number): Promise<any> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/table/sources/`)
      .then((response) => response.data);
  public fetchViewsCareerPageChart = (
    unitId: number,
    dateAttribute: URLSearchParams
  ): Promise<any[]> =>
    this.axios
      .get(`${this.baseUrl}maive/unit/${unitId}/career-page/chart/views/`, {
        params: dateAttribute,
      })
      .then((response) => response.data);
  public fetchJobApplicationScores = (
    jobId: number
  ): Promise<IMaiveApplicationScore[]> =>
    this.axios
      .get(`${this.baseUrl}maive/job/${jobId}/application-score/`)
      .then((response) => response.data);
}
