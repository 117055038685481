import {
  IWsNotification,
  NotificationCommand,
  NotificationCommandJoin,
  NotificationCommandLoadApplicant,
  NotificationCommandLoadRecruiter,
  NotificationCommandMarkUnitRoomReadRecruiter,
  NotificationCommandSend,
} from '../mj-models/ws-notification.interface';
import { action, observable } from 'mobx';
import { MjWebsocketService } from '../mj-services/mj-websocket.service';
import { WsCommand } from '../mj-models/ws.interface';

export default interface IWsNotificationStore {
  notifications: IWsNotification[];
  loading: boolean;
  recruiterFeed: boolean;
  unitId?: number;
  newNotification?: IWsNotification;
  connect(): void;
  retryConnection(): void;
  disconnect(): void;
  markUnitRoomAsRead(unitId: number, unitRoomId: number): void;
}

export class WsNotificationStore
  extends MjWebsocketService
  implements IWsNotificationStore {
  @observable public notifications: IWsNotification[] = [];
  @observable public loading: boolean = false;
  @observable public recruiterFeed: boolean = false;
  @observable public unitId?: number;
  @observable public newNotification: IWsNotification = { data: {} };

  @action('CONNECT')
  public connect() {
    this.loading = true;
    this.connectWebsocket(this.url);
  }

  @action('DISCONNECT')
  public disconnect() {
    this.notifications = [];
    this.disconnectWebsocket();
  }

  @action('RETRY CONNECTION')
  public retryConnection() {
    this.loading = true;
    this.retryWebsocketConnection();
  }

  @action('MARK UNIT ROOM AS READ')
  public markUnitRoomAsRead = (unitId: number, unitRoomId: number) => {
    if (this.recruiterFeed) {
      this.sendCommand(
        new NotificationCommandMarkUnitRoomReadRecruiter(unitId, unitRoomId)
      );
    }
  };

  protected handleOnOpen(event: any) {
    if (this.socket$) {
      this.sendJoinNotification();
      this.sendLoadNotification();
    }
  }

  protected handleCommand(command: WsCommand) {
    if (command.command === NotificationCommand.LOAD_APPLICANT_APPLICATIONS) {
      this.handleCommandLoad(command as NotificationCommandLoadApplicant);
    } else if (
      command.command === NotificationCommand.LOAD_RECRUITER_APPLICANTS
    ) {
      this.handleCommandLoad(command as NotificationCommandLoadRecruiter);
    } else if (command.command === NotificationCommand.SEND) {
      this.handleCommandSend(command as NotificationCommandSend);
    } else if (
      command.command === NotificationCommand.MARK_UNIT_ROOM_READ_RECRUITER
    ) {
      this.handleUnitRoomReadRecruiter(
        command as NotificationCommandMarkUnitRoomReadRecruiter
      );
    }
    this.loading = false;
  }

  private handleCommandLoad(command: NotificationCommandLoadApplicant) {
    this.notifications = command.notifications;
  }

  private handleCommandSend(command: NotificationCommandSend) {
    this.notifications = [command.notification, ...this.notifications];
    this.newNotification = { ...command.notification };
  }

  private handleUnitRoomReadRecruiter(
    command: NotificationCommandMarkUnitRoomReadRecruiter
  ) {
    this.notifications = this.notifications.map((notification) => {
      if (notification.unit_room === command.unit_room) {
        notification.seen = true;
        notification.read = true;
        return notification;
      }
      return notification;
    });
  }

  private sendJoinNotification() {
    this.sendCommand(new NotificationCommandJoin());
  }

  private sendLoadNotification() {
    this.hasLoadedNotifications = true;
    if (this.recruiterFeed) {
      this.sendCommand(new NotificationCommandLoadRecruiter(this.unitId!));
    } else {
      this.sendCommand(new NotificationCommandLoadApplicant());
    }
  }
}
