import {
  IJob,
  IJobActionPermission,
  IJobs,
  IJobSubscription,
  IOgGraph,
  IPositionFunction,
  IPublicJobWithListing,
} from '../mj-models/job.interface';
import BaseApi from './base.api';
import { IPage, IPaginator } from '../mj-models/utils.interface';
import { IApplicationLead } from '../mj-models/application.interface';
import { AxiosTransformer } from 'axios';
import { removeAuthKeyTransformer } from './api.utils';

export default class JobApi extends BaseApi {
  public createJob = (job: IJob): Promise<IJob> =>
    this.axios
      .post(`${this.baseUrl}jobs/`, job)
      .then((response) => response.data);

  public getJobDetail = (jobId: number): Promise<IJob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/`)
      .then((response) => response.data);

  public fetchPublicJob = (jobId: number): Promise<IJob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/public/`, {
        transformRequest: [
          removeAuthKeyTransformer,
          ...(this.axios.defaults.transformRequest as AxiosTransformer[]),
        ],
      })
      .then((response) => response.data);

  public fetchPublicJobListing = (
    jobId: number
  ): Promise<IPublicJobWithListing> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/public-listing/`)
      .then((response) => response.data);

  public fetchJobs = (
    unitId: number,
    query: string = '',
    paginator: IPaginator,
    order: string,
    search: string
  ): Promise<IJobs> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/jobs/?ql=${query}&is_template=False&page=${paginator.page}&page_size=${paginator.itemsPerPage}&ordering=${order}&search=${search}`
      )
      .then((response) => response.data);

  public getJobAssignments = (
    jobId: number,
    pageSize: number
  ): Promise<any[]> =>
    this.axios
      .get(
        `${this.baseUrl}jobs/${jobId}/assignments/?use_pagination=false&page_size=${pageSize}`
      )
      .then((response) => response.data.results);

  public addAssignment = (event: {
    assignment: any;
    job: any;
  }): Promise<boolean> =>
    this.axios
      .post(`${this.baseUrl}jobs/${event.job.id}/assignments/`, {
        user: {
          id: event.assignment.user.id,
          user_profile: {
            id: event.assignment.user.user_profile.id,
          },
        },
        job: event.job.id,
        user_id: event.assignment.user_id,
        job_id: event.job.id,
      })
      .then((response) => response.data);

  public removeAssignment = (event: {
    assignment: any;
    job: any;
  }): Promise<boolean> =>
    this.axios
      .delete(
        `${this.baseUrl}jobs/${event.job.id}/assignments/${event.assignment.id}/`
      )
      .then((response) => response.data);

  public fetchBusinessDashboardJobOpenings = (
    unitId: number,
    page: number,
    query: string = '',
    includeTemplates = false
  ): Promise<IJobs> =>
    this.axios
      .get(
        `${
          this.baseUrl
        }units/${unitId}/jobs/overview/?page=${page}&ql=${query}${
          includeTemplates ? '' : '&is_template=False'
        }`
      )
      .then((response) => response.data);

  public unpublish = (jobId: number): Promise<IJob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/unpublish/`)
      .then((response) => response.data);

  public publishJob = (jobId: number): Promise<IJob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/publish/`)
      .then((response) => response.data);

  public submitChanges = (jobId: number): Promise<IJob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/submit-changes/`)
      .then((response) => response.data);

  public delete = (jobId: number): Promise<IJob> =>
    this.axios
      .delete(`${this.baseUrl}jobs/${jobId}/`)
      .then((response) => response.data);

  public clone = (jobId: number, unitId: number): Promise<IJob> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/clone/?unit_id=${unitId}`)
      .then((response) => response.data);

  public fetchJobAdTitleSuggestions = (term: string, pageSize: number) =>
    this.axios
      .get(
        `${this.baseUrl}suggestions/job-titles/?search=${term}&page_size=${pageSize}`
      )
      .then((response) => response.data);

  public fetchJobPositionFunctionSuggestions = (
    term: string,
    pageSize: number,
    language: string = 'en'
  ): Promise<IPage<IPositionFunction>> =>
    this.axios
      .get(
        `${this.baseUrl}jobs/position-functions/?search=${term}&page_size=${pageSize}&language=${language}`
      )
      .then((response) => response.data);

  public fetchApplicationVideoInterviewAnswers = (applicationId: number) =>
    this.axios
      .get(
        `${this.baseUrl}jobs/applications/${applicationId}/videointerviewanswers/`
      )
      .then((response) => response.data);

  public getJobs = (
    unitId: number,
    paginator: IPaginator,
    order: string,
    isTemplate: string,
    isDraft: string,
    isPublished: string,
    searchValue: string
  ): Promise<IPage<IJob>> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/jobs/?page=${paginator.page}&page_size=${paginator.itemsPerPage}&ordering=${order}&is_template=${isTemplate}&is_draft=${isDraft}&is_published=${isPublished}&search=${searchValue}`
      )
      .then((response) => response.data);

  public patchJob = (job: IJob): Promise<IJob> =>
    this.axios
      .patch(`${this.baseUrl}jobs/${job.id}/`, job)
      .then((response) => response.data);

  public checkHasJobActionPermission = (
    jobId: number
  ): Promise<IJobActionPermission> =>
    this.axios
      .get(`${this.baseUrl}jobs/${jobId}/action-permission/`)
      .then((response) => response.data);

  public getApplicationLeads = (
    jobId: number,
    pageSize: number,
    page: number,
    order: string
  ): Promise<IPage<IApplicationLead>> =>
    this.axios
      .get(
        `${this.baseUrl}jobs/${jobId}/applicant-leads/?page=${page}&page_size=${pageSize}&ordering=${order}`
      )
      .then((response) => response.data);

  public getOgGraph = (ogGraphId: number) =>
    this.axios
      .get(`${this.baseUrl}media/og-graphs/${ogGraphId}/`)
      .then((response) => response.data);

  public saveOgGraph(ogGraph: IOgGraph) {
    if (ogGraph.id) {
      return this.axios
        .put(`${this.baseUrl}media/og-graphs/${ogGraph.id}/`, ogGraph)
        .then((response) => response.data);
    } else {
      return this.axios
        .post(`${this.baseUrl}media/og-graphs/`, ogGraph)
        .then((response) => response.data);
    }
  }

  public fetchJobSubscriptions = (email: string): Promise<IPage<IJobSubscription>> =>
    this.axios
      .get(`${this.baseUrl}jobs/subscriptions/${email}/list/`)
      .then((response) => response.data);

  public createJobSubscription = (
    jobSubscription: IJobSubscription
  ): Promise<IJobSubscription> =>
    this.axios
      .post(`${this.baseUrl}jobs/subscriptions/`, jobSubscription)
      .then((response) => response.data);

  public unSubscribeJobSubscription = (
    jobSubscriptionId: string
  ): Promise<boolean> =>
    this.axios
      .get(`${this.baseUrl}jobs/subscriptions/${jobSubscriptionId}/unsubscribe/`)
      .then((response) => response.data);
}
