











import { Component, Prop } from 'vue-property-decorator';
import MjTypographyBase from './MjTypographyBase.vue';

@Component({
  inheritAttrs: false,
})
export default class MjTypographyOverline extends MjTypographyBase {
  @Prop({ default: '0px', type: String }) public readonly margin!: string;
  @Prop({ default: 300, type: Number }) public readonly fontWeight!: number;

  get propsClass() {
    return {
      'mj-typography overline': true,
      'mj-typography--oblique': this.oblique,
    };
  }
}
