import { CancelTokenSource } from 'axios';

import { IAutomationRule } from './automation-rules.interface';
import { IJob } from './job.interface';
import {
  IProcessApp,
  IProcessStep,
  JobProcessAppQuestionAnswer,
} from './process.interface';
import { IUser, IUserEducation, IUserExperience } from './user.interface';
import { ChatRoom } from './ws-chat.interface';

export enum ScreeningViewState {
  table,
  board,
}

export enum ApplicationRate {
  sad,
  almostSad,
  neutral,
  almostHappy,
  happy,
}

export enum ApplicationProcessFormInternalRefererChoices {
  UNKNOWN = 'UNKNOWN',
  CAREER_PAGE = 'CAREER_PAGE',
  WEB_JOB_AD = 'WEB_JOB_AD',
  APPLICATION_FORM = 'APPLICATION_FORM',
  MOBILE_JOB_AD = 'MOBILE_JOB_AD',
  MANUAL = 'MANUAL',
  MANUAL_ACCEPTED_INVITATION = 'MANUAL_ACCEPTED_INVITATION',
  WEB_JOB_FEED = 'WEB_JOB_FEED',
}

export interface IApplicationRating {
  id?: number;
  application_id?: number;
  process_step_id?: number;
  author_id?: number;
  author?: IUser;
  rate: ApplicationRate;
}

export interface IApplicationWebtempExportResponse {
  success: boolean;
  export_response?: string;
}

export interface IBoardApplications {
  [key: number]: IBoardStepApplications;
}

export enum IBoardStepState {
  HIDDEN = 'HIDDEN',
  LOADING = 'LOADING',
  FINISHED = 'FINISHED',
}

export interface IBoardStepApplications {
  page?: number;
  applications?: IApplication[];
  totalApplicationsCount?: number;
  isLastPage?: boolean;
  sortQuery?: string;
  pageSize?: number;
  cancelTokenSource?: CancelTokenSource;
  state: IBoardStepState;
}

export interface ApplicationJobMove {
  old_job_id?: number;
  new_job_id?: number;
  process_step_id?: number;
  old_application_id?: number;
  remove_original_application?: boolean;
  notify_candidate?: boolean;
}

export interface ApplicationBulkDelete {
  application_id?: number;
  job_id?: number;
}

export interface IScheduledApplicationMove {
  id?: number;
  delay_in_ms?: number;
  name?: string;
  created_at?: string;
  updated_at?: string;
  process_step?: IProcessStep;
  app_author?: IProcessApp;
  initiator_app?: IProcessApp;
  applied_automation_rule?: IAutomationRule;
}

export interface IApplication {
  [key: string]: unknown;
  id?: number;
  answers: JobProcessAppQuestionAnswer[];
  unit_room?: ChatRoom;
  // job?: IJob;
  applicant?: IUser;
  has_video_interview_answer?: boolean;
  latest_experience?: IUserExperience;
  latest_education?: IUserEducation;
  num_unread_notifications?: number;
  process_step?: IProcessStep;
  created_at?: string;
  num_ratings?: number;
  avg_rating?: number;
  unit_room_id?: number;
  latest_activity?: string;
  permission_expiration_date?: string;
  external_referer?: string;
  internal_referer?: ApplicationProcessFormInternalRefererChoices;
  manual_application_creation_id?: number | null;
  last_move_hide_step_name?: boolean;
  latest_scheduled_move?: IScheduledApplicationMove;
  source_name?: string;
  source_id?: number;
  source_type?: string;
  source_address?: string;
  source_unit_name?: string;
  source_unit_display_name?: string;
  source_unit_logo_url?: string;
  source_unit_description_image_url?: string;
  source_customer_name?: string;
  source?: IJob | undefined;
  gdpr_deleted?: boolean;
}

export interface IApplicationExport {
  id?: number;
  applications?: number[];
  author_id?: number;
  job_id?: number;
  export_all?: boolean;
  export_file_id?: number;
  export_file_name?: string;
  export_file_url?: string;
  completed?: boolean;
}

export interface IApplicationLead {
  id?: number;
  phone?: string;
  email?: string;
}

export enum MassAction {
  reject = 0,
  move = 1,
  note = 2,
  message = 3,
  select_all = 4,
  export_excel = 5,
  unselect_all = 6,
  tag = 7,
  move_to_another_job = 8,
  delete = 9,
}

export interface DetailedRatingsOfApplications {
  [applicationId: number]: IApplicationRating[];
}
