import IJobStore, { JobStore } from '../mj-store/job.store';
import IApplicationStore, { ApplicationStore } from '../mj-store/application.store';
import IUserStore, { UserStore } from '../mj-store/user.store';
import INotificationStore, { NotificationStore } from '../mj-store/notification.store';
import IMediaStore, { MediaStore } from '../mj-store/media.store';
import IUiStore, { UiStore } from '../mj-store/ui.store';
import IVideoInterviewStore, { VideoInterviewStore } from '../mj-store/video-interview.store';
import IWsNotificationStore, { WsNotificationStore } from '../mj-store/ws-notification.store';
import IWsChatStore, { WsChatStore } from '../mj-store/ws-chat.store';
import UserApi from '../mj-api/user.api';
import GDPRApi from '../mj-api/gdpr.api';
import JobApi from '../mj-api/job.api';
import ApplicationApi from '../mj-api/application.api';
import MediaApi from '../mj-api/media.api';
import ChatApi from '../mj-api/chat.api';
import AttributeApi from '../mj-api/attribute.api';
import IWsApplicantStore, { WsApplicantStore } from '../mj-store/ws-applicant.store';
import IAppointmentStore, { AppointmentStore } from '../mj-store/appointment.store';
import AppointmentApi from '../mj-api/appointment.api';
import IMaiveStore, { MaiveStore } from '../mj-store/maive.store';
import MaiveApi from '../mj-api/maive.api';
import IUnitStore, { UnitStore } from '../mj-store/unit.store';
import UnitApi from '../mj-api/unit.api';
import DynamicProcessApi from '../mj-api/dynamic-process.api';
import IDynamicProcessStore, { DynamicProcessStore } from '../mj-store/dynamic-process.store';
import ChecklistApi from '../mj-api/checklist.api';
import IBusinessDashboardStore, {
  BusinessDashboardStore,
} from '../mj-store/businessDashboard.store';
import IPostNewJobStore, { PostNewJobStore } from '../mj-store/postNewJob.store';
import AnalyticsApi from '../mj-api/analytics.api';
import ITalentPoolStore, { TalentPoolStore } from '../mj-store/talentPool.store';
import ISelectUnitEmploymentStore, {
  SelectUnitEmploymentStore,
} from '../mj-store/select.unit.employment.store';
import IBusinessUnitStore, { BusinessUnitStore } from '../mj-store/business.unit.store';
import IBusinessNavigationStore, {
  BusinessNavigationStore,
} from '../mj-store/business.navigation.store';
import TagApi from '../mj-api/tag.api';
import IBusinessJobEditorStore, {
  BusinessJobEditorStore,
} from '../mj-store/business.job.editor.store';
import ListingApi from '../mj-api/listing.api';
import NotificationsApi from '../mj-api/notifications.api';
import JobSourcingApi from '../mj-api/job-sourcing.api';
import IBusinessLeadsStore, { BusinessLeadsStore } from '../mj-store/business.leads.store';
import IBusinessSettingsStore, { BusinessSettingsStore } from '../mj-store/business.settings.store';
import axios, { AxiosStatic } from 'axios';
import * as firebase from 'firebase/app';
import IBusinessBlogStore, { BusinessBlogStore } from '../mj-store/business.blog.store';
import BlogApi from '../mj-api/blog.api';
import IReferenceStore, { ReferenceStore } from '../mj-store/reference.store';
import ReferenceApi from '../mj-api/reference.api';
import IBusinessAnalyticsStore, {
  BusinessAnalyticsStore,
} from '../mj-store/business.analytics.store';
import INpsStore, { NpsStore } from '../mj-store/nps.store';
import NpsApi from '../mj-api/nps.api';
import IReleaseStore, { ReleaseStore } from '../mj-store/release.store';
import ReleaseApi from '../mj-api/releases.api';
import IManualCandidateStore, { ManualCandidateStore } from '../mj-store/manual-candidate.store';
import ManualCandidateApi from '../mj-api/manual-candidate.api';
import IIntegrationStore, { IntegrationStore } from '../mj-store/integration.store';
import IntegrationApi from '../mj-api/integration.api';
import IFacebookStore, { FacebookStore } from '../mj-store/facebook.store';
import FacebookApi from '../mj-api/facebook.api';
import PushNotificationsApi from '../mj-api/push-notifications.api';
import IPushNotificationStore, { PushNotificationStore } from '../mj-store/push-notification.store';
import IAttributeStore, { AttributeStore } from '../mj-store/attribute.store';

export default class Deps {
  public businessAnalyticsStore!: IBusinessAnalyticsStore;
  public jobStore!: IJobStore;
  public applicationStore!: IApplicationStore;
  public userStore!: IUserStore;
  public notificationStore!: INotificationStore;
  public mediaStore!: IMediaStore;
  public uiStore!: IUiStore;
  public videoInterviewStore!: IVideoInterviewStore;
  public wsNotificationStore!: IWsNotificationStore;
  public wsChatStore!: IWsChatStore;
  public wsApplicantStore!: IWsApplicantStore;
  public appointmentsStore!: IAppointmentStore;
  public maiveStore!: IMaiveStore;
  public unitStore!: IUnitStore;
  public dynamicProcessStore!: IDynamicProcessStore;
  public businessDashboardStore!: IBusinessDashboardStore;
  public postNewJobStore!: IPostNewJobStore;
  public talentPoolStore!: ITalentPoolStore;
  public selectUnitEmploymentStore!: ISelectUnitEmploymentStore;
  public businessUnitStore!: IBusinessUnitStore;
  public businessNavigationStore!: IBusinessNavigationStore;
  public businessJobEditorStore!: IBusinessJobEditorStore;
  public businessLeadsStore!: IBusinessLeadsStore;
  public businessSettingsStore!: IBusinessSettingsStore;
  public businessBlogStore!: IBusinessBlogStore;
  public referenceStore!: IReferenceStore;
  public npsStore!: INpsStore;
  public releaseStore!: IReleaseStore;
  public manualCandidateStore!: IManualCandidateStore;
  public integrationStore!: IIntegrationStore;
  public facebookStore!: IFacebookStore;
  public pushNotificationStore!: IPushNotificationStore;
  public attributeStore!: IAttributeStore;

  constructor(
    axiosInstance: AxiosStatic,
    router?: any,
    baseUrl?: string,
    chatWsUrl?: string,
    notificationWsUrl?: string,
    applicantsWsUrl?: string,
    firebaseAuth?: firebase.auth.Auth
  ) {
    const url = baseUrl || process.env.VUE_APP_API_URL!;
    const userApi = new UserApi(url, axiosInstance);
    const gdprApi = new GDPRApi(url, axiosInstance);
    const jobApi = new JobApi(url, axiosInstance);
    const applicationApi = new ApplicationApi(url, axiosInstance);
    const mediaApi = new MediaApi(url, axiosInstance);
    const chatApi = new ChatApi(url, axiosInstance);
    const maiveApi = new MaiveApi(url, axiosInstance);
    const unitApi = new UnitApi(url, axiosInstance);
    const dynamicProcessApi = new DynamicProcessApi(url, axiosInstance);
    const checklistApi = new ChecklistApi(url, axiosInstance);
    const appointmentApi = new AppointmentApi(url, axiosInstance);
    const analyticsApi = new AnalyticsApi(url, axiosInstance);
    const tagApi = new TagApi(url, axiosInstance);
    const listingApi = new ListingApi(url, axiosInstance);
    const jobSourcingApi = new JobSourcingApi(url, axiosInstance);
    const notificationsApi = new NotificationsApi(url, axiosInstance);
    const blogApi = new BlogApi(url, axiosInstance);
    const referenceApi = new ReferenceApi(url, axiosInstance);
    const npsApi = new NpsApi(url, axiosInstance);
    const releaseApi = new ReleaseApi(url, axiosInstance);
    const manualCandidateApi = new ManualCandidateApi(url, axiosInstance);
    const integrationApi = new IntegrationApi(url, axiosInstance);
    const facebookApi = new FacebookApi(url, axiosInstance);
    const pushNotificationApi = new PushNotificationsApi(url, axiosInstance);
    const attributeApi = new AttributeApi(url, axiosInstance);

    this.businessAnalyticsStore = new BusinessAnalyticsStore(analyticsApi);
    this.referenceStore = new ReferenceStore(referenceApi);
    this.releaseStore = new ReleaseStore(releaseApi);
    this.npsStore = new NpsStore(npsApi);
    this.manualCandidateStore = new ManualCandidateStore(manualCandidateApi);
    this.notificationStore = new NotificationStore();
    this.jobStore = new JobStore(jobApi, applicationApi, this.notificationStore, router);
    this.uiStore = new UiStore();
    this.mediaStore = new MediaStore(mediaApi, this.notificationStore);
    this.videoInterviewStore = new VideoInterviewStore(applicationApi, dynamicProcessApi, router);
    this.wsNotificationStore = new WsNotificationStore(
      notificationWsUrl || process.env.VUE_APP_NOTIFICATION_WEBSOCKET_URL!
    );
    this.wsChatStore = new WsChatStore(
      chatWsUrl || process.env.VUE_APP_CHAT_WEBSOCKET_URL!,
      chatApi
    );

    this.appointmentsStore = new AppointmentStore(new AppointmentApi(url, axiosInstance));
    this.maiveStore = new MaiveStore(maiveApi);
    this.unitStore = new UnitStore(unitApi, analyticsApi, this.notificationStore);
    this.applicationStore = new ApplicationStore(
      applicationApi,
      chatApi,
      unitApi,
      router,
      this.notificationStore,
      tagApi,
      this.unitStore,
      this.jobStore
    );
    this.dynamicProcessStore = new DynamicProcessStore(
      dynamicProcessApi,
      applicationApi,
      appointmentApi,
      this.notificationStore,
      this.userStore,
      this.unitStore,
      unitApi
    );
    this.userStore = new UserStore(
      userApi,
      router,
      this.notificationStore,
      this.applicationStore,
      this.jobStore,
      this.uiStore,
      this.dynamicProcessStore,
      firebaseAuth
    );
    this.wsApplicantStore = new WsApplicantStore(
      this.dynamicProcessStore,
      this.notificationStore,
      applicationApi,
      applicantsWsUrl || process.env.VUE_APP_APPLICATION_WEBSOCKET_URL!
    );
    this.businessDashboardStore = new BusinessDashboardStore(
      unitApi,
      jobApi,
      checklistApi,
      notificationsApi
    );
    this.postNewJobStore = new PostNewJobStore(jobApi);
    this.talentPoolStore = new TalentPoolStore(jobApi, unitApi);
    this.selectUnitEmploymentStore = new SelectUnitEmploymentStore(unitApi);
    this.businessUnitStore = new BusinessUnitStore(unitApi, listingApi, this.notificationStore);
    this.businessNavigationStore = new BusinessNavigationStore();
    this.businessJobEditorStore = new BusinessJobEditorStore(
      jobApi,
      unitApi,
      this.notificationStore,
      listingApi,
      jobSourcingApi,
      integrationApi
    );
    this.businessLeadsStore = new BusinessLeadsStore(jobApi);
    this.businessSettingsStore = new BusinessSettingsStore(
      unitApi,
      this.notificationStore,
      gdprApi
    );
    this.businessBlogStore = new BusinessBlogStore(blogApi, this.notificationStore);
    this.integrationStore = new IntegrationStore(integrationApi);
    this.facebookStore = new FacebookStore(facebookApi);
    this.pushNotificationStore = new PushNotificationStore(pushNotificationApi);
    this.attributeStore = new AttributeStore(attributeApi);
  }
}
