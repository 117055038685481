import BaseApi from './base.api';
import { IAttribute } from '../mj-models/attribute.interface';
import { IESPage } from '../mj-models/utils.interface';
import {
  ITalentAttribute,
  ITalentAttributeRate,
} from '../mj-models/user-attribute.interface';

export default class AttributeApi extends BaseApi {
  public getAttribute = (
    search_text: string,
    path_filter: string,
    level?: number
  ): Promise<IESPage<IAttribute>> => {
    return this.axios
      .get(
        `${
          this.baseUrl
        }user-attributes/attributes/?search_text=${search_text}&path_filter=${path_filter}${
          level ? `&level=${level}` : ''
        }`
      )
      .then((response) => response.data);
  };

  public createAttribute = (
    name: string,
    path: string,
    extraData: Partial<IAttribute>
  ): Promise<IAttribute> => {
    return this.axios
      .post(
        `${this.baseUrl}user-attributes/attribute/?create_in_path=${path}`,
        {
          name,
          name_en: name,
          name_uk: name,
          name_fi: name,
          name_nb: name,
          name_sv: name,
          ...extraData,
        }
      )
      .then((response) => response.data);
  };

  public getTalentAttributes = async (
    talent_id: number
  ): Promise<ITalentAttribute[]> => {
    return this.axios
      .get(`${this.baseUrl}user-attributes/${talent_id}/talent-attributes/`)
      .then((response) => response.data);
  };

  public bulkCreateTalentAttributes = (
    talentAttributes: ITalentAttribute[]
  ): Promise<ITalentAttribute[]> => {
    return this.axios
      .post(
        `${this.baseUrl}user-attributes/talent-attributes/`,
        talentAttributes
      )
      .then((response) => response.data);
  };

  public bulkPatchTalentAttributes = (
    talentAttributes: ITalentAttribute[]
  ): Promise<ITalentAttribute[]> => {
    return this.axios
      .patch(
        `${this.baseUrl}user-attributes/talent-attributes/`,
        talentAttributes
      )
      .then((response) => response.data);
  };

  public bulkCreateTalentAttributeRates = async (
    talentAttributeRates: ITalentAttributeRate[]
  ): Promise<ITalentAttributeRate[]> => {
    return this.axios
      .post(
        `${this.baseUrl}user-attributes/talent-attribute/rates/`,
        talentAttributeRates
      )
      .then((response) => response.data);
  };
}
