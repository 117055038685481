import { action, computed, observable, runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';

import ApplicationApi from '../mj-api/application.api';
import AppointmentApi from '../mj-api/appointment.api';
import DynamicProcessApi from '../mj-api/dynamic-process.api';
import UnitApi from '../mj-api/unit.api';
import { IApplication } from '../mj-models/application.interface';
import {
  IAppointment,
  IAppointmentSlot,
  IAppointmentsMicrosoftOutlookAccount,
} from '../mj-models/appointment.interface';
import { IAutomationRule } from '../mj-models/automation-rules.interface';
import { IUnitQuestionTemplate } from '../mj-models/business-settings.interface';
import { IUploadedQuestionFiles } from '../mj-models/media.interface';
import {
  IMotimateGroup,
  IMotimatePosition,
} from '../mj-models/motimate.interface';
import {
  APP_PREVIEW_TYPES,
  BaseProcessAppStaffingAssignmentInvitationResponse,
  IJobProcess,
  IProcessApp,
  IProcessStep,
  JobProcessAppChoices,
  JobProcessAppCv,
  JobProcessAppCvChoices,
  JobProcessAppNps,
  JobProcessAppQuestionAnswer,
  JobProcessAppReference,
  JobProcessAppScrive,
  JobProcessApplicationMove,
  QuestionType,
  ScreeningQuestionSuggestion,
} from '../mj-models/process.interface';
import { IReferenceQuestion } from '../mj-models/references.interface';
import { IBulkTags } from '../mj-models/tags.interface';
import { IPage } from '../mj-models/utils.interface';
import {
  fixProcess,
  isProcessValid,
} from '../mj-utils/process/validation.utils';
import INotificationStore, { NotificationStore } from './notification.store';
import IUnitStore from './unit.store';
import IUserStore from './user.store';

export default interface IDynamicProcessStore {
  process: IJobProcess;
  selectedApp: IProcessApp | null;
  jobProcessAppCv?: JobProcessAppCv;

  questionsJobProcessApp?: IProcessApp;
  jobProcessAppQuestionAnswers: JobProcessAppQuestionAnswer[];
  uploadedFilesByQuestionId: IUploadedQuestionFiles;
  userAttributesAnswers: JobProcessAppQuestionAnswer[];

  openedStepId: number | null;
  focusedStepId: number | null;

  currentScrollYPosition: number;

  motimateOrganisationId?: number;
  motimateUsesPublicApi?: boolean | null;
  motimateGroups: IMotimateGroup[];
  motimatePositions: IMotimatePosition[];

  appPreviewSelectedApplication: IApplication | null;
  appPreviewType: APP_PREVIEW_TYPES | null;
  appPreviewId: number | null;
  boardColumnsToBeReloaded: number[];

  hitBottomOfJobEditorPage: boolean;

  // Planday
  plandayDepartments: any[];
  plandayDepartmentsLimit: number;
  plandayDepartmentsOffset: number;
  plandayDepartmentsTotal: number;
  plandayDepartmentsIsLoading: boolean;
  plandayEmployeeGroups: any[];
  plandayEmployeeGroupsLimit: number;
  plandayEmployeeGroupsOffset: number;
  plandayIntegrationId: number;
  plandayEmployeeGroupsTotal: number;
  plandayEmployeeGroupIsLoading: boolean;
  onFetchMorePlandayDepartments: (unitId: number) => Promise<void>;
  onFetchMorePlandayEmployeeGroups: (unitId: number) => Promise<void>;
  addTagToApplications: (userTags: any) => Promise<boolean>;
  onFetchInitialPlandayData: (
    unitId: number,
    plandayIntegrationId: number
  ) => Promise<void>;

  // Learnifier
  learnifierProjects: any[];
  learnifierIntegrationId: number;

  // Recman
  recManQuestions: ScreeningQuestionSuggestion[];
  onFetchRecmanQuestions: (unitId: number) => Promise<void>;

  isLoading: boolean;

  fetchSuggestedQuestions: (
    unitId: number
  ) => Promise<ScreeningQuestionSuggestion[] | undefined>;
  fetchDynamicProcess: (
    jobId: number,
    rejectedStepFirst?: boolean,
    autoCreateOn404?: boolean,
    applyProcess?: boolean
  ) => Promise<IJobProcess | undefined>;
  fetchDynamicProcessSteps: (
    jobId: number
  ) => Promise<IProcessStep[] | undefined>;
  patchProcess: (process: IJobProcess) => Promise<IJobProcess | undefined>;
  createDynamicProcess: (
    jobId: number,
    applyProcess?: boolean
  ) => Promise<IJobProcess | undefined>;
  updateDynamicProcess: (
    process: IJobProcess
  ) => Promise<IJobProcess | undefined>;
  getApp: (appId: number) => Promise<IProcessApp | undefined>;
  cloneApp: (appId: number) => Promise<IProcessApp | undefined>;
  selectApp: (app: any) => void;
  deleteApp: (appId: number) => Promise<boolean>;
  addApp: (app: any) => void;
  updateApp: (app: any) => Promise<IProcessApp | undefined>;
  fetchApplicationsFromStepId: (
    jobId: number,
    stepId: number
  ) => Promise<IPage<IApplication> | null>;
  getStep: (stepId: number) => Promise<IProcessStep | undefined>;
  deleteStep: (stepId: number) => any;
  patchStep: (
    stepId: number,
    step: IProcessStep
  ) => Promise<IProcessStep | undefined>;
  setBoardColumnsToBeUpdated: (
    applicationMoves: JobProcessApplicationMove[]
  ) => void;
  addStep: (step: IProcessStep) => void;
  fetchPublicJobProcess: (jobId: number) => Promise<boolean>;
  fetchAppQuestionAnswers: (
    appId: number,
    applicationId: number
  ) => Promise<JobProcessAppQuestionAnswer[]>;
  deliverQuestionApp: (
    appId: number,
    answers: JobProcessAppQuestionAnswer[]
  ) => Promise<boolean>;
  deliverStaffingAssignmentInvitationResponse: (
    appId: number,
    response: BaseProcessAppStaffingAssignmentInvitationResponse
  ) => Promise<boolean>;

  updateApplicationAnswer: ($event: {
    value: JobProcessAppQuestionAnswer;
    index: number;
  }) => void;

  moveApplications: (
    jobId: number,
    applicationMoves: JobProcessApplicationMove[]
  ) => Promise<boolean>;
  updateDelayedApplicationMove: (delayedApplicationMoveUpdate: {
    id: number;
    delayed_move_stopped: boolean;
  }) => Promise<boolean>;
  getBaseUrl: () => any;
  createAppointment: (
    appointment: IAppointment
  ) => Promise<IAppointment | undefined>;
  updateAppointment: (
    appointment: IAppointment
  ) => Promise<IAppointment | undefined>;
  getAppointment: (id: number) => Promise<IAppointment | undefined>;
  getMicrosoftOutlookConnections: (
    unitId: number,
    searchTerm?: string
  ) => Promise<IAppointmentsMicrosoftOutlookAccount[]>;
  createMicrosoftOutlookConnection: (
    connection: IAppointmentsMicrosoftOutlookAccount
  ) => Promise<IAppointmentsMicrosoftOutlookAccount | undefined>;
  getMagicToken: () => Promise<any>;
  setMotimateOrganisationId: (motimateOrganisationId: number) => void;
  fetchMotimateGroups: () => Promise<boolean>;
  fetchMotimatePositions: () => Promise<boolean>;

  openAppPreview: (
    previewType: APP_PREVIEW_TYPES,
    application: IApplication,
    appId: number
  ) => void;

  fetchTimeSlots: (appointment_id: number) => Promise<IAppointmentSlot[]>;
  updateTimeSlot: (
    slots: IAppointmentSlot
  ) => Promise<IAppointmentSlot | false>;
  removeTimeSlot: (slot: IAppointmentSlot) => Promise<boolean>;
  createTimeSlot: (slot: IAppointmentSlot) => Promise<IAppointmentSlot | false>;
  cancelTimeSlot: (slot: IAppointmentSlot) => Promise<IAppointmentSlot | false>;
  cancelTimeSlotForApplication: (
    slot: IAppointmentSlot,
    applicationId: number
  ) => Promise<IAppointmentSlot | false>;
  createAutomationRule: (
    automationRule: IAutomationRule
  ) => Promise<IAutomationRule | undefined>;

  i18n: any;
  hasScreeningQuestions: boolean;
  EMPTY_CV_APP: IProcessApp;
  EMPTY_STEP: IProcessStep;
  EMPTY_MESSAGE_APP: IProcessApp;
  EMPTY_QUESTIONS_APP: IProcessApp;
  EMPTY_AUTOMATION_RULES_APP: IProcessApp;
  EMPTY_VIDEO_APP: IProcessApp;
  EMPTY_MOTIMATE_APP: IProcessApp;
  EMPTY_INTERVIEW_APP: IProcessApp;
  EMPTY_NOTE_MESSAGE_APP: IProcessApp;
  EMPTY_RECMAN_APP: IProcessApp;
  EMPTY_LEARNIFIER_APP: IProcessApp;
  EMPTY_PLANDAY_APP: IProcessApp;
  EMPTY_REFERENCE_APP: IProcessApp;
  EMPTY_NPS_APP: IProcessApp;
  EMPTY_REASON_FOR_DECLINE_APP: IProcessApp;
  EMPTY_SCRIVE_APP: IProcessApp;
  DEFAULT_PROCESS: IJobProcess;
}

export class DynamicProcessStore implements IDynamicProcessStore {
  private static extractQuestionsApp(process: IJobProcess): IProcessApp {
    return process!
      .base_process!.steps!.filter((step) => step.position === 0)[0]
      .apps!.filter(
        (app) =>
          app.app_type === JobProcessAppChoices.PROCESS_APP_SCREENING_QUESTIONS
      )[0];
  }

  private static extractCvApp(process: IJobProcess): JobProcessAppCv {
    return process!
      .base_process!.steps!.filter((step) => step.position === 0)[0]
      .apps!.filter(
        (app) => app.app_type === JobProcessAppChoices.PROCESS_APP_CHOICE_CV
      )[0].cv_app!;
  }

  @observable public process: IJobProcess = { base_process: { steps: [] } };
  @observable public selectedApp: IProcessApp | null = null;
  @observable public jobProcessAppCv?: JobProcessAppCv;

  @observable public questionsJobProcessApp?: IProcessApp;
  @observable
  public jobProcessAppQuestionAnswers: JobProcessAppQuestionAnswer[] = [];
  @observable
  public userAttributesAnswers: JobProcessAppQuestionAnswer[] = [];
  @observable public uploadedFilesByQuestionId: IUploadedQuestionFiles = {};
  @observable public motimateOrganisationId?: number = undefined;
  @observable public motimateUsesPublicApi?: boolean | null = null;
  @observable public motimateGroups: IMotimateGroup[] = [];
  @observable public motimatePositions: IMotimatePosition[] = [];
  @observable public appPreviewSelectedApplication: IApplication | null = null;
  @observable public appPreviewType: APP_PREVIEW_TYPES | null = null;
  @observable public appPreviewId: number | null = null;
  @observable public boardColumnsToBeReloaded: number[] = [];
  @observable public isLoading: boolean = false;
  @observable public openedStepId: number | null = null;
  @observable public focusedStepId: number | null = null;
  @observable public currentScrollYPosition: number = 0;

  @observable public hitBottomOfJobEditorPage: boolean = false;

  // Planday
  @observable public plandayDepartments: any[] = [];
  @observable public plandayDepartmentsLimit: number = 50;
  @observable public plandayDepartmentsOffset: number = 0;
  @observable public plandayDepartmentsTotal: number = 0;
  @observable public plandayDepartmentsIsLoading: boolean = false;
  @observable public plandayEmployeeGroups: any[] = [];
  @observable public plandayEmployeeGroupsLimit: number = 50;
  @observable public plandayEmployeeGroupsOffset: number = 0;
  @observable public plandayIntegrationId: number = 0;
  @observable public plandayEmployeeGroupsTotal: number = 0;
  @observable public plandayEmployeeGroupIsLoading: boolean = false;

  // Learnifier
  @observable public learnifierProjects: any[] = [];
  @observable public learnifierIntegrationId: number = 0;

  // RecMan
  @observable public recManQuestions: ScreeningQuestionSuggestion[] = [];

  public i18n: any;

  public dynamicProcessApi: DynamicProcessApi;
  public applicationApi: ApplicationApi;
  public notificationStore: NotificationStore;
  public userStore: IUserStore;
  public unitStore: IUnitStore;
  public appointmentApi: AppointmentApi;
  public unitApi: UnitApi;

  constructor(
    dynamicProcessApi: DynamicProcessApi,
    applicationApi: ApplicationApi,
    appointmentApi: AppointmentApi,
    notificationStore: INotificationStore,
    userStore: IUserStore,
    unitStore: IUnitStore,
    unitApi: UnitApi
  ) {
    this.dynamicProcessApi = dynamicProcessApi;
    this.applicationApi = applicationApi;
    this.notificationStore = notificationStore;
    this.userStore = userStore;
    this.appointmentApi = appointmentApi;
    this.unitStore = unitStore;
    this.unitApi = unitApi;
  }

  @action('FETCH SUGGESTED QUESTIONS')
  public fetchSuggestedQuestions = async (
    unitId: number
  ): Promise<ScreeningQuestionSuggestion[] | undefined> => {
    try {
      const response = await this.unitApi.fetchQuestionsTemplates(unitId);

      const suggestedQuestions = response.results.map(
        (template: IUnitQuestionTemplate) =>
          ({
            uuid: uuid(),
            ...template.question,
          } as ScreeningQuestionSuggestion)
      );

      return suggestedQuestions;
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH RECMAN QUESTIONS')
  public onFetchRecmanQuestions = async (unitId: number): Promise<void> => {
    const recManQuestions = await this.unitStore.fetchUnitRecManAttributes(
      unitId
    );
    this.recManQuestions = recManQuestions.map(
      (question: ScreeningQuestionSuggestion) => {
        question.uuid = uuid();
        return question;
      }
    );
  };

  @action('FETCH INITIAL PLANDAY DATA')
  public onFetchInitialPlandayData = async (
    unitId: number,
    plandayIntegrationId: number
  ): Promise<void> => {
    this.plandayDepartmentsIsLoading = true;
    this.plandayEmployeeGroupIsLoading = true;
    this.plandayIntegrationId = plandayIntegrationId;
    this.plandayDepartmentsOffset = 0;
    this.plandayEmployeeGroupsOffset = 0;
    const plandayDepartmentsPage =
      await this.unitStore.fetchUnitPlandayDepartments(
        unitId,
        this.plandayDepartmentsLimit,
        this.plandayDepartmentsOffset
      );
    this.plandayDepartmentsTotal = plandayDepartmentsPage.paging.total;
    this.plandayDepartments = plandayDepartmentsPage.data;
    const plandayEmployeeGroupsPage =
      await this.unitStore.fetchUnitPlandayEmployeeGroups(
        unitId,
        this.plandayEmployeeGroupsLimit,
        this.plandayEmployeeGroupsOffset
      );
    this.plandayEmployeeGroups = plandayEmployeeGroupsPage.data;
    this.plandayEmployeeGroupsTotal = plandayEmployeeGroupsPage.paging.total;
    this.plandayDepartmentsOffset += this.plandayDepartmentsLimit;
    this.plandayEmployeeGroupsOffset += this.plandayEmployeeGroupsLimit;
    this.plandayDepartmentsIsLoading = false;
    this.plandayEmployeeGroupIsLoading = false;
  };

  @action('FETCH FORE PLANDAY DEPARTMENTS')
  public onFetchMorePlandayDepartments = async (
    unitId: number
  ): Promise<void> => {
    if (
      this.plandayDepartmentsIsLoading ||
      this.plandayDepartmentsTotal <= this.plandayDepartments.length
    ) {
      return;
    }
    this.plandayDepartmentsIsLoading = true;
    const newPlandayDepartmentsPage =
      await this.unitStore.fetchUnitPlandayDepartments(
        unitId,
        this.plandayDepartmentsLimit,
        this.plandayDepartmentsOffset
      );
    this.plandayDepartments = [
      ...this.plandayDepartments,
      ...newPlandayDepartmentsPage.data,
    ];
    this.plandayDepartmentsOffset += this.plandayDepartmentsLimit;
    this.plandayDepartmentsIsLoading = false;
  };
  @action('FETCH MORE PLANDAY EMPLOYEE GROUPS')
  public onFetchMorePlandayEmployeeGroups = async (
    unitId: number
  ): Promise<void> => {
    if (
      this.plandayEmployeeGroupIsLoading ||
      this.plandayEmployeeGroupsTotal <= this.plandayEmployeeGroups.length
    ) {
      return;
    }
    this.plandayEmployeeGroupIsLoading = true;
    const newEmployeeGroupsPage =
      await this.unitStore.fetchUnitPlandayEmployeeGroups(
        unitId,
        this.plandayEmployeeGroupsLimit,
        this.plandayEmployeeGroupsOffset
      );
    this.plandayEmployeeGroups = [
      ...this.plandayEmployeeGroups,
      ...newEmployeeGroupsPage.data,
    ];
    this.plandayEmployeeGroupsOffset += this.plandayEmployeeGroupsLimit;
    this.plandayEmployeeGroupIsLoading = false;
  };

  @action('OPEN APP PREVIEW')
  public openAppPreview(
    previewType: APP_PREVIEW_TYPES,
    application: IApplication,
    appId: number
  ) {
    this.appPreviewSelectedApplication = application;
    this.appPreviewId = appId;
    this.appPreviewType = previewType;
  }

  @action('SET BOARD COLUMNS TO BE UPDATED')
  public setBoardColumnsToBeUpdated(
    applicationMoves: JobProcessApplicationMove[]
  ) {
    const toBeReloadedResult: number[] = (this.boardColumnsToBeReloaded = []);

    applicationMoves.forEach((moved) => {
      const fromStepId: number = moved.application!.process_step!.id!;

      if (
        toBeReloadedResult.findIndex((element) => element === fromStepId) === -1
      ) {
        toBeReloadedResult.push(fromStepId);
      }
    });

    this.boardColumnsToBeReloaded = toBeReloadedResult;
  }

  @action('MOVE APPLICATIONS')
  public moveApplications = async (
    jobId: number,
    applicationMoves: JobProcessApplicationMove[]
  ): Promise<boolean> => {
    try {
      await this.dynamicProcessApi.moveApplications(jobId, applicationMoves);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('UPDATE DELAYED APPLICATION MOVE')
  public updateDelayedApplicationMove = async (delayedApplicationMoveUpdate: {
    id: number;
    delayed_move_stopped: boolean;
  }): Promise<boolean> => {
    try {
      await this.dynamicProcessApi.updateDelayedApplicationMove(
        delayedApplicationMoveUpdate
      );
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('ADD TAG APPLICATIONS')
  public addTagToApplications = async (
    userTags: IBulkTags
  ): Promise<boolean> => {
    try {
      await this.dynamicProcessApi.addTagToApplications(userTags);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('GET APP')
  public getApp = async (appId: number): Promise<IProcessApp | undefined> => {
    try {
      return await this.dynamicProcessApi.getApp(appId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('CLONE APP')
  public cloneApp = async (appId: number): Promise<IProcessApp | undefined> => {
    try {
      return await this.dynamicProcessApi.cloneApp(appId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('FETCH APP QUESTION ANSWERS')
  public fetchAppQuestionAnswers = async (
    appId: number,
    applicationId: number
  ): Promise<JobProcessAppQuestionAnswer[]> => {
    try {
      const page = await this.dynamicProcessApi.getAppQuestionAnswers(
        appId,
        applicationId
      );
      return page.results;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return [];
    }
  };

  @action('DELIVER QUESTION APP')
  public deliverQuestionApp = async (
    appId: number,
    answers: JobProcessAppQuestionAnswer[]
  ): Promise<boolean> => {
    try {
      await this.dynamicProcessApi.deliverApp(appId, answers);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('DELIVER STAFFING ASSIGNMENT INVITATION RESPONSE')
  public deliverStaffingAssignmentInvitationResponse = async (
    appId: number,
    response: BaseProcessAppStaffingAssignmentInvitationResponse
  ): Promise<boolean> => {
    try {
      await this.dynamicProcessApi.deliverApp(appId, [response]);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('PATCH PROCESS')
  public patchProcess = async (
    process: IJobProcess
  ): Promise<IJobProcess | undefined> => {
    try {
      return await this.dynamicProcessApi.patchJobProcess(process);
    } catch (err) {
      return undefined;
    }
  };

  @action('FETCH DYNAMIC PROCESS')
  public fetchDynamicProcess = async (
    jobId: number,
    rejectedStepFirst = false,
    autoCreateOn404 = true,
    applyProcess = true
  ): Promise<IJobProcess | undefined> => {
    try {
      this.isLoading = true;
      let process: any = await this.dynamicProcessApi.fetchJobProcess(jobId);
      if (rejectedStepFirst && process.base_process!.steps) {
        process = {
          ...process,
          steps: process.base_process!.steps.sort(
            (a: IProcessStep, b: IProcessStep) => {
              return a.is_failure ? -1 : a.position! < b.position! ? -1 : 0;
            }
          ),
        };
      }
      if (applyProcess) {
        this.process = process;
      }
      this.isLoading = false;

      return process;
    } catch (err) {
      if (err.response.status === 404 && autoCreateOn404) {
        return await this.createDynamicProcess(jobId, applyProcess);
      } else {
        this.notificationStore.notifyAboutError(err);
      }
      this.isLoading = false;
      return undefined;
    }
  };

  @action('FETCH DYNAMIC PROCESS STEPS')
  public fetchDynamicProcessSteps = async (
    jobId: number
  ): Promise<IProcessStep[] | undefined> => {
    try {
      const response = await this.dynamicProcessApi.fetchJobProcessSteps(jobId);
      return response?.base_process!.steps ? response.base_process!.steps : [];
    } catch (err) {
      this.notificationStore.notifyAboutError(err);
      return undefined;
    }
  };

  @action('UPDATE DYNAMIC PROCESS')
  public updateDynamicProcess = async (
    process: IJobProcess
  ): Promise<IJobProcess | undefined> => {
    try {
      this.isLoading = true;
      const updatedProcess = await this.dynamicProcessApi.updateJobProcess(
        isProcessValid(process) ? process : fixProcess(process)
      );
      this.isLoading = false;

      return updatedProcess;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      this.isLoading = false;
      return undefined;
    }
  };

  @action('CREATE DYNAMIC PROCESS')
  public createDynamicProcess = async (
    jobId: number,
    applyProcess = true
  ): Promise<IJobProcess | undefined> => {
    // create new process if it doesnt exist
    try {
      const defaultProcess: IJobProcess = this.DEFAULT_PROCESS;
      const process: IJobProcess =
        await this.dynamicProcessApi.createJobProcess({
          ...defaultProcess,
          job_id: jobId,
        });
      if (applyProcess) {
        this.process = process;
      }
      return process;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('SELECT APP')
  public selectApp = (app: any) => {
    runInAction(() => {
      this.selectedApp = app;
    });
  };

  @action('UPDATE APP')
  public updateApp = async (app: any): Promise<IProcessApp | undefined> => {
    try {
      return await this.dynamicProcessApi.updateApp(app);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('DELETE APP')
  public deleteApp = async (appId: number): Promise<boolean> => {
    try {
      await this.dynamicProcessApi.deleteApp(appId);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('DELETE STEP')
  public fetchApplicationsFromStepId = async (
    jobId: number,
    stepId: number
  ) => {
    try {
      return await this.applicationApi.getApplicationsByProcessStepId(
        jobId,
        '',
        '',
        stepId,
        1
      );
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return null;
    }
  };

  @action('GET STEP')
  public getStep = async (
    stepId: number
  ): Promise<IProcessStep | undefined> => {
    try {
      return await this.dynamicProcessApi.getStep(stepId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('DELETE STEP')
  public deleteStep = (stepId: number): any => {
    this.dynamicProcessApi.deleteStep(stepId).then((newApp: any) => {
      // refresh process
      this.fetchDynamicProcess(this.process.job_id!);
    });
  };

  @action('PATCH STEP')
  public patchStep = async (
    id: number,
    value: any
  ): Promise<IProcessStep | undefined> => {
    try {
      return await this.dynamicProcessApi.patchStep(id, value);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('ADD PROCESS STEP')
  public addStep = (step: IProcessStep) => {
    this.dynamicProcessApi
      .updateJobProcess({
        ...this.process,
        base_process: {
          ...this.process.base_process!,
          steps: [...this.process!.base_process!.steps!, step],
        },
      })
      .then((newStep: any) => {
        // refresh process
        this.fetchDynamicProcess(this.process.job_id!);
      });
  };

  @action('ADD APP TO PROCESS')
  public addApp = (app: any) => {
    this.dynamicProcessApi.addApp(app).then((newStep: any) => {
      // refresh process
      this.fetchDynamicProcess(this.process.job_id!);
    });
  };

  @action('SET MOTIMATE ORGANISATION ID')
  public setMotimateOrganisationId = (motimateOrganisationId: number) => {
    this.motimateOrganisationId = motimateOrganisationId;
  };

  @action('CREATE MICROSOFT OUTLOOK CONNECTIONS')
  public createMicrosoftOutlookConnection = async (
    connection: IAppointmentsMicrosoftOutlookAccount
  ): Promise<IAppointmentsMicrosoftOutlookAccount | undefined> => {
    try {
      return await this.appointmentApi.createMicrosoftOutlookConnections(
        connection
      );
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('GET MICROSOFT OUTLOOK CONNECTIONS')
  public getMicrosoftOutlookConnections = async (
    unitId: number,
    searchTerm = ''
  ): Promise<IAppointmentsMicrosoftOutlookAccount[]> => {
    try {
      const page = await this.appointmentApi.getMicrosoftOutlookConnections(
        unitId,
        searchTerm
      );
      return page.results;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return [];
    }
  };

  @action('CREATE APPOINTMENT')
  public createAppointment = async (
    appointment: IAppointment
  ): Promise<IAppointment | undefined> => {
    try {
      return this.appointmentApi.createAppointment(appointment);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('UPDATE APPOINTMENT')
  public updateAppointment = async (
    appointment: IAppointment
  ): Promise<IAppointment | undefined> => {
    try {
      return this.appointmentApi.updateAppointment(appointment);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('GET APPOINTMENT')
  public getAppointment = async (
    id: number
  ): Promise<IAppointment | undefined> => {
    try {
      return this.appointmentApi.getAppointment(id);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('CREATE TIMESLOT')
  public createTimeSlot = async (
    slot: IAppointmentSlot
  ): Promise<IAppointmentSlot | false> => {
    try {
      return await this.appointmentApi.createAppointmentSlot(slot);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('CANCEL TIMESLOT')
  public cancelTimeSlot = async (
    slot: IAppointmentSlot
  ): Promise<IAppointmentSlot | false> => {
    try {
      return await this.appointmentApi.cancelAppointmentSlot(slot);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('CANCEL TIMESLOT OF APPLICATION')
  public cancelTimeSlotForApplication = async (
    slot: IAppointmentSlot,
    applicationId: number
  ): Promise<IAppointmentSlot | false> => {
    try {
      return await this.appointmentApi.cancelAppointmentSlotForApplication(
        slot,
        applicationId
      );
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('FETCH TIMESLOTS')
  public fetchTimeSlots = async (
    appointment_id: number
  ): Promise<IAppointmentSlot[]> => {
    try {
      const response = await this.appointmentApi.getAppointmentSlots(
        appointment_id
      );
      return response.results;
    } catch (error) {
      return [];
    }
  };

  @action('UPDATE TIME SLOT')
  public updateTimeSlot = async (
    slot: IAppointmentSlot
  ): Promise<IAppointmentSlot | false> => {
    try {
      return await this.appointmentApi.updateAppointmentSlot(slot);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('REMOVE TIME SLOT')
  public removeTimeSlot = async (slot: IAppointmentSlot): Promise<boolean> => {
    try {
      await this.appointmentApi.deleteAppointmentSlot(slot);
      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('FETCH JOB PROCESS')
  public fetchPublicJobProcess = async (jobId: number): Promise<boolean> => {
    try {
      try {
        this.process = await this.dynamicProcessApi.fetchPublicJobProcess(
          jobId
        );
      } catch (error) {
        // token has expired but we still can fetch since the endpoint is public
        if (error.response && error.response.status === 401) {
          // remove expired token to avoid middleware responding with HTTP 401
          await this.userStore.logout();
          // fetch once again without the expired token
          this.process = await this.dynamicProcessApi.fetchPublicJobProcess(
            jobId
          );
        }
      }

      this.jobProcessAppCv = DynamicProcessStore.extractCvApp(this.process);
      this.questionsJobProcessApp = DynamicProcessStore.extractQuestionsApp(
        this.process
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('FETCH MOTIMATE GROUPS')
  public fetchMotimateGroups = async (): Promise<boolean> => {
    try {
      if (this.motimateUsesPublicApi) {
        const res = await this.dynamicProcessApi.fetchMotimateGroupsPublicApi(
          this.motimateOrganisationId!
        );
        this.motimateGroups = res.data;
      } else {
        const res = await this.dynamicProcessApi.fetchMotimateGroups(
          this.motimateOrganisationId!
        );
        this.motimateGroups = res.Resources.map((resource: any) => {
          return {
            external_id: resource.externalId,
            name: resource.displayName,
          } as IMotimateGroup;
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('FETCH MOTIMATE POSITIONS')
  public fetchMotimatePositions = async (): Promise<boolean> => {
    try {
      const res = await this.dynamicProcessApi.fetchMotimatePositionsPublicApi(
        this.motimateOrganisationId!
      );
      this.motimatePositions = res.data;
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('GET MAGIC TOKEN')
  public getMagicToken = async (): Promise<any> => {
    try {
      const resp = await this.dynamicProcessApi.getMagicToken();
      return resp;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  @action('UPDATE APPLICATION ANSWERS')
  public updateApplicationAnswer = ($event: {
    value: JobProcessAppQuestionAnswer;
    index: number;
  }) => {
    const newAnswers = [...this.jobProcessAppQuestionAnswers];
    newAnswers[$event.index] = {
      application_id: $event.value.application_id,
      question_id: $event.value.question_id,
      answer_text: $event.value.answer_text,
      alternative_ids: $event.value.alternative_ids,
      attribute_ids: $event.value.attribute_ids,
      type: $event.value.type,
      file_ids: $event.value.file_ids,
    };
    this.jobProcessAppQuestionAnswers = [...newAnswers];
  };

  @action('CREATE AUTOMATION RULE')
  public createAutomationRule = async (
    automationRule: IAutomationRule
  ): Promise<IAutomationRule | undefined> => {
    try {
      return await this.dynamicProcessApi.createAutomationRule(automationRule);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  public getBaseUrl = () => this.dynamicProcessApi.getBaseUrl();

  @computed
  public get hasScreeningQuestions(): boolean {
    return !!(
      this.questionsJobProcessApp &&
      this.questionsJobProcessApp.question_apps &&
      this.questionsJobProcessApp.question_apps.length > 0
    );
  }

  @computed
  public get EMPTY_CV_APP(): IProcessApp {
    return {
      cv_app: {
        id: undefined,
        process_app_id: undefined,
        app_type: JobProcessAppChoices.PROCESS_APP_CHOICE_CV,
        email: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_REQUIRED,
        name: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_REQUIRED,
        birth_date: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        gender: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_NOT_NEEDED,
        location: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        photo: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        phone_number: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        education: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        experience: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        cv_file_attached: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        about_me: JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
        social_media_contact:
          JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_OPTIONAL,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_CHOICE_CV,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_RECMAN_APP() {
    return {
      recman_app: {
        id: undefined,
        process_app_id: undefined,
        recman_api_key: '',
        recman_corporation_id: '',
        email: true,
        name: true,
        birth_date: true,
        location: true,
        photo: true,
        phone_number: true,
        education: true,
        experience: true,
        cv_file_attached: true,
        recman_attributes: true,
        about_me: true,
        social_media_contact: true,
        notes: true,
        video_interviews: true,
        motimate_courses: true,
        reference_survey: true,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_RECMAN_INTEGRATION,
    };
  }

  @computed
  public get EMPTY_LEARNIFIER_APP() {
    return {
      learnifier_app: {
        id: undefined,
        process_app_id: undefined,
        projects: [],
        learnifier_integration_id: undefined,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_LEARNIFIER,
    };
  }

  @computed
  public get EMPTY_PLANDAY_APP() {
    return {
      planday_app: {
        id: undefined,
        process_app_id: undefined,
        department_name: '',
        department_id: undefined,
        employee_groups: [],
        planday_integration_id: undefined,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_PLANDAY,
    };
  }

  @computed
  public get EMPTY_SCRIVE_APP() {
    return {
      scrive_app: {
        id: undefined,
        process_app_id: undefined,
        signing_confirmation_email: '',
        signing_invitation_email: '',
        attachment_ids: [],
        attachments: [],
        recipients: [],
        title: '',
        file: undefined,
        scrive_file_id: '',
        scrive_file_name: '',
        scrive_integration: undefined,
        use_mojob_integration: false,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_SCRIVE,
    };
  }

  @computed
  public get EMPTY_STEP() {
    return {
      apps: [this.EMPTY_AUTOMATION_RULES_APP],
      name: 'New Process Step',
      color: '#2e2e2e',
      hide_step_name: false,
    } as IProcessStep;
  }

  @computed
  public get EMPTY_MESSAGE_APP() {
    return {
      message_app: {
        app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
        text: '',
        attachment_id: undefined,
        attachment_name: undefined,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_REASON_FOR_DECLINE_APP() {
    return {
      reason_for_decline_app: {
        app_type: JobProcessAppChoices.PROCESS_APP_REASON_FOR_DECLINE,
        message_text: '',
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_REASON_FOR_DECLINE,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_QUESTIONS_APP() {
    return {
      question_apps: [],
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_SCREENING_QUESTIONS,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_AUTOMATION_RULES_APP() {
    return {
      automation_rule_apps: [],
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_AUTOMATION_RULE,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_VIDEO_APP() {
    return {
      question_apps: [],
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_VIDEO_INTERVIEW,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_MOTIMATE_APP() {
    return {
      motimate_app: {
        app_type: JobProcessAppChoices.PROCESS_APP_MOTIMATE,
        external_id: undefined,
        motimate_group_id: undefined,
        motimate_position_id: undefined,
        position_name: '',
        name: '',
        motimate_organisation_id: undefined,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_MOTIMATE,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_INTERVIEW_APP() {
    return {
      onsite_interview_app: {
        app_type: JobProcessAppChoices.PROCESS_APP_ON_SITE_INTERVIEW,
        appointment_id: undefined,
        appointment: {
          max_attendees: 1,
          duration: 3600, // 1 hour in seconds
          microsoft_outlook_accounts: [],
        },
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_ON_SITE_INTERVIEW,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_REFERENCE_APP() {
    return {
      reference_app: {
        app_type: JobProcessAppChoices.PROCESS_APP_REFERENCE,
        num_contacts: 3,
        num_days_to_provide_contacts: 3,
        reference_questions: [
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__1_title'),
            type: QuestionType.SINGLE_CHOICE,
            alternatives: [
              {
                position: 0,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__1_alternative_1'),
              },
              {
                position: 1,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__1_alternative_2'),
              },
              {
                position: 2,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__1_alternative_3'),
              },
              {
                position: 3,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__1_alternative_4'),
              },
            ],
            position: 0,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__2_title'),
            type: QuestionType.SINGLE_CHOICE,
            alternatives: [
              {
                position: 0,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__2_alternative_1'),
              },
              {
                position: 1,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__2_alternative_2'),
              },
              {
                position: 2,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__2_alternative_3'),
              },
              {
                position: 3,
                answer_text:
                  this.i18n &&
                  this.i18n.t('default_reference_question__2_alternative_4'),
              },
            ],
            position: 1,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__3_title'),
            type: QuestionType.FREE_TEXT,
            alternatives: [],
            position: 2,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__4_title'),
            type: QuestionType.FREE_TEXT,
            alternatives: [],
            position: 2,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__5_title'),
            type: QuestionType.SCALE,
            alternatives: [],
            scale_from: 1,
            scale_to: 5,
            position: 3,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__6_title'),
            type: QuestionType.SCALE,
            alternatives: [],
            scale_from: 1,
            scale_to: 5,
            position: 4,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__7_title'),
            type: QuestionType.SCALE,
            alternatives: [],
            scale_from: 1,
            scale_to: 5,
            position: 5,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__8_title'),
            type: QuestionType.SCALE,
            alternatives: [],
            scale_from: 1,
            scale_to: 5,
            position: 6,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__9_title'),
            type: QuestionType.SCALE,
            alternatives: [],
            scale_from: 1,
            scale_to: 5,
            position: 7,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__10_title'),
            type: QuestionType.SCALE,
            alternatives: [],
            scale_from: 1,
            scale_to: 5,
            position: 8,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__11_title'),
            type: QuestionType.SCALE,
            alternatives: [],
            scale_from: 1,
            scale_to: 5,
            position: 9,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__12_title'),
            type: QuestionType.FREE_TEXT_SINGLE_LINE,
            alternatives: [],
            position: 10,
          },
          {
            id: uuid(),
            expanded: false,
            question_text:
              this.i18n && this.i18n.t('default_reference_question__13_title'),
            type: QuestionType.YES_NO,
            alternatives: [
              {
                position: 0,
                answer_text: this.i18n && this.i18n.t('generic_yes'),
              },
              {
                position: 1,
                answer_text: this.i18n && this.i18n.t('generic_no'),
              },
            ],
            position: 11,
          },
        ],
      } as JobProcessAppReference,
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_REFERENCE,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_NPS_APP() {
    return {
      nps_app: {
        app_type: JobProcessAppChoices.PROCESS_APP_NPS,
        question_text: this.i18n.t('default_nps_app_question'),
        message_text: this.i18n.t('default_nps_app_message'),
        survey_delay_in_days: 1,
      } as JobProcessAppNps,
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_NPS,
    } as IProcessApp;
  }

  @computed
  public get EMPTY_NOTE_MESSAGE_APP() {
    return {
      note_message_app: {
        app_type: JobProcessAppChoices.PROCESS_APP_NOTE_MESSAGE,
        text: '',
        attachment_id: undefined,
        attachment_name: undefined,
      },
      position: undefined,
      process_step_id: undefined,
      app_type: JobProcessAppChoices.PROCESS_APP_NOTE_MESSAGE,
    } as IProcessApp;
  }

  @computed
  public get DEFAULT_PROCESS(): IJobProcess {
    return {
      job_id: 0,
      base_process: {
        steps: [
          {
            apps: [
              {
                question_apps: [],
                cv_app: { ...this.EMPTY_CV_APP.cv_app } as JobProcessAppCv,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_CHOICE_CV,
                position: 0,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                cv_app: undefined,
                message_app: {
                  text:
                    this.i18n &&
                    this.i18n.t(
                      'application_status_applied_message_placeholder.message'
                    ),
                },
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
                position: 1,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                automation_rule_apps: [],
                cv_app: undefined,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_AUTOMATION_RULE,
                position: 2,
                publisher_linked: undefined,
              },
            ],
            position: 0,
            name: this.i18n && this.i18n.t('generic__applied.message'),
            color: '#2e2e2e',
            is_failure: false,
            is_success: false,
            is_waiting_list: false,
          },
          {
            apps: [
              {
                question_apps: [],
                cv_app: undefined,
                message_app: {
                  text:
                    this.i18n &&
                    this.i18n.t(
                      'application_status_qualified_message_placeholder.message'
                    ),
                },
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
                position: 0,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                automation_rule_apps: [],
                cv_app: undefined,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_AUTOMATION_RULE,
                position: 1,
                publisher_linked: undefined,
              },
            ],
            position: 1,
            name: this.i18n && this.i18n.t('application_status_qualified'),
            color: '#00857b',
            is_failure: false,
            is_success: false,
            is_waiting_list: false,
          },
          {
            apps: [
              {
                question_apps: [],
                cv_app: undefined,
                message_app: {
                  text:
                    this.i18n &&
                    this.i18n.t(
                      'application_status_interview_message_placeholder.message'
                    ),
                },
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
                position: 0,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                automation_rule_apps: [],
                cv_app: undefined,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                move_app: undefined,
                motimate_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_AUTOMATION_RULE,
                position: 1,
                publisher_linked: undefined,
              },
            ],
            position: 2,
            name: this.i18n && this.i18n.t('application_status_interview'),
            color: '#0081b3',
            is_failure: false,
            is_success: false,
            is_waiting_list: false,
          },
          {
            apps: [
              {
                question_apps: [],
                cv_app: undefined,
                message_app: {
                  text:
                    this.i18n &&
                    this.i18n.t(
                      'application_status_offer_message_placeholder.message'
                    ),
                },
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
                position: 0,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                automation_rule_apps: [],
                cv_app: undefined,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                move_app: undefined,
                motimate_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_AUTOMATION_RULE,
                position: 1,
                publisher_linked: undefined,
              },
            ],
            position: 3,
            name: this.i18n && this.i18n.t('application_status_offer'),
            color: '#59b87f',
            is_failure: false,
            is_success: false,
            is_waiting_list: false,
          },
          {
            apps: [
              {
                question_apps: [],
                cv_app: undefined,
                message_app: {
                  text:
                    this.i18n &&
                    this.i18n.t(
                      'application_status_hired_message_placeholder.message'
                    ),
                },
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
                position: 0,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                cv_app: undefined,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_NPS,
                position: 1,
                publisher_linked: undefined,
                nps_app: { ...this.EMPTY_NPS_APP.nps_app } as JobProcessAppNps,
              },
            ],
            position: 4,
            name: this.i18n && this.i18n.t('application_status_hired'),
            color: '#ffa17a',
            is_failure: false,
            is_success: true,
            is_waiting_list: false,
          },
          {
            apps: [
              {
                question_apps: [],
                cv_app: undefined,
                message_app: {
                  text:
                    this.i18n &&
                    this.i18n.t(
                      'application_status_declined_message_placeholder.message'
                    ),
                },
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
                position: 0,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                cv_app: undefined,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_NPS,
                position: 1,
                publisher_linked: undefined,
                nps_app: { ...this.EMPTY_NPS_APP.nps_app } as JobProcessAppNps,
              },
            ],
            position: 5,
            name: this.i18n && this.i18n.t('application_status_declined'),
            color: '#c10043',
            is_failure: true,
            is_success: false,
            is_waiting_list: false,
          },
          {
            apps: [
              {
                question_apps: [],
                cv_app: undefined,
                message_app: {
                  text:
                    this.i18n &&
                    this.i18n.t(
                      'application_status_waiting_list_message_placeholder.message'
                    ),
                },
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_MESSAGE,
                position: 0,
                publisher_linked: undefined,
              },
              {
                question_apps: [],
                cv_app: undefined,
                message_app: undefined,
                note_message_app: undefined,
                onsite_interview_app: undefined,
                motimate_app: undefined,
                move_app: undefined,
                app_type: JobProcessAppChoices.PROCESS_APP_NPS,
                position: 1,
                nps_app: { ...this.EMPTY_NPS_APP.nps_app } as JobProcessAppNps,
              },
            ],
            position: 6,
            name: this.i18n && this.i18n.t('application_status_waiting_list'),
            color: '#46bbb3',
            is_failure: false,
            is_success: false,
            is_waiting_list: true,
          },
        ],
      },
    };
  }
}
