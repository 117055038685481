/* tslint:disable:max-classes-per-file */
import { WsCommand } from './ws.interface';
import { IApplication } from './application.interface';

export enum ApplicationCommand {
  JOIN = 'join',
  LEAVE = 'leave',
  APPLICATION_CHANGED = 'application_changed',
}

export class ApplicationJoinCommand extends WsCommand {
  public job_id: number;

  constructor(job_id: number) {
    super(ApplicationCommand.JOIN);
    this.job_id = job_id;
  }
}

export class ApplicationLeaveCommand extends WsCommand {
  public job_id: number;

  constructor(job_id: number) {
    super(ApplicationCommand.LEAVE);
    this.job_id = job_id;
  }
}

export class ApplicationChangedCommand extends WsCommand {
  public job_id: number;
  public application: IApplication;

  constructor(job_id: number, application: IApplication) {
    super(ApplicationCommand.APPLICATION_CHANGED);
    this.job_id = job_id;
    this.application = application;
  }
}
