import { IFinnAdvertisement } from './finn-advertisement.interface';
import { ServerResponse } from './utils.interface';
import { IArbetsformedlingen } from './arbetsformedlingen.interface';
import {
  IFacebookAd,
  IFacebookAdResponse,
  IFacebookAdStatus,
  IFacebookError,
} from './facebook-ads.interface';

export interface IJobSourcing {
  id?: number;
  job_boards?: IJobBoard[];
  publisher_is_draft?: boolean;
  publisher_is_published?: boolean;
  publisher_linked?: number;
  publisher_modified_at?: string;
  publisher_published_at?: string;
  created_at?: string;
  updated_at?: string;
  job_id?: number;
}

export interface IEquestBoard {
  draft_id: number;
  requisition_number: number;
}

export interface IJobBoard {
  id: number;
  job_board_specification?: IJobBoardSpecification;
  job_board_specification_id?: number;
  finn_ad: IFinnAdvertisement | null;
  arbetsformedlingen_ad: IArbetsformedlingen | null;
  facebook_ad?: IFacebookAd | null;
  equest_board: IEquestBoard | null;
  created_at: string;
  updated_at: string;
  enabled: boolean;
  deleted: boolean | null;
  configured: boolean;
  order_line?: IOrderLine;
  order_line_id: number | null;
  job_sourcing: number;
  publisher_linked: number;
  // Nav fields
  nav_sector?: string;
  nav_posting_response?: any;
  nav_ad_status_responses?: any[];
  nav_errors?: any[];
  // Arbetsformedlingen field
  arbetsformedlingen_error_message?: string;
  // Local field
  disabled: boolean;
  // Facebook errors
  facebook_ad_status_responses?: IFacebookAdResponse[];
  facebook_errors?: IFacebookError[];
}

export interface IOrderLine extends ServerResponse {
  product: IProduct;
  unit_price: number;
  quantity: number;
  amount: number;
}

export enum IJobBoardSpecificationTagId {
  NORWAY = 2,
  SWEDEN = 3,
  FREE = 4,
  DENMARK = 5,
  FINLAND = 6,
  GLOBAL = 7,
}

export interface IJobBoardSpecificationTag {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface ICampaignUrl {
  job_id?: number;
  job_board_id?: number;
  url?: string;
  link_with_parameters?: string;
  job_ad_link_with_parameters?: string;
}

export interface IJobBoardSpecification {
  id: number;
  product: IProduct;
  product_id: number;
  provider: IProvider;
  tags: IJobBoardSpecificationTag[];
  can_be_added_many_times: boolean;
  integrated: boolean;
  url: string;
}

export interface IProduct {
  id: number;
  name: string;
  description: string;
  code: IProvider;
  image: string;
  amount: number;
  active: boolean;
  enabled_by_default: boolean;
  agreement_url?: string;
}

export enum IProvider {
  CODE_FINN = 'FINN',
  CODE_FINN_SUPPORT = 'FINN_SUPPORT',
  CODE_NAV = 'NAV',
  CODE_EQUEST = 'BOARD_EQUEST',
  CODE_INDEED = 'INDEED',
  CODE_MOJOB_SOCIAL_MEDIA_BOOST = 'SOCIAL_MEDIA',
  CODE_MOJOB_FEED = 'BOARD_MOJOB_FEED',
  CODE_MOJOB_FULL_SUPPORT = 'mojob_full_support',
  CODE_MOJOB_CAREER_PAGE = 'BOARD_MOJOB_CAREER_PAGE',
  CODE_PLATSBANKEN = 'platsbanken',
  CODE_CAREERBUILDER = 'careerbuilder',
  CODE_BLOCKET = 'blocket',
  CODE_DUUNITORI = 'duunitori',
  CODE_MONSTER = 'monster',
  CODE_TE_PAVELUT = 'te_pavelut',
  CODE_STEPSTONE = 'stepstone',
  CODE_JOBINDEX = 'jobindex',
  CODE_JOBNET = 'jobnet',
  CODE_BOARD_GOOGLE_JOBS = 'BOARD_GOOGLE_JOBS',
  CODE_FACEBOOK_ADS = 'facebookads',
  CODE_ARBETSFORMEDLINGEN = 'arbetsformedlingen',
}

// used when passing events between business components
export interface ISourcingChannelActionEvent {
  action: SourcingChannelAction;
  board: IJobBoard;
}

// maps a sourcing channel id to an error string
export interface SourcingChannelErrors {
  [key: number]: { error: string; detail?: string };
}

// user can interact with sourcing channel card in these ways:
export enum SourcingChannelAction {
  ON = 'ON',
  OFF = 'OFF',
  TOGGLE = 'TOGGLE',
  OPEN = 'OPEN',
  MANAGE = 'MANAGE',
  PREVIEW = 'PREVIEW',
}
