import { action, computed, observable } from 'mobx';
import {
  IUser,
  IUserDocument,
  IUserEducation,
  IUserExperience,
  IUsernameUpdate,
  SocialAccount,
  GenderOptions,
} from '../mj-models/user.interface';
import UserApi from '../mj-api/user.api';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import INotificationStore from '../mj-store/notification.store';
import IApplicationStore from './application.store';
import { IMediaImage } from '../mj-models/media.interface';
import { UpdatePhonePopupState } from '../mj-models/update-phone-popup.interface';
import OAuthCredential = firebase.auth.OAuthCredential;
import OAuthProvider = firebase.auth.OAuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import ITermsAndConditions from '../mj-models/terms.interface';
import IJobStore from './job.store';
import IUiStore from './ui.store';
import { IApplication } from '../mj-models/application.interface';
import IHistory from '../mj-models/history.interface';
import IDynamicProcessStore from '../mj-store/dynamic-process.store';
import { JobProcessAppCvChoices } from '../mj-models/process.interface';
import { getSupportedBrowserLanguage } from '../mj-utils/application.utils';
import { Languages } from '../mj-models/user.interface';
import { IAttribute } from '../mj-models/attribute.interface';

export enum UserState {
  signedIn,
  signedOut,
  signingIn,
  signingUp,
}

export interface UploadUserDocumentResult {
  userDocuments: IUserDocument[];
  success: boolean;
  error?: string;
}

export interface ParseCVResult {
  userDocuments: IUserDocument[];
  user: IUser;
  success: boolean;
  error?: string;
}

export default interface IUserStore {
  user: IUser;
  userState?: UserState;
  userDocuments: IUserDocument[];
  historyExperiences: IHistory[];
  historyEducations: IHistory[];
  seekerApplications: IApplication[];
  showNoUserFoundPopup: boolean;
  age?: number | null;
  recaptchaSiteKey?: string;
  login: () => Promise<boolean>;
  loginWithToken: (token: string) => void;
  dashboardLogin: () => Promise<boolean>;
  continueWithProvider: (provider: string, useRedirect: boolean) => void;
  dashboardContinueWithProvider: (
    provider: string,
    useRedirect: boolean
  ) => Promise<boolean>;
  checkRedirectResult: () => void;
  dashboardCheckRedirectResult: () => Promise<boolean>;
  logout: () => void;
  signUp: () => Promise<boolean>;
  signupWithUser: (user: IUser) => Promise<boolean>;
  dashboardSignup: () => Promise<boolean>;
  createApplicantLead: (jobId: number) => void;
  updateUser: () => void;
  updateUserFromObject: (user: IUser) => Promise<IUser | undefined>;
  updateUserPromise: () => Promise<boolean>;
  checkIfUserExist: () => void;
  dashboardCheckIfUserExist: () => Promise<boolean>;
  doesUserExist: (username: string) => Promise<boolean>;
  retrieveRecaptchaSiteKey: () => void;
  sendPhoneVerificationCode: (
    recaptchaToken: string,
    setProfilePopupState?: boolean
  ) => void;
  verifyPhoneVerificationCode: (
    verificationCode: string,
    setProfilePopupState?: boolean
  ) => Promise<boolean>;
  getUserDocuments: () => void;
  deleteUserDocument: (documentId: number) => void;
  uploadUserDocument: (file: File) => Promise<boolean>;
  fetchSocialAccounts: (unitId: number) => Promise<SocialAccount[]>;
  getUser: () => void;
  continueWithToken: () => Promise<string>;
  dashboardContinueWithToken: () => Promise<boolean>;
  parseCV: (file: File) => Promise<void>;
  updateExperiences: (fields: IHistory[]) => void;
  updateEducations: (fields: IHistory[]) => void;
  updateUserFields: (user: any) => void;
  retrieveMagicToken: () => Promise<string | undefined>;
  sendResetPassword: () => void;
  i18n: any;

  notAcceptedTermsAndConditions: ITermsAndConditions[];
  acceptTermsAndConditions: () => Promise<boolean>;
  changeUserPassword: (password: string) => Promise<void>;
  deleteUserAccount: () => Promise<void>;
  changeUserUsername: (username: string) => Promise<void>;
  resetPassword: (password: string, token: string) => Promise<boolean>;
  resetPasswordReturnToken: (
    password: string,
    token: string
  ) => Promise<string | undefined>;
  acceptInvitation: (
    password: string,
    token: string,
    baseApplicationId: number
  ) => Promise<{ token: string } | false>;

  getSeekerApplications: () => Promise<boolean>;
  getCurrentUser: () => Promise<IUser | undefined>;
  validateAuthLinkToken: (
    authLinkToken: string
  ) => Promise<
    { token: string; id: number; last_password_change: string } | undefined
  >;
  getIntercomHash: () => Promise<string>;
  resetStore: () => void;

  updateUserProfilePicture(result: IMediaImage): void;
  setEducationValidity(educations: IUserEducation[]): IUserEducation[];
  setExperienceValidity(experiences: IUserExperience[]): IUserExperience[];
  deleteUserDocumentModification(
    userDocuments: IUserDocument[],
    documentId: number
  ): Promise<IUserDocument[]>;
  updateUserProfilePictureModification(user: IUser, result: IMediaImage): IUser;
  uploadUserDocumentModification(
    userDocuments: IUserDocument[],
    file: File
  ): Promise<UploadUserDocumentResult>;
  parseCVModification(
    userDocuments: IUserDocument[],
    user: IUser,
    file: File
  ): Promise<ParseCVResult>;
  notifyAboutError(error: any): void;
  historyExperiencesToHistory(experiences: IUserExperience[]): IHistory[];
  historyEducationsToHistory(educations: IUserEducation[]): IHistory[];
  getFirebaseAuthInstance(): firebase.auth.Auth;
  getNameFromAttribute(attribute: IAttribute | undefined): string;
}

export class UserStore implements IUserStore {
  @computed
  public get age(): number | null {
    if (this.user.user_profile && this.user.user_profile.date_of_birth) {
      const db = new Date(this.user.user_profile.date_of_birth);
      const timeDiff = Math.abs(Date.now() - db.getTime());
      return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    } else {
      return null;
    }
  }

  @observable public user: IUser;
  @observable public userState?: UserState = UserState.signedOut;
  @observable public userDocuments: IUserDocument[] = [];
  @observable public recaptchaSiteKey?: string;
  @observable public phoneVerificationResult: any;
  @observable public notAcceptedTermsAndConditions: ITermsAndConditions[] = [];
  @observable public showNoUserFoundPopup: boolean = false;

  @observable public seekerApplications: IApplication[] = [];

  public i18n: any;
  private firebaseAuth?: firebase.auth.Auth;
  private isNewUser: boolean = false;

  constructor(
    private userApi: UserApi,
    private router: any,
    private notificationStore: INotificationStore,
    private applicationStore: IApplicationStore,
    private jobStore: IJobStore,
    private uiStore: IUiStore,
    private dynamicProcessStore: IDynamicProcessStore,
    private firebaseAuthInjection?: firebase.auth.Auth
  ) {
    if (firebaseAuthInjection) {
      this.firebaseAuth = firebaseAuthInjection;
    }
    this.user = {
      user_profile: {
        address: '',
        phone: {
          phone_number: '',
          country_code: '',
          verified: false,
        },
      },
      educations: [],
      experiences: [],
    };
  }

  public getNameFromAttribute(attribute: IAttribute | undefined): string {
    if (!attribute) {
      return '';
    }
    if (
      this.user &&
      this.user.user_profile &&
      this.user.user_profile.language
    ) {
      const key = `name_${this.user.user_profile.language}`;
      if (({ ...attribute } as any)[key]) {
        return ({ ...attribute } as any)[key];
      }
    }
    return attribute.name;
  }

  public historyExperiencesToHistory(
    experiences: IUserExperience[]
  ): IHistory[] {
    return experiences.map((e) => ({
      secondary: e.experience_attribute
        ? e.experience_attribute.name
        : e.position,
      primary: e.place_of_work_attribute
        ? e.place_of_work_attribute.name
        : e.company,
      description: e.description,
      startDate: e.start_date,
      tertiary: '',
      quarternary: '',
      endDate: e.end_date,
      isCurrent: e.is_current,
      isOpen: e.isOpen,
      isValid: e.isValid,
      experience_attribute: e.experience_attribute
        ? { ...e.experience_attribute }
        : undefined,
      experience_attribute_id: e.experience_attribute_id,
      place_of_work_attribute: e.place_of_work_attribute
        ? { ...e.place_of_work_attribute }
        : undefined,
      place_of_work_attribute_id: e.place_of_work_attribute_id,
      education_attribute_id: undefined,
      educational_institution_attribute_id: undefined,
    }));
  }

  @computed
  public get historyExperiences(): IHistory[] {
    return this.historyExperiencesToHistory(this.user.experiences);
  }

  public isAttributeOnly2Levels(attribute: IAttribute | undefined): boolean {
    if (!attribute) {
      return false;
    }
    return (
      [
        'education_certificates_',
        'education_diplomas_',
        'education_courses_',
      ].some((path) => attribute.path?.includes(path)) || 
      ['primary education', 'basic education'].includes(attribute.parent!.name.toLowerCase())
    );
  }

  public getTopLevelEducationAttribute(
    attribute: IAttribute | undefined
  ): IAttribute | undefined {
    if (!attribute) {
      return undefined;
    }
    const hasOnly2Levels = this.isAttributeOnly2Levels(attribute);
    if (!attribute.parent) {
      return undefined;
    }
    return hasOnly2Levels ? attribute.parent! : attribute.parent!.parent!;
  }

  public historyEducationsToHistory(educations: IUserEducation[]): IHistory[] {
    return educations.map((e) => {
      const topLevelEducationParent =
        this.getTopLevelEducationAttribute(e.education_attribute) || e.education_type_attribute;
      return {
        secondary:
          e.educational_institution_attribute &&
          e.educational_institution_attribute.name
            ? this.getNameFromAttribute(e.educational_institution_attribute)
            : e.school,
        primary:
          e.education_attribute &&
          e.education_attribute.parent &&
          !this.isAttributeOnly2Levels(e.education_attribute)
            ? this.getNameFromAttribute(e.education_attribute.parent)
            : e.degree,
        description: e.description,
        startDate: e.start_date,
        tertiary: e.education_attribute && !!topLevelEducationParent && !['basic education', 'primary education'].includes(topLevelEducationParent.name.toLowerCase())
          ? this.getNameFromAttribute(e.education_attribute)
          : '',
        quarternary: !!topLevelEducationParent
          ? this.getNameFromAttribute(topLevelEducationParent)
          : '',
        endDate: e.end_date,
        isCurrent: e.is_current,
        isOpen: e.isOpen,
        isValid: e.isValid,
        education_attribute_id: e.education_attribute_id,
        educational_institution_attribute_id:
          e.educational_institution_attribute_id,
        education_attribute: e.education_attribute
          ? { ...e.education_attribute }
          : undefined,
        educational_institution_attribute: e.educational_institution_attribute
          ? { ...e.educational_institution_attribute }
          : undefined,
        // help variables
        education_type_attribute: topLevelEducationParent
          ? { ...topLevelEducationParent }
          : undefined,
        // Degree attribute is empty when education attribute is only 2 levels (diplomas courses etc)
        degree_attribute:
          e.education_attribute &&
          !this.isAttributeOnly2Levels(e.education_attribute) &&
          e.education_attribute.parent
            ? JSON.parse(JSON.stringify(e.education_attribute.parent!))
            : undefined,
      };
    });
  }

  @computed
  public get historyEducations(): IHistory[] {
    return this.historyEducationsToHistory(this.user.educations);
  }

  @action('GET APPLICATIONS')
  public getSeekerApplications = async (): Promise<boolean> => {
    try {
      const response = await this.userApi.getSeekerApplications();
      this.seekerApplications = response.results;
      return true;
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('GET FIREBASE AUTH INSTANCE')
  public getFirebaseAuthInstance = (): firebase.auth.Auth => {
    return this.firebaseAuth!;
  };

  @action('UPDATE EXPERIENCES')
  public updateExperiences(fields: IHistory[]): void {
    const experiences = fields.map(
      (field) =>
        ({
          description: field.description,
          position: field.secondary,
          company: field.primary,
          start_date: field.startDate,
          end_date: field.endDate,
          is_current: field.isCurrent,
          isOpen: field.isOpen,
          isValid: field.isValid,
          experience_attribute: JSON.parse(
            JSON.stringify({ ...field.experience_attribute })
          ),
          experience_attribute_id: field.experience_attribute_id,
          place_of_work_attribute: JSON.parse(
            JSON.stringify({ ...field.place_of_work_attribute })
          ),
          place_of_work_attribute_id: field.place_of_work_attribute_id,
        } as IUserExperience)
    );
    this.user = {
      ...this.user,
      experiences: [...this.setExperienceValidity(experiences)],
    };
  }

  @action('UPDATE EDUCATIONS')
  public updateEducations(fields: IHistory[]): void {
    const educations = fields.map(
      (field) =>
        ({
          description: field.description,
          degree: field.primary,
          school: field.secondary,
          start_date: field.startDate,
          end_date: field.endDate,
          is_current: field.isCurrent,
          isOpen: field.isOpen,
          isValid: field.isValid,
          education_attribute_id: field.education_attribute_id,
          educational_institution_attribute_id:
            field.educational_institution_attribute_id,
          education_attribute: JSON.parse(
            JSON.stringify({ ...field.education_attribute })
          ),
          education_type_attribute: JSON.parse(JSON.stringify({ name: field.quarternary })),
          educational_institution_attribute: JSON.parse(
            JSON.stringify({
              ...field.educational_institution_attribute,
            })
          ),
        } as IUserEducation)
    );
    this.user = {
      ...this.user,
      educations: [...this.setEducationValidity(educations)],
    };
  }

  @action('UPDATE USER FIELDS')
  public updateUserFields(user: any): void {
    this.user = { ...this.user, ...user };
  }

  @action('CHANGE USER PASSWORD')
  public changeUserPassword = async (password: string) => {
    try {
      const resp = await this.userApi.changeUserPassword(password);
    } catch (error) {
      console.log(error);
    }
  };

  @action('CHANGE USER USERNAME')
  public changeUserUsername = async (username: string) => {
    try {
      const { id } = this.user;
      if (id) {
        const resp = await this.userApi.changeUserUsername({
          id,
          username,
        } as IUsernameUpdate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action('DELETE USER ACCOUNT')
  public deleteUserAccount = async () => {
    try {
      const { id } = this.user;
      if (id) {
        const resp = await this.userApi.deleteUserAccount(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action('LOGIN WITH EMAIL')
  public login = async () => {
    if (this.user.email && this.user.password) {
      try {
        const resp = await this.userApi.loginWithEmail(
          this.user.email,
          this.user.password
        );
        const canLogin = await this._continueLogin();

        return canLogin;
      } catch (error) {
        this.notifyAboutError(
          this.i18n.t('snack__email_or_pass_not_valid.message')
        );
        return false;
      }
    }

    return false;
  };

  @action('DASHBOARD LOGIN WITH EMAIL')
  public dashboardLogin = async (): Promise<boolean> => {
    if (this.user.email && this.user.password) {
      let userEmailExists = null;
      try {
        userEmailExists = await this.userApi.checkIfUserExist(
          this.user.email.toLowerCase()
        );
        if (userEmailExists) {
          this.showNoUserFoundPopup = false;
          const resp = await this.userApi.loginWithEmail(
            this.user.email.toLowerCase(),
            this.user.password
          );
          return true;
        }
        return false;
      } catch (error) {
        if (!userEmailExists) {
          this.showNoUserFoundPopup = true;
        } else {
          this.notifyAboutError(
            this.i18n.t('snack__email_or_pass_not_valid.message')
          );
        }
        return false;
      }
    } else {
      return false;
    }
  };

  @action('LOGIN WITH TOKEN')
  public loginWithToken = (token: string) => {
    this.userApi.setToken(token);
  };

  @action('GET USER')
  public getUser = async () => {
    try {
      this.user = await this.userApi.getUser();
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  @action('GET CURRENT USER')
  public getCurrentUser = async (): Promise<IUser | undefined> => {
    try {
      return await this.userApi.getUser();
    } catch (error) {
      this.notifyAboutError(error);
      return undefined;
    }
  };

  @action('VALIDATE AUTH LINK TOKEN')
  public validateAuthLinkToken = async (
    authLinkToken: string
  ): Promise<
    { token: string; id: number; last_password_change: string } | undefined
  > => {
    try {
      return await this.userApi.validateAuthLinkToken(authLinkToken);
    } catch (error) {
      return undefined;
    }
  };

  @action('DASHBOARD CONTINUE WITH TOKEN')
  public dashboardContinueWithToken = async (): Promise<boolean> => {
    try {
      this.user = await this.userApi.getUser();
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('CONTINUE WITH TOKEN')
  public continueWithToken = async (): Promise<string> => {
    if (await this.applicationStore.checkIfApplicationAlreadyExist()) {
      this.notificationStore.showMessageWithAction(
        this.i18n.t('snack__application_already_exist.message'),
        this.i18n.t('snack__click_here_to_see_application.message'),
        () => {
          this.router.push({ name: 'applications' }).catch((err: any) => {});
        }
      );
      return 'login';
    } else {
      this.notAcceptedTermsAndConditions =
        await this.userApi.fetchNotAcceptedTerms();
      if (
        this.notAcceptedTermsAndConditions &&
        this.notAcceptedTermsAndConditions.length
      ) {
        return 'confirm-privacy-terms';
      } else {
        return 'edit-resume';
      }
    }
  };

  @action('ACCEPT TERMS AND CONDITIONS')
  public acceptTermsAndConditions = async (): Promise<boolean> => {
    try {
      await this.userApi.acceptTerms(
        this.notAcceptedTermsAndConditions.map(({ id }) => id!)
      );
      this.notAcceptedTermsAndConditions = [];
      return true;
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('CHECK_IF_USER_EXISTS')
  public checkIfUserExist = async () => {
    if (!this.user.email) {
      this.userState = UserState.signedOut;
      return;
    }
    try {
      await this.userApi.checkIfUserExist(this.user.email);
      this.userState = UserState.signingIn;
    } catch (error) {
      this.userState = UserState.signingUp;
    }
  };

  @action('DASHBOARD CHECK IF USER EXISTS')
  public dashboardCheckIfUserExist = async (): Promise<boolean> => {
    try {
      await this.userApi.checkIfUserExist(this.user.email!);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('DOES USER EXIST')
  public doesUserExist = async (username: string): Promise<boolean> => {
    try {
      await this.userApi.checkIfUserExist(username);
      return true;
    } catch (error) {
      return false;
    }
  };

  @action('CREATE APPLICANT LEAD')
  public createApplicantLead = async (jobId: number) => {
    try {
      await this.userApi.createApplicantLead({
        email: this.user.email! || this.user.username!,
        phone: this.user.user_profile.phone
          ? this.user.user_profile.phone.phone_number!
          : '',
        job_id: jobId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action('UPDATE_USER')
  public updateUser = async () => {
    try {
      /*
      it's possible to provide full social media url ('https://twitter.com/example')
      OR just the id ('example'), below we make sure that we always send a url
      */
      const profileUrls = this.getProfileUrls(this.user);

      const resp: IUser = await this.userApi.updateUser({
        ...this.user,
        user_profile: {
          ...this.user.user_profile,
          ...this.changeIdsToUrls(profileUrls),
        },
      });

      this.user = {
        ...this.user,
        ...resp,
        user_profile: resp.user_profile
          ? { ...resp.user_profile }
          : { ...this.user.user_profile },
      };
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  @action('UPDATE_USER')
  public updateUserPromise = async (): Promise<boolean> => {
    try {
      /*
      it's possible to provide full social media url ('https://twitter.com/example')
      OR just the id ('example'), below we make sure that we always send a url
      */
      const profileUrls = this.getProfileUrls(this.user);

      const resp: IUser = await this.userApi.updateUser({
        ...this.user,
        user_profile: {
          ...this.user.user_profile,
          ...this.changeIdsToUrls(profileUrls),
        },
      });

      this.user = {
        ...this.user,
        ...resp,
        user_profile: resp.user_profile
          ? { ...resp.user_profile }
          : { ...this.user.user_profile },
      };
      return true;
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('UPDATE USER FROM OBJECT')
  public updateUserFromObject = async (
    user: IUser
  ): Promise<IUser | undefined> => {
    try {
      const profileUrls = this.getProfileUrls(user);
      return await this.userApi.updateUser({
        ...user,
        user_profile: {
          ...user.user_profile,
          ...this.changeIdsToUrls(profileUrls),
        },
      });
    } catch (error) {
      this.notifyAboutError(error);
      return undefined;
    }
  };

  @action('SEND_RESET_PASSWORD')
  public sendResetPassword = async () => {
    try {
      await this.userApi.sendResetPassword(this.user.email!);
      this.notificationStore.showMessage(
        this.i18n.t('snack__check_your_email_to_reset_password.message')
      );
    } catch (error) {
      this.notifyAboutError(
        this.i18n.t('snack__provide_valid_email_to_reset_password.message')
      );
    }
  };

  @action('RESET PASSWORD RETURN TOKEN')
  public resetPasswordReturnToken = async (
    password: string,
    token: string
  ): Promise<string | undefined> => {
    try {
      const tokenObj = await this.userApi.resetPassword(password, token);
      this.notificationStore.showMessage(this.i18n.t('generic__success'));
      return tokenObj.token;
    } catch (error) {
      this.notifyAboutError(error);
      return undefined;
    }
  };

  @action('RESET PASSWORD')
  public resetPassword = async (
    password: string,
    token: string
  ): Promise<boolean> => {
    try {
      await this.userApi.resetPassword(password, token);
      return true;
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('ACCEPT INVITATION')
  public acceptInvitation = async (
    password: string,
    token: string,
    baseApplicationId: number
  ): Promise<{ token: string } | false> => {
    try {
      return await this.userApi.acceptInvitation(
        password,
        token,
        baseApplicationId
      );
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('SEND_PHONE_VERIFICATION_CODE')
  public sendPhoneVerificationCode = async (
    recaptchaToken: string,
    setProfilePopupState = false
  ) => {
    try {
      this.phoneVerificationResult =
        await this.userApi.sendPhoneVerificationCode(
          recaptchaToken,
          this.user.user_profile.phone.country_code,
          this.user.user_profile.phone.phone_number
        );
      if (setProfilePopupState) {
        this.uiStore.changeUpdatePhonePopupState(
          UpdatePhonePopupState.CONFIRM_NUMBER
        );
      }
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  @action('RETRIEVE_RECAPTCHA_SITE_KEY')
  public retrieveRecaptchaSiteKey = async () => {
    try {
      const result = await this.userApi.retrieveRecaptchaSiteKey();
      this.recaptchaSiteKey = result.site_key;
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  @action('VERIFY_PHONE_VERIFICATION_CODE')
  public verifyPhoneVerificationCode = async (
    verificationCode: string,
    setProfilePopupState = false
  ) => {
    try {
      this.phoneVerificationResult =
        await this.userApi.verifyPhoneVerificationCode(verificationCode);
      if (setProfilePopupState) {
        this.uiStore.changeUpdatePhonePopupState(UpdatePhonePopupState.HIDDEN);
        this.notificationStore.showMessage(
          this.i18n.t('generic__changes_applied.message')
        );
      }
      return true;
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('SIGN UP USER')
  public signUp = async () => {
    try {
      const email = this.user.email;
      const password = this.user.password;
      if (email && password) {
        const browserLang = getSupportedBrowserLanguage();
        this.user = await this.userApi.createUser({
          educations: [],
          experiences: [],
          email,
          username: email,
          password,
          user_profile: {
            registered_on_web: true,
            phone: {
              verified: false,
              phone_number: '',
              country_code: '47',
            },
            language: (browserLang as Languages) || Languages.en,
          },
        });
        await this.userApi.loginWithEmail(email, password);

        this.isNewUser = true;

        await this._continueLogin();

        return true;
      }
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }

    return false;
  };

  @action('DASHBOARD SIGN UP USER')
  public dashboardSignup = async (): Promise<boolean> => {
    try {
      const email = this.user.email;
      const password = this.user.password;
      if (email && password) {
        const browserLang = getSupportedBrowserLanguage();
        this.user = await this.userApi.createUser({
          educations: [],
          experiences: [],
          email,
          username: email,
          password,
          user_profile: {
            registered_on_web: true,
            phone: {
              verified: false,
              phone_number: '',
              country_code: '47',
            },
            language: (browserLang as Languages) || Languages.en,
          },
        });

        this.isNewUser = true;

        await this.userApi.loginWithEmail(email, password);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('SIGN UP WITH USER')
  public signupWithUser = async (user: IUser) => {
    this.user = user;
    try {
      const email = this.user.email;
      const password = this.user.password;
      if (email && password) {
        this.user = await this.userApi.createUser(user);
        await this.userApi.loginWithEmail(email, password);

        this.isNewUser = true;

        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('LOGOUT')
  public logout = () => {
    try {
      this.userApi.logoutUser();
      this.resetStore();
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  @action('RETRIEVE MAGIC TOKEN')
  public retrieveMagicToken = async (): Promise<string | undefined> => {
    try {
      return await this.userApi.retrieveMagicToken();
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  @action('GET_USER_DOCUMENTS')
  public getUserDocuments = async () => {
    try {
      const userDocuments = await this.userApi.getUserDocuments();
      this.userDocuments = userDocuments.map((val: IUserDocument) => {
        val.isDone = false;
        val.isLoading = false;
        val.deletable = true;
        return val;
      });
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  public async deleteUserDocumentModification(
    userDocuments: IUserDocument[],
    documentId: number
  ) {
    let modifiedUserDocuments = userDocuments.map((ud: IUserDocument) => {
      if (ud.id === documentId) {
        ud.isDone = false;
        ud.isLoading = true;
        ud.deletable = false;
      } else {
        ud.isDone = true;
        ud.isLoading = false;
        ud.deletable = false;
      }
      return ud;
    });

    await this.userApi.deleteUserDocument(documentId);

    modifiedUserDocuments = modifiedUserDocuments.filter(
      (ud) => ud.id !== documentId
    );

    return modifiedUserDocuments.map((ud: IUserDocument) => {
      ud.isDone = true;
      ud.isLoading = false;
      ud.deletable = true;
      return ud;
    });
  }

  @action('DELETE_USER_DOCUMENT')
  public deleteUserDocument = async (documentId: number) => {
    try {
      this.userDocuments = await this.deleteUserDocumentModification(
        this.userDocuments,
        documentId
      );
    } catch (error) {
      this.notifyAboutError(error);
    }
  };

  public updateUserProfilePictureModification(
    user: IUser,
    result: IMediaImage
  ) {
    return {
      ...user,
      user_profile: {
        ...user.user_profile,
        profile_picture_media_id: result.id,
        profile_picture_thumbnail: result.image_thumbnail,
      },
    } as IUser;
  }

  @action('UPDATE USER PROFILE PICTURE LOCALLY')
  public updateUserProfilePicture(result: IMediaImage): void {
    this.user = this.updateUserProfilePictureModification(this.user, result);
  }

  @action('CHECK REDIRECT RESULTS')
  public checkRedirectResult = async () => {
    const result = await this.firebaseAuth!.getRedirectResult();
    const currentUser = this.firebaseAuth!.currentUser;
    if (currentUser && currentUser.displayName && result.credential) {
      await this._continueSocialLogin(
        result.credential.providerId,
        result.credential!
      );
    }
  };

  @action('DASHBOARD REDIRECT RESULTS')
  public dashboardCheckRedirectResult = async (): Promise<boolean> => {
    try {
      const result = await this.firebaseAuth!.getRedirectResult();
      const currentUser = this.firebaseAuth!.currentUser;
      if (currentUser && currentUser.displayName && result.credential) {
        await this._dashboardContinueSocialLogin(
          result.credential.providerId,
          result.credential!
        );
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.notifyAboutError(error);
      return false;
    }
  };

  @action('CONTINUE WITH PROVIDER')
  public continueWithProvider = async (
    providerType: string,
    useRedirect: boolean
  ) => {
    const provider = this._getProviderFromType(providerType);
    provider.setCustomParameters({
      display: 'popup',
    });
    try {
      if (useRedirect) {
        await this.firebaseAuth!.signInWithRedirect(provider);
      } else {
        const result = await this.firebaseAuth!.signInWithPopup(provider);
        await this._continueSocialLogin(providerType, result.credential!);
      }
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        const pendingCred = error.credential;
        const email = error.email;
        const methods = await this.firebaseAuth!.fetchSignInMethodsForEmail(
          email
        );
        const newProvider = this._getProviderFromType(methods[0]);
        newProvider.setCustomParameters({
          display: 'popup',
        });
        if (useRedirect) {
          await this.firebaseAuth!.signInWithRedirect(newProvider);
        } else {
          const newResult = await this.firebaseAuth!.signInWithPopup(
            newProvider
          );
          const newCredential = await newResult.user!.linkWithCredential(
            pendingCred
          );
          await this._continueSocialLogin(
            providerType,
            newCredential.credential!
          );
        }
      } else {
        this.notifyAboutError(error);
      }
    }
  };

  @action('DASHBOARD CONTINUE WITH PROVIDER')
  public dashboardContinueWithProvider = async (
    providerType: string,
    useRedirect: boolean
  ): Promise<boolean> => {
    const provider = this._getProviderFromType(providerType);
    provider.setCustomParameters({
      display: 'popup',
    });
    try {
      if (useRedirect) {
        await this.firebaseAuth!.signInWithRedirect(provider);
        // TODO: CheckIfThisWorks with a facebook browser
        return false;
      } else {
        const result = await this.firebaseAuth!.signInWithPopup(provider);
        await this._dashboardContinueSocialLogin(
          providerType,
          result.credential!
        );
        return true;
      }
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        const pendingCred = error.credential;
        const email = error.email;
        const methods = await this.firebaseAuth!.fetchSignInMethodsForEmail(
          email
        );
        const newProvider = this._getProviderFromType(methods[0]);
        newProvider.setCustomParameters({
          display: 'popup',
        });
        if (useRedirect) {
          await this.firebaseAuth!.signInWithRedirect(newProvider);
          // TODO: CheckIfThisWorks with a facebook browser
          return false;
        } else {
          const newResult = await this.firebaseAuth!.signInWithPopup(
            newProvider
          );
          const newCredential = await newResult.user!.linkWithCredential(
            pendingCred
          );
          await this._dashboardContinueSocialLogin(
            providerType,
            newCredential.credential!
          );
          return true;
        }
      } else {
        this.notifyAboutError(error);
        return false;
      }
    }
  };

  public async parseCVModification(
    userDocuments: IUserDocument[],
    user: IUser,
    file: File
  ): Promise<ParseCVResult> {
    const temp_id = Math.floor(Math.random() * 50000) + 1;
    try {
      const newDocument: IUserDocument = {
        id: temp_id,
        filename: file.name,
        parsed_cv: {
          educations: [],
          experiences: [],
          user_profile: {
            phone: {
              verified: false,
              phone_number: '',
              country_code: '',
            },
          },
        },
        isLoading: true,
        isDone: false,
        deletable: false,
      };
      let modifiedUserDocuments = [...userDocuments, newDocument];
      const formData = new FormData();
      formData.append('file', file);
      const parsedDocument = await this.userApi.parseCV(formData);
      parsedDocument.isLoading = false;
      parsedDocument.isDone = true;
      parsedDocument.deletable = true;
      const modifiedUser = {
        ...this.patchUser(user, parsedDocument.parsed_cv),
      };
      modifiedUserDocuments = [
        ...modifiedUserDocuments.filter((ud) => ud.id !== temp_id),
        parsedDocument,
      ];

      return {
        userDocuments: modifiedUserDocuments,
        user: modifiedUser,
        success: true,
      };
    } catch (error) {
      return { userDocuments, user, success: false, error: error as string };
    }
  }

  @action('PARSE_CV')
  public parseCV = async (file: File) => {
    const upload = await this.parseCVModification(
      this.userDocuments,
      this.user,
      file
    );

    if (upload.success) {
      this.userDocuments = upload.userDocuments;
      this.user = upload.user;
    } else if (upload.error) {
      this.notifyAboutError(upload.error);
    }
  };

  public async uploadUserDocumentModification(
    userDocuments: IUserDocument[],
    file: File
  ): Promise<UploadUserDocumentResult> {
    const temp_id = Math.floor(Math.random() * 50000) + 1;
    try {
      const newDocument: IUserDocument = {
        id: temp_id,
        filename: file.name,
        parsed_cv: {
          educations: [],
          experiences: [],
          user_profile: {
            phone: {
              verified: false,
              phone_number: '',
              country_code: '',
            },
          },
        },
        isLoading: true,
        isDone: false,
        deletable: false,
      };
      const modifiedUserDocuments = [...userDocuments, newDocument];
      const formData = new FormData();
      formData.append('file', file);
      const parsedDocuments = await this.userApi.uploadUserDocument(formData);
      const documents = parsedDocuments.map((doc: IUserDocument) => {
        return {
          ...doc,
          isLoading: false,
          isDone: true,
          deletable: true,
        };
      });

      return {
        userDocuments: [
          ...modifiedUserDocuments.filter((ud) => ud.id !== temp_id),
          ...documents,
        ],
        success: true,
      };
    } catch (error) {
      return { userDocuments, success: false, error: error as string };
    }
  }

  @action('UPLOAD USER DOCUMENT')
  public uploadUserDocument = async (file: File): Promise<boolean> => {
    const upload = await this.uploadUserDocumentModification(
      this.userDocuments,
      file
    );

    this.userDocuments = upload.userDocuments;

    if (!upload.success) {
      this.notifyAboutError(upload.error);
    }

    return upload.success;
  };

  @action('FETCH SOCIAL ACCOUNTS')
  public fetchSocialAccounts = async (
    unitId: number
  ): Promise<SocialAccount[]> => {
    try {
      const page = await this.userApi.fetchSocialAccounts(unitId);
      return page.results;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  @action('GET INTERCOM HASH')
  public getIntercomHash = async (): Promise<string> => {
    try {
      return await this.userApi.getIntercomHash();
    } catch (error) {
      return '';
    }
  };

  @action('RESET STORE')
  public resetStore() {
    this.user = {
      user_profile: {
        address: '',
        phone: {
          phone_number: '',
          country_code: '',
          verified: false,
        },
      },
      educations: [],
      experiences: [],
    };
    this.userState = UserState.signedOut;
    this.userDocuments = [];
    this.notAcceptedTermsAndConditions = [];
    this.seekerApplications = [];
  }

  public notifyAboutError(error: any) {
    if (typeof error === 'string' || error instanceof String) {
      this.notificationStore.showMessage(error as string);
    } else if (error.response) {
      const detailError = error.response.data.detail;
      if (error.response.data.non_field_errors) {
        const nonFieldError = error.response.data.non_field_errors[0];
        if (nonFieldError) {
          this.notificationStore.showMessage(nonFieldError);
        }
      } else if (detailError) {
        this.notificationStore.showMessage(detailError);
      } else if (error.response.data.password) {
        this.notificationStore.showMessage(error.response.data.password[0]);
      } else {
        this.notificationStore.showMessage(error.response.data);
      }
    }
  }

  public setEducationValidity(educations: IUserEducation[]): IUserEducation[] {
    return educations.map((edu: IUserEducation) => {
      edu.isValid = !!(
        edu.education_attribute &&
        edu.education_attribute.path &&
        edu.educational_institution_attribute &&
        edu.educational_institution_attribute.path &&
        edu.start_date &&
        edu.start_date.length > 0
      );
      return edu;
    });
  }

  public setExperienceValidity(
    experiences: IUserExperience[]
  ): IUserExperience[] {
    return experiences.map((exp: IUserExperience) => {
      exp.isValid = !!(
        exp.experience_attribute &&
        exp.experience_attribute.path &&
        exp.place_of_work_attribute &&
        exp.place_of_work_attribute.path &&
        exp.start_date &&
        exp.start_date.length > 0
      );
      return exp;
    });
  }

  private async _continueLogin() {
    if (await this.applicationStore.checkIfApplicationAlreadyExist()) {
      this.notificationStore.showMessageWithAction(
        this.i18n.t('snack__application_already_exist.message'),
        this.i18n.t('snack__click_here_to_see_application.message'),
        () => {
          this.router.push({ name: 'applications' }).catch((err: any) => {});
        }
      );

      return false;
    } else {
      const resp: IUser = await this.userApi.getUser();
      this.user = {
        ...this.user,
        ...resp,
        user_profile: resp.user_profile
          ? { ...resp.user_profile }
          : { ...this.user.user_profile },
      };
      this.notAcceptedTermsAndConditions =
        await this.userApi.fetchNotAcceptedTerms();

      if (
        this.notAcceptedTermsAndConditions &&
        this.notAcceptedTermsAndConditions.length
      ) {
        this.router
          .push({
            name: 'confirm-privacy-terms',
          })
          .catch((err: any) => {});
      } else if (this.isNewUser) {
        this.router.push({ name: 'edit-resume' }).catch((err: any) => {});
      } else {
        const resumeFields = this.dynamicProcessStore.jobProcessAppCv;
        let hasRequiredFieldEmpty = false;

        await this.getUserDocuments();

        if (resumeFields) {
          for (const fieldKey of Object.keys(resumeFields)) {
            if (
              resumeFields[fieldKey as keyof typeof resumeFields] ===
              JobProcessAppCvChoices.PROCESS_APP_CV_CHOICE_REQUIRED
            ) {
              if (fieldKey === 'cv_file_attached') {
                if (!this.userDocuments || this.userDocuments.length === 0) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'about_me') {
                if (!this.user.user_profile.bio) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'birth_date') {
                if (!this.user.user_profile.date_of_birth) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'education') {
                if (
                  !this.user.educations ||
                  this.user.educations.length === 0
                ) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'experience') {
                if (
                  !this.user.experiences ||
                  this.user.experiences.length === 0
                ) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'email') {
                // sometimes there is empty email property but username not
                if (!this.user.email && !this.user.username) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'gender') {
                if (this.user.user_profile.gender === GenderOptions.unknown) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'name') {
                if (!this.user.first_name || !this.user.last_name) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'phone_number') {
                if (!this.user.user_profile.phone.phone_number) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'photo') {
                if (!this.user.user_profile.profile_picture_media_id) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              } else if (fieldKey === 'social_media_contact') {
                if (
                  !this.user.user_profile.linkedin_profile_url &&
                  !this.user.user_profile.facebook_profile_url &&
                  !this.user.user_profile.instagram_profile_url &&
                  !this.user.user_profile.twitter_profile_url
                ) {
                  hasRequiredFieldEmpty = true;
                  break;
                }
              }
            }
          }
        }

        if (hasRequiredFieldEmpty) {
          this.router.push({ name: 'edit-resume' }).catch((err: any) => {});
        } else if (this.dynamicProcessStore.hasScreeningQuestions) {
          this.router.push({ name: 'edit-questions' }).catch((err: any) => {});
        } else {
          await this.applicationStore.sendApplication();
        }
      }
    }

    return true;
  }

  private _getProviderFromType(
    providerType: string
  ): GoogleAuthProvider | FacebookAuthProvider | OAuthProvider {
    switch (providerType) {
      case 'google.com':
        return new firebase.auth.GoogleAuthProvider();
      case 'facebook.com':
        return new firebase.auth.FacebookAuthProvider();
      default:
        return new firebase.auth.OAuthProvider(providerType);
    }
  }

  private async _dashboardContinueSocialLogin(
    providerType: string,
    credential: OAuthCredential
  ) {
    switch (providerType) {
      case 'google.com':
        await this.userApi.loginWithGoogle(
          credential.accessToken!,
          credential.idToken!
        );
        break;
      case 'facebook.com':
        await this.userApi.loginWithFacebook(
          credential.accessToken!,
          credential.idToken!
        );
        break;
    }
  }

  private async _continueSocialLogin(
    providerType: string,
    credential: OAuthCredential
  ) {
    switch (providerType) {
      case 'google.com':
        await this.userApi.loginWithGoogle(
          credential.accessToken!,
          credential.idToken!
        );
        await this._continueLogin();
        break;
      case 'facebook.com':
        await this.userApi.loginWithFacebook(
          credential.accessToken!,
          credential.idToken!
        );
        await this._continueLogin();
        break;
    }
  }

  private patchUser(currentUser: IUser, patch_user?: IUser) {
    if (!patch_user) {
      return currentUser;
    }
    if (patch_user.first_name && !currentUser.first_name) {
      currentUser.first_name = patch_user.first_name;
    }
    if (patch_user.last_name && !currentUser.last_name) {
      currentUser.last_name = patch_user.last_name;
    }
    if (!currentUser.email && patch_user.email) {
      currentUser.email = patch_user.email;
    }
    if (patch_user.educations) {
      patch_user.educations = this.setEducationValidity(patch_user.educations);
      if (currentUser.educations) {
        currentUser.educations = [
          ...currentUser.educations!,
          ...patch_user.educations,
        ];
      } else {
        currentUser.educations = patch_user.educations;
      }
    }
    if (patch_user.experiences) {
      patch_user.experiences = this.setExperienceValidity(
        patch_user.experiences
      );
      if (currentUser.experiences) {
        currentUser.experiences = [
          ...currentUser.experiences!,
          ...patch_user.experiences,
        ];
      } else {
        currentUser.experiences = patch_user.experiences;
      }
    }
    if (patch_user.user_profile && currentUser.user_profile) {
      if (patch_user.user_profile.bio && !currentUser.user_profile.bio) {
        currentUser.user_profile.bio = patch_user.user_profile.bio;
      }
      if (
        patch_user.user_profile.date_of_birth &&
        !currentUser.user_profile.date_of_birth
      ) {
        currentUser.user_profile.date_of_birth =
          patch_user.user_profile.date_of_birth;
      }
      if (
        patch_user.user_profile.profile_picture_media_id &&
        !currentUser.user_profile.profile_picture_media_id
      ) {
        currentUser.user_profile.profile_picture_media_id =
          patch_user.user_profile.profile_picture_media_id;
        currentUser.user_profile.profile_picture_thumbnail =
          patch_user.user_profile.profile_picture_thumbnail;
      }
    }
    return currentUser;
  }

  private changeIdsToUrls(profileUrls: any) {
    const newUrls = { ...profileUrls };

    // regex based on https://github.com/lorey/social-media-profiles-regexs

    const {
      linkedin_profile_url,
      facebook_profile_url,
      instagram_profile_url,
      twitter_profile_url,
    } = profileUrls;

    let pattern = new RegExp(/http(s)?:\/\/([w]+.)?linkedin.com\/in\//);
    newUrls.linkedin_profile_url = this.addPrefixIfNoMatch(
      pattern,
      linkedin_profile_url,
      'https://www.linkedin.com/in/'
    );

    pattern = new RegExp(/http(s)?:\/\/(www.)?(facebook|fb).com\//);
    newUrls.facebook_profile_url = this.addPrefixIfNoMatch(
      pattern,
      facebook_profile_url,
      'https://www.facebook.com/'
    );

    pattern = new RegExp(/https?:\/\/(www.)?instagram.com\//);
    newUrls.instagram_profile_url = this.addPrefixIfNoMatch(
      pattern,
      instagram_profile_url,
      'https://www.instagram.com/'
    );

    pattern = new RegExp(/http(s)?:\/\/(.*.)?twitter.com\//);
    newUrls.twitter_profile_url = this.addPrefixIfNoMatch(
      pattern,
      twitter_profile_url,
      'https://twitter.com/'
    );
    return newUrls;
  }

  private addPrefixIfNoMatch(pattern: RegExp, text: string, prefix: string) {
    if (!text) {
      return ''; // don't add a prefix since it's an empty url
    } else if (pattern.test(text)) {
      return text;
    } else {
      return `${prefix}${encodeURI(text)}`;
    }
  }

  private getProfileUrls(user: IUser) {
    return {
      linkedin_profile_url: user.user_profile.linkedin_profile_url,
      facebook_profile_url: user.user_profile.facebook_profile_url,
      instagram_profile_url: user.user_profile.instagram_profile_url,
      twitter_profile_url: user.user_profile.twitter_profile_url,
    };
  }
}
