import { IUnitGalleryImage } from '../mj-models/business-settings.interface';
import {
  IGalleryParams,
  IUnsplashPhotoInfo,
} from '../mj-models/job-listing.interface';
import { IPage } from '../mj-models/utils.interface';
import { IMediaFile, IMediaImage } from '../mj-models/media.interface';
import BaseApi from './base.api';

export default class MediaApi extends BaseApi {
  public createImage = (formData: FormData): Promise<IMediaImage> =>
    this.axios
      .post(`${this.baseUrl}media/images/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);
  public createFile = (formData: FormData): Promise<IMediaFile> =>
    this.axios
      .post(`${this.baseUrl}media/files/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);
  public patchImageMedia = (imageMedia: IMediaImage): Promise<IMediaImage> =>
    this.axios
      .patch(`${this.baseUrl}media/images/${imageMedia.id}/`, imageMedia)
      .then((response) => response.data);

  public fetchImageGallery = (
    unitId: number,
    params: IGalleryParams
  ): Promise<IPage<IUnitGalleryImage>> =>
    this.axios
      .get(
        `${this.baseUrl}units/${unitId}/image-gallery/?page=${
          params.page
        }&search=${params.searchValue || ''}`
      )
      .then((response) => response.data);

  public addToImageGallery = (
    unitId: number,
    imageId: number
  ): Promise<IUnitGalleryImage> =>
    this.axios
      .post(`${this.baseUrl}units/${unitId}/image-gallery/`, {
        unit_id: unitId,
        image_id: imageId,
      })
      .then((response) => response.data);

  public deleteFromImageGallery = (
    unitId: number,
    galleryImageId: number
  ): Promise<IUnitGalleryImage> =>
    this.axios
      .delete(`${this.baseUrl}units/${unitId}/image-gallery/${galleryImageId}/`)
      .then((response) => response.data);

  public getUnsplashPhotos = (
    customParams: IGalleryParams
  ): Promise<IPage<IUnsplashPhotoInfo>> => {
    const params = `&page=${customParams.page}&per_page=30`;
    const unsplashApi = 'https://api.unsplash.com/search/photos';
    const clientId =
      '01d26ffed1d2fc06474873a447b67c4e5ed1aca0f36b02865798c991a196a7dc';

    return this.axios
      .get(
        `${unsplashApi}?client_id=${clientId}&query=${
          customParams.searchValue || 'office'
        }&${params}`
      )
      .then((response) => response.data);
  };
}
