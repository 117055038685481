import BaseApi from './base.api';
import { IGDPRSettings } from '../mj-models/business-settings.interface';

export default class GDPRApi extends BaseApi {
  public fetchUnitGDPRSettings = (
    gdprSettingsId: number
  ): Promise<IGDPRSettings> =>
    this.axios
      .get(`${this.baseUrl}gdpr/${gdprSettingsId}/`)
      .then((response) => response.data);

  public updateUnitGDPRSettings = (
    gdprSettings: IGDPRSettings
  ): Promise<IGDPRSettings> =>
    this.axios
      .put(`${this.baseUrl}gdpr/${gdprSettings.id}/`, gdprSettings)
      .then((response) => response.data);

  public createUnitGDPRSettings = (
    gdprSettings: IGDPRSettings
  ): Promise<IGDPRSettings> =>
    this.axios
      .post(`${this.baseUrl}gdpr/`, gdprSettings)
      .then((response) => response.data);
}
