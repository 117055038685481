import { IJobBoard, IProvider } from '../mj-models/job-sourcing.interface';
import { NpsRespondentType } from '../mj-models/nps.interface';

import { IUser } from '../mj-models/user.interface';
import { IJob } from '../mj-models/job.interface';
import { IVueI18n } from 'vue-i18n';
import {
  ApplicationProcessFormInternalRefererChoices,
  IApplication,
} from '../mj-models/application.interface';
import { IMessage } from '../mj-models/ws.interface';
import { ScriveAppStatus } from '../mj-models/process.interface';
import { IScriveDocument, IScriveField, IScriveParty } from '../mj-models/scrive.interface';
import { IAttribute } from '../mj-models/attribute.interface';


export function compareDate(date1: Date, date2: Date): number {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  if (d1.getTime() === d2.getTime()) {
    return 0;
  } else if (d1 > d2) {
    return 1;
  } else if (d1 < d2) {
    return -1;
  } else {
    return 0;
  }
}

export function getAmountOfDaysPassed(specifiedDate: Date): number {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  specifiedDate.setHours(0, 0, 0, 0);

  // @ts-ignore
  const timeDifference = Math.abs(currentDate - specifiedDate);
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

export function getAmountOfDaysLeft(specifiedDate: Date): number {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  specifiedDate.setHours(0, 0, 0, 0);

  // @ts-ignore
  const timeDifference = Math.abs(specifiedDate - currentDate);
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

// to know when specified amounts of minutes passed between 2 dates
export function minutesIntervalExceeded(
  firstDateString: string,
  secondDateString: string,
  intervalInMin: number = 5
) {
  if (!!firstDateString && !!secondDateString) {
    const firstDate = new Date(Date.parse(firstDateString));
    const secondDate = new Date(Date.parse(secondDateString));

    if (Math.abs(firstDate.getTime() - secondDate.getTime()) / 60000 <= intervalInMin) {
      firstDate.setHours(0, 0, 0, 0); // now we need to compare dates without time
      secondDate.setHours(0, 0, 0, 0);

      return !(firstDate.valueOf === secondDate.valueOf);
    }
  }

  return true;
}

export function replaceAtId<T extends { id?: number | string }>(originalArray: T[], newObject: T) {
  // clone the array
  const newArray = JSON.parse(JSON.stringify(originalArray)) as T[];

  // replace at id
  const idx = newArray.findIndex((x) => x.id === newObject.id);

  if (idx === -1) {
    throw new Error(`Object with id ${newObject.id} not found in the array.`);
  }
  newArray[idx] = newObject;

  return newArray;
}

// sortByPriority(["First", "Second", "Third"], [0, 2, 1])
// returns ["First", "Third", "Second"]
export function sortByPriority<T>(originalArray: T[], priorityArray: number[]) {
  if (originalArray.length !== priorityArray.length) {
    throw new Error('Priority array should be the same length as the original array.');
  }

  return originalArray.sort((a: T, b: T) => {
    const aIndex = originalArray.indexOf(a);
    const bIndex = originalArray.indexOf(b);

    return priorityArray[aIndex] - priorityArray[bIndex];
  });
}

export function adjustSourcingChannelBoards(
  jobBoards: IJobBoard[],
  i18n: IVueI18n | null,
  unitName: string,
  unitLogoUrl: string
) {
  return jobBoards.map((job_board) => {
    const { product } = job_board.job_board_specification!;

    const code = product.code.toLowerCase();

    if (i18n) {
      const name = i18n.t(`product__${code}_name`, {
        unit_name: unitName,
      }) as string;

      const { integrated } = job_board.job_board_specification!;
      let description = '';
      if (code === IProvider.CODE_FACEBOOK_ADS) {
        description = i18n
          .t(`product__${code}_description`, {
            unit_name: unitName,
          })
          .toString();
      } else if (integrated) {
        description = i18n.t(`product__integrated_description`).toString();
      } else if (!integrated) {
        description = i18n
          .t(`product__unintegrated_description`, {
            channel_name: name,
          })
          .toString();
      }

      // some channels have custom descriptions
      if (
        [
          IProvider.CODE_BOARD_GOOGLE_JOBS,
          IProvider.CODE_MOJOB_FEED,
          IProvider.CODE_MOJOB_CAREER_PAGE,
          IProvider.CODE_EQUEST,
        ]
          .map((x) => x.toLowerCase())
          .includes(code as IProvider)
      ) {
        description = i18n.t(`product__${code}_description`) as string;
      }

      job_board.job_board_specification!.product.name = name;
      job_board.job_board_specification!.product.description = description;
    }

    let image: string = '';

    if (job_board.job_board_specification!.product!.image) {
      image = job_board.job_board_specification!.product.image.split('?')[0];
    }

    // dynamically display unit's logo in career page channel
    if (unitLogoUrl && code === IProvider.CODE_MOJOB_CAREER_PAGE.toLowerCase()) {
      image = unitLogoUrl;
    }

    job_board.job_board_specification!.product.image = image;

    return job_board;
  });
}

export function isBoardIntegrated(board: IJobBoard) {
  return board.job_board_specification?.integrated;
}

export function boardPreviewUrl(board: IJobBoard) {
  if (board.job_board_specification) {
    if (
      (board.job_board_specification.provider.toLowerCase() === IProvider.CODE_FINN.toLowerCase() ||
        board.job_board_specification.provider.toLowerCase() ===
          IProvider.CODE_FINN_SUPPORT.toLowerCase()) &&
      board.finn_ad &&
      board.finn_ad.ad_url
    ) {
      return board.finn_ad.ad_url!;
    }
    if (isBoardNav(board)) {
      if (board.nav_ad_status_responses && board.nav_ad_status_responses.length > 0) {
        const lastAdStatus =
          board.nav_ad_status_responses[board.nav_ad_status_responses.length - 1];
        if (lastAdStatus.status && lastAdStatus.status.url) {
          return lastAdStatus.status.url;
        }
      }
    }
  }
  return '';
}

export function isBoardFinn(board: IJobBoard) {
  return (
    board.job_board_specification &&
    [IProvider.CODE_FINN.toLowerCase(), IProvider.CODE_FINN_SUPPORT.toLowerCase()].includes(
      board.job_board_specification.provider.toLowerCase()
    )
  );
}

export function isBoardArbetsformedlingen(board: IJobBoard) {
  return (
    board.job_board_specification &&
    IProvider.CODE_ARBETSFORMEDLINGEN.toLowerCase() ===
      board.job_board_specification.provider.toLowerCase()
  );
}

export function isBoardNav(board: IJobBoard) {
  return (
    board.job_board_specification &&
    IProvider.CODE_NAV.toLowerCase() === board.job_board_specification.provider.toLowerCase()
  );
}

export function isBoardFacebookAd(board: IJobBoard) {
  return (
    board.job_board_specification &&
    IProvider.CODE_FACEBOOK_ADS.toLowerCase() ===
      board.job_board_specification.provider.toLowerCase()
  );
}

export function isEmailValid(email: string) {
  // tslint:disable-next-line:max-line-length
  const re =
    /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([._-]+)?([a-zA-Z0-9])([._-]+)?)*@(([a-zA-Z0-9-])+(\.))+([a-zA-Z]{2,4})+$/i;
  return re.test(String(email));
}

export function isNotPersonalEmail(email: string) {
  return [
    'gmail.com',
    'outlook.com',
    'hotmail.com',
    'live.com',
    'yahoo.com',
    'icloud.com',
    'live.se',
    'yahoo.no',
    'hotmail.se',
    'hotmail.no',
    'live.no',
    'online.no',
    'outlook.no',
  ].every((domain) => !email.endsWith(domain));
}

export function isPhoneValid(phoneNumber: string) {
  // tslint:disable-next-line:max-line-length
  const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i;

  return re.test(String(phoneNumber));
}

export function isRichTextFieldEmpty(content: string) {
  return content.length === 0 || content === '\n' || content === '↵';
}

export function omitField<T, K extends keyof T>(entity: T, prop: K): Omit<T, K> {
  const { [prop]: deleted, ...newState } = entity;
  return newState;
}

export function omitFields<T, K extends keyof T>(entity: T, props: K[]): Omit<T, K> {
  let result = entity as Omit<T, K>;
  props.forEach((prop) => {
    result = omitField(result, prop as unknown as keyof Omit<T, K>) as Omit<T, K>;
  });
  return result;
}

export const defaultAvatarURI = `data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15ZM15.0001 4.49992C17.4901 4.49992 19.5001 6.50992 19.5001 8.99992C19.5001 11.4899 17.4901 13.4999 15.0001 13.4999C12.5101 13.4999 10.5001 11.4899 10.5001 8.99992C10.5001 6.50992 12.5101 4.49992 15.0001 4.49992ZM6.00016 20.9698C7.93516 23.8798 11.2502 25.7998 15.0002 25.7998C18.7502 25.7998 22.0652 23.8798 24.0002 20.9698C23.9552 17.9848 17.9852 16.3498 15.0002 16.3498C12.0002 16.3498 6.04516 17.9848 6.00016 20.9698Z' fill='%23D8D8D8' /%3E%3C/svg%3E"`;

export function renameFile(file: File, newName: string) {
  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export function getNpsRespondentType(score: number) {
  if (score >= 9) {
    return NpsRespondentType.PROMOTER;
  } else if (score <= 6) {
    return NpsRespondentType.DETRACTOR;
  } else {
    return NpsRespondentType.PASSIVE;
  }
}

export function isUserManualCandidate(userData?: Partial<IUser>) {
  return !!userData?.user_profile?.is_dummy;
}

export function wasApplicationManuallyAdded(application: Partial<IApplication>) {
  return (
    application.internal_referer === ApplicationProcessFormInternalRefererChoices.MANUAL ||
    application.internal_referer ===
      ApplicationProcessFormInternalRefererChoices.MANUAL_ACCEPTED_INVITATION
  );
}

export function getApplicantDisplayedName(
  userData?: Partial<{
    full_name: string;
    first_name: string;
    last_name: string;
    email: string;
    username: string;
  }>
) {
  let name = '';

  if (userData?.full_name) {
    name = userData.full_name;
  } else if (userData?.first_name || userData?.last_name) {
    name = `${userData.first_name || ''} ${userData.last_name || ''}`.trim();
  } else if (userData?.email) {
    name = userData.email;
  } else if (userData?.username) {
    name = userData.username;
  }

  return name;
}

export function getJobStatusTranslatedText(job: IJob, i18n: IVueI18n): string {
  let status: string = '';
  if (job.is_live) {
    status = i18n.t('generic__live.message') as string;
  }
  if (job.is_draft) {
    status = i18n.t('generic__draft.message') as string;
  }
  if (job.is_unpublished) {
    status = i18n.t('generic__unpublished.message') as string;
  }
  if (job.is_completed) {
    status = i18n.t('generic__completed.message') as string;
  }
  return status;
}

export function getApplicationSource(application: Partial<IApplication>): string {
  if (!application) {
    return '';
  }

  if (application.internal_referer) {
    if (
      application.internal_referer === ApplicationProcessFormInternalRefererChoices.MOBILE_JOB_AD
    ) {
      return 'Mojob mobile app';
    } else if (
      application.internal_referer === ApplicationProcessFormInternalRefererChoices.WEB_JOB_FEED
    ) {
      return 'Mojob web job feed';
    }
  }

  if (application.external_referer) {
    return application.external_referer;
  } else {
    return 'Mojob';
  }
}

interface RequestQueueItem<T> {
  getPromise: () => Promise<T>;
  key: number;
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}
export class RequestQueue<T> {
  public queue: Array<RequestQueueItem<T>> = [];
  private workingOnPromise = false;

  /**
   * Adds a Promise to a request queue in order to avoid duplicate requests.
   *
   * @param getPromise - The function that returns a Promise
   * @param key - Key to differentiate requests used to avoid duplicates
   */
  public enqueue(getPromise: () => Promise<T>, key: number) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        getPromise,
        resolve,
        reject,
        key,
      });
      this.dequeue();
    });
  }

  public remove(key: number) {
    const item = this.queue.find((q) => q.key === key);

    this.queue = this.queue.filter((q) => q.key !== key);

    return item;
  }

  public dequeue() {
    if (this.workingOnPromise) {
      return false;
    }
    const item = this.queue.shift();
    if (!item) {
      return false;
    }
    try {
      this.workingOnPromise = true;

      item
        .getPromise()
        .then((value) => {
          this.workingOnPromise = false;
          item.resolve(value);
          this.dequeue();
        })
        .catch((err) => {
          this.workingOnPromise = false;
          item.reject(err);
          this.dequeue();
        });
    } catch (err) {
      this.workingOnPromise = false;
      item.reject(err);
      this.dequeue();
    }
    return true;
  }
}

export function groupBy<K, V>(list: V[], keyGetter: (input: V) => K): Map<K, V[]> {
  const map = new Map<K, V[]>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function getAllPendingScrivePartyApprovers(parties: IScriveParty[]): IScriveParty[] {
  const candidateParty: IScriveParty | undefined = getCandidateScriveParty(parties);
  if (candidateParty) {
    return parties.filter(
      (party: IScriveParty) =>
        party.id !== candidateParty.id && party.signatory_role === 'approver' && !party.sign_time
    );
  }
  return [];
}

export function getAllPendingScrivePartySigners(parties: IScriveParty[]): IScriveParty[] {
  const candidateParty: IScriveParty | undefined = getCandidateScriveParty(parties);
  if (candidateParty) {
    return parties.filter(
      (party: IScriveParty) =>
        party.id !== candidateParty.id &&
        party.signatory_role === 'signing_party' &&
        !party.sign_time
    );
  }
  return [];
}

export function isCandidateParty(party: IScriveParty) {
  return (
    party.fields.find(
      (field: IScriveField) => field.name === 'is_candidate' && field.value === 'true'
    ) !== undefined
  );
}

export function getCandidateScriveParty(parties: IScriveParty[]): IScriveParty | undefined {
  return parties.find((party: IScriveParty) => {
    return (
      party.fields.find(
        (field: IScriveField) => field.name === 'is_candidate' && field.value === 'true'
      ) !== undefined
    );
  });
}

export function getScrivePartyFromEmail(
  parties: IScriveParty[],
  emailToCheck: string
): IScriveParty | undefined {
  if (!emailToCheck) {
    return undefined;
  }
  return parties.find((party: IScriveParty) => {
    /*
      Since the user that delivers API-keys is automatically added as a
      Scrive party with is_author - we must ignore this party.
      It is not possible to remove this user from the scrive party list.
     */
    return (
      !party.is_author &&
      party.fields.find(
        (field: IScriveField) => field.type === 'email' && field.value === emailToCheck
      ) !== undefined
    );
  });
}

export function getScrivePartyName(party: IScriveParty): string {
  let name = '';
  for (const field of party.fields) {
    if (field.type === 'name' && field.value) {
      name += field.value;
      if (field.order === 1) {
        // Is the first name
        name += ' ';
      }
    }
  }
  return name;
}

export function hasCandidateSignedDocument(parties: IScriveParty[]): boolean {
  const candidateParty: IScriveParty | undefined = getCandidateScriveParty(parties);
  return !!candidateParty && !!candidateParty.sign_time;
}

export function numberOfSigningOrApprovingScriveParties(parties: IScriveParty[]): number {
  return parties.filter((party: IScriveParty) => {
    return party.signatory_role === 'signing_party' || party.signatory_role === 'approver';
  }).length;
}

export function numberOfSigningOrApprovingScrivePartiesThatHasSigned(
  parties: IScriveParty[]
): number {
  return parties.filter((party: IScriveParty) => {
    return (
      (party.signatory_role === 'signing_party' || party.signatory_role === 'approver') &&
      !!party.sign_time
    );
  }).length;
}

export function getScriveSigningStatus(message: IMessage): ScriveAppStatus {
  if (!message.export_data || Object.keys(message.export_data).length === 0) {
    return ScriveAppStatus.IN_PROGRESS;
  }
  if (message.export_data.error_text) {
    return ScriveAppStatus.DOCUMENT_HAS_ERROR;
  }
  const scriveDocument: IScriveDocument = message.export_data! as IScriveDocument;
  if (scriveDocument.status === 'pending') {
    return ScriveAppStatus.DOCUMENT_PENDING;
  } else if (scriveDocument.status === 'timedout') {
    return ScriveAppStatus.DOCUMENT_TIMEDOUT;
  } else if (scriveDocument.status === 'rejected') {
    return ScriveAppStatus.DOCUMENT_REJECTED;
  } else if (scriveDocument.status === 'closed' && !!message.attachment_id) {
    return ScriveAppStatus.DOCUMENT_SEALED;
  } else if (scriveDocument.status === 'canceled') {
    return ScriveAppStatus.DOCUMENT_CANCELED;
  } else {
    return ScriveAppStatus.DOCUMENT_HAS_ERROR;
  }
}

export function isCurrentUserRecipient(message: IMessage, currentUsername: string): boolean {
  if (!message.export_data) {
    return false;
  }
  if (message.export_data.error_text && message.export_data.recipient_emails) {
    return (
      message.export_data!.recipient_emails!.findIndex(
        (email: string) => email === currentUsername
      ) !== -1
    );
  }
  const scriveDocument: IScriveDocument = message.export_data! as IScriveDocument;
  if (!scriveDocument.parties) {
    return false;
  }
  return getScrivePartyFromEmail(scriveDocument.parties, currentUsername) !== undefined;
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}

export function truncateText(str: string, len: number, addDots: boolean): string {
  return `${str.slice?.(0, len)}${addDots ? '...' : ''}`;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getLocaleAttributeName(attribute: IAttribute, locale: string): string {
  const localeName = attribute[`name_${locale}` as keyof IAttribute];
  if (typeof localeName === 'string') {
    return localeName;
  }
  return attribute.name;
}
