import IUnit from '../mj-models/unit.interface';

export function getFormattedTableInsightsValue(value: any, formatZeroValue: boolean = true) {
  if (!!value) {
    return value;
  } else if (value === null) {
    return '—';
  } else if (value === undefined) {
    return '—';
  } else if (value === 0 && formatZeroValue) {
    return '...';
  } else if (value === 0 && !formatZeroValue) {
    return '0';
  }
}

export function saveAnalyticsForSendApplication(jobId: number, unitWithAnalyticsAttributes: IUnit) {
  if (
    unitWithAnalyticsAttributes.facebook_pixel_id &&
    unitWithAnalyticsAttributes.facebook_pixel_conversion_id
  ) {
    try {
      const pixelConversionId: string = unitWithAnalyticsAttributes.facebook_pixel_conversion_id;
      // @ts-ignore
      window.fbq('track', `${pixelConversionId}`, {
        content_ids: jobId,
        content_name: jobId,
      });
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  if (
    unitWithAnalyticsAttributes.linkedin_pixel_id &&
    unitWithAnalyticsAttributes.linkedin_pixel_conversion_id
  ) {
    try {
      const pixelId: string = unitWithAnalyticsAttributes.linkedin_pixel_id;
      const pixelConversionId: string = unitWithAnalyticsAttributes.linkedin_pixel_conversion_id;
      const img = new Image();
      img.height = 1;
      img.width = 1;
      img.src = `https://px.ads.linkedin.com/collect/?pid=${pixelId}&conversionId=${pixelConversionId}&fmt=gif`;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  if (
    unitWithAnalyticsAttributes.xandr_pixel_id &&
    unitWithAnalyticsAttributes.xandr_pixel_conversion_id
  ) {
    try {
      const pixelId: string = unitWithAnalyticsAttributes.xandr_pixel_id;
      const pixelConversionId: string = unitWithAnalyticsAttributes.xandr_pixel_conversion_id;
      const pageViewImg = new Image();
      pageViewImg.height = 1;
      pageViewImg.width = 1;
      pageViewImg.src = `https://ib.adnxs.com/pixie?pi=${pixelId}&e=PageView&script=0`;
      // @ts-ignore
      window.pixie('event', 'PageView');
      const conversionImg = new Image();
      conversionImg.height = 1;
      conversionImg.width = 1;
      conversionImg.src = `https://ib.adnxs.com/pixie?pi=${pixelId}&e=Lead&ii=${jobId}&script=0`;
      // @ts-ignore
      window.pixie('event', `${pixelConversionId}`, {
        item_id: jobId,
      });
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  return true;
}

// Function to get query parameter value by name
export function getQueryParamValue(name: string): string | null {
  if (typeof window === 'undefined') {
    return null;
  }
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(window.location.href);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
