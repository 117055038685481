import { action, observable, runInAction } from 'mobx';
import { IAttribute } from '../mj-models/attribute.interface';
import AttributeApi from '../mj-api/attribute.api';
import { IESPage } from '../mj-models/utils.interface';
import {
  ITalentAttribute,
  ITalentAttributeRate,
} from '../mj-models/user-attribute.interface';

export default interface IAttributeStore {
  getAttributes: (
    searchText: string,
    pathFilter: string,
    level?: number
  ) => Promise<IESPage<IAttribute> | false>;
  createNewAttributeInPath: (
    name: string,
    path: string,
    extraData?: Partial<IAttribute>
  ) => Promise<IAttribute | false>;
  getTalentAttributes: (
    talentId: number
  ) => Promise<ITalentAttribute[] | false>;
  updateTalentAttribute: ($event: { value: ITalentAttribute }) => void;
  updateTalentAttributeRate: (
    talentAttributeRate: ITalentAttributeRate
  ) => void;
  saveTalentAttributes: (
    talentAttributes?: ITalentAttribute[]
  ) => Promise<boolean>;
  talentAttributes: ITalentAttribute[];
  saveTalentAttributesRates: (
    base_application_id: number,
    talentAttributes?: ITalentAttributeRate[]
  ) => Promise<boolean>;
  talentAttributesRates: ITalentAttributeRate[];
}

export class AttributeStore implements IAttributeStore {
  public attributeApi: AttributeApi;

  @observable
  public talentAttributes: ITalentAttribute[] = [];

  @observable
  public talentAttributesRates: ITalentAttributeRate[] = [];

  constructor(attributeApi: AttributeApi) {
    this.attributeApi = attributeApi;
  }

  @action('GET ATTRIBUTES')
  public getAttributes = async (
    searchText: string,
    pathFilter: string,
    level?: number
  ): Promise<IESPage<IAttribute> | false> => {
    try {
      return await this.attributeApi.getAttribute(
        searchText || '',
        pathFilter || '',
        level
      );
    } catch (error) {
      return false;
    }
  };

  @action('CREATE ATTRIBUTE IN PATH')
  public createNewAttributeInPath = async (
    name: string,
    path: string,
    extraData?: Partial<IAttribute>
  ): Promise<IAttribute | false> => {
    try {
      return await this.attributeApi.createAttribute(
        name,
        path,
        extraData || {}
      );
    } catch (error) {
      return false;
    }
  };

  @action('GET TALENT ATTRIBUTES')
  public getTalentAttributes = async (
    talentId: number
  ): Promise<ITalentAttribute[] | false> => {
    try {
      const talentAttributes = await this.attributeApi.getTalentAttributes(
        talentId
      );
      if (talentAttributes) {
        runInAction(() => {
          this.talentAttributes = talentAttributes;
        });
        return talentAttributes;
      }
      return [];
    } catch (error) {
      return false;
    }
  };

  @action('UPDATE STORE TALENT ATTRIBUTE')
  public updateTalentAttribute = ($event: { value: ITalentAttribute }) => {
    let newTalentAttributes = [...this.talentAttributes];
    newTalentAttributes = newTalentAttributes.filter(
      (ta) =>
        !(
          (ta.attribute?.id === $event.value.attribute?.id ||
            ta.attribute?.id === $event.value.attribute_id) &&
          (ta.talent?.id === $event.value.talent?.id ||
            ta.talent?.id === $event.value.talent_id)
        )
    );
    const updatedTalentAttribute = {
      id: $event.value.id,
      owns: $event.value.owns,
      obtained_from_date: $event.value.obtained_from_date,
      obtained_to_date: $event.value.obtained_to_date,
      validity_from_date: $event.value.validity_from_date,
      validity_to_date: $event.value.validity_to_date,
      author_id: $event.value.author_id,
      app_author: $event.value.app_author,
      app_author_id: $event.value.app_author_id,
      talent: $event.value.talent,
      talent_id: $event.value.talent_id,
      attribute: $event.value.attribute,
      attribute_id: $event.value.attribute_id,
      files: $event.value.files,
      file_ids: $event.value.file_ids,
      answer: $event.value.answer,
      not_saved: true,
    };
    this.talentAttributes = [...newTalentAttributes, updatedTalentAttribute];
  };

  @action('UPDATE STORE TALENT ATTRIBUTE RATE')
  public updateTalentAttributeRate = (tar: ITalentAttributeRate) => {
    let newTalentAttributeRates = [...this.talentAttributesRates];
    newTalentAttributeRates = newTalentAttributeRates.filter(
      (talentAttributeRate) =>
        !(
          talentAttributeRate.attribute?.id === tar.attribute?.id &&
          talentAttributeRate.applicant_id === tar.applicant_id &&
          talentAttributeRate.author_id === tar.author_id
        )
    );
    const updatedTalentAttributeRate: ITalentAttributeRate = {
      id: tar.id,
      rating: tar.rating,
      author_id: tar.author_id,
      applicant_id: tar.applicant_id,
      attribute: tar.attribute,
      talent_attribute_id: tar.talent_attribute_id,
      base_application_id: tar.base_application_id,
      not_saved: true,
    };
    this.talentAttributesRates = [
      ...newTalentAttributeRates,
      updatedTalentAttributeRate,
    ];
  };

  @action('SAVE TALENT ATTRIBUTES')
  public saveTalentAttributes = async (
    talentAttributes?: ITalentAttribute[]
  ): Promise<boolean> => {
    try {
      let localTalentAttributes = talentAttributes || [
        ...this.talentAttributes.values(),
      ];
      localTalentAttributes = localTalentAttributes.map((ta) => {
        delete ta.talent;
        delete ta.author;
        delete ta.app_author;
        delete ta.attribute;
        ta.obtained_from_date =
          ta.obtained_from_date instanceof Date
            ? ta.obtained_from_date?.toISOString().slice(0, 10) || undefined
            : ta.obtained_from_date;
        ta.obtained_to_date =
          ta.obtained_to_date instanceof Date
            ? ta.obtained_to_date?.toISOString().slice(0, 10) || undefined
            : ta.obtained_to_date;
        ta.validity_from_date =
          ta.validity_from_date instanceof Date
            ? ta.validity_from_date?.toISOString().slice(0, 10) || undefined
            : ta.validity_from_date;
        ta.validity_to_date =
          ta.validity_to_date instanceof Date
            ? ta.validity_to_date?.toISOString().slice(0, 10) || undefined
            : ta.validity_to_date;
        return ta;
      });
      const newTalentAttributes = localTalentAttributes.filter(
        (ta) => ta.id == null
      );
      let createdTalentAttributes: ITalentAttribute[] = [];
      if (newTalentAttributes.length > 0) {
        createdTalentAttributes =
          await this.attributeApi.bulkCreateTalentAttributes(
            newTalentAttributes
          );
      }
      const editedTalentAttributes = localTalentAttributes.filter(
        (ta) => ta.id != null && ta.not_saved
      );
      let updatedTalentAttributes: ITalentAttribute[] = [];
      if (editedTalentAttributes.length > 0) {
        updatedTalentAttributes =
          await this.attributeApi.bulkPatchTalentAttributes(
            editedTalentAttributes
          );
      }
      const savedTalentAttributes = [
        ...createdTalentAttributes,
        ...updatedTalentAttributes,
      ];
      runInAction(() => {
        this.talentAttributes = this.talentAttributes.filter(
          (ta) => !savedTalentAttributes.some((t) => t.id === ta.id)
        );
        this.talentAttributes = this.talentAttributes.concat(
          savedTalentAttributes
        );
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  @action('SAVE TALENT ATTRIBUTES RATES')
  public saveTalentAttributesRates = async (
    base_application_id: number,
    talentAttributeRates?: ITalentAttributeRate[]
  ): Promise<boolean> => {
    try {
      let localTalentAttributeRates = talentAttributeRates || [
        ...this.talentAttributesRates.values(),
      ];
      localTalentAttributeRates = localTalentAttributeRates.map((tar) => {
        if (!tar.talent_attribute_id) {
          const talentAttribute = this.talentAttributes.find(
            (ta) =>
              ta.attribute?.id === tar.attribute?.id &&
              ta.talent?.id === tar.applicant_id
          );
          if (!talentAttribute) {
            throw new Error('Talent attribute not found for this Rate!');
          }
          tar.talent_attribute_id = talentAttribute.id!;
        }
        tar.base_application_id = base_application_id;
        delete tar.author;
        delete tar.attribute;
        return tar;
      });
      const newTalentAttributeRates = localTalentAttributeRates.filter(
        (tar) => tar.id == null
      );
      let createdTalentAttributeRates: ITalentAttribute[] = [];
      if (newTalentAttributeRates.length > 0) {
        createdTalentAttributeRates =
          await this.attributeApi.bulkCreateTalentAttributeRates(
            newTalentAttributeRates
          );
      }

      runInAction(() => {
        this.talentAttributesRates = this.talentAttributesRates.filter(
          (ta) => !createdTalentAttributeRates.some((t) => t.id === ta.id)
        );
        this.talentAttributes = this.talentAttributes.concat(
          createdTalentAttributeRates
        );
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}
