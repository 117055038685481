import { action, observable } from 'mobx';
import { IMediaImage, IMediaFile } from '../mj-models/media.interface';
import MediaApi from '../mj-api/media.api';
import {
  IGalleryImage,
  IGalleryParams,
  IUnsplashPhotoInfo,
} from '../mj-models/job-listing.interface';
import { IUnitGalleryImage } from '../mj-models/business-settings.interface';
import INotificationStore from './notification.store';
import { eraseQueryParameters } from '../mj-utils/links.utils';

const DEFAULT_IMAGE_GALLERY_PARAMS: IGalleryParams = {
  searchValue: '',
  page: 1,
};

export default interface IMediaStore {
  imageGalleryUnsplashPhotos: IUnsplashPhotoInfo[];
  imageGalleryUnitImageGalleryPhotos: IGalleryImage[];

  uploadImage: (
    file: File,
    unit_id: number | null,
    type?: number
  ) => Promise<IMediaImage>;
  uploadFile: (file: File, unit_id: number | null) => Promise<IMediaFile>;
  patchImageMedia: (
    imageMedia: IMediaImage
  ) => Promise<IMediaImage | undefined>;

  fetchImageGallery: (
    unitId: number,
    params?: IGalleryParams
  ) => Promise<boolean>;
  addToImageGallery: (
    unitId: number,
    imageId: number
  ) => Promise<IUnitGalleryImage | undefined>;
  deleteFromImageGallery: (
    unitId: number,
    galleryImageId: number
  ) => Promise<IUnitGalleryImage | undefined>;
  getUnsplashPhotos(params?: IGalleryParams): Promise<boolean>;
  askIfAddToImageGallery(
    unitId: number,
    imageId: number,
    message_text: string,
    yes_text: string,
    no_text: string
  ): Promise<IUnitGalleryImage | undefined>;
}

export class MediaStore implements IMediaStore {
  @observable public imageGalleryUnsplashPhotos: IUnsplashPhotoInfo[] = [];
  @observable public imageGalleryUnitImageGalleryPhotos: IGalleryImage[] = [];

  private mediaApi: MediaApi;
  private notificationStore: INotificationStore;

  constructor(mediaApi: MediaApi, notificationStore: INotificationStore) {
    this.mediaApi = mediaApi;
    this.notificationStore = notificationStore;
  }

  @action('UPLOAD IMAGE')
  public uploadImage = async (
    file: File,
    unit_id: number | null,
    type?: number
  ): Promise<IMediaImage> => {
    const formData = new FormData();
    formData.append('image', file);
    if (type) {
      formData.append('type', type.toString(10));
    }
    if (unit_id) {
      formData.append('unit_id', unit_id.toString());
    }
    return await this.mediaApi.createImage(formData);
  };

  @action('UPLOAD FILE')
  public uploadFile = async (
    file: File,
    unit_id: number | null
  ): Promise<IMediaFile> => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      if (unit_id) {
        formData.append('unit_id', unit_id.toString());
      }
      return await this.mediaApi.createFile(formData);
    } catch (error) {
      return {
        id: null,
      };
    }
  };

  @action('PATCH IMAGE MEDIA')
  public patchImageMedia = async (
    imageMedia: IMediaImage
  ): Promise<IMediaImage | undefined> => {
    try {
      return await this.mediaApi.patchImageMedia(imageMedia);
    } catch (error) {
      return undefined;
    }
  };

  @action('FETCH IMAGE GALLERY')
  public fetchImageGallery = async (
    unitId: number,
    params = DEFAULT_IMAGE_GALLERY_PARAMS
  ): Promise<boolean> => {
    try {
      const response = await this.mediaApi.fetchImageGallery(unitId, params);

      const newPhotos = response.results.map(
        (galleryImage: IUnitGalleryImage) =>
          ({
            id: galleryImage.id,
            urls: {
              small: eraseQueryParameters(galleryImage.image!.image_thumbnail!),
              raw: eraseQueryParameters(galleryImage.image!.image_url!),
            },
            unit_id: galleryImage.unit_id,
            unit_name: galleryImage.unit!.name,
            image: galleryImage.image,
            width: galleryImage.image!.image_width,
            height: galleryImage.image!.image_height,
          } as IGalleryImage)
      );

      this.imageGalleryUnitImageGalleryPhotos =
        params.page === 1
          ? newPhotos
          : [...this.imageGalleryUnitImageGalleryPhotos, ...newPhotos];

      return true;
    } catch (error) {
      return false;
    }
  };

  @action('ADD TO IMAGE GALLERY')
  public addToImageGallery = async (
    unitId: number,
    imageId: number
  ): Promise<IUnitGalleryImage | undefined> => {
    try {
      return await this.mediaApi.addToImageGallery(unitId, imageId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('DELETE FROM IMAGE GALLERY')
  public deleteFromImageGallery = async (
    unitId: number,
    galleryImageId: number
  ): Promise<IUnitGalleryImage | undefined> => {
    try {
      return await this.mediaApi.deleteFromImageGallery(unitId, galleryImageId);
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return undefined;
    }
  };

  @action('GET UNSPLASH PHOTOS')
  public getUnsplashPhotos = async (
    params = DEFAULT_IMAGE_GALLERY_PARAMS
  ): Promise<boolean> => {
    try {
      const response = await this.mediaApi.getUnsplashPhotos(params);

      const newPhotos: IUnsplashPhotoInfo[] = response.results.map(
        (photo: IUnsplashPhotoInfo) => {
          const modifiedPhoto = { ...photo };

          modifiedPhoto.urls!.raw = `${modifiedPhoto.urls!.raw}&w=2100&q=40`;

          return modifiedPhoto;
        }
      );

      this.imageGalleryUnsplashPhotos =
        params.page === 1
          ? newPhotos
          : [...this.imageGalleryUnsplashPhotos, ...newPhotos];

      return true;
    } catch (error) {
      this.notificationStore.notifyAboutError(error);
      return false;
    }
  };

  @action('ASK IF ADD TO IMAGE GALLERY')
  public askIfAddToImageGallery = async (
    unitId: number,
    imageId: number,
    message_text: string,
    yes_text: string,
    no_text: string
  ): Promise<IUnitGalleryImage | undefined> =>
    new Promise((res) => {
      this.notificationStore.showMessageWithActions(
        message_text,
        [
          {
            name: yes_text,
            action: () => {
              res(this.addToImageGallery(unitId, imageId));
            },
            iconKey: 'done',
          },
          {
            name: no_text,
            action: () => {
              res(undefined);
            },
            iconKey: 'clear',
          },
        ],
        'info'
      );
    });
}
